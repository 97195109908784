export const demoSetup = {
  showAllActions: true,
  typeMainCouranteActionToShow: 'opened',
  defaultMapStyle: 'mapbox://styles/mapbox/satellite-v9',
  autoFocusOff: true,
  extraServices: [],
  categories: [
    {
      id: 1,
      color: '#3396F1',
      name: 'All',
      subcategories: [
        {
          id: 4,
          name: 'Earthquake',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 132,
          created_at: null,
          updated_at: '2021-05-18T09:26:34.999Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 161,
          name: 'Roadway Accidents',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 263,
          created_at: null,
          updated_at: '2021-07-07T14:50:46.455Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/roadway_accidents_icon.svg',
        },
        {
          id: 363,
          name: 'Electricity Incident',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:04.008Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/electricity.svg',
        },
        {
          id: 46,
          name: 'Tobacco Trafficking',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/tobacco.svg',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: '2021-03-22T06:53:34.431Z',
          updated_at: '2021-06-28T11:46:34.748Z',
          deleted_at: null,
        },
        {
          id: 27,
          name: 'Cocaine Trafficking',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/cocaine.svg',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: null,
          updated_at: '2021-06-28T11:49:40.159Z',
          deleted_at: null,
        },
        {
          id: 30,
          name: 'Terrorist Arrests/Captured/Killed Locations',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/arrests.svg',
          color: '#FFA500',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: '2021-03-19T14:39:44.827Z',
          updated_at: '2021-05-18T14:53:06.205Z',
          deleted_at: null,
        },
        {
          id: 35,
          name: 'Embassies Incidents',
          color: '#9F1122',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/embassies%20incidents.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 141,
          created_at: '2021-03-19T14:43:07.372Z',
          updated_at: '2021-05-18T12:59:59.455Z',
          deleted_at: null,
        },
      ],
    },
  ],
};

export const mediterrainSetup = {
  defaultMapStyle: 'mapbox://styles/mapbox/satellite-v9',
  defaultDynamicDataLocation: {
    id: 'region.18403589793676100',
    type: 'Feature',
    place_type: ['region'],
    relevance: 1,
    properties: {
      wikidata: 'Q3139',
      short_code: 'FR-06',
    },
    text: 'Alpes-Maritimes',
    place_name: 'Alpes-Maritimes, France',
    bbox: [
      6.6354020316523, 43.4107714236878, 7.71891997997037, 44.3612849482565,
    ],
    center: [7.16667, 43.83333],
    geometry: {
      type: 'Point',
      coordinates: [7.16667, 43.83333],
    },
    context: [
      {
        id: 'country.19008108158641660',
        wikidata: 'Q142',
        short_code: 'fr',
        text: 'France',
      },
    ],
  },
  limitGeometry: {
    type: 'Polygon',
    coordinates: [
      [
        [7.630053154268214, 45.33648989029282],
        [3.3339498009327637, 45.3496748060031],
        [3.3193548491544247, 41.19862879581921],
        [11.507122797279072, 41.165685955964456],
        [11.448742990162842, 45.33942013692855],
        [7.630053154268214, 45.33648989029282],
      ],
    ],
  },
  extraServices: [
    'analytics',
    'analytics.dynamic_data',
    'analytics.historical_data',
    'analytics.main_courante_flow_ratio',
  ],
  categories: [
    {
      id: 1,
      name: 'Phénomènes naturels',
      color: '#3396F1',
      subcategories: [
        {
          id: 4,
          name: 'Risque sismique',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 132,
          created_at: null,
          updated_at: '2021-05-18T09:26:34.999Z',
          deleted_at: null,
        },
        {
          id: 6,
          name: 'Inondations',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/floods_icon.svg',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 133,
          created_at: null,
          updated_at: '2021-05-19T09:54:55.693Z',
          deleted_at: null,
        },
        {
          id: 8,
          name: 'Feux de forêt',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/wildfires_icon.svg',
          color: '#B22222',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 134,
          created_at: null,
          updated_at: '2021-05-19T09:56:35.480Z',
          deleted_at: null,
        },
        {
          id: 7,
          name: 'Cyclones',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/cyclone_icon.svg',
          color: '#3396F1',
          description:
            'A tropical storm with winds of 74 miles (119 km) per hour or greater that can be several hundred miles/kilometres wide and is usually accompanied by rain, thunder, and lightning.',
          parent_id: 135,
          created_at: null,
          updated_at: '2021-06-18T15:36:17.860Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
        },
        {
          id: 159,
          name: 'Tempêtes',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/storm_icon.svg',
          color: '#3396F1',
          description: '',
          parent_id: 135,
          created_at: '2021-05-19T09:59:33.265Z',
          updated_at: '2021-05-25T14:06:48.478Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
        },
        {
          id: 155,
          name: 'Avalanche',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/avalanche_icon.svg',
          color: '#3396F1',
          description: '',
          parent_id: 133,
          created_at: '2021-05-19T09:54:36.309Z',
          updated_at: '2021-05-19T09:54:36.309Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
        },
        {
          id: 227,
          name: 'Vagues subversives',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/tidal_waves_icon.svg',
          color: '#3396F1',
          description: '',
          parent_id: 135,
          created_at: '2021-05-25T14:06:38.797Z',
          updated_at: '2021-05-25T14:06:38.797Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
        },
        {
          id: 250,
          name: 'Érosion côtière',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/coastal_flood_icon.svg',
          color: '#3396F1',
          description: '',
          parent_id: 6,
          created_at: '2021-06-22T09:20:33.372Z',
          updated_at: '2021-06-22T09:20:33.372Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
        },
        {
          id: 251,
          name: 'Canicule',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/heatwave_icon.svg',
          color: '#8B0000',
          description: '',
          parent_id: 156,
          created_at: '2021-06-22T09:21:10.810Z',
          updated_at: '2021-06-22T09:21:10.810Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
        },
        {
          id: 252,
          name: 'Grand froid',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/severe_cold_icon.svg',
          color: '#3396F1',
          description: '',
          parent_id: 156,
          created_at: '2021-06-22T09:21:22.570Z',
          updated_at: '2021-06-22T09:21:22.570Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
        },
        {
          id: 253,
          name: 'Vent',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/wind_icon.svg',
          color: '#3396F1',
          description: '',
          parent_id: 135,
          created_at: '2021-06-22T09:22:17.286Z',
          updated_at: '2021-06-22T09:22:17.286Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
        },
        {
          id: 254,
          name: 'Neige',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/snow_icon.svg',
          color: '#3396F1',
          description: '',
          parent_id: 135,
          created_at: '2021-06-22T09:22:27.046Z',
          updated_at: '2021-06-22T09:22:27.046Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
        },
        {
          id: 256,
          name: 'Crue soudaine',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/rain_flood_icon.svg',
          color: '#3396F1',
          description: '',
          parent_id: 255,
          created_at: '2021-06-22T09:24:59.364Z',
          updated_at: '2021-06-22T09:24:59.364Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
        },
        {
          id: 275,
          name: 'Glissement de terrains',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/mouvements.svg',
          color: '#3396F1',
          description: '',
          parent_id: 132,
          created_at: '2021-07-05T14:00:34.518Z',
          updated_at: '2021-07-05T14:00:34.518Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
        },
      ],
    },
    {
      id: 263,
      name: 'Evènements routiers',
      color: '#8B4513',
      subcategories: [
        {
          id: 161,
          name: 'Accident de la route',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/roadway_accidents_icon.svg',
          color: '#8B4513',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 263,
          created_at: '2021-05-19T10:07:15.422Z',
          updated_at: '2021-06-29T10:29:12.641Z',
          deleted_at: null,
        },
        {
          id: 266,
          name: 'Col fermé',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/mountain_pass_closed_icon.svg',
          color: '#8B4513',
          description: '',
          parent_id: 263,
          created_at: '2021-06-29T10:33:03.868Z',
          updated_at: '2021-06-29T10:34:47.889Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
        },
        {
          id: 264,
          name: 'Chantier routier',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/roadway_work_icon.svg',
          color: '#8B4513',
          description: '',
          parent_id: 263,
          created_at: '2021-06-29T10:31:32.346Z',
          updated_at: '2021-06-29T10:34:38.154Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
        },
        {
          id: 265,
          name: 'Limitation tonnage',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/tonnage_limit_icon.svg',
          color: '#8B4513',
          description: '',
          parent_id: 263,
          created_at: '2021-06-29T10:32:54.462Z',
          updated_at: '2021-06-29T10:34:43.466Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
        },
        {
          id: 267,
          name: 'Incident de la route info',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/roadway_incidents_icon.svg',
          color: '#8B4513',
          description: '',
          parent_id: 263,
          created_at: '2021-06-29T10:33:13.978Z',
          updated_at: '2021-06-29T10:34:52.548Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
        },
        {
          id: 268,
          name: 'Route barrée',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/prohibited_traffic_icon.svg',
          color: '#8B4513',
          description: '',
          parent_id: 263,
          created_at: '2021-06-29T10:33:21.968Z',
          updated_at: '2021-06-29T10:34:56.581Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
        },
        {
          id: 269,
          name: 'Danger routier',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/roadway_danger_icon.svg',
          color: '#8B4513',
          description: '',
          parent_id: 263,
          created_at: '2021-06-29T10:33:34.279Z',
          updated_at: '2021-06-29T10:35:01.087Z',
          deleted_at: null,
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
        },
      ],
    },
    {
      id: 182,
      color: '#9F1122',
      name: 'Incident armé',
      subcategories: [
        {
          id: 352,
          name: 'Incident armé - Misc',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-10-29T10:34:39.775Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 248,
          name: 'Incident armé : arme non-létales (gaz, canons à eau, etc.)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:30:32.010Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 247,
          name: `Incident à l'arme blanche ou contondante`,
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:28:25.157Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 222,
          name: 'Incident armé - petits calibres (bombardement)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-11T14:54:07.151Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 221,
          name: `Incident armé - armes légères (Fusils d'assaut, pistolets, fusil de précision)`,
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-14T14:28:23.911Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 179,
          name: 'Bombardement, engin explosif improvisé',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:26:38.443Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 175,
          name: 'Incident armé - armes lourdes (Tanks, Véhicules militaires blindés)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:22:59.025Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 36,
          name: 'Assassinat',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-05-18T10:52:23.557Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 121,
      color: '#1E5A90',
      name: `Incidents techniques`,
      subcategories: [
        {
          id: 364,
          name: 'Décès/Disparition',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 121,
          created_at: null,
          updated_at: '2021-06-29T15:34:34.743Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 272,
          name: 'Opération de sauvetage',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 121,
          created_at: null,
          updated_at: '2021-06-29T15:34:34.743Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 162,
          name: 'Famine',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 121,
          created_at: null,
          updated_at: '2021-05-19T10:08:40.163Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 160,
          name: 'Accident Nautique',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 271,
          created_at: null,
          updated_at: '2021-06-29T10:37:23.111Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 161,
          name: 'Accident Routier',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 263,
          created_at: null,
          updated_at: '2021-07-07T14:50:46.455Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 230,
          name: 'Accident Ferroviaire',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 270,
          created_at: null,
          updated_at: '2021-06-29T10:36:19.441Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 284,
          name: 'Explosion',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 50,
          created_at: null,
          updated_at: '2021-07-06T15:34:34.204Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 279,
          name: 'Incendie',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 50,
          created_at: null,
          updated_at: '2021-07-06T14:26:50.568Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 274,
          name: 'Défaillance Bâtiment/Infrastructure',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 353,
          created_at: null,
          updated_at: '2021-07-02T10:24:24.197Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 56,
          name: 'Matière dangereuse - Drogues',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-05-10T16:17:50.107Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 55,
          name: 'Matière dangereuse - Poudre Suspecte',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:57:18.020Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 54,
          name: 'Matière dangereuse - Fause Alerte',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:57:00.716Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 53,
          name: 'Matière dangereuse - Chimique',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:56:44.206Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 52,
          name: 'Matière dangereuse - Odeurs/Fumées',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:56:30.745Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 51,
          name: 'Matière dangereuse - Other/Inconnu',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:56:19.358Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 49,
          name: 'Matière dangereuse - Essence/Huile/Gaz',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:53.938Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 48,
          name: 'Matière dangereuse - Chimique',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:21.168Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 47,
          name: 'Matière dangereuse- Biologique',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:04.008Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 363,
          name: 'Incident Electrique',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:04.008Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 356,
      color: '#ffbd3e',
      name: 'Irrespect de la loi',
      subcategories: [
        {
          id: 359,
          name: 'Accusation',
          color: '#ffbd3e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 356,
          created_at: null,
          updated_at: '2021-10-29T12:33:02.407Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 358,
          name: 'Arrestation',
          color: '#ffbd3e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 356,
          created_at: null,
          updated_at: '2021-10-29T12:33:02.396Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 357,
          name: 'Police',
          color: '#ffbd3e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 356,
          created_at: null,
          updated_at: '2021-10-29T12:33:02.389Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 125,
      color: '#FFAD0F',
      name: 'Crime',
      subcategories: [
        {
          id: 297,
          name: 'Incidents - armes à feux (fusillade)',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-07-26T13:21:44.390Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 294,
          name: `Incidents à l'arme blanche ou contondante`,
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-07-26T13:21:02.795Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 280,
          name: 'Bombardement',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-07-06T14:42:11.033Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 260,
          name: 'Actions de la force étatique',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:53:56.717Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 219,
          name: 'Hijacking',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:43:01.244Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 217,
          name: 'Vandalisme',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:42:50.527Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 216,
          name: 'Vol / Cambriolage',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:43:46.601Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 213,
          name: `Assasinat / Tentative d'Assanitat Attempt / Homicide`,
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:43:25.992Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 209,
          name: 'Cyber-crime / Cyber-attaque',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:45:38.138Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 38,
          name: 'Incendie criminel',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:42:43.237Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 37,
          name: 'Enlèvement / Kidnapping',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-05-18T10:52:47.820Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 34,
          name: 'Menaces biologiques (enveloppes contaminées au bacille du charbon)',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-11T10:15:30.602Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
  ],
};

export const rmdSetup = {
  defaultMapStyle: 'mapbox://styles/mapbox/satellite-v9',
  extraServices: [],
  categories: [
    {
      id: 261,
      name: 'Aviation Incidents',
      color: '#3B7BEF',
      subcategories: [
        {
          id: 86,
          name: 'Airspace Intrusion',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/tour-de-laeroport.svg',
          color: '#3B7BEF',
          description: 'Airspace violation',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:45:35.113Z',
          updated_at: '2021-06-29T10:21:29.856Z',
          deleted_at: null,
        },
        {
          id: 95,
          name: 'Military Forced/ Emergency Landing /Diversion',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/airplane.svg',
          color: '#3B7BEF',
          description: 'Emergency, Forced Landing, Diversion - Military',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:54:23.845Z',
          updated_at: '2021-06-29T10:22:10.921Z',
          deleted_at: null,
        },
        {
          id: 92,
          name: 'Military Helicopter Crash/Emergency Landing',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/helicopter.svg',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:49:13.627Z',
          updated_at: '2021-06-29T10:21:49.489Z',
          deleted_at: null,
        },
        {
          id: 90,
          name: 'Helicopter Crash/Emergency Landing',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/helicopter.svg',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:48:14.160Z',
          updated_at: '2021-06-29T10:21:41.861Z',
          deleted_at: null,
        },
        {
          id: 93,
          name: 'Hot Air Balloon/Blimp Incidents',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/hot-air-balloon.svg',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:49:54.773Z',
          updated_at: '2021-06-29T10:21:53.758Z',
          deleted_at: null,
        },
        {
          id: 89,
          name: 'Plane Crash - Military Aircraft',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/airplane.svg',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:47:06.105Z',
          updated_at: '2021-06-29T10:22:24.014Z',
          deleted_at: null,
        },
      ],
    },
    {
      id: 137,
      name: 'War, Conflit and Terrorism',
      color: '#9F1122',
      subcategories: [
        {
          id: 287,
          name: 'Military Movements',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/military.svg',
          color: '#9F1122',
          description:
            'Military, state troop movements (withdrawal, deployment, etc)',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 141,
          created_at: '2021-07-07T13:13:28.014Z',
          updated_at: '2021-07-07T13:34:14.192Z',
          deleted_at: null,
        },
        {
          id: 182,
          name: 'Clashes with Opposition Forces',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/shooting.svg',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 139,
          created_at: '2021-05-19T10:22:52.709Z',
          updated_at: '2021-05-19T10:22:52.709Z',
          deleted_at: null,
        },
        {
          id: 296,
          name: 'Light Weapons Incidents',
          color: '#9F1122',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/shooting.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 0,
          created_at: '2021-07-22T16:56:38.331Z',
          updated_at: '2021-07-22T16:56:38.331Z',
          deleted_at: null,
        },
        {
          id: 295,
          name: 'Small Arms Incidents',
          color: '#9F1122',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/shooting.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 0,
          created_at: '2021-07-22T16:56:29.517Z',
          updated_at: '2021-07-22T16:56:29.517Z',
          deleted_at: null,
        },
        {
          id: 36,
          name: 'Assasination',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/assasination.svg',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 20,
          created_at: '2021-03-19T14:43:32.004Z',
          updated_at: '2021-05-18T10:52:23.557Z',
          deleted_at: null,
        },
        {
          id: 37,
          name: 'Abductions / Kidnappings',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/kidnapping.svg',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 20,
          created_at: '2021-03-19T14:43:58.106Z',
          updated_at: '2021-05-18T10:52:47.820Z',
          deleted_at: null,
        },

        {
          id: 166,
          name: 'Law Enforcements/State Forces actions',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/law.svg',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 144,
          created_at: '2021-05-19T10:11:12.344Z',
          updated_at: '2021-06-04T13:40:32.189Z',
          deleted_at: null,
        },
        {
          id: 30,
          name: 'Terrorist Arrests/Captured/Killed',
          color: '#9F1122',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/terrorism.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 20,
          created_at: '2021-03-19T14:39:44.827Z',
          updated_at: '2021-05-18T14:53:06.205Z',
          deleted_at: null,
        },
        {
          id: 35,
          name: 'Embassies Incidents',
          color: '#9F1122',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/embassies%20incidents.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 141,
          created_at: '2021-03-19T14:43:07.372Z',
          updated_at: '2021-05-18T12:59:59.455Z',
          deleted_at: null,
        },
        {
          id: 33,
          name: 'Terrorism And Related Court Cases',
          color: '#9F1122',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/terrorism.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 20,
          created_at: '2021-03-19T14:41:59.143Z',
          updated_at: '2021-05-18T14:55:20.377Z',
          deleted_at: null,
        },
      ],
    },
    {
      id: 125,
      name: 'Crime',
      color: '#FFAD0F',
      subcategories: [
        {
          id: 294,
          name: 'Non-Firearms Weapon Incidents (bladed, blunt)',
          color: '#FFAD0F',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/knife.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 0,
          created_at: '2021-07-22T16:56:18.648Z',
          updated_at: '2021-07-26T13:21:02.795Z',
          deleted_at: null,
        },
        {
          id: 297,
          name: 'Firearms Incidents (mass shooting)',
          color: '#FFAD0F',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/shooting.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 0,
          created_at: '2021-07-22T16:56:05.221Z',
          updated_at: '2021-07-26T13:21:39.043Z',
          deleted_at: null,
        },
        {
          id: 293,
          name: 'Firearms Incidents (shooting)',
          color: '#FFAD0F',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/shooting.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 0,
          created_at: '2021-07-22T17:27:38.218Z',
          updated_at: '2021-07-26T13:21:44.390Z',
          deleted_at: null,
        },
      ],
    },
    {
      id: 116,
      name: 'Trafficking',
      color: '#FF7D05',
      subcategories: [
        {
          id: 83,
          name: 'Medicine smuggling',
          color: '#FF7D05',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/medicine.svg',
          description: 'Medicine Smuggling',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: '2021-05-11T11:33:58.595Z',
          updated_at: '2021-06-28T11:46:46.088Z',
          deleted_at: null,
        },
        {
          id: 46,
          name: 'Tobacco Trafficking',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/tobacco.svg',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: '2021-03-22T06:53:34.431Z',
          updated_at: '2021-06-28T11:46:34.748Z',
          deleted_at: null,
        },
      ],
    },
    {
      id: 115,
      name: 'Drug Trafficking',
      color: '#FF7D05',
      subcategories: [
        {
          id: 27,
          name: 'Cocaine Trafficking',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/cocaine.svg',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: null,
          updated_at: '2021-06-28T11:49:40.159Z',
          deleted_at: null,
        },
        {
          id: 22,
          name: 'Marijuana / Hashish Trafficking',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/cannabis.svg',
          color: '#FF7D05',
          description: 'Marijuana Trafficking',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: '2021-03-15T16:56:42.862Z',
          updated_at: '2021-06-28T11:49:55.172Z',
          deleted_at: null,
        },
        {
          id: 25,
          name: 'Heroin / Krokodil Trafficking',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/heroin.svg',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: '2021-03-15T16:59:12.764Z',
          updated_at: '2021-06-28T11:49:51.011Z',
          deleted_at: null,
        },
        {
          id: 23,
          name: 'Methamphetamine / Amphetamine',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/meth.svg',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: '2021-03-15T16:58:22.670Z',
          updated_at: '2021-06-28T11:50:07.459Z',
          deleted_at: null,
        },
        {
          id: 24,
          name: 'Multi Drug Raid / Arrests / Discoveries',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/arrests.svg',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: '2021-03-15T16:58:47.932Z',
          updated_at: '2021-06-28T11:49:47.782Z',
          deleted_at: null,
        },
        {
          id: 82,
          name: 'Opium Trafficking',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/opium.svg',
          color: '#FF7D05',
          description: 'Opium traffic',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: '2021-05-11T11:33:12.248Z',
          updated_at: '2021-06-28T11:49:59.187Z',
          deleted_at: null,
        },
      ],
    },
  ],
};

export const epkSetup = {
  showAllActions: true,
  typeMainCouranteActionToShow: 'opened',
  defaultMapStyle: 'mapbox://styles/mapbox/satellite-v9',
  autoFocusOff: true,
  centerGeometry: {
    type: 'Polygon',
    coordinates: [
      [
        [2.1440912362510036, 48.889349971022234],
        [2.1440569504812625, 48.87993720805249],
        [2.1752690788038267, 48.87985829117363],
        [2.1752690788038267, 48.88936124273012],
        [2.1440912362510036, 48.889349971022234],
      ],
    ],
  },
  extraServices: [
    'analytics',
    'analytics.dynamic_data',
    'analytics.main_courante_flow_ratio',
  ],
  categories: [],
};
export const testSetup = {
  showAllActions: true,
  typeMainCouranteActionToShow: 'opened',
  defaultMapStyle: 'mapbox://styles/mapbox/satellite-v9',
  autoFocusOff: true,
  extraServices: [],
  categories: [
    {
      id: 1,
      color: '#3396F1',
      name: 'All',
      subcategories: [
        {
          id: 275,
          name: 'Landslide',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 132,
          created_at: null,
          updated_at: '2021-07-05T14:00:34.518Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 154,
          name: 'Volcanic activity',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 132,
          created_at: null,
          updated_at: '2021-05-19T09:53:52.510Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 153,
          name: 'Tsunami',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 132,
          created_at: null,
          updated_at: '2021-05-19T09:53:39.146Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 7,
          name: 'Hurricane/Typhoons/Cyclones',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-06-18T15:36:17.860Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 254,
          name: 'Snow',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-06-22T09:22:27.046Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 253,
          name: 'Wind',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-06-22T09:22:17.286Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 249,
          name: 'Tornado',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-06-18T15:36:59.850Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 227,
          name: 'Tidal waves',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-05-25T14:06:38.797Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 159,
          name: 'Storms',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-05-25T14:06:48.478Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 129,
          name: 'Tropical Storm',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-07-09T13:31:33.926Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 6,
          name: 'Floods',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 133,
          created_at: null,
          updated_at: '2021-05-19T09:54:55.693Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 250,
          name: 'Coastal Flooding',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 133,
          created_at: null,
          updated_at: '2021-07-15T08:48:47.417Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 155,
          name: 'Avalanche',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 133,
          created_at: null,
          updated_at: '2021-05-19T09:54:36.309Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 157,
          name: 'Droughts',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 134,
          created_at: null,
          updated_at: '2021-05-19T09:56:05.492Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 252,
          name: 'Severe cold',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 156,
          created_at: null,
          updated_at: '2021-06-22T09:21:22.570Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 251,
          name: 'Heat wave',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 156,
          created_at: null,
          updated_at: '2021-06-22T09:21:10.810Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 256,
          name: 'Rain-Flood',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 255,
          created_at: null,
          updated_at: '2021-06-22T09:24:59.364Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 120,
          name: 'Aviation Incidents',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-10-27T13:49:16.709Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 328,
          name: 'Plane Crash - Private Or Corporate Jet',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-10-27T13:49:16.709Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 327,
          name: 'Plane Crash - Commercial Passenger Jet',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-10-27T13:49:16.700Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 283,
          name: 'Airway Traffic and Regulation',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-07-06T14:53:26.386Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 278,
          name: 'Plane - Lost contact',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-07-06T11:15:17.483Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 262,
          name: 'Air traffic controllers strike',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:27:00.334Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 94,
          name: 'Ultra-Light Aircraft - Hang Gliders - Etc',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:22:05.555Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 364,
          name: 'Death / Demise',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 121,
          created_at: null,
          updated_at: '2021-06-29T15:34:34.743Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 272,
          name: 'Rescue Operation',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 121,
          created_at: null,
          updated_at: '2021-06-29T15:34:34.743Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 162,
          name: 'Famine',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 121,
          created_at: null,
          updated_at: '2021-05-19T10:08:40.163Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 230,
          name: 'Railway accidents',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 270,
          created_at: null,
          updated_at: '2021-06-29T10:36:19.441Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 274,
          name: 'Structural failure',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 353,
          created_at: null,
          updated_at: '2021-07-02T10:24:24.197Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 54,
          name: 'Hazmat - False Alarm',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:57:00.716Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 53,
          name: 'Hazmat - Chemical',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:56:44.206Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 51,
          name: 'Hazmat - Other/Unknown',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:56:19.358Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 48,
          name: 'Hazmat - Chemical',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:21.168Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 47,
          name: 'Hazmat- Biological',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:04.008Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 363,
          name: 'Electricity Incident',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:04.008Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 359,
          name: 'Accusation',
          color: '#ffbd3e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 356,
          created_at: null,
          updated_at: '2021-10-29T12:33:02.407Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 297,
          name: 'Firearms incident (shooting)',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-07-26T13:21:44.390Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 280,
          name: 'Bomb incident',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-07-06T14:42:11.033Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 260,
          name: 'State Force Actions',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:53:56.717Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 219,
          name: 'Hijacking',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:43:01.244Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 217,
          name: 'Vandalism',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:42:50.527Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 216,
          name: 'Theft / Robbery',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:43:46.601Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 34,
          name: 'Biological Threats/ Anthrax Hoaxes',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-11T10:15:30.602Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 24,
          name: 'Multi Drug Raid / Arrests / Discoveries',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: null,
          updated_at: '2021-06-28T11:49:47.782Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 288,
          name: 'Trafficking - other',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-07-07T13:33:07.344Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 286,
          name: 'Goods Trafficking',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-07-07T08:50:31.548Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 259,
          name: 'Justice: Illegal wildlife and plant trade',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:52:57.062Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 258,
          name: 'Justice: Illegal fishing trade',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:52:37.150Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 212,
          name: 'Illegal wildlife & plant trade',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:46:55.898Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 211,
          name: 'Weapons/firearms trafficking',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:46:52.974Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 210,
          name: 'Migrant smuggling',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:47:25.248Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 83,
          name: 'Medicine - Smuggling',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:46:46.088Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 292,
          name: 'Child Pornography / Endangerment',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 114,
          created_at: null,
          updated_at: '2021-07-15T07:47:13.444Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 231,
          name: 'Child exploitation/porn',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 114,
          created_at: null,
          updated_at: '2021-06-28T11:48:14.233Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 41,
          name: 'Human Trafficking',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 114,
          created_at: null,
          updated_at: '2021-06-28T11:47:54.233Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 347,
          name: 'Strikes',
          color: '#b2404e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 144,
          created_at: null,
          updated_at: '2021-10-29T09:26:49.262Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 346,
          name: 'Protests',
          color: '#b2404e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 144,
          created_at: null,
          updated_at: '2021-10-29T09:26:49.253Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 166,
          name: 'State Force Actions',
          color: '#b2404e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 144,
          created_at: null,
          updated_at: '2021-06-04T13:40:32.189Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 164,
          name: 'Massacre',
          color: '#b2404e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 144,
          created_at: null,
          updated_at: '2021-05-19T10:10:55.039Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 163,
          name: 'Civil unrest / Riots / Rebellion',
          color: '#b2404e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 144,
          created_at: null,
          updated_at: '2021-05-19T12:48:16.161Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 181,
          name: 'Captured Territory',
          color: '#a82838',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 350,
          created_at: null,
          updated_at: '2021-05-19T10:22:44.113Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 147,
          name: 'Hijacking',
          color: '#a82838',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 350,
          created_at: null,
          updated_at: '2021-05-18T10:40:26.845Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 32,
          name: 'General Terrorism News',
          color: '#a82838',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 350,
          created_at: null,
          updated_at: '2021-05-18T14:54:39.573Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 142,
          name: 'Piracy',
          color: '#a82838',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 137,
          created_at: null,
          updated_at: '2021-05-18T09:45:16.246Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 180,
          name: 'Hostage Situation',
          color: '#a82838',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 180,
          created_at: null,
          updated_at: '2021-10-29T09:56:24.029Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 248,
          name: 'Non-lethal weapons incident (gas, water canons, etc.)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:30:32.010Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 247,
          name: 'Non-firearms weapon incident (bladed, blunt, etc.)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:28:25.157Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 222,
          name: 'Light weapons incident (Shelling)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-11T14:54:07.151Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 221,
          name: 'Small arms incident (Assault rifles, pistols, sniper rifles)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-14T14:28:23.911Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 179,
          name: 'Bombings / IEDs',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:26:38.443Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 175,
          name: 'Heavy weapons incident (Tanks, Armoured vehicles)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:22:59.025Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 36,
          name: 'Assassination',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-05-18T10:52:23.557Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 287,
          name: 'Military Movements',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-07-07T13:34:14.192Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 174,
          name: 'Human security',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-05-19T10:16:42.262Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 173,
          name: 'Peace Talks',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-05-19T10:16:31.207Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 172,
          name: 'Conflict Resolution',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-05-19T10:16:23.437Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 171,
          name: 'Important Announcements/ State/war declarations',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-05-19T10:16:10.078Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 152,
          name: 'Migration',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-05-18T15:14:09.536Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 326,
          name: 'Coronavirus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.493Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 325,
          name: 'Nipah Virus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.485Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 324,
          name: 'H3N2 - Swine Flu / Canine Influenza',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.477Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 323,
          name: 'General News',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.470Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 322,
          name: 'Small Pox',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.464Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 321,
          name: 'Q-Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.456Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 320,
          name: 'Schmallenberg Virus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.448Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 319,
          name: 'Ricin',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.440Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 318,
          name: 'KCP',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.433Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 317,
          name: 'Glanders',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.426Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 316,
          name: 'NDM-1',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.418Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 315,
          name: 'Hendra Virus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.408Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 314,
          name: 'Biological Incidents/ Threats/ Anthrax Hoaxes etc',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.400Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 313,
          name: 'Monkey Pox',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.394Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 312,
          name: 'Miscellaneous / Unknown Diseases or Illnesses',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.386Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 311,
          name: 'Rathayibacter ',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.378Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 310,
          name: 'Rift Valley Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.371Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 309,
          name: 'Avian Flu',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.363Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 308,
          name: 'Notable H1N1 News And Announcements',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.355Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 307,
          name: 'Rabies',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.349Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 306,
          name: 'West Nile Virus (suspected or confirmed)',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.340Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 305,
          name: 'Vaccines',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.334Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 304,
          name: 'Suspicious or Threatening Powder',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.327Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 303,
          name: 'Typhoid / Typhus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.320Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 302,
          name: 'Anthrax ',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.314Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 301,
          name: 'Classical Swine Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.306Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 300,
          name: 'Meningitis Outbreak ( Suspected or Confirmed)',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.299Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 299,
          name: 'Salmonella Outbreak (Suspected or Confirmed)',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.271Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 80,
          name: 'Botulism',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:11.240Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 79,
          name: 'Newcastle Disease',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:17.153Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 78,
          name: 'Brucellosis',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:33.067Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 77,
          name: 'Tularemia',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:28.004Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 75,
          name: 'Lassa Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:20.716Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 72,
          name: 'Malaria',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:59.201Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 70,
          name: 'Hantavirus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:49.322Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 69,
          name: 'Swine Flu - Suspected or Probable Cases',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:13.851Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 68,
          name: 'Anthrax',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:45.086Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 66,
          name: 'Congo Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:37.409Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 65,
          name: 'Polio',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:31.440Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 64,
          name: 'Plague',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:19.522Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 63,
          name: 'Zika',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:09.749Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 58,
          name: 'Swine Flu - Confirmed Cases',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:26.433Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 57,
          name: 'H7N9 / H5N1 / H5N2 / H7N1 / H7N3 / H7N7 / H5N8',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:23.055Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 360,
          name: 'Epidemic Hazard',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:23.055Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 361,
          name: 'Epidemic Hazard (Animal)',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:23.055Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 362,
          name: 'Infection Hazard',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:23.055Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 287,
          name: 'Military Movements',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/military.svg',
          color: '#9F1122',
          description:
            'Military, state troop movements (withdrawal, deployment, etc)',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 141,
          created_at: '2021-07-07T13:13:28.014Z',
          updated_at: '2021-07-07T13:34:14.192Z',
          deleted_at: null,
        },
        {
          id: 182,
          name: 'Clashes with Opposition Forces',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/shooting.svg',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 139,
          created_at: '2021-05-19T10:22:52.709Z',
          updated_at: '2021-05-19T10:22:52.709Z',
          deleted_at: null,
        },
        {
          id: 296,
          name: 'Light Weapons Incidents',
          color: '#9F1122',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/shooting.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 0,
          created_at: '2021-07-22T16:56:38.331Z',
          updated_at: '2021-07-22T16:56:38.331Z',
          deleted_at: null,
        },
        {
          id: 295,
          name: 'Small Arms Incidents',
          color: '#9F1122',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/shooting.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 0,
          created_at: '2021-07-22T16:56:29.517Z',
          updated_at: '2021-07-22T16:56:29.517Z',
          deleted_at: null,
        },
        {
          id: 36,
          name: 'Assasination',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/assasination.svg',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 20,
          created_at: '2021-03-19T14:43:32.004Z',
          updated_at: '2021-05-18T10:52:23.557Z',
          deleted_at: null,
        },
        {
          id: 166,
          name: 'Law Enforcements/State Forces actions',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/law.svg',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 144,
          created_at: '2021-05-19T10:11:12.344Z',
          updated_at: '2021-06-04T13:40:32.189Z',
          deleted_at: null,
        },
        {
          id: 297,
          name: 'Firearms Incidents (mass shooting)',
          color: '#FFAD0F',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/shooting.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 0,
          created_at: '2021-07-22T16:56:05.221Z',
          updated_at: '2021-07-26T13:21:39.043Z',
          deleted_at: null,
        },
        {
          id: 293,
          name: 'Firearms Incidents (shooting)',
          color: '#FFAD0F',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/shooting.svg',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 0,
          created_at: '2021-07-22T17:27:38.218Z',
          updated_at: '2021-07-26T13:21:44.390Z',
          deleted_at: null,
        },
        {
          id: 83,
          name: 'Medicine smuggling',
          color: '#FF7D05',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/medicine.svg',
          description: 'Medicine Smuggling',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: '2021-05-11T11:33:58.595Z',
          updated_at: '2021-06-28T11:46:46.088Z',
          deleted_at: null,
        },
        {
          id: 24,
          name: 'Multi Drug Raid / Arrests / Discoveries',
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/arrests.svg',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: '2021-03-15T16:58:47.932Z',
          updated_at: '2021-06-28T11:49:47.782Z',
          deleted_at: null,
        },
        {
          id: 8,
          name: 'Wildfires',
          color: '#E25822',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 134,
          created_at: null,
          updated_at: '2021-05-19T09:56:35.480Z',
          deleted_at: null,
        },
        {
          id: 27,
          name: 'Cocaine Trafficking',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: null,
          updated_at: '2021-06-28T11:49:40.159Z',
          deleted_at: null,
        },
        {
          id: 4,
          name: 'Earthquake',
          color: '#00FF99',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 132,
          created_at: null,
          updated_at: '2021-05-18T09:26:34.999Z',
          deleted_at: null,
        },
        {
          id: 384,
          name: 'Child Exploitation',
          color: '#124BA3C',
          description: null,
          kw_in: null,
          kw_ex: null,
          classifier_score: null,
          geolocation: null,
          parent_id: 125,
          created_at: '2021-11-04T09:27:49.347Z',
          updated_at: '2021-11-04T09:27:49.347Z',
          deleted_at: null,
        },
        {
          id: 386,
          name: 'Elections',
          color: '#3EB1B1',
          description: null,
          kw_in: null,
          kw_ex: null,
          classifier_score: null,
          geolocation: null,
          parent_id: 141,
          created_at: '2021-11-04T09:27:36.073Z',
          updated_at: '2021-11-04T09:27:36.073Z',
          deleted_at: null,
        },
        {
          id: 358,
          name: 'Arrests',
          color: '#FFD422',
          description: null,
          kw_in: null,
          kw_ex: null,
          classifier_score: null,
          geolocation: null,
          parent_id: 356,
          created_at: '2021-11-03T11:26:44.844Z',
          updated_at: '2021-11-03T11:26:44.844Z',
          deleted_at: null,
        },
        {
          id: 357,
          name: 'Police',
          color: '#FFD422',
          description: null,
          kw_in: null,
          kw_ex: null,
          classifier_score: null,
          geolocation: null,
          parent_id: 356,
          created_at: '2021-11-03T11:26:44.838Z',
          updated_at: '2021-11-03T11:26:44.838Z',
          deleted_at: null,
        },
        {
          id: 352,
          name: 'Armed Incidents - Misc',
          color: '#FF0000',
          description: null,
          kw_in: null,
          kw_ex: null,
          classifier_score: null,
          geolocation: null,
          parent_id: 182,
          created_at: '2021-11-03T11:26:26.401Z',
          updated_at: '2021-11-03T11:26:26.401Z',
          deleted_at: null,
        },
        {
          id: 332,
          name: 'General News',
          color: '#B081ED',
          description: null,
          kw_in: null,
          kw_ex: null,
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: '2021-10-27T13:36:17.251Z',
          updated_at: '2021-10-27T13:36:17.251Z',
          deleted_at: null,
        },
        {
          id: 294,
          name: 'Non-firearms weapon incident (bladed, blunt)',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: '2021-07-22T16:56:18.648Z',
          updated_at: '2021-07-26T13:21:02.795Z',
          deleted_at: null,
        },
        {
          id: 284,
          name: 'Explosion Incident',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 50,
          created_at: '2021-07-06T15:18:47.740Z',
          updated_at: '2021-07-06T15:34:34.204Z',
          deleted_at: null,
        },
        {
          id: 279,
          name: 'Fire incident',
          color: '#1963E9',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 50,
          created_at: '2021-07-06T14:23:37.153Z',
          updated_at: '2021-07-06T14:26:50.568Z',
          deleted_at: null,
        },
        {
          id: 213,
          name: 'Assasination / Assanitation Attempt / Homicide',
          color: '#FFD422',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: '2021-05-19T10:34:17.912Z',
          updated_at: '2021-06-28T11:43:25.992Z',
          deleted_at: null,
        },
        {
          id: 209,
          name: 'Cyber crime / Cyber Attack',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: '2021-05-19T10:30:23.610Z',
          updated_at: '2021-06-28T11:45:38.138Z',
          deleted_at: null,
        },
        {
          id: 161,
          name: 'Roadway Accidents',
          color: '#CE192F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 263,
          created_at: '2021-05-19T10:07:15.422Z',
          updated_at: '2021-07-07T14:50:46.455Z',
          deleted_at: null,
        },
        {
          id: 160,
          name: 'Nautical Accidents',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 271,
          created_at: '2021-05-19T10:07:04.961Z',
          updated_at: '2021-06-29T10:37:23.111Z',
          deleted_at: null,
        },
        {
          id: 137,
          name: 'WAR, CONFLICT AND TERRORISM',
          color: '#5DBDBD',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: null,
          created_at: '2021-05-18T09:42:31.356Z',
          updated_at: '2021-05-18T09:42:31.356Z',
          deleted_at: null,
        },
        {
          id: 115,
          name: 'Drug Trafficking',
          color: '#FF7D05',
          description: ' ',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: '2021-05-13T13:49:43.895Z',
          updated_at: '2021-06-28T11:46:39.491Z',
          deleted_at: null,
        },
        {
          id: 108,
          name: 'Medicine - Counterfeit',
          color: '#6002D9',
          description: 'Medicine - Counterfeit',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 150,
          created_at: '2021-05-11T13:10:10.805Z',
          updated_at: '2021-06-29T16:14:44.724Z',
          deleted_at: null,
        },
        {
          id: 106,
          name: 'Food adulterated',
          color: '#8C41ED',
          description:
            'Food is adulterated if it omits a valuable constituent or substitutes another substance',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 136,
          created_at: '2021-05-11T13:09:04.094Z',
          updated_at: '2021-06-29T16:15:51.654Z',
          deleted_at: null,
        },
        {
          id: 103,
          name: 'Food - Contaminated',
          color: '#8C41ED',
          description: 'Food - Contaminated',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 136,
          created_at: '2021-05-11T13:05:16.298Z',
          updated_at: '2021-06-29T16:15:47.143Z',
          deleted_at: null,
        },
        {
          id: 102,
          name: 'Water Shortages',
          color: '#333300',
          description: 'Water Shortages',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 224,
          created_at: '2021-05-11T13:04:53.120Z',
          updated_at: '2021-05-25T14:03:27.540Z',
          deleted_at: null,
        },
        {
          id: 100,
          name: 'Food misbranded',
          color: '#8C41ED',
          description: 'Food misbranded',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 136,
          created_at: '2021-05-11T13:03:52.669Z',
          updated_at: '2021-06-29T16:15:43.843Z',
          deleted_at: null,
        },
        {
          id: 96,
          name: 'Glider Crash Or Emergency Landing',
          color: '#3B7BEF',
          description: 'Glider Crash Or Emergency Landing',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:55:14.021Z',
          updated_at: '2021-06-29T10:22:19.368Z',
          deleted_at: null,
        },
        {
          id: 95,
          name: 'Emergency, Forced Landing, Diversion - Military',
          color: '#3B7BEF',
          description: 'Emergency, Forced Landing, Diversion - Military',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:54:23.845Z',
          updated_at: '2021-06-29T10:22:10.921Z',
          deleted_at: null,
        },
        {
          id: 93,
          name: 'Hot Air Balloon Or Blimp Incidents',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:49:54.773Z',
          updated_at: '2021-06-29T10:21:53.758Z',
          deleted_at: null,
        },
        {
          id: 92,
          name: 'Helicopter Crash - Or Emergency Landing - Military',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:49:13.627Z',
          updated_at: '2021-06-29T10:21:49.489Z',
          deleted_at: null,
        },
        {
          id: 91,
          name: 'Aircraft Laser Incidents - Lasing',
          color: '#3B7BEF',
          description:
            'Laser strikes on aircraft remain a serious threat to aviation safety. Intentionally aiming lasers at aircrafts poses a safety threat to pilots and violates federal law. ',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:48:38.988Z',
          updated_at: '2021-07-06T07:23:28.444Z',
          deleted_at: null,
        },
        {
          id: 90,
          name: 'Helicopter/Gyrocopter Crash - Or Emergency Landing',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:48:14.160Z',
          updated_at: '2021-06-29T10:21:41.861Z',
          deleted_at: null,
        },
        {
          id: 89,
          name: 'Plane Crash - Military Aircraft',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:47:06.105Z',
          updated_at: '2021-06-29T10:22:24.014Z',
          deleted_at: null,
        },
        {
          id: 88,
          name: 'Emergency, Forced Landing, Diversion - Commercial',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:46:29.681Z',
          updated_at: '2021-06-29T10:21:38.130Z',
          deleted_at: null,
        },
        {
          id: 87,
          name: 'Emergency, Forced Landing, Diversion - Private',
          color: '#3B7BEF',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:46:04.395Z',
          updated_at: '2021-06-29T10:21:33.973Z',
          deleted_at: null,
        },
        {
          id: 86,
          name: 'Airspace Intrusion',
          color: '#3B7BEF',
          description: 'Airspace violation',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T12:45:35.113Z',
          updated_at: '2021-06-29T10:21:29.856Z',
          deleted_at: null,
        },
        {
          id: 85,
          name: 'Plane Crash - Private Or Corporate Small Aircraft',
          color: '#3B7BEF',
          description: 'Plane Crash - Private Or Corporate Small Aircraft',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T11:45:17.165Z',
          updated_at: '2021-06-29T10:21:26.000Z',
          deleted_at: null,
        },
        {
          id: 84,
          name: 'Airport Incident - Arson - Weather Events - Other',
          color: '#3B7BEF',
          description: 'Airport Incident - Arson - Weather Events - Other',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: '2021-05-11T11:42:39.870Z',
          updated_at: '2021-06-29T10:21:21.701Z',
          deleted_at: null,
        },
        {
          id: 82,
          name: 'Opium',
          color: '#FF7D05',
          description: 'Opium traffic',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: '2021-05-11T11:33:12.248Z',
          updated_at: '2021-06-28T11:49:59.187Z',
          deleted_at: null,
        },
        {
          id: 76,
          name: 'Foot-And-Mouth Disease',
          color: ' #B081ED',
          description: 'Foot-And-Mouth Disease viral disease',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: '2021-05-10T16:05:27.215Z',
          updated_at: '2021-06-29T16:18:24.745Z',
          deleted_at: null,
        },
        {
          id: 74,
          name: 'Ebola / Marburg',
          color: ' #B081ED',
          description: 'Ebola / Marburg viruses',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: '2021-05-10T16:03:33.347Z',
          updated_at: '2021-06-29T16:18:07.172Z',
          deleted_at: null,
        },
        {
          id: 73,
          name: 'Cholera Outbreak',
          color: ' #B081ED',
          description: 'Cholera Outbreak',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: '2021-05-10T16:02:17.344Z',
          updated_at: '2021-06-29T16:18:03.363Z',
          deleted_at: null,
        },
        {
          id: 71,
          name: 'Chikungunya',
          color: ' #B081ED',
          description: 'Chikungunya virus',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: '2021-05-10T15:58:20.557Z',
          updated_at: '2021-06-29T16:17:54.085Z',
          deleted_at: null,
        },
        {
          id: 67,
          name: 'Encephalitis',
          color: ' #B081ED',
          description: 'Encephalitis brain inflammation',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: '2021-05-10T15:46:53.623Z',
          updated_at: '2021-06-29T16:17:41.187Z',
          deleted_at: null,
        },
        {
          id: 62,
          name: 'African Swine Fever / Swine Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: '2021-05-10T15:40:17.250Z',
          updated_at: '2021-06-29T16:17:06.199Z',
          deleted_at: null,
        },
        {
          id: 61,
          name: 'Swine Flu - Confirmed / Possible Related Death',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: '2021-05-10T15:39:50.828Z',
          updated_at: '2021-06-29T16:17:02.208Z',
          deleted_at: null,
        },
        {
          id: 60,
          name: 'Dengue / Hemorrhagic Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: '2021-05-10T15:39:01.149Z',
          updated_at: '2021-06-29T16:16:57.884Z',
          deleted_at: null,
        },
        {
          id: 56,
          name: 'Hazmat - Drugs',
          color: '#00FF00',
          description: 'Hazmat on drugs',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 119,
          created_at: '2021-05-10T15:12:43.395Z',
          updated_at: '2021-05-10T16:17:50.107Z',
          deleted_at: null,
        },
        {
          id: 55,
          name: 'Hazmat - Suspicious or Threatening Powder',
          color: '#9ACD32',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: '2021-03-22T06:57:18.020Z',
          updated_at: '2021-03-22T06:57:18.020Z',
          deleted_at: null,
        },
        {
          id: 52,
          name: 'Hazmat - Odors/Fumes/Suspicious Fumes',
          color: '#008000\\t',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: '2021-03-22T06:56:30.745Z',
          updated_at: '2021-03-22T06:56:30.745Z',
          deleted_at: null,
        },
        {
          id: 49,
          name: 'Hazmat - Fuel/Oil/Gas',
          color: '#32CD32',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: '2021-03-22T06:55:53.938Z',
          updated_at: '2021-03-22T06:55:53.938Z',
          deleted_at: null,
        },
        {
          id: 46,
          name: 'Tobacco Trafficking',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: '2021-03-22T06:53:34.431Z',
          updated_at: '2021-06-28T11:46:34.748Z',
          deleted_at: null,
        },
        {
          id: 43,
          name: 'Human Trafficking - General News',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 114,
          created_at: '2021-03-22T05:47:37.971Z',
          updated_at: '2021-06-28T11:48:03.069Z',
          deleted_at: null,
        },
        {
          id: 42,
          name: 'Human Trafficking Court Cases',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 114,
          created_at: '2021-03-22T05:47:22.777Z',
          updated_at: '2021-06-28T11:47:58.349Z',
          deleted_at: null,
        },
        {
          id: 38,
          name: 'Arson',
          color: '#FFD422',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: '2021-03-19T14:44:14.657Z',
          updated_at: '2021-06-28T11:42:43.237Z',
          deleted_at: null,
        },
        {
          id: 37,
          name: 'Abductions/ Kidnapping',
          color: '#FFFF9A',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: '2021-03-19T14:43:58.106Z',
          updated_at: '2021-05-18T10:52:47.820Z',
          deleted_at: null,
        },
        {
          id: 35,
          name: 'Embassies incidents',
          color: '#FFFF4D',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 141,
          created_at: '2021-03-19T14:43:07.372Z',
          updated_at: '2021-05-18T12:59:59.455Z',
          deleted_at: null,
        },
        {
          id: 33,
          name: 'Terrorism And Related Court Cases',
          color: '#FF6347\\t',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: '2021-03-19T14:41:59.143Z',
          updated_at: '2021-05-18T14:55:20.377Z',
          deleted_at: null,
        },
        {
          id: 30,
          name: 'Terrorist Arrests/Captured/Killed Locations',
          color: '#FFA500',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: '2021-03-19T14:39:44.827Z',
          updated_at: '2021-05-18T14:53:06.205Z',
          deleted_at: null,
        },
        {
          id: 25,
          name: 'Heroin / Krokodil / Brown Sugar',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: '2021-03-15T16:59:12.764Z',
          updated_at: '2021-06-28T11:49:51.011Z',
          deleted_at: null,
        },
        {
          id: 23,
          name: 'Methamphetamine / Amphetamine - Trafficking',
          color: '#FF7D05',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: '2021-03-15T16:58:22.670Z',
          updated_at: '2021-06-28T11:50:07.459Z',
          deleted_at: null,
        },
        {
          id: 22,
          name: 'Marijuana / Hashish / Edible - Trafficking',
          color: '#FF7D05',
          description: 'Marijuana Trafficking',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: '2021-03-15T16:56:42.862Z',
          updated_at: '2021-06-28T11:49:55.172Z',
          deleted_at: null,
        },
      ],
    },
  ],
};

export const scutumSetup = {
  defaultMapStyle: 'mapbox://styles/joaogn/ckq2kyiwc2g1k18qvfownydky',
  extraServices: [],
  categories: [
    {
      id: 132,
      color: '#3396F1',
      name: 'Natural Hazards',
      subcategories: [
        {
          id: 4,
          name: 'Earthquake',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 132,
          created_at: null,
          updated_at: '2021-05-18T09:26:34.999Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 275,
          name: 'Landslide',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 132,
          created_at: null,
          updated_at: '2021-07-05T14:00:34.518Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 154,
          name: 'Volcanic activity',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 132,
          created_at: null,
          updated_at: '2021-05-19T09:53:52.510Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 153,
          name: 'Tsunami',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 132,
          created_at: null,
          updated_at: '2021-05-19T09:53:39.146Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 7,
          name: 'Hurricane/Typhoons/Cyclones',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-06-18T15:36:17.860Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 254,
          name: 'Snow',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-06-22T09:22:27.046Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 253,
          name: 'Wind',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-06-22T09:22:17.286Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 249,
          name: 'Tornado',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-06-18T15:36:59.850Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 227,
          name: 'Tidal waves',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-05-25T14:06:38.797Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 159,
          name: 'Storms',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-05-25T14:06:48.478Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 129,
          name: 'Tropical Storm',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 135,
          created_at: null,
          updated_at: '2021-07-09T13:31:33.926Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 6,
          name: 'Floods',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 133,
          created_at: null,
          updated_at: '2021-05-19T09:54:55.693Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 250,
          name: 'Coastal Flooding',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 133,
          created_at: null,
          updated_at: '2021-07-15T08:48:47.417Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 155,
          name: 'Avalanche',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 133,
          created_at: null,
          updated_at: '2021-05-19T09:54:36.309Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 8,
          name: 'Wildfires',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 134,
          created_at: null,
          updated_at: '2021-05-19T09:56:35.480Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 157,
          name: 'Droughts',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 134,
          created_at: null,
          updated_at: '2021-05-19T09:56:05.492Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 252,
          name: 'Severe cold',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 156,
          created_at: null,
          updated_at: '2021-06-22T09:21:22.570Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 251,
          name: 'Heat wave',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 156,
          created_at: null,
          updated_at: '2021-06-22T09:21:10.810Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 256,
          name: 'Rain-Flood',
          color: '#3396F1',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 255,
          created_at: null,
          updated_at: '2021-06-22T09:24:59.364Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 120,
      color: '#2878C0',
      name: 'Aviation Incidents',
      subcategories: [
        {
          id: 120,
          name: 'Aviation Incidents',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-10-27T13:49:16.709Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 328,
          name: 'Plane Crash - Private Or Corporate Jet',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-10-27T13:49:16.709Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 327,
          name: 'Plane Crash - Commercial Passenger Jet',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-10-27T13:49:16.700Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 283,
          name: 'Airway Traffic and Regulation',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-07-06T14:53:26.386Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 278,
          name: 'Plane - Lost contact',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-07-06T11:15:17.483Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 262,
          name: 'Air traffic controllers strike',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:27:00.334Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 96,
          name: 'Glider Crash Or Emergency Landing',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:22:19.368Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 95,
          name: 'Emergency, Forced Landing, Diversion - Military',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:22:10.921Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 94,
          name: 'Ultra-Light Aircraft - Hang Gliders - Etc',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:22:05.555Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 93,
          name: 'Hot Air Balloon Or Blimp Incidents',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:21:53.758Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 92,
          name: 'Helicopter Crash - Or Emergency Landing - Military',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:21:49.489Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 91,
          name: 'Aircraft Laser Incidents - Lasing',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-07-06T07:23:28.444Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 90,
          name: 'Helicopter/Gyrocopter Crash - Or Emergency Landing',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:21:41.861Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 89,
          name: 'Plane Crash - Military Aircraft',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:22:24.014Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 88,
          name: 'Emergency, Forced Landing, Diversion - Commercial',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:21:38.130Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 87,
          name: 'Emergency, Forced Landing, Diversion - Private',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:21:33.973Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 86,
          name: 'Airspace Intrusion',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:21:29.856Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 85,
          name: 'Plane Crash - Private Or Corporate Small Aircraft',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:21:26.000Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 84,
          name: 'Airport Incident',
          color: '#2878C0',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 120,
          created_at: null,
          updated_at: '2021-06-29T10:21:21.701Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 121,
      color: '#1E5A90',
      name: 'Technological / Man-Made Hazards',
      subcategories: [
        {
          id: 364,
          name: 'Death / Demise',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 121,
          created_at: null,
          updated_at: '2021-06-29T15:34:34.743Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 272,
          name: 'Rescue Operation',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 121,
          created_at: null,
          updated_at: '2021-06-29T15:34:34.743Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 162,
          name: 'Famine',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 121,
          created_at: null,
          updated_at: '2021-05-19T10:08:40.163Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 160,
          name: 'Nautical Accidents',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 271,
          created_at: null,
          updated_at: '2021-06-29T10:37:23.111Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 161,
          name: 'Roadway Accidents',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 263,
          created_at: null,
          updated_at: '2021-07-07T14:50:46.455Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 230,
          name: 'Railway accidents',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 270,
          created_at: null,
          updated_at: '2021-06-29T10:36:19.441Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 284,
          name: 'Explosion Incident',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 50,
          created_at: null,
          updated_at: '2021-07-06T15:34:34.204Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 279,
          name: 'Fire incident',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 50,
          created_at: null,
          updated_at: '2021-07-06T14:26:50.568Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 274,
          name: 'Structural failure',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 353,
          created_at: null,
          updated_at: '2021-07-02T10:24:24.197Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 56,
          name: 'Hazmat - Drugs',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-05-10T16:17:50.107Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 55,
          name: 'Hazmat - Suspicious or Threatening Powder',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:57:18.020Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 54,
          name: 'Hazmat - False Alarm',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:57:00.716Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 53,
          name: 'Hazmat - Chemical',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:56:44.206Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 52,
          name: 'Hazmat - Odors/Fumes',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:56:30.745Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 51,
          name: 'Hazmat - Other/Unknown',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:56:19.358Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 49,
          name: 'Hazmat - Fuel/Oil/Gas',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:53.938Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 48,
          name: 'Hazmat - Chemical',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:21.168Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 47,
          name: 'Hazmat- Biological',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:04.008Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 363,
          name: 'Electricity Incident',
          color: '#1E5A90',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 119,
          created_at: null,
          updated_at: '2021-03-22T06:55:04.008Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 356,
      color: '#ffbd3e',
      name: 'Law Enforcement',
      subcategories: [
        {
          id: 359,
          name: 'Accusation',
          color: '#ffbd3e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 356,
          created_at: null,
          updated_at: '2021-10-29T12:33:02.407Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 358,
          name: 'Arrests',
          color: '#ffbd3e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 356,
          created_at: null,
          updated_at: '2021-10-29T12:33:02.396Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 357,
          name: 'Police',
          color: '#ffbd3e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 356,
          created_at: null,
          updated_at: '2021-10-29T12:33:02.389Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 125,
      color: '#FFAD0F',
      name: 'Crime',
      subcategories: [
        {
          id: 297,
          name: 'Firearms incident (shooting)',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-07-26T13:21:44.390Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 294,
          name: 'Non-firearms weapon incident (bladed, blunt)',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-07-26T13:21:02.795Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 280,
          name: 'Bomb incident',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-07-06T14:42:11.033Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 260,
          name: 'State Force Actions',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:53:56.717Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 219,
          name: 'Hijacking',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:43:01.244Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 217,
          name: 'Vandalism',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:42:50.527Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 216,
          name: 'Theft / Robbery',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:43:46.601Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 213,
          name: 'Assasination / Assanitation Attempt / Homicide',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:43:25.992Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 209,
          name: 'Cyber crime / Cyber Attack',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:45:38.138Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 38,
          name: 'Arson',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-28T11:42:43.237Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 37,
          name: 'Abductions/ Kidnapping',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-05-18T10:52:47.820Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 34,
          name: 'Biological Threats/ Anthrax Hoaxes',
          color: '#FFAD0F',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 125,
          created_at: null,
          updated_at: '2021-06-11T10:15:30.602Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 116,
      color: '#e59b0d',
      name: 'Trafficking',
      subcategories: [
        {
          id: 27,
          name: 'Cocaine Trafficking',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: null,
          updated_at: '2021-06-28T11:49:40.159Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 82,
          name: 'Opium',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: null,
          updated_at: '2021-06-28T11:49:59.187Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 25,
          name: 'Heroin / Krokodil / Brown Sugar',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: null,
          updated_at: '2021-06-28T11:49:51.011Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 24,
          name: 'Multi Drug Raid / Arrests / Discoveries',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: null,
          updated_at: '2021-06-28T11:49:47.782Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 23,
          name: 'Methamphetamine / Amphetamine - Trafficking',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: null,
          updated_at: '2021-06-28T11:50:07.459Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 22,
          name: 'Marijuana / Hashish / Edible - Trafficking',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 115,
          created_at: null,
          updated_at: '2021-06-28T11:49:55.172Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 288,
          name: 'Trafficking - other',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-07-07T13:33:07.344Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 286,
          name: 'Goods Trafficking',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-07-07T08:50:31.548Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 259,
          name: 'Justice: Illegal wildlife and plant trade',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:52:57.062Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 258,
          name: 'Justice: Illegal fishing trade',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:52:37.150Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 212,
          name: 'Illegal wildlife & plant trade',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:46:55.898Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 211,
          name: 'Weapons/firearms trafficking',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:46:52.974Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 210,
          name: 'Migrant smuggling',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:47:25.248Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 83,
          name: 'Medicine - Smuggling',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:46:46.088Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 46,
          name: 'Tobacco Trafficking',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 116,
          created_at: null,
          updated_at: '2021-06-28T11:46:34.748Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 292,
          name: 'Child Pornography / Endangerment',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 114,
          created_at: null,
          updated_at: '2021-07-15T07:47:13.444Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 231,
          name: 'Child exploitation/porn',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 114,
          created_at: null,
          updated_at: '2021-06-28T11:48:14.233Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 41,
          name: 'Human Trafficking',
          color: '#e59b0d',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 114,
          created_at: null,
          updated_at: '2021-06-28T11:47:54.233Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 144,
      color: '#b2404e',
      name: 'Political Violence',
      subcategories: [
        {
          id: 347,
          name: 'Strikes',
          color: '#b2404e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 144,
          created_at: null,
          updated_at: '2021-10-29T09:26:49.262Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 346,
          name: 'Protests',
          color: '#b2404e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 144,
          created_at: null,
          updated_at: '2021-10-29T09:26:49.253Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 166,
          name: 'State Force Actions',
          color: '#b2404e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 144,
          created_at: null,
          updated_at: '2021-06-04T13:40:32.189Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 164,
          name: 'Massacre',
          color: '#b2404e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 144,
          created_at: null,
          updated_at: '2021-05-19T10:10:55.039Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 163,
          name: 'Civil unrest / Riots / Rebellion',
          color: '#b2404e',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 144,
          created_at: null,
          updated_at: '2021-05-19T12:48:16.161Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 350,
      color: '#a82838',
      name: 'Military Conflicts / Insurgencies',
      subcategories: [
        {
          id: 181,
          name: 'Captured Territory',
          color: '#a82838',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 350,
          created_at: null,
          updated_at: '2021-05-19T10:22:44.113Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 147,
          name: 'Hijacking',
          color: '#a82838',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 350,
          created_at: null,
          updated_at: '2021-05-18T10:40:26.845Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 32,
          name: 'General Terrorism News',
          color: '#a82838',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 350,
          created_at: null,
          updated_at: '2021-05-18T14:54:39.573Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 142,
          name: 'Piracy',
          color: '#a82838',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 137,
          created_at: null,
          updated_at: '2021-05-18T09:45:16.246Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 180,
          name: 'Hostage Situation',
          color: '#a82838',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 180,
          created_at: null,
          updated_at: '2021-10-29T09:56:24.029Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 33,
          name: 'Terrorism And Related Court Cases',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-05-18T14:55:20.377Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 30,
          name: 'Terrorist Arrests/Killed',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-05-18T14:53:06.205Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 182,
      color: '#9F1122',
      name: 'Armed Incidents',
      subcategories: [
        {
          id: 352,
          name: 'Armed Incidents - Misc',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-10-29T10:34:39.775Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 248,
          name: 'Non-lethal weapons incident (gas, water canons, etc.)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:30:32.010Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 247,
          name: 'Non-firearms weapon incident (bladed, blunt, etc.)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:28:25.157Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 222,
          name: 'Light weapons incident (Shelling)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-11T14:54:07.151Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 221,
          name: 'Small arms incident (Assault rifles, pistols, sniper rifles)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-14T14:28:23.911Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 179,
          name: 'Bombings / IEDs',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:26:38.443Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 175,
          name: 'Heavy weapons incident (Tanks, Armoured vehicles)',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-06-15T09:22:59.025Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 36,
          name: 'Assassination',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 182,
          created_at: null,
          updated_at: '2021-05-18T10:52:23.557Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 141,
      color: '#9F1122',
      name: 'Geopolitics and Diplomacy',
      subcategories: [
        {
          id: 287,
          name: 'Military Movements',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-07-07T13:34:14.192Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 174,
          name: 'Human security',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-05-19T10:16:42.262Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 173,
          name: 'Peace Talks',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-05-19T10:16:31.207Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 172,
          name: 'Conflict Resolution',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-05-19T10:16:23.437Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 171,
          name: 'Important Announcements/ State/war declarations',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-05-19T10:16:10.078Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 152,
          name: 'Migration',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-05-18T15:14:09.536Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 35,
          name: 'Embassies incidents',
          color: '#9F1122',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 141,
          created_at: null,
          updated_at: '2021-05-18T12:59:59.455Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
      ],
    },
    {
      id: 117,
      color: ' #B081ED',
      name: 'Diseases',
      subcategories: [
        {
          id: 326,
          name: 'Coronavirus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.493Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 325,
          name: 'Nipah Virus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.485Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 324,
          name: 'H3N2 - Swine Flu / Canine Influenza',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.477Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 323,
          name: 'General News',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.470Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 322,
          name: 'Small Pox',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.464Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 321,
          name: 'Q-Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.456Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 320,
          name: 'Schmallenberg Virus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.448Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 319,
          name: 'Ricin',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.440Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 318,
          name: 'KCP',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.433Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 317,
          name: 'Glanders',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.426Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 316,
          name: 'NDM-1',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.418Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 315,
          name: 'Hendra Virus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.408Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 314,
          name: 'Biological Incidents/ Threats/ Anthrax Hoaxes etc',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.400Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 313,
          name: 'Monkey Pox',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.394Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 312,
          name: 'Miscellaneous / Unknown Diseases or Illnesses',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.386Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 311,
          name: 'Rathayibacter ',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.378Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 310,
          name: 'Rift Valley Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.371Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 309,
          name: 'Avian Flu',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.363Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 308,
          name: 'Notable H1N1 News And Announcements',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.355Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 307,
          name: 'Rabies',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.349Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 306,
          name: 'West Nile Virus (suspected or confirmed)',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.340Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 305,
          name: 'Vaccines',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.334Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 304,
          name: 'Suspicious or Threatening Powder',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.327Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 303,
          name: 'Typhoid / Typhus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.320Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 302,
          name: 'Anthrax ',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.314Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 301,
          name: 'Classical Swine Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.306Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 300,
          name: 'Meningitis Outbreak ( Suspected or Confirmed)',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.299Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 299,
          name: 'Salmonella Outbreak (Suspected or Confirmed)',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: null,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-10-27T13:19:14.271Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 80,
          name: 'Botulism',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:11.240Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 79,
          name: 'Newcastle Disease',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:17.153Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 78,
          name: 'Brucellosis',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:33.067Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 77,
          name: 'Tularemia',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:28.004Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 76,
          name: 'Foot-And-Mouth Disease',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:24.745Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 75,
          name: 'Lassa Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:20.716Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 74,
          name: 'Ebola / Marburg',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:07.172Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 73,
          name: 'Cholera Outbreak',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:18:03.363Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 72,
          name: 'Malaria',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:59.201Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 71,
          name: 'Chikungunya',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:54.085Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 70,
          name: 'Hantavirus',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:49.322Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 69,
          name: 'Swine Flu - Suspected or Probable Cases',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:13.851Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 68,
          name: 'Anthrax',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:45.086Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 67,
          name: 'Encephalitis',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:41.187Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 66,
          name: 'Congo Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:37.409Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 65,
          name: 'Polio',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:31.440Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 64,
          name: 'Plague',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:19.522Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 63,
          name: 'Zika',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:09.749Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 62,
          name: 'African Swine Fever / Swine Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:06.199Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 61,
          name: 'Swine Flu - Confirmed / Possible Related Death',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:02.208Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 60,
          name: 'Dengue / Hemorrhagic Fever',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:16:57.884Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 58,
          name: 'Swine Flu - Confirmed Cases',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:26.433Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 57,
          name: 'H7N9 / H5N1 / H5N2 / H7N1 / H7N3 / H7N7 / H5N8',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:23.055Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 360,
          name: 'Epidemic Hazard',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:23.055Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 361,
          name: 'Epidemic Hazard (Animal)',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:23.055Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/earthquake_icon.svg',
        },
        {
          id: 362,
          name: 'Infection Hazard',
          color: ' #B081ED',
          description: '',
          kw_in: [],
          kw_ex: [],
          classifier_score: 0.9,
          geolocation: null,
          parent_id: 117,
          created_at: null,
          updated_at: '2021-06-29T16:17:23.055Z',
          deleted_at: null,
          icon: 'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/_icon.svg',
        },
      ],
    },
  ],
  mainCouranteByName: true,
};
