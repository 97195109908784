import React, { ComponentType, useState } from 'react';
import { TextInputProps } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import ErrorIcon from '../../assets/icons/error_outline.svg';
import { Caption } from '../Typography';

import {
  Container,
  Label,
  InputContainer,
  InputText,
  IconsContainer,
  ErrorContainer,
  ErrorText,
} from './styles';

export interface InputProps extends TextInputProps {
  label?: string;
  error?: string;
  icon?: ComponentType<SvgProps>;
}

export const Input = ({
  icon: Icon,
  label,
  error,
  style,
  value,
  placeholder,
  ...rest
}: InputProps) => {
  const theme = useTheme();
  const [hasFocus, setHasFocus] = useState(false);

  return (
    <Container style={style}>
      {!label && value && placeholder && (
        <Caption style={{ color: '#6a6a9f' }}>{placeholder}</Caption>
      )}
      {label && <Label>{label}</Label>}
      <InputContainer>
        <InputText
          value={value}
          testID="input-element"
          withIcon={!!Icon}
          error={!!error && !hasFocus}
          hasFocus={hasFocus}
          placeholderTextColor={theme.purpleBlue}
          placeholder={placeholder}
          onBlur={() => setHasFocus(false)}
          onFocus={() => setHasFocus(true)}
          {...rest}
        />
        {((!!error && !hasFocus) || !!Icon) && (
          <IconsContainer withIcon={!!Icon}>
            {Icon && (
              <Icon
                testID="input-icon"
                width={20}
                height={20}
                viewBox="0 0 24 24"
                fill={theme.white}
              />
            )}
          </IconsContainer>
        )}
      </InputContainer>
      {error && !hasFocus && (
        <ErrorContainer>
          <ErrorIcon
            testID="input-error-icon"
            fill={theme.red}
            width={16}
            height={16}
            viewBox="0 0 24 24"
          />
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
      )}
    </Container>
  );
};
