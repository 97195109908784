import { StyleProp, ViewStyle } from 'react-native';
import { Container, BadgeText } from './styles';

interface MainCouranteActionSelectorProps {
  text: string;
  color: string;
  style?: StyleProp<ViewStyle>;
}

export const Badge = ({
  text,
  color,
  style,
}: MainCouranteActionSelectorProps) => {
  return (
    <Container color={color} style={style}>
      <BadgeText>{text}</BadgeText>
    </Container>
  );
};
