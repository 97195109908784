/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useState } from 'react';
import { Modal } from '@atoms/Modal';
import { WorkspacePageAddNewWidgetModalStep1 } from '@molecules/WorkspacePageAddNewWidgetModalStep1';
import { WorkspacePageAddNewWidgetModalStep2 } from '@molecules/WorkspacePageAddNewWidgetModalStep2';
import { WorkspacePageAddNewWidgetModalStep3 } from '@molecules/WorkspacePageAddNewWidgetModalStep3';
import { WorkspacePageAddNewWidgetModalNameModal } from '@molecules/WorkspacePageAddNewWidgetModalNameModal';

import { IconButton } from '@atoms/IconButton';
import {
  useQueryWidgetTypes,
  WidgetType,
} from '@services/hooks/useQueryWidgetTypes';
import { useCreateNewWidget } from '../../../hooks/useCreateNewWidget';

import { Container } from './styles';
import closeIcon from '../../../assets/icons/close.svg';

interface WorkspaceAreaAddModalProps {
  pageId: string;
  isVisible?: boolean;
  onClose: () => void;
}

export const WorkspacePageAddNewWidgetModal = ({
  pageId,
  onClose,
  isVisible,
}: WorkspaceAreaAddModalProps) => {
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [subTypes, setSubTypes] = useState<WidgetType[]>([]);
  const [widgetTypeName, setWidgetTypeName] = useState<string>();
  const [isNameModalOpen, setIsNameModalOpen] = useState(false);
  const [createWidgetType, setCreateWidgetType] = useState<WidgetType>();
  const [createWidgetConfigId, setCreateWidgetConfigId] = useState<string>();
  const widgetTypesQuery = useQueryWidgetTypes();

  const { createMapWidget } = useCreateNewWidget(pageId);

  const handleSelectType = async (widgetType: WidgetType) => {
    if (widgetType.types.length > 0) {
      setWidgetTypeName(widgetType.name);
      setSubTypes(widgetType.types);
      setStep(2);
      return;
    }
    if (widgetType.is_configurable) {
      setCreateWidgetType(widgetType);
      setStep(3);
      return;
    }
    setCreateWidgetType(widgetType);
    setIsNameModalOpen(true);
  };

  const handleSelectConfig = async (configId: string | undefined) => {
    setCreateWidgetConfigId(configId);
    setIsNameModalOpen(true);
  };

  const handleCreateWidget = async (name: string) => {
    if (createWidgetType) {
      await createMapWidget({
        name,
        page_id: pageId,
        type_id: createWidgetType.id,
        type_slug: createWidgetType.slug,
        config_id: createWidgetConfigId,
        is_configurable: createWidgetType.is_configurable,
      });
      onClose();
    }
  };

  const handleBackToStep1 = () => {
    setWidgetTypeName(undefined);
    setSubTypes([]);
    setStep(1);
  };

  return (
    <Modal visible={isVisible}>
      <Container>
        <IconButton
          icon={closeIcon}
          viewBox="0 0 30 30"
          width={24}
          height={24}
          style={{ position: 'absolute', right: 8, top: 8 }}
          onPress={onClose}
        />
        {step === 1 ? (
          <WorkspacePageAddNewWidgetModalStep1
            isloading={widgetTypesQuery.isLoading}
            widgetTypes={widgetTypesQuery.data || []}
            onSelectType={handleSelectType}
          />
        ) : step === 2 ? (
          widgetTypeName &&
          subTypes.length > 0 && (
            <WorkspacePageAddNewWidgetModalStep2
              parentWidgetName={widgetTypeName}
              widgetTypes={subTypes}
              onSelectType={handleSelectType}
              onBackPress={handleBackToStep1}
            />
          )
        ) : (
          createWidgetType && (
            <WorkspacePageAddNewWidgetModalStep3
              widgetTypeName={createWidgetType.name}
              widgetTypeId={createWidgetType.id}
              onSelectConfig={configId => handleSelectConfig(configId)}
              onBackPress={handleBackToStep1}
            />
          )
        )}
      </Container>
      {isNameModalOpen && (
        <WorkspacePageAddNewWidgetModalNameModal
          onClose={() => setIsNameModalOpen(false)}
          onSave={handleCreateWidget}
          isVisible={isNameModalOpen}
        />
      )}
    </Modal>
  );
};
