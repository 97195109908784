import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 200px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  z-index: 2;
`;

export const BoardsContainer = styled.View`
  margin-top: 4px;
  margin-bottom: 5px;
  padding: 0 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  z-index: 1;
`;
