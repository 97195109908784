import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Body1 } from '../../Typography';
import { Input } from '../../Input';
import { useMutationActionType } from '../../../services/hooks/useMutationActionType';
import { Container, Content, ButtonsContainer, ActionButton } from './styles';
import { useToastContext } from '../../../contexts/ToastContext';

interface CreateActionTypeModalProps {
  teamId: string;
  onClose: () => void;
}

interface CreateActionTypeFormData {
  name: string;
}

const createActionTypFormValidation = yup.object().shape({
  name: yup.string().required(),
});

export const CreateActionTypeModal = ({
  teamId,
  onClose,
}: CreateActionTypeModalProps) => {
  const { t } = useTranslation();
  const useQuery = useMutationActionType(teamId);
  const { dispatchToast } = useToastContext();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateActionTypeFormData>({
    resolver: yupResolver(createActionTypFormValidation),
  });

  const onSubmit = async ({ name }: CreateActionTypeFormData) => {
    try {
      await useQuery.mutateAsync({
        name,
        team_id: teamId,
      });
      onClose();
    } catch (err) {
      dispatchToast({ message: t('error_create_action_type'), type: 'error' });
    }
  };

  return (
    <Container>
      <Content>
        <Body1>{t('create_action_type')}</Body1>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              value={value}
              onChangeText={onChange}
              placeholder={t('create_action_type_placeholder')}
              label={t('name_of_the_action')}
              error={errors?.name?.message}
            />
          )}
          name="name"
        />

        <ButtonsContainer>
          <ActionButton outline onPress={onClose}>
            {t('modal_cancel')}
          </ActionButton>
          <ActionButton onPress={handleSubmit(onSubmit)} loading={isSubmitting}>
            {t('modal_confirm')}
          </ActionButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};
