import styled from 'styled-components';
import styledNative from 'styled-components/native';
import { Rnd } from 'react-rnd';
import { Button } from '@atoms/Button';
import { Dimensions } from 'react-native';

export const ClearButton = styledNative(Button)`
  height: ${() => {
    const { width } = Dimensions.get('window');
    if (width < 1300) {
      return '40px';
    }
    return '48px';
  }};
`;

export const Container = styled(Rnd)`
  z-index: 5;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px #05050f;
  position: absolute;
  padding-bottom: 8px;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
`;
