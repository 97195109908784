/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { api } from '../userManagementApi';

export interface Document {
  id: string;
  document_name: string;
  pre_signed_url: string;
  type?: 'video' | 'image';
}

const types = new Map([
  ['png', 'image'],
  ['jpeg', 'image'],
  ['bmp', 'image'],
  ['webp', 'image'],
  ['jpg', 'image'],
  ['gif', 'image'],
  ['mp4', 'video'],
  ['3gp', 'video'],
  ['ogg', 'video'],
  ['avi', 'video'],
  ['webm', 'video'],
  ['mkv', 'video'],
  ['flv', 'video'],
  ['mov', 'video'],
  ['vob', 'video'],
  ['wmv', 'video'],
  ['mpg', 'video'],
  ['mpeg', 'video'],
  ['m4v', 'video'],
]);

function getFileNameWithExt(name: string) {
  const lastDot = name.lastIndexOf('.');

  return name.substring(lastDot + 1);
}

export async function getDocuments(
  team_id: string,
  main_courante_id?: string,
  pre_signed = false,
): Promise<Document[]> {
  const response = await api.get('/v1/documents', {
    params: {
      team_id,
      main_courante_id,
      pre_signed,
    },
  });

  const normalizeData = response.data.map((document: any) => {
    return {
      id: document.id,
      document_name: document.document_name,
      pre_signed_url: document.pre_signed_url,
      type: types.get(getFileNameWithExt(document.document_name)),
    };
  });

  return normalizeData;
}

export function useQueryDocuments(
  team_id: string,
  main_courante_id?: string,
  pre_signed = false,
) {
  return useQuery(
    ['documents', team_id, main_courante_id, pre_signed],
    () => getDocuments(team_id, main_courante_id, pre_signed),
    {
      refetchOnWindowFocus: false,
    },
  );
}
