import { useTheme } from 'styled-components/native';
import {
  CreateLocationExistedAddressAutoCompleteInput,
  ExistedAddressFeature,
} from '@molecules/CreateLocationExistedAddressAutoCompleteInput';
import { CreateLocationExistedAddressSelectCustomAndConfirmButtons } from '@molecules/CreateLocationExistedAddressSelectAndConfirmButtons';
import { useZonesContext } from '@contexts/ZonesContext';
import { Location } from '@contexts/CreateLocationContext';
import { Container } from './styles';

interface CreateLocationExistedAddressProps {
  locationZoneType: 'main_courante' | 'main_courante_action';
  selectedAddress?: Location;
  setSelectedAddress: (location: Location) => void;
  setSelectMode: (mode: 'custom') => void;
  onConfirm: (location: Location) => void;
  locationId: string;
}

export function CreateLocationExistedAddress({
  selectedAddress,
  setSelectedAddress,
  setSelectMode,
  onConfirm,
  locationId,
  locationZoneType,
}: CreateLocationExistedAddressProps) {
  const { addNewZone } = useZonesContext();
  const theme = useTheme();
  function handleSelectedAdress(data: ExistedAddressFeature) {
    const zone = {
      id: locationId,
      type: 'Feature' as const,
      properties: {
        name: `${locationZoneType}_event`,
        type: locationZoneType,
        color:
          locationZoneType === 'main_courante'
            ? 'url(#main_courante)'
            : theme.eletricGreen,
        editable: false,
      },
      geometry: data.geometry,
    };
    const location_name = data.place_name;
    addNewZone(zone);
    setSelectedAddress({ zone, location_name });
  }

  return (
    <Container>
      <CreateLocationExistedAddressAutoCompleteInput
        placeholder="Address"
        onSelectAddress={data => handleSelectedAdress(data)}
      />
      <CreateLocationExistedAddressSelectCustomAndConfirmButtons
        onSelectCustomPress={() => setSelectMode('custom')}
        onConfirmPress={() =>
          selectedAddress ? onConfirm(selectedAddress) : {}
        }
        hasSelectedAddress={!!selectedAddress}
      />
    </Container>
  );
}
