import styled from 'styled-components/native';
import { transparentize } from 'polished';

export const Container = styled.View`
  padding: 4px;
  width: 45%;
  max-width: 250px;
  height: 80px;
  background-color: ${({ theme }) => transparentize(0.5, theme.black)};
  border-radius: 20px;
`;
