import styled from 'styled-components';
import { gridConstants } from '../../../hooks/useGridHelper';

const { WIDGET_INNER_PADDING } = gridConstants;

interface ContainerProps {
  left: number;
  top: number;
  isDragging: boolean;
}

export const Container = styled.div<ContainerProps>`
  padding: ${WIDGET_INNER_PADDING}px;
  position: absolute;
  transform: ${({ left, top }) => `translate3d(${left}px, ${top}px, 0)`};
  // IE fallback: hide the real node using CSS when dragging
  // because IE will ignore our custom "empty image" drag preview.
  opacity: ${({ isDragging }) => (isDragging ? 0 : 1)};
  height: ${({ isDragging }) => (isDragging ? 0 : '')};
`;
