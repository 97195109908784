import { Caption } from '@nucleus/Typography';

import { Container } from './styles';

interface TagProps {
  tag: string;
}
export const Tag = ({ tag }: TagProps) => {
  return (
    <Container>
      <Caption numberOfLines={1}>{tag}</Caption>
    </Container>
  );
};
