import { Overline } from '@nucleus/Typography';
import { Icon } from '@nucleus/Icon';
import windDirectionIcon from '../../../assets/icons/wind_direction.svg';
import { Container, DirectionIconContainer } from './styles';

interface AnalyticsTabsDynamicDataCardWindLabelProps {
  label: string;
  direction: number;
}

export const AnalyticsTabsDynamicDataCardWindLabel = ({
  label,
  direction,
}: AnalyticsTabsDynamicDataCardWindLabelProps) => {
  return (
    <Container>
      <Overline>{label}</Overline>
      <DirectionIconContainer direction={direction}>
        <Icon as={windDirectionIcon} />
      </DirectionIconContainer>
    </Container>
  );
};
