import { ReactNode } from 'react';

import {
  Modal as ReactNativeModal,
  ModalProps as ReactNativeModalProps,
} from 'react-native';

import { PortalHost } from '@gorhom/portal';
import { Container } from './styles';

interface ModalProps extends ReactNativeModalProps {
  children: ReactNode;
}
export const Modal = ({ children, ...props }: ModalProps) => {
  return (
    <ReactNativeModal {...props} transparent>
      <Container>
        {children}
        <PortalHost name="root" />
      </Container>
    </ReactNativeModal>
  );
};
