import styled from 'styled-components/native';
import { Button } from '../../Button';
import { ButtonPrimary } from '../../Typography';

export const Container = styled.View`
  width: 95%;
  max-width: 275px;
  align-items: center;
`;

export const CreateButton = styled(Button)`
  width: 200px;
  height: 32px;
  background-color: ${({ theme }) => theme.purpleBlue};
`;

export const ErrorContainer = styled.View`
  margin-top: 36px;
  flex-direction: row;
  position: absolute;
`;

export const ErrorText = styled(ButtonPrimary)`
  color: ${({ theme }) => theme.red};
  margin-left: 4px;
  font-weight: 400;
`;
