import React from 'react';
import ExpoCheckbox from 'expo-checkbox';
import { useTheme } from 'styled-components/native';
import { CheckBoxProps } from './types';

export const CheckBox = ({ value, onValueChange, size }: CheckBoxProps) => {
  const theme = useTheme();
  function handleChange() {
    onValueChange(!value);
  }
  return (
    <ExpoCheckbox
      data-testid="checkbox-container"
      testID="checkbox-container"
      value={value}
      onValueChange={handleChange}
      style={{
        height: size || 12,
        width: size || 12,
        borderColor: theme.blue,
        backgroundColor: 'transparent',
      }}
      color={value ? theme.blue : '#d5d5d5'}
    />
  );
};
