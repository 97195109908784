import styled from 'styled-components';
import { transparentize } from 'polished';

export const Container = styled.div``;

export const TooltipContent = styled.div`
  border-radius: 20px;
  background-color: ${({ theme }) => theme.purpleBlue};
  padding: 8px;
  align-items: center;
  justify-content: center;
  max-width: 350px;
  box-shadow: 0px 0px 4px
    ${({ theme }) => transparentize(0.55, theme.purpleBlue)};
`;

export const DeltaTop = styled.div`
  size: 20px;
  position: absolute;
  bottom: -8px;
  width: 0px;
  height: 0px;
  background-color: transparent;
  border-style: solid;
  border-top-width: 8px;
  border-right-width: 8px;
  border-bottom-width: 0;
  border-left-width: 8px;
  border-top-color: ${({ theme }) => theme.purpleBlue};
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
  box-shadow: 0px 0px 4px
    ${({ theme }) => transparentize(0.55, theme.purpleBlue)};
`;

export const DeltaBottom = styled.div`
  size: 20px;
  position: absolute;
  top: -8px;
  width: 0px;
  height: 0px;
  background-color: transparent;
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 8px;
  border-bottom-width: 8px;
  border-left-width: 8px;
  border-top-color: transparent;
  border-bottom-color: ${({ theme }) => theme.purpleBlue};
  border-right-color: transparent;
  border-left-color: transparent;
  box-shadow: 0px 0px 4px
    ${({ theme }) => transparentize(0.55, theme.purpleBlue)};
`;
