/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { api } from '../userManagementApi';

export interface Page {
  id: string;
  name: string;
}

export interface Workspace {
  pages: Page[];
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export async function getWorkspace(workspace_id: string): Promise<Workspace> {
  const getWorkspaceResponse = await api.get(`/v1/workspaces/${workspace_id}`);

  return getWorkspaceResponse.data;
}

export function useQueryWorkspace(workspace_id: string) {
  return useQuery(['workspace', workspace_id], () =>
    getWorkspace(workspace_id),
  );
}
