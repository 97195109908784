import styled from 'styled-components/native';
import { Button } from '../../Button';

export const Container = styled.View`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

export const SideBySide = styled.View`
  margin-top: 16px;
  width: 98%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ConfirmButton = styled(Button)`
  margin-top: 24px;
  margin-right: 16px;
  width: 136px;
  height: 40px;
  align-self: flex-end;
`;
