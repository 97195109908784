/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation } from '@react-navigation/native';
import { Image } from '../../components/Image';
import loginLogo from '../../assets/loginLogo.png';
import {
  Container,
  LoginForm,
  SubLogoContainer,
  ButtonsContainer,
} from './styles';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import alpesMaritimes from '../../assets/alpesMaritimes.png';
import { api } from '../../services/userManagementApi';
import { useToastContext } from '../../contexts/ToastContext';
import { config } from '../../config';

interface ForgetCredentials {
  email: string;
}

export function ForgetPassword() {
  const navigation = useNavigation();
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  const forgetSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(t('valid_email')).required(t('valid_field')),
      }),
    [t],
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgetCredentials>({
    resolver: yupResolver(forgetSchema),
  });

  async function onSubmit({ email }: ForgetCredentials) {
    try {
      await api.post('v1/password/forgot', {
        email,
        redirect_url: `${config.APP_URL}/resetpassword`,
      });
      dispatchToast({
        message: t('success_email_sent'),
        type: 'success',
      });
      navigation.navigate('SignIn');
    } catch (err: any) {
      dispatchToast({
        message: err.response?.data?.message
          ? err.response.data.message
          : t('generic_request_error'),
        type: 'error',
      });
    }
  }

  return (
    <Container>
      <Image source={loginLogo} style={{ marginTop: '4%' }} />
      <LoginForm>
        <SubLogoContainer>
          <Image source={alpesMaritimes} />
        </SubLogoContainer>

        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              placeholder="mlp@gmail.com"
              label={t('email')}
              autoCompleteType="email"
              onChangeText={newValue => onChange(newValue)}
              value={value}
              error={errors.email?.message}
            />
          )}
          name="email"
          defaultValue=""
        />
        <ButtonsContainer>
          <Button loading={isSubmitting} onPress={handleSubmit(onSubmit)}>
            {t('send_email')}
          </Button>
          <Button onPress={() => navigation.navigate('SignIn')}>
            {t('cancel')}
          </Button>
        </ButtonsContainer>
      </LoginForm>
    </Container>
  );
}
