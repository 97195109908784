/* eslint-disable consistent-return */

import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { queryClient } from '../queryClient';
import { useToastContext } from '../../contexts/ToastContext';
import { createTab, deleteTab, updateTabData, Tab } from '../../utils/tabApi';

interface MutationData {
  newTab?: Tab;
  tabId?: string;
  type: 'create' | 'delete' | 'updateName';
  newTabName?: string;
}

export const useMutationTabs = () => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({ newTab, type, tabId, newTabName }: MutationData) => {
      if (type === 'create' && newTab) {
        const result = await createTab(newTab);
        return { result, type };
      }
      if (type === 'delete' && tabId) {
        const result = await deleteTab(tabId);
        return { result, type };
      }
      if (type === 'updateName' && tabId && newTabName) {
        const result = await updateTabData(tabId, { name: newTabName });
        return { result, type };
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tabs']);
      },
      onError: () => {
        dispatchToast({
          message: t('error_create_tab'),
          type: 'error',
        });
      },
    },
  );
};
