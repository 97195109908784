/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { useEffect, useCallback } from 'react';
import { Filters } from 'utils/tabApi';
import { socket } from '../services/eventSocket';
import { queryClient } from '../services/queryClient';
import { MainCourante } from '../services/hooks/useQueryFiltredMainCourantes';
import { MainCouranteAction } from '../services/hooks/useQueryMainCouranteInfo';
import { centerLocation } from '../utils/centerLocation';

const ACTION = {
  ADD: 'add',
  UPDATE: 'update',
  DELETE: 'delete',
};

interface ReducedMainCouranteActionCount {
  main_courante_id: string;
  closed_count: number;
  main_courante: MainCouranteAction['main_courante'];
  opened_count: number;
}

interface MainCouranteActionSocketAdd {
  action: 'add';
  main_courante_action: MainCouranteAction;
}

interface MainCouranteActionSocketUpdate {
  action: 'update';
  main_courante_actions: MainCouranteAction[];
}

interface MainCouranteActionSocketDelete {
  action: 'delete';
  main_courante_action_id: string;
}

type MainCouranteActionSocketResponse =
  | MainCouranteActionSocketAdd
  | MainCouranteActionSocketUpdate
  | MainCouranteActionSocketDelete;

interface UseMainCouranteActionRealTimeProps {
  filters: Filters;
  teamId?: string;
  selectedTab: string;
  setListOfActivedMainCouranteActions: React.Dispatch<
    React.SetStateAction<MainCouranteAction[]>
  >;
}

export const useMainCouranteActionRealTime = ({
  filters,
  teamId,
  selectedTab,
  setListOfActivedMainCouranteActions,
}: UseMainCouranteActionRealTimeProps) => {
  const verifyIsValidInput = useCallback(
    (main_courante_id: string) => {
      if (filters.mainCourante.length > 0) {
        const finded = filters.mainCourante.find(id => main_courante_id === id);
        if (finded) {
          return true;
        }
        return false;
      }
      return true;
    },
    [filters.mainCourante],
  );

  useEffect(() => {
    if (teamId)
      socket.on(
        `team:${teamId}:main_courante_action`,
        async (data: MainCouranteActionSocketResponse) => {
          if (data.action === ACTION.ADD) {
            const { main_courante_action } =
              data as MainCouranteActionSocketAdd;
            if (verifyIsValidInput(main_courante_action.main_courante_id)) {
              setListOfActivedMainCouranteActions(oldList => [
                ...oldList,
                {
                  ...main_courante_action,
                  location_coordinates: centerLocation(
                    main_courante_action.location_coordinates,
                  ),
                  zone:
                    main_courante_action.location_coordinates &&
                    main_courante_action.location_coordinates.type !== 'Point'
                      ? {
                          id: main_courante_action.id,
                          type: 'Feature' as const,
                          properties: {
                            main_courante_id:
                              main_courante_action.main_courante_id,
                            name: 'main_courante_action_event',
                            type: 'main_courante_action',
                            color: main_courante_action.location_color
                              ? main_courante_action.location_color
                              : main_courante_action.closed
                              ? '#FF2D2E'
                              : '#5EFF5A',
                            editable: false,
                          },
                          geometry: main_courante_action.location_coordinates,
                        }
                      : undefined,
                },
              ]);
              await queryClient.setQueryData(
                ['filtredMainCourantes', selectedTab, filters],
                oldData => {
                  const events = oldData as MainCourante[];
                  const updatedEvents = events.map(event => {
                    if (
                      event.id === main_courante_action.main_courante_id &&
                      event.type === 'main_courante'
                    ) {
                      return {
                        ...event,
                        main_courante_actions_total:
                          event.main_courante_actions_total + 1,
                        main_courante_actions_closed:
                          main_courante_action.closed
                            ? event.main_courante_actions_closed + 1
                            : event.main_courante_actions_closed,
                        main_courante_actions_opened:
                          main_courante_action.closed
                            ? event.main_courante_actions_opened
                            : event.main_courante_actions_opened + 1,
                      };
                    }
                    return event;
                  });

                  return updatedEvents;
                },
              );
            }
          }
          if (data.action === ACTION.DELETE) {
            const { main_courante_action_id } =
              data as MainCouranteActionSocketDelete;
            setListOfActivedMainCouranteActions(oldList =>
              oldList.filter(
                oldAction => oldAction.id !== main_courante_action_id,
              ),
            );
          }
          if (data.action === ACTION.UPDATE) {
            const { main_courante_actions } =
              data as MainCouranteActionSocketUpdate;
            setListOfActivedMainCouranteActions(oldList => {
              const allNotFindedItens = oldList.filter(
                oldAction =>
                  !main_courante_actions.find(
                    action => action.id === oldAction.id,
                  ),
              );
              return [
                ...allNotFindedItens.map(main_courante_action => ({
                  ...main_courante_action,
                  location_coordinates: centerLocation(
                    main_courante_action.location_coordinates,
                  ),
                  zone:
                    main_courante_action.location_coordinates &&
                    main_courante_action.location_coordinates.type !== 'Point'
                      ? {
                          id: main_courante_action.id,
                          type: 'Feature' as const,
                          properties: {
                            main_courante_id:
                              main_courante_action.main_courante_id,
                            name: 'main_courante_action_event',
                            type: 'main_courante_action',
                            color: main_courante_action.location_color
                              ? main_courante_action.location_color
                              : main_courante_action.closed
                              ? '#FF2D2E'
                              : '#5EFF5A',
                            editable: false,
                          },
                          geometry: main_courante_action.location_coordinates,
                        }
                      : undefined,
                })),
                ...main_courante_actions,
              ];
            });
            const reducedCount = main_courante_actions.reduce(
              (prev: ReducedMainCouranteActionCount[], curr) => {
                const index = prev.findIndex(
                  prevItem =>
                    prevItem.main_courante_id === curr.main_courante_id,
                );
                if (index === -1) {
                  prev.push({
                    main_courante_id: curr.main_courante_id,
                    main_courante: curr.main_courante,
                    closed_count: curr.closed ? 1 : 0,
                    opened_count: curr.closed ? 0 : 1,
                  });
                } else {
                  prev[index].closed_count = curr.closed
                    ? prev[index].closed_count + 1
                    : prev[index].closed_count;
                  prev[index].opened_count = curr.closed
                    ? prev[index].opened_count
                    : prev[index].opened_count + 1;
                }
                return prev;
              },
              [],
            );

            await queryClient.setQueryData(
              ['filtredMainCourantes', selectedTab, filters],
              oldData => {
                const events = oldData as MainCourante[];
                const updatedEvents = events.map(event => {
                  if (event.type === 'main_courante') {
                    const foundedCountData = reducedCount.find(
                      countData => countData.main_courante_id === event.id,
                    );
                    if (foundedCountData) {
                      if (
                        foundedCountData.closed_count ===
                          event.main_courante_actions_total ||
                        foundedCountData.opened_count ===
                          event.main_courante_actions_total
                      ) {
                        return {
                          ...event,
                          closed: foundedCountData.main_courante?.closed,
                          main_courante_actions_closed:
                            foundedCountData.closed_count,
                          main_courante_actions_opened:
                            foundedCountData.opened_count,
                        };
                      }

                      if (
                        foundedCountData.opened_count === 0 &&
                        foundedCountData.closed_count !== 0
                      ) {
                        return {
                          ...event,
                          closed: foundedCountData.main_courante?.closed,
                          main_courante_actions_closed:
                            event.main_courante_actions_closed +
                            foundedCountData.closed_count,
                          main_courante_actions_opened:
                            event.main_courante_actions_opened -
                            foundedCountData.closed_count,
                        };
                      }

                      if (
                        foundedCountData.opened_count > 0 &&
                        foundedCountData.closed_count === 0
                      ) {
                        return {
                          ...event,
                          closed: foundedCountData.main_courante?.closed,
                          main_courante_actions_closed:
                            event.main_courante_actions_closed -
                            foundedCountData.opened_count,
                          main_courante_actions_opened:
                            event.main_courante_actions_opened +
                            foundedCountData.opened_count,
                        };
                      }

                      return event;
                    }
                  }
                  return event;
                });
                return updatedEvents;
              },
            );
          }
        },
      );

    return () => {
      socket.off(`team:${teamId}:main_courante_action`);
    };
  }, [
    verifyIsValidInput,
    selectedTab,
    teamId,
    setListOfActivedMainCouranteActions,
    filters,
  ]);
};
