/* eslint-disable @typescript-eslint/no-empty-function */
import {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import { ActivityIndicator } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { AnalyticsTabsMainCouranteFlowRatioAddActionTypeModal } from '@organisms/AnalyticsTabsMainCouranteFlowRatioAddActionTypeModal';
import { AnalyticsTabsMainCouranteFlowRatioRatioBar } from '@organisms/AnalyticsTabsMainCouranteFlowRatioRatioBar';
import { AnalyticsTabsMainCouranteFlowRatioConfigureForm } from '@organisms/AnalyticsTabsMainCouranteFlowRatioConfigureForm';
import { Container, ContainerTitle } from './styles';
import { useQueryMainCourantes } from '../../../services/hooks/useQueryMainCourantes';
import { useAuthContext } from '../../../contexts/AuthContext';
import { getState, storeState } from '../../../utils/asyncStorage';

interface SelectActionData {
  color: string;
  action_type: {
    value: string;
    label: string;
  };
}

export const AnalyticsTabsMainCouranteFlowRatio = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useAuthContext();
  const { data: mainCourantes, isLoading } = useQueryMainCourantes(
    user?.teams[0].id,
  );
  const [selectedMainCourante, setSelectedMainCourante] = useState<string>();
  const [listOfInputs, setListOfInputs] = useState<SelectActionData[]>([]);
  const [listOfOutputs, setListOfOutputs] = useState<SelectActionData[]>([]);
  const [openSelectActionModal, setOpenSelectActionModal] = useState(false);
  const [typeOfActionType, setTypeOfActionType] = useState<
    'input' | 'output'
  >();

  const mainCourantesOptions = useMemo(() => {
    return mainCourantes
      ? mainCourantes.map(mainCourante => ({
          value: mainCourante.id,
          label: mainCourante.name,
        }))
      : [];
  }, [mainCourantes]);

  const onAddActionType = useCallback(
    ({ color, action_type }: SelectActionData) => {
      if (typeOfActionType === 'input') {
        setListOfInputs(oldInputsList => [
          ...oldInputsList,
          { color, action_type },
        ]);
        setTypeOfActionType(undefined);
      }
      if (typeOfActionType === 'output') {
        setListOfOutputs(oldOutputsList => [
          ...oldOutputsList,
          { color, action_type },
        ]);
        setTypeOfActionType(undefined);
      }
    },
    [typeOfActionType],
  );

  function removeItemFromInputArray(removeActionValue: string) {
    setListOfInputs(oldInputsList =>
      oldInputsList.filter(
        input => input.action_type.value !== removeActionValue,
      ),
    );
  }

  function removeItemFromOutputArray(removeActionValue: string) {
    setListOfOutputs(oldOutputsList =>
      oldOutputsList.filter(
        output => output.action_type.value !== removeActionValue,
      ),
    );
  }

  function handleAddInputPress() {
    setOpenSelectActionModal(true);
    setTypeOfActionType('input');
  }

  function handleAddOutputPress() {
    setOpenSelectActionModal(true);
    setTypeOfActionType('output');
  }

  useEffect(() => {
    async function storeData() {
      await storeState('@Challenge:MainCouranteFlowRatio', {
        selectedMainCourante,
        listOfInputs,
        listOfOutputs,
      });
    }
    storeData();
  }, [selectedMainCourante, listOfInputs, listOfOutputs]);

  useLayoutEffect(() => {
    async function getData() {
      const data = await getState('@Challenge:MainCouranteFlowRatio');
      setSelectedMainCourante(data.selectedMainCourante || undefined);
      setListOfInputs(data.listOfInputs || []);
      setListOfOutputs(data.listOfOutputs || []);
    }

    getData();
  }, []);

  return (
    <Container>
      <ContainerTitle>{t('main_courante_flow_ratio')}</ContainerTitle>
      {isLoading ? (
        <ActivityIndicator size="large" color={theme.purple} />
      ) : (
        <AnalyticsTabsMainCouranteFlowRatioConfigureForm
          selectedMainCourante={selectedMainCourante}
          onSelectMainCourante={value => setSelectedMainCourante(value[0])}
          mainCourantesOptions={mainCourantesOptions}
          listOfInputs={listOfInputs}
          onRemoveInputPress={value => removeItemFromInputArray(value)}
          onAddInputPress={handleAddInputPress}
          listOfOutputs={listOfOutputs}
          onRemoveOutputPress={value => removeItemFromOutputArray(value)}
          onAddOutputPress={handleAddOutputPress}
        />
      )}
      {selectedMainCourante &&
        listOfInputs.length > 0 &&
        listOfOutputs.length > 0 && (
          <AnalyticsTabsMainCouranteFlowRatioRatioBar
            main_courante_id={selectedMainCourante}
            listOfInputs={listOfInputs}
            listOfOutputs={listOfOutputs}
          />
        )}
      {openSelectActionModal && (
        <AnalyticsTabsMainCouranteFlowRatioAddActionTypeModal
          isVisible
          onCancel={() => setOpenSelectActionModal(false)}
          onConfirm={onAddActionType}
        />
      )}
    </Container>
  );
};
