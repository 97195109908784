/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Filters } from 'utils/tabApi';
import { api } from '../eventApi';
import { queryClient } from '../queryClient';
import { useToastContext } from '../../contexts/ToastContext';
import { MainCourante } from './useQueryFiltredMainCourantes';

interface CloseMainCouranteMutationProps {
  main_courante_id?: string;
  tabId: string;
  filters: Filters;
}

interface CloseMainCouranteMutationData {
  closed: boolean;
}

export const useMutationCloseMainCourante = ({
  main_courante_id,
  tabId,
  filters,
}: CloseMainCouranteMutationProps) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({ closed }: CloseMainCouranteMutationData) => {
      const { data } = await api.put(
        `/v1/main_courantes/closed_status/${main_courante_id}`,
        {
          closed,
        },
      );

      return data;
    },
    {
      onSuccess: async (data: any) => {
        await queryClient.invalidateQueries(['mainCourante', main_courante_id]);
        await queryClient.setQueryData(
          ['filtredMainCourantes', tabId, filters],
          oldData => {
            const events = oldData as MainCourante[];
            const newEvents = events.map(item => {
              if (item.id === main_courante_id) {
                return {
                  ...item,
                  closed: data.closed,
                  main_courante_actions_closed:
                    data.main_courante_actions.filter(
                      (action: any) => action.closed,
                    ).length,
                  main_courante_actions_opened:
                    data.main_courante_actions.filter(
                      (action: any) => !action.closed,
                    ).length,
                };
              }
              return item;
            });
            return newEvents;
          },
        );
        dispatchToast({
          message: t('closed_status_changed_success'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('closed_status_changed_fail'),
          type: 'error',
        });
      },
    },
  );
};
