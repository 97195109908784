import { useTranslation } from 'react-i18next';
import { Caption } from '@nucleus/Typography';
import { UserAvatar } from '@atoms/UserAvatar';
import { Container } from './styles';

interface MainCouranteCardActorProps {
  avatar?: string;
  fallbackName: string;
}

export const MainCouranteCardActor = ({
  avatar,
  fallbackName,
}: MainCouranteCardActorProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Caption>{`${t('Auteur')}:`}</Caption>
      <UserAvatar avatar={avatar} fallbackName={fallbackName} />
    </Container>
  );
};
