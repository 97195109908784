import { useState, useEffect, useCallback } from 'react';
import { WebMercatorViewport, ViewportProps } from 'react-map-gl';
import { Event } from '@services/hooks/useQueryFiltredEvents';
import { MainCourante } from '@services/hooks/useQueryFiltredMainCourantes';
import { MainCouranteAction } from '@services/hooks/useQueryMainCouranteInfo';
import { Zone } from '@contexts/ZonesContext';
import { Location } from '@contexts/CreateLocationContext';
import Geolocation from '@react-native-community/geolocation';
import bbox from '@turf/bbox';
import { useDebouncedCallback } from 'use-debounce';
import { Size } from '../utils/tabApi';

interface useMapControllerParams {
  parentSize: Size;
  autoFocusOff?: boolean;
  centerGeometry?: {
    type: 'Polygon';
    coordinates: number[][][];
  };
  selectedEvent?: Event | MainCourante;
  selectedAction?: MainCouranteAction;
  isCreatingZone: boolean;
  isEditingZone: boolean;
  selectedZone: Zone | null;
  selectedAddress?: Location;
}

export const useMapController = ({
  parentSize,
  autoFocusOff,
  selectedEvent,
  selectedAction,
  centerGeometry,
  isCreatingZone,
  isEditingZone,
  selectedZone,
  selectedAddress,
}: useMapControllerParams) => {
  const [oldActionId, setOldActionId] = useState<string | number>();
  const [viewport, setViewport] = useState<ViewportProps>({
    latitude: 0,
    longitude: 0,
    zoom: 1,
    bearing: 0,
    pitch: 0,
  });

  const changeViewPortWithDebounce = useDebouncedCallback(
    (newViewPort: ViewportProps) => {
      setViewport(newViewPort);
    },
    1000,
  );

  const onZoomIn = useCallback(() => {
    setViewport(oldViewport => ({
      ...oldViewport,
      zoom:
        oldViewport.zoom && oldViewport.zoom < 22 ? oldViewport.zoom + 1 : 22,
    }));
  }, [setViewport]);

  const onZoomOut = useCallback(() => {
    setViewport(oldViewport => ({
      ...oldViewport,
      zoom:
        oldViewport.zoom && oldViewport.zoom >= 1 ? oldViewport.zoom - 1 : 0,
    }));
  }, [setViewport]);

  const onRecenter = useCallback(() => {
    if (centerGeometry) {
      setViewport(oldViewport => {
        const [minLng, minLat, maxLng, maxLat] = bbox(centerGeometry);
        const newViewport = new WebMercatorViewport({
          ...oldViewport,
          width: parentSize.width,
          height: parentSize.height,
        }).fitBounds([
          [minLng, minLat],
          [maxLng, maxLat],
        ]);

        return {
          ...newViewport,
          //   transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
          //    transitionDuration: 'auto' as any,
        };
      });
      return;
    }
    Geolocation.getCurrentPosition(locationData => {
      setViewport(oldViewport => ({
        ...oldViewport,
        zoom: 9,
        latitude: locationData.coords.latitude,
        longitude: locationData.coords.longitude,
      }));
    });
  }, [centerGeometry, parentSize]);

  useEffect(() => {
    if (!selectedEvent) {
      setOldActionId(undefined);
      onRecenter();
      return;
    }
    if (autoFocusOff) {
      return;
    }

    if (!selectedEvent.location_coordinates?.coordinates) return;

    let geo;
    if (selectedAction && oldActionId) {
      geo = selectedAction.zone
        ? selectedAction.zone.geometry
        : selectedAction.location_coordinates;
    } else {
      geo =
        selectedEvent.type === 'main_courante' && selectedEvent.zone
          ? selectedEvent.zone.geometry
          : selectedEvent.location_coordinates;
    }
    if (!geo) return;
    setOldActionId(selectedAction?.id);

    const [minLng, minLat, maxLng, maxLat] = bbox(geo);

    const newViewport = new WebMercatorViewport({
      ...viewport,
      width: parentSize.width,
      height: parentSize.height,
    }).fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      {
        maxZoom: geo.type === 'LineString' ? 13 : 10,
        padding: { top: parentSize.height / 2, bottom: 0, left: 0, right: 0 },
      },
    );

    changeViewPortWithDebounce({
      ...newViewport,
      latitude:
        // eslint-disable-next-line no-nested-ternary
        geo.type === 'Point'
          ? newViewport.latitude + 0.05
          : geo.type === 'LineString'
          ? newViewport.latitude + 0.01
          : newViewport.latitude,
      zoom: newViewport.zoom,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent, selectedAction]);

  useEffect(() => {
    if (autoFocusOff) {
      return;
    }
    if (isCreatingZone || isEditingZone) return;

    if (selectedZone) {
      if (selectedZone.properties?.type === 'main_courante_action') return;
      if (selectedZone.properties?.type === 'main_courante') return;
      if (selectedZone.properties?.guideType === 'editHandle') {
        return;
      }
      setViewport(oldViewport => {
        const [minLng, minLat, maxLng, maxLat] = bbox(selectedZone);
        const newViewport = new WebMercatorViewport({
          ...oldViewport,
          width: parentSize.width,
          height: parentSize.height,
        }).fitBounds([
          [minLng, minLat],
          [maxLng, maxLat],
        ]);

        return {
          ...newViewport,
          //   transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
          //    transitionDuration: 'auto' as any,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedZone]);

  useEffect(() => {
    if (selectedAddress?.zone) {
      setViewport(oldViewport => {
        const [minLng, minLat, maxLng, maxLat] = bbox(selectedAddress.zone);
        const newViewport = new WebMercatorViewport({
          ...oldViewport,
          width: parentSize.width,
          height: parentSize.height,
        }).fitBounds([
          [minLng, minLat],
          [maxLng, maxLat],
        ]);

        return {
          ...newViewport,
          zoom: 11,
          //   transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
          //    transitionDuration: 'auto' as any,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress]);

  return { viewport, onRecenter, onZoomIn, onZoomOut, setViewport };
};
