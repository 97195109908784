import { Tag } from '@atoms/Tag';
import { Container } from './styles';

interface MainCouranteInfoDetailActionTypeListProps {
  actionTypeList: string[];
}

export const MainCouranteInfoDetailActionTypeList = ({
  actionTypeList,
}: MainCouranteInfoDetailActionTypeListProps) => {
  return (
    <Container>
      {actionTypeList.map(actionType => (
        <Tag key={`${actionType}_info`} tag={actionType} />
      ))}
    </Container>
  );
};
