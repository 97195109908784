import styled from 'styled-components/native';

export const Container = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
`;

export const ValueContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface UnitContainerProps {
  small?: boolean;
}
export const UnitContainer = styled.View<UnitContainerProps>`
  margin-left: 8px;
  height: ${({ small }) => (small ? '45px' : '60px')};
`;
