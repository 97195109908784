/* eslint-disable no-alert */
import { useEffect } from 'react';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { Platform } from 'react-native';
import { Container } from './styles';
import AddFileIcon from '../../../assets/icons/add_file.svg';

export interface FileInfo {
  uri: string;
  name: string;
  type: string;
}

interface AddButtonProps {
  onChange: (files: FileInfo) => void;
}

export const AddButton = ({ onChange }: AddButtonProps) => {
  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          alert('Sorry, we need camera roll permissions to make this work!');
        }
      }
    })();
  }, []);

  const pickImage = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: 'image/*, video/*',
    });
    if (result.type === 'success' && result.file) {
      onChange({
        uri: result.uri,
        name: result.file.name,
        type: result.file.type,
      });
    }
  };

  return (
    <Container onPress={pickImage}>
      <AddFileIcon width={16} viewBox="0 0 51 57" fill="#fff" />
    </Container>
  );
};
