import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Geometry as NebulaGeometry } from '@nebula.gl/edit-modes';
import { MainCourante } from '@services/hooks/useQueryFiltredMainCourantes';
import { useToastContext } from '@contexts/ToastContext';
import { queryClient } from '@services/queryClient';
import { api } from '@services/eventApi';

export type Geometry = NebulaGeometry;

interface MutationDeleteMainCourante {
  tabId: string;
}

interface MutationDeleteMainCouranteData {
  main_courante_id?: string;
}

export async function deleteMainCourante(main_courante_id: string) {
  const response = await api.delete<MainCourante>(
    `/v1/main_courantes/${main_courante_id}`,
  );
  return response.data || response;
}

export const useMutationDeleteMainCourante = ({
  tabId,
}: MutationDeleteMainCourante) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({ main_courante_id }: MutationDeleteMainCouranteData) => {
      if (!main_courante_id) return;
      await deleteMainCourante(main_courante_id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['filtredMainCourantes', tabId]);
        dispatchToast({
          message: t('success_delete_main_courante'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_delete_main_courante'),
          type: 'error',
        });
      },
    },
  );
};
