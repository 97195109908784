import { Icon } from '@nucleus/Icon';
import { StyleProp, ViewStyle } from 'react-native';
import updateIcon from '../../../assets/icons/update.svg';
import { Container, Text } from './styles';

interface UpdateTimeProps {
  withIcon?: boolean;
  prefix?: string;
  formattedDate: string;
  small?: boolean;
  style?: StyleProp<ViewStyle>;
}

export const TimeLabel = ({
  formattedDate,
  prefix,
  withIcon,
  small,
  style,
}: UpdateTimeProps) => {
  return (
    <Container style={style}>
      {withIcon && <Icon as={updateIcon} style={{ marginRight: 4 }} />}
      <Text small={small}>{`${
        prefix ? `${prefix}: ` : ''
      }${formattedDate}`}</Text>
    </Container>
  );
};
