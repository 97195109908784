import styled from 'styled-components/native';
import { Platform } from 'react-native';
import { Body1, ButtonPrimary } from '../Typography';

interface InputProps {
  error?: boolean;
  hasFocus?: boolean;
  withIcon?: boolean;
}

export const Container = styled.View`
  width: 95%;
  max-width: 400px;
  padding: 4px;
`;

export const InputText = styled.TextInput<InputProps>`
  margin-top: 8px;
  height: 120px;
  background-color: #6a6a9f33;
  border-radius: 20px;
  padding: 8px 16px;

  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.4rem' : '14px'};
  line-height: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.044rem' : '-0.44px'};
  color: ${({ theme }) => theme.white};
`;

export const Label = styled(Body1)`
  color: ${({ theme }) => theme.white};
`;

export const ErrorContainer = styled.View`
  top: 158px;
  left: 20px;
  flex-direction: row;
  position: absolute;
`;

export const ErrorText = styled(ButtonPrimary)`
  color: ${({ theme }) => theme.red};
  margin-left: 4px;
  font-weight: 400;
`;
