import React from 'react';
import { Hoverable } from 'react-native-web-hooks';
import { Caption } from '@nucleus/Typography';
import { Icon } from '@nucleus/Icon';
import closeIcon from '../../../assets/icons/close.svg';

import { Container, ColorCircle, RemoveButton } from './styles';

interface ActionTypeTagProps {
  name: string;
  color: string;
  onClosePress: () => void;
}
export const ActionTypeTag = ({
  name,
  color,
  onClosePress,
}: ActionTypeTagProps) => {
  return (
    <Container>
      <ColorCircle color={color} />
      <Caption>{name}</Caption>
      <Hoverable>
        {isHovered => (
          <RemoveButton isHovered={isHovered} onPress={onClosePress}>
            <Icon
              as={closeIcon}
              width="10"
              height="10"
              viewBox="0 0 30 30"
              fill="#ffffff"
            />
          </RemoveButton>
        )}
      </Hoverable>
    </Container>
  );
};
