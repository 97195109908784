/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { api } from '../eventApi';
import { queryClient } from '../queryClient';
import { useToastContext } from '../../contexts/ToastContext';

interface ActionTypeMutationData {
  name: string;
  team_id: string;
}

export const useMutationActionType = (team_id: string) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({ name }: ActionTypeMutationData) => {
      const response = await api.post('/v1/action_types', {
        name,
        team_id,
      });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['action_types', team_id]);
        dispatchToast({
          message: t('action_type_created'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_create_action_type'),
          type: 'error',
        });
      },
    },
  );
};
