import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTheme } from 'styled-components/native';
import { Dashboard } from '../screens/Dashboard';

const MainStack = createStackNavigator();

function MainNavigator() {
  const theme = useTheme();
  return (
    <MainStack.Navigator
      screenOptions={{
        headerTintColor: theme.white,
        cardStyle: { backgroundColor: theme.background },
      }}
    >
      <MainStack.Screen
        name="Dashboard"
        component={Dashboard}
        options={{
          headerShown: false,
        }}
      />
    </MainStack.Navigator>
  );
}

export default MainNavigator;
