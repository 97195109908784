/* eslint-disable @typescript-eslint/no-empty-function */
import { useState } from 'react';
import { WorkspacesAreaAddButton } from '@molecules/WorkspacesAreaAddButton';
import { WorkspacesAreaCard } from '@molecules/WorkspacesAreaCard';
import { WorkspacesAreaAddModal } from '@molecules/WorkspacesAreaAddModal';
import { WorkspacesAreaGrid } from '@organisms/WorkspacesAreaGrid';
import { useQueryWorkpaces } from '@services/hooks/useQueryWorkpaces';
import { useMutationCreateWorkspace } from '@services/hooks/useMutationCreateWorkspace';
import { useNavigation } from '@react-navigation/native';
import { Container } from './styles';

export const WorkspacesArea = () => {
  const { navigate } = useNavigation();
  const [createNewWorkspaceModalOpen, setCreateNewWorkspaceModalOpen] =
    useState(false);

  const workspacesQuery = useQueryWorkpaces();

  const createWorkspaceMutation = useMutationCreateWorkspace();

  async function handleCreateNewWorkspace(newWorkspaceName: string) {
    await createWorkspaceMutation.mutateAsync({ name: newWorkspaceName });
    setCreateNewWorkspaceModalOpen(false);
  }

  return (
    <Container>
      <WorkspacesAreaAddButton
        onAddPress={() => setCreateNewWorkspaceModalOpen(true)}
      />
      <WorkspacesAreaGrid>
        {workspacesQuery.data &&
          workspacesQuery.data.map(workspace => (
            <WorkspacesAreaCard
              onPress={() => navigate('Workspace', { id: workspace.id })}
              name={workspace.name}
              formatedDate={workspace.formatted_updated_at}
            />
          ))}
      </WorkspacesAreaGrid>
      {createNewWorkspaceModalOpen && (
        <WorkspacesAreaAddModal
          isVisible={createNewWorkspaceModalOpen}
          onCancel={() => setCreateNewWorkspaceModalOpen(false)}
          onConfirm={handleCreateNewWorkspace}
          isCreating={createWorkspaceMutation.isLoading}
        />
      )}
    </Container>
  );
};
