import { useRef, useLayoutEffect } from 'react';
import { TextInputProps } from 'react-native';
import { useTheme } from 'styled-components/native';
import { InputText } from './styles';

export type MainCouranteInfoFormTextAreaProps = TextInputProps;

const MIN_TEXTAREA_HEIGHT = 28;

export const MainCouranteInfoFormTextArea = ({
  style,
  value,
  ...rest
}: MainCouranteInfoFormTextAreaProps) => {
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textareaRef = useRef<any>(null);

  useLayoutEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = MIN_TEXTAREA_HEIGHT;
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT,
      )}px`;
    }
  }, [value]);

  return (
    <InputText
      ref={textareaRef}
      multiline
      placeholderTextColor={theme.purpleBlue}
      value={value}
      // eslint-disable-next-line prefer-object-spread
      style={Object.assign({}, style, {
        minHeight: MIN_TEXTAREA_HEIGHT,
        height: MIN_TEXTAREA_HEIGHT,
      })}
      {...rest}
    />
  );
};
