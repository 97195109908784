import styled from 'styled-components/native';
import { Input } from '@atoms/Input';

interface ContainerProps {
  isOpen: boolean;
}

export const Container = styled.View<ContainerProps>`
  width: 95%;
  z-index: ${({ isOpen }) => (isOpen ? 10 : 0)} !important;
`;

export const ButtonsWrapper = styled.View`
  width: 100%;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-evenly;
  z-index: -1;
`;

export const CreateZoneButton = styled.TouchableOpacity`
  justify-content: space-evenly;
  height: 46px;
  width: 46px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.purpleBlue};
  justify-content: center;
  align-items: center;
`;

export const NameInput = styled(Input)`
  width: 70%;
`;

export const NameColorWrapper = styled.View`
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
`;
