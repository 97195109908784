import styled from 'styled-components/native';
import { EventsMapButton } from '@atoms/EventsMapButton';

export const Container = styled.View`
  position: absolute;
  right: 16px;
  bottom: 32px;
  z-index: 1;
`;

export const Zoom = styled.View`
  width: 46px;
  height: 92px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
`;

export const PlusButton = styled(EventsMapButton)`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`;

export const MinusButton = styled(EventsMapButton)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;
