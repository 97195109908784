/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnalyticsTabsDynamicData } from '@templates/AnalyticsTabsDynamicData';
import { AnalyticsTabsHistoricalData } from '@templates/AnalyticsTabsHistoricalData';
import { AnalyticsTabsMainCouranteFlowRatio } from '@templates/AnalyticsTabsMainCouranteFlowRatio';

import { TabsView, TabPane } from '../TabsView';
// import CloseIcon from '../../assets/icons/close.svg';
// import MaximizeIcon from '../../assets/icons/maximize.svg';
import { Container } from './styles.web';
// import { DynamicData } from './DynamicData';
// import { HistoricalData } from './HistoricalData';
// import { MainCouranteFlowRatio } from './MainCouranteFlowRatio';
import { useAuthContext } from '../../contexts/AuthContext';

export const AnalyticsTabs = () => {
  const [selectedTable, setSelectedTable] = useState('dynamic_data');
  const { user } = useAuthContext();
  const { t } = useTranslation();

  const extraServices = user?.organization?.setup?.extraServices;

  return (
    <Container>
      <TabsView
        activeTabKey={selectedTable}
        onChange={setSelectedTable}
        /*  tabBarExtraContent={{
          right: (
            <Control>
              <MaximizeIcon />
              <CloseIcon />
            </Control>
          ),
        }} */
      >
        {extraServices ? (
          <>
            {extraServices.find(
              service => service === 'analytics.dynamic_data',
            ) && (
              <TabPane key="dynamic_data" tab={t('dynamic_data')}>
                <AnalyticsTabsDynamicData />
              </TabPane>
            )}
          </>
        ) : (
          <TabPane key="dynamic_data" tab={t('dynamic_data')}>
            <AnalyticsTabsDynamicData />
          </TabPane>
        )}
        {extraServices ? (
          <>
            {extraServices.find(
              service => service === 'analytics.historical_data',
            ) && (
              <TabPane key="historical_data" tab={t('historical_data')}>
                <AnalyticsTabsHistoricalData />
              </TabPane>
            )}
          </>
        ) : (
          <TabPane key="historical_data" tab={t('historical_data')}>
            <AnalyticsTabsHistoricalData />
          </TabPane>
        )}
        {extraServices ? (
          <>
            {extraServices.find(
              service => service === 'analytics.main_courante_flow_ratio',
            ) && (
              <TabPane
                key="main_courante_flow_ratio"
                tab={t('main_courante_flow_ratio')}
              >
                <AnalyticsTabsMainCouranteFlowRatio />
              </TabPane>
            )}
          </>
        ) : (
          <TabPane
            key="main_courante_flow_ratio"
            tab={t('main_courante_flow_ratio')}
          >
            <AnalyticsTabsMainCouranteFlowRatio />
          </TabPane>
        )}
      </TabsView>
    </Container>
  );
};
