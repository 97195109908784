import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  flex-direction: row;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;
