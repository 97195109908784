import { H5, H6 } from '@nucleus/Typography';
import { useWindowDimensions } from 'react-native';
import { Container, ItemTitle } from './styles';

interface AnalyticsTabsMainCouranteFlowRatioRatioBarItemValueProps {
  titleColor?: string;
  color: string;
  title: string;
  value: number;
  isRatio?: boolean;
}

export const AnalyticsTabsMainCouranteFlowRatioRatioBarItemValue = ({
  color,
  title,
  value,
  titleColor,
  isRatio,
}: AnalyticsTabsMainCouranteFlowRatioRatioBarItemValueProps) => {
  const { width } = useWindowDimensions();
  return (
    <Container isRatio={isRatio}>
      <ItemTitle color={titleColor}>{title}</ItemTitle>
      {width < 1700 ? (
        <H6 style={{ color }}>{value}</H6>
      ) : (
        <H5 style={{ color }}>{value}</H5>
      )}
    </Container>
  );
};
