/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Image } from '../../components/Image';
import loginLogo from '../../assets/loginLogo.png';
import {
  Container,
  LoginForm,
  SubLogoContainer,
  InputsContainer,
} from './styles';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import alpesMaritimes from '../../assets/alpesMaritimes.png';
import { api } from '../../services/userManagementApi';
import { useToastContext } from '../../contexts/ToastContext';

interface ResetCredentials {
  password: string;
  password_confirmation: string;
}

export function ResetPassword() {
  const navigation = useNavigation();
  const route = useRoute<any>();
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  const forgetSchema = useMemo(
    () =>
      yup.object().shape({
        password: yup.string().min(6).required(t('valid_field')),
        password_confirmation: yup
          .string()
          .oneOf([null, yup.ref('password')], t('valid_field')),
      }),
    [t],
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ResetCredentials>({
    resolver: yupResolver(forgetSchema),
  });

  async function onSubmit({
    password,
    password_confirmation,
  }: ResetCredentials) {
    try {
      await api.post('/v1/password/reset', {
        password,
        password_confirmation,
        token: route.params?.token,
      });
      dispatchToast({
        message: t('success_password_sent'),
        type: 'success',
      });
      navigation.navigate('SignIn');
    } catch (err: any) {
      dispatchToast({
        message: err.response?.data?.message
          ? err.response.data.message
          : t('generic_request_error'),
        type: 'error',
      });
    }
  }

  return (
    <Container>
      <Image source={loginLogo} style={{ marginTop: '4%' }} />
      <LoginForm>
        <SubLogoContainer>
          <Image source={alpesMaritimes} />
        </SubLogoContainer>
        <InputsContainer>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                placeholder="*******"
                label={t('password')}
                autoCompleteType="password"
                secureTextEntry
                onChangeText={newValue => onChange(newValue)}
                value={value}
                error={errors.password?.message}
              />
            )}
            name="password"
            defaultValue=""
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <Input
                placeholder="*******"
                label={t('password_confirmation')}
                autoCompleteType="password"
                secureTextEntry
                onChangeText={newValue => onChange(newValue)}
                value={value}
                error={errors.password_confirmation?.message}
              />
            )}
            name="password_confirmation"
            defaultValue=""
          />
        </InputsContainer>
        <Button loading={isSubmitting} onPress={handleSubmit(onSubmit)}>
          {t('save')}
        </Button>
      </LoginForm>
    </Container>
  );
}
