/* eslint-disable @typescript-eslint/no-explicit-any */
import AsyncStorage from '@react-native-async-storage/async-storage';

const STORAGE_KEY = '@ChallengeIA';

export async function getAllStates() {
  const stringStates = await AsyncStorage.getItem(STORAGE_KEY);
  return stringStates !== null ? JSON.parse(stringStates) : {};
}

export async function removeState(keyName: string) {
  await AsyncStorage.removeItem(`${STORAGE_KEY}:${keyName}`);
}

export async function storeState(keyName: string, state: any) {
  const stringnewStates = JSON.stringify(state);
  await AsyncStorage.setItem(`${STORAGE_KEY}:${keyName}`, stringnewStates);
}

export async function getState<S = any>(keyName: string): Promise<S | null> {
  const stringStates = await AsyncStorage.getItem(`${STORAGE_KEY}:${keyName}`);
  return stringStates !== null ? (JSON.parse(stringStates) as S) : null;
}
