import styled from 'styled-components/native';
import { Button } from '../../Button';

export const Container = styled.View`
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.View`
  width: 70%;
  height: 30%;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 20px;
  padding: 16px 24px;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 8px;
`;

export const ActionButton = styled(Button)`
  margin-top: 16px;
  margin-right: 16px;
  width: 136px;
  height: 40px;
  align-self: flex-end;
`;
