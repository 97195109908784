/* eslint-disable @typescript-eslint/no-explicit-any */
import styledWeb from 'styled-components';
import styled from 'styled-components/native';
import { Platform } from 'react-native';

const ContainerWeb = styledWeb.div`
  width: 100%;
  height: calc(100% - 195px);
  border-top: 1.5px solid ${({ theme }) => theme.background};
  padding-left: 8px;
  padding-right: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1;
`;

const ContainerMobile = styled.View`
  width: 100%;
  height: calc(100% - 280px);
  border-top: 1.5px solid ${({ theme }) => theme.background};
  padding-left: 8px;
  padding-right: 8px;
  z-index: 1;
`;

export const Container: any =
  Platform.OS === 'web' ? ContainerWeb : ContainerMobile;

export const ActionSelectorContainer = styled.View`
  width: 100%;
  height: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;
