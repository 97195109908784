import { AnalyticsTabsDynamicDataTemperatureCard } from '@organisms/AnalyticsTabsDynamicDataTemperatureCard';
import { AnalyticsTabsDynamicDataWindCard } from '@organisms/AnalyticsTabsDynamicDataWindCard';
import { AnalyticsTabsDynamicDataHydrometeorologyCard } from '@organisms/AnalyticsTabsDynamicDataHydrometeorologyCard';
import { AnalyticsTabsDynamicDataVigilanceCard } from '@organisms/AnalyticsTabsDynamicDataVigilanceCard';
import { AnalyticsTabsDynamicDataHeader } from '@organisms/AnalyticsTabsDynamicDataHeader';
import { useState, useLayoutEffect, useMemo } from 'react';
import { Location } from '@molecules/AddressFilterInput';
import { Spinner } from '@atoms/Spinner';
import { formatDistanceToNow } from 'date-fns';
import { useAuthContext } from '@contexts/AuthContext';
import { storeState, getState } from '../../../utils/asyncStorage';
import { useQueryWeatherInfo } from '../../../services/hooks/useQueryWeatherInfo';
import { Container, ContentContainer, SpinnerContainer } from './styles';
import { getDateFnsLocale } from '../../../utils/getDateFnsLocale';
import { getSourceLink } from '../../../utils/meteorFranceVigilanceSource';

export const AnalyticsTabsDynamicData = () => {
  const { user } = useAuthContext();
  const [location, setLocation] = useState<Location>();
  const weatherInfoQuery = useQueryWeatherInfo({
    lat: location && location.geometry.coordinates[1],
    lng: location && location.geometry.coordinates[0],
  });

  const handleSaveLocation = async (newLocation: Location | undefined) => {
    await storeState('weatherLocation', newLocation);
    setLocation(newLocation);
  };

  const vigilanceSourceLink = useMemo(
    () => (location ? getSourceLink(location.text || '') : null),
    [location],
  );

  const lastUpdateFormatted = useMemo(() => {
    const locale = getDateFnsLocale();
    if (weatherInfoQuery.data) {
      return formatDistanceToNow(weatherInfoQuery.data?.last_update, {
        locale,
        addSuffix: true,
      });
    }
    return '';
  }, [weatherInfoQuery]);

  useLayoutEffect(() => {
    async function getStoredLocation() {
      const storedLocation = await getState<Location>('weatherLocation');
      if (
        !storedLocation &&
        user?.organization.setup?.defaultDynamicDataLocation
      ) {
        setLocation(user?.organization.setup?.defaultDynamicDataLocation);
        return;
      }
      setLocation(storedLocation || undefined);
    }
    getStoredLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <AnalyticsTabsDynamicDataHeader
        location={location}
        changeLocation={handleSaveLocation}
        isFetching={weatherInfoQuery.isFetching && !weatherInfoQuery.isLoading}
      />
      {weatherInfoQuery.isLoading ? (
        <SpinnerContainer>
          <Spinner size="large" />
        </SpinnerContainer>
      ) : (
        weatherInfoQuery.data && (
          <ContentContainer>
            <AnalyticsTabsDynamicDataTemperatureCard
              maxTemperature={{
                unit: '°C',
                data: String(weatherInfoQuery.data.max_temp),
                source: weatherInfoQuery.data.source,
                sourceUrl: weatherInfoQuery.data.source_url,
                lastUpdateFormatted,
              }}
              minTemperature={{
                unit: '°C',
                data: String(weatherInfoQuery.data.min_temp),
                source: weatherInfoQuery.data.source,
                sourceUrl: weatherInfoQuery.data.source_url,
                lastUpdateFormatted,
              }}
              currentTemperature={{
                unit: '°C',
                data: String(weatherInfoQuery.data.temp),
                source: weatherInfoQuery.data.source,
                sourceUrl: weatherInfoQuery.data.source_url,
                lastUpdateFormatted,
              }}
            />
            <AnalyticsTabsDynamicDataWindCard
              windForce={{
                unit: 'KM/H',
                data: String(weatherInfoQuery.data.wind_speed * 3.6),
                source: weatherInfoQuery.data.source,
                name: 'Force du vent',
                lastUpdateFormatted,
                sourceUrl: weatherInfoQuery.data.source_url,
              }}
              maxBurst={{
                unit: 'KM/H',
                data: String(
                  weatherInfoQuery.data.wind_gust * 3.6 ||
                    weatherInfoQuery.data.wind_speed * 3.6,
                ),
                source: weatherInfoQuery.data.source,
                name: 'Rafale maximale',
                lastUpdateFormatted,
                sourceUrl: weatherInfoQuery.data.source_url,
              }}
              windDirection={{
                unit: '°',
                data: String(weatherInfoQuery.data.wind_deg),
                source: weatherInfoQuery.data.source,
                name: 'vent direction',
                lastUpdateFormatted,
                sourceUrl: weatherInfoQuery.data.source_url,
              }}
            />
            <AnalyticsTabsDynamicDataHydrometeorologyCard
              humidity={{
                unit: '%',
                data: String(weatherInfoQuery.data.humidity),
                source: weatherInfoQuery.data.source,
                name: 'Humidité',
                lastUpdateFormatted,
                sourceUrl: weatherInfoQuery.data.source_url,
              }}
              pressure={{
                unit: 'HPA',
                data: String(weatherInfoQuery.data.pressure),
                source: weatherInfoQuery.data.source,
                name: 'Pression',
                lastUpdateFormatted,
                sourceUrl: weatherInfoQuery.data.source_url,
              }}
              visibility={{
                unit: 'KM',
                data: String(
                  (weatherInfoQuery.data.visibility / 1000).toFixed(1),
                ),
                source: weatherInfoQuery.data.source,
                name: 'Visibilité',
                lastUpdateFormatted,
                sourceUrl: weatherInfoQuery.data.source_url,
              }}
              maxAccumulationHour={{
                unit: 'MM',
                data: String(weatherInfoQuery.data.rain_cumul_1h),
                source: weatherInfoQuery.data.source,
                name: 'Cumul maxi en 1h',
                lastUpdateFormatted,
                sourceUrl: weatherInfoQuery.data.source_url,
              }}
              dayAccumulation={{
                unit: 'MM',
                data: String(weatherInfoQuery.data.rain_cumul_day),
                source: weatherInfoQuery.data.source,
                name: 'Cumul du jour',
                lastUpdateFormatted,
                sourceUrl: weatherInfoQuery.data.source_url,
              }}
            />

            <AnalyticsTabsDynamicDataVigilanceCard
              alerts={weatherInfoQuery.data.alerts}
              lastUpdateFormatted={lastUpdateFormatted}
              sourceUrl={vigilanceSourceLink}
            />
          </ContentContainer>
        )
      )}
    </Container>
  );
};
