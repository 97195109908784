import React from 'react';
import { MapWidget } from '@templates/MapWidget';
import { MapWidgetSideBar } from '@organisms/MapWidgetSideBar';
import { IWidget } from '@contexts/WidgetGridContext';

// types slugs "custom-event-list" "event-list" "map-widget"

interface WidgetComponentProps {
  widgetData: IWidget;
}

interface WidgetComponents {
  [key: string]: ({ widgetData }: WidgetComponentProps) => JSX.Element;
}

interface WidgetInfo {
  typeSlug: string;
  widgetData: IWidget;
}

const widgetComponents: WidgetComponents = {
  'map-widget': MapWidget,
};

const widgetSidePanelComponents: WidgetComponents = {
  'map-widget': MapWidgetSideBar,
};

export const useWidgetComponentSelector = () => {
  const getWidgetComponent = ({ typeSlug, widgetData }: WidgetInfo) => {
    const Component = widgetComponents[typeSlug];
    return Component && React.createElement(Component, { widgetData }, null);
  };

  const getWidgetSidePanelComponent = ({
    typeSlug,
    widgetData,
  }: WidgetInfo) => {
    const Component = widgetSidePanelComponents[typeSlug];
    return Component && React.createElement(Component, { widgetData }, null);
  };
  return {
    getWidgetComponent,
    getWidgetSidePanelComponent,
  };
};
