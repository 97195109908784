/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '@nucleus/Typography';
import { HistoricalDataColumnChart } from '@atoms/HistoricalDataColumnChart';
import { HistoricalDataRadialBarChart } from '@atoms/HistoricalDataRadialBarChart';
import { Select } from '@atoms/Select';
import { historicalData } from '../../../utils/historicalData';
import {
  Cointainer,
  ChartsContainer,
  TopContainer,
  Legends,
  RiskStatisticsWrapper,
  BottomContainer,
  BottomContainerLeft,
  BottomContainerRigth,
} from './styles';

const options = [
  { value: 'feux_de_forêts', label: 'Feux de forêts' },
  { value: 'inondations', label: 'Inondations' },
  { value: 'risques_sisimiques', label: 'Risques sisimiques' },
  { value: 'mouvements_de_terrain', label: 'Mouvements de terrain' },
];

export const AnalyticsTabsHistoricalData = () => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState(['feux_de_forêts']);
  const selectedValue = selectedOptions[0] as
    | 'feux_de_forêts'
    | 'inondations'
    | 'risques_sisimiques'
    | 'mouvements_de_terrain';
  const selectedHistoricalData = useMemo(
    () => historicalData[selectedValue],

    [selectedValue],
  );
  return (
    <Cointainer>
      <RiskStatisticsWrapper>
        <Title>{t('risk_statistics')}</Title>
        <Select
          style={{ width: 200, marginLeft: 20, zIndex: 3 }}
          selectedOptions={selectedOptions}
          options={options}
          onChange={setSelectedOptions}
        />
      </RiskStatisticsWrapper>
      <ChartsContainer>
        {selectedHistoricalData.barChart && (
          <TopContainer>
            <HistoricalDataColumnChart
              series={selectedHistoricalData.barChart.series}
              categories={selectedHistoricalData.barChart.categories}
              xLegend={selectedHistoricalData.barChart.xLegend}
              yLegend={selectedHistoricalData.barChart.yLegend}
            />
            <Legends>{selectedHistoricalData.barChart.title}</Legends>
          </TopContainer>
        )}
        {(selectedHistoricalData.roundDiagramLeft ||
          selectedHistoricalData.roundDiagramRight) && (
          <BottomContainer>
            {selectedHistoricalData.roundDiagramLeft && (
              <BottomContainerLeft>
                <HistoricalDataRadialBarChart
                  size={selectedHistoricalData.roundDiagramLeft.size}
                  series={selectedHistoricalData.roundDiagramLeft.series}
                  labels={selectedHistoricalData.roundDiagramLeft.labels}
                />
                <Legends>
                  {selectedHistoricalData.roundDiagramLeft.title}
                </Legends>
              </BottomContainerLeft>
            )}
            {selectedHistoricalData.roundDiagramRight && (
              <BottomContainerRigth>
                <HistoricalDataRadialBarChart
                  size={selectedHistoricalData.roundDiagramRight.size}
                  series={selectedHistoricalData.roundDiagramRight.series}
                  labels={selectedHistoricalData.roundDiagramRight.labels}
                />
                <Legends>
                  {selectedHistoricalData.roundDiagramRight.title}
                </Legends>
              </BottomContainerRigth>
            )}
          </BottomContainer>
        )}
      </ChartsContainer>
    </Cointainer>
  );
};
