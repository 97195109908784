import { ReactNode } from 'react';
import { H3, H4, Overline } from '@nucleus/Typography';
import { useTheme } from 'styled-components/native';
import { Linking } from 'react-native';
import { Container, ValueContainer, UnitContainer } from './styles';

export interface AnalyticsTabsDynamicDataTemperatureCardTemperatureProps {
  color: string;
  unit: string;
  value: string;
  label?: ReactNode;
  topLabel?: string;
  bottomLabel?: string;
  small?: boolean;
  sourceUrl: string;
}

export const AnalyticsTabsDynamicDataCardValue = ({
  color,
  unit,
  value,
  label,
  topLabel,
  bottomLabel,
  small = false,
  sourceUrl,
}: AnalyticsTabsDynamicDataTemperatureCardTemperatureProps) => {
  const theme = useTheme();
  return (
    <Container onPress={() => Linking.openURL(sourceUrl)}>
      {topLabel && (
        <Overline style={{ color: theme.purpleBlue }}>{topLabel}</Overline>
      )}
      <ValueContainer>
        {label}
        {small ? (
          <H4 style={{ color, marginLeft: 8 }}>{value}</H4>
        ) : (
          <H3 style={{ color, marginLeft: 8 }}>{value}</H3>
        )}
        <UnitContainer small={small}>
          <Overline>{unit.toUpperCase()}</Overline>
        </UnitContainer>
      </ValueContainer>
      {bottomLabel && (
        <Overline style={{ color: theme.purpleBlue }}>{bottomLabel}</Overline>
      )}
    </Container>
  );
};
