import styled, { css } from 'styled-components/native';
import { Platform, NativeMethods } from 'react-native';
import { Body1, Caption } from '@nucleus/Typography';

export const Container = styled.View`
  width: 95%;
  max-width: 272px;
  border-bottom-width: 2px;
  border-color: ${({ theme }) => theme.white};
  position: relative;
  display: inline-block;
`;

export const InputContainer = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2px;
`;

export const Label = styled(Body1)`
  color: ${({ theme }) => theme.white};
`;

interface InputProps {
  error?: boolean;
  hasFocus?: boolean;
  withIcon?: boolean;
}

export const InputText = styled.TextInput<InputProps>`
  width: ${({ error, withIcon }) => {
    if (Platform.OS === 'web') {
      return '100%';
    }
    if (error && withIcon) {
      return '80%';
    }
    if (error || withIcon) {
      return '90%';
    }
    return '100%';
  }};
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.4rem' : '14px'};
  line-height: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.044rem' : '-0.44px'};
  color: ${({ theme }) => theme.white};

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.red};
    `}

  ${({ hasFocus }) =>
    hasFocus &&
    css`
      color: ${({ theme }) => theme.white};
    `}
`;

interface DropDownMasterWraperProps {
  parentElement: HTMLElement | NativeMethods;
}

export const DropDownMasterWraper = styled.View<DropDownMasterWraperProps>`
  ${({ parentElement }) => {
    if (parentElement instanceof HTMLElement) {
      const { top, left, width } = parentElement.getBoundingClientRect();
      return css`
        left: ${left};
        top: ${top + 24};
        width: ${width};
      `;
    }
    return css``;
  }};
  position: absolute;
  background: #212337;
  overflow: auto;
  border-width: 1px;
  border-color: #6a6a9f;
`;

export const DropDownItem = styled.TouchableOpacity`
  position: relative;
  padding: 8px;
  width: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-bottom-width: 1px;
  border-color: #6a6a9f;
  flex-direction: row;
`;

export const DropDownItemText = styled(Caption)`
  width: 95%;
`;
