import styled from 'styled-components/native';
import { shade } from 'polished';

export const Container = styled.TouchableOpacity`
  display: flex;
  height: 24px;
  width: 24px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.purple};
  align-items: center;
  justify-content: center;
  cursor: pointer;
  input {
    display: none;
  }

  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => shade(0.2, theme.purple)};
  }
`;
