import { useState, useEffect } from 'react';
import { Position, Size } from '../utils/tabApi';

interface InitialData {
  parentSize: Size;
}

interface UseScreenControlReturn {
  screenSize?: Size;
  screenPosition: Position;
  isFullscreen: boolean;
  handleFullScreen: () => void;
  setScreenSize: (value: Size) => void;
  setScreenPosition: (positon: Position) => void;
}

export const useScreenControl = ({
  parentSize,
}: InitialData): UseScreenControlReturn => {
  const [screenSize, setScreenSize] = useState<Size>();
  const [screenPosition, setScreenPosition] = useState<Position>({
    x: 0,
    y: 0,
  });

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [oldParentSize, setOldParentSize] = useState<Size>();
  const [sizeBeforeFullScreen, setSizeBeforeFullScreen] =
    useState<Size>(parentSize);

  function handleFullScreen() {
    if (!screenSize) {
      return;
    }
    if (!isFullscreen && parentSize) {
      setSizeBeforeFullScreen({
        width: screenSize.width,
        height: screenSize.height,
      });
      setScreenSize({
        width: parentSize.width,
        height: parentSize.height,
      });
      setScreenPosition({
        x: 0,
        y: 0,
      });
      setIsFullscreen(true);
      return;
    }
    if (sizeBeforeFullScreen.width > parentSize.width) {
      if (sizeBeforeFullScreen.height > parentSize.height) {
        setScreenSize({
          width: parentSize.width,
          height: parentSize.height,
        });
      }
      setScreenSize({
        width: parentSize.width,
        height: sizeBeforeFullScreen.height,
      });
      return;
    }
    if (sizeBeforeFullScreen.height > parentSize.height) {
      setScreenSize({
        width: sizeBeforeFullScreen.width,
        height: parentSize.height,
      });
      return;
    }
    setScreenSize({
      width: sizeBeforeFullScreen.width,
      height: sizeBeforeFullScreen.height,
    });
    setIsFullscreen(false);
  }

  useEffect(() => {
    if (!oldParentSize) {
      if (parentSize.width < 980) {
        setScreenSize({
          width: parentSize.width,
          height: 450,
        });
        return;
      }
      if (parentSize.width < 1200) {
        setScreenSize({
          width: parentSize.width * 0.9,
          height: 450,
        });
        return;
      }
      setScreenSize({
        width: parentSize.width * 0.8,
        height: 450,
      });
      setOldParentSize(parentSize);
      return;
    }
    if (!screenSize) {
      return;
    }
    if (!screenPosition) {
      return;
    }
    if (
      parentSize.width !== oldParentSize.width ||
      parentSize.height !== oldParentSize.height
    ) {
      setOldParentSize(parentSize);
      if (isFullscreen) {
        setScreenSize({
          width: parentSize.width,
          height: parentSize.height,
        });
        setScreenPosition({
          x: parentSize.width / 2 - parentSize.width / 2,
          y: screenPosition.y,
        });
        return;
      }

      if (parentSize.width < 980) {
        setScreenSize({
          width: parentSize.width,
          height: screenSize.height,
        });
        setScreenPosition({
          x: parentSize.width / 2 - parentSize.width / 2,
          y: screenPosition.y,
        });
        return;
      }
      if (parentSize.width < 1200) {
        setScreenSize({
          width: parentSize.width * 0.9,
          height: screenSize.height,
        });
        setScreenPosition({
          x: parentSize.width / 2 - (parentSize.width * 0.9) / 2,
          y: screenPosition.y,
        });
        return;
      }
      setScreenSize({
        width: parentSize.width * 0.8,
        height: screenSize.height,
      });
      if (parentSize.width > oldParentSize.width) {
        setScreenPosition({
          x: parentSize.width / 2 - (parentSize.width * 0.8) / 2,
          y: screenPosition.y,
        });
      } else {
        setScreenPosition({
          x: parentSize.width / 2 - (parentSize.width * 0.8) / 2,
          y: screenPosition.y,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentSize]);

  return {
    screenSize,
    screenPosition,
    isFullscreen,
    handleFullScreen,
    setScreenSize,
    setScreenPosition,
  };
};
