import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  background: '#111121',
  purpleBlue: '#6A6A9F',
  blueishBlack: '#191932',
  blue: '#023AFF',
  yellow: '#FFA63F',
  lightYellow: '#FFD422',
  eletricGreen: '#5EFF5A',
  red: '#FF2D2E',
  black: '#05050F',
  purpleGradient: 'linear-gradient(269.95deg, #E323FF 0.02%, #7517F8 97.45%)',
  darkUltramarineGradient:
    'linear-gradient(270.15deg, #7D40FF 19.44%, #02A4FF 81.9%)',
  lightUltramarineGradient:
    'linear-gradient(270deg, #4DFFDF 0%, #4DA1FF 96.35%)',
  purple: '#991BFA',
  birches: '#01F1E3',
  white: '#FFFFFF',
  linearYellow: 'linear-gradient(269.95deg, #FFD422 0.02%, #FF7D05 97.45%)',
  purpleBlack: '#212337',
  grey: '#C4C4C4',
};
