import styled from 'styled-components/native';
import { transparentize } from 'polished';
import { Button as ButtonComponent } from '@atoms/Button';

export const Container = styled.View`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => transparentize(0.3, theme.black)};
  margin: auto;
  justify-content: flex-start;
  padding-top: 100px;
  align-items: center;
`;

export const ContentContainer = styled.View`
  max-width: 397px;
  width: 90%;
  max-height: 200px;
  height: 45%;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 20px;
  padding: 20px;
  align-items: center;
`;

export const ButtonsContainer = styled.View`
  flex-direction: row;
  margin-top: 32px;
  width: 100%;
  justify-content: space-evenly;
`;

export const SideBySide = styled.View`
  flex-direction: row;
  margin-top: 16px;
  width: 100%;
  justify-content: space-evenly;
  z-index: 2;
  align-items: flex-end;
  height: 40px;
`;

export const Button = styled(ButtonComponent)`
  width: 137px;
  height: 39px;
`;
