/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { format, parseISO } from 'date-fns';
import { getDateFnsLocale } from '../../utils/getDateFnsLocale';
import { api } from '../eventApi';

export interface Media {
  photos: string[];
  videos: string[];
}

export interface Category {
  id: number;
  name: string;
  icon?: string;
  color: string;
  description: string;
  parent_id: number;
}
export interface Content {
  id: string;
  source_name: string | null;
  source_image: string | null;
  text: string;
  date: string;
}

export interface SourceCount {
  count: number;
  name: string;
  contents: Content[];
}

export interface Event {
  id: number;
  title: string;
  tags: string[];
  intensity: number;
  contents_count: number;
  location_name: string;
  location_coordinates: {
    type: string;
    coordinates: number[];
  };
  gdelt_id: number;
  created_at: string;
  updated_at: string;
  category: Category;
  sources_count: SourceCount[];
  media: Media;
}

export async function getEventInfo(event_id: number): Promise<Event> {
  const response = await api.get(`/v1/events/${event_id}`);

  const locale = getDateFnsLocale();

  const normalizeData = {
    ...response.data,
    sources_count: response.data.sources_count
      ? response.data.sources_count.map((source: SourceCount) => {
          if (source.contents) {
            return {
              ...source,
              contents: source.contents.map((content: Content) => ({
                ...content,
                date: format(parseISO(content.date), "dd MMMM HH'h'mm OOOO", {
                  locale,
                }),
              })),
            };
          }
          return source;
        })
      : response.data.sources_count,
  };

  return normalizeData;
}

export function useQueryEventInfo(eventId: number) {
  return useQuery(['event', eventId], () => getEventInfo(eventId), {
    enabled: !!eventId,
    refetchOnWindowFocus: false,
  });
}
