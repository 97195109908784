import styled from 'styled-components/native';

interface ContainerProps {
  small?: boolean;
}

export const Container = styled.View<ContainerProps>`
  height: auto;
  width: 100%;
  border-radius: 20px;
  padding: 16px;
  background-color: ${({ theme }) => theme.background};
  margin-top: 8px;
`;

export const VigilancesContainer = styled.TouchableOpacity<ContainerProps>`
  height: calc(100% - 22px);
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: ${({ small }) => (small ? '8px' : '16px')};
`;
