import styled from 'styled-components/native';

export const Container = styled.View`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

export const LoginForm = styled.View`
  margin-top: 3.3%;
  width: 90%;
  max-width: 730px;
  height: 60%;
  max-height: 520px;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 20px;
  justify-content: space-evenly;
  align-items: center;
`;

export const SubLogoContainer = styled.View`
  width: 272px;
  height: 62px;
  max-height: 62px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.white};
  justify-content: center;
  align-items: center;
`;

export const ButtonsContainer = styled.View`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 110px;
`;
