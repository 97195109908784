import styled from 'styled-components/native';
import { Caption } from '@nucleus/Typography';
import { Gradient } from '@nucleus/Gradient';

export const Container = styled(Gradient)`
  position: relative;
  height: 100%;
  width: 30px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  align-items: center;
`;

export const CategoryName = styled(Caption)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.white};
  width: 180px;
  transform: rotate(-90deg) translateX(0px) translateY(100px);
`;
