import Chart from 'react-apexcharts';
import { HistoricalDataColumnChartProps } from './types';

const options = {
  chart: {
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    foreColor: '#5D5DAD',
  },
  plotOptions: {
    bar: {
      s̶t̶a̶r̶t̶i̶n̶g̶S̶h̶a̶p̶e̶: 'flat',
      e̶n̶d̶i̶n̶g̶S̶h̶a̶p̶e̶: 'rounded',
      borderRadius: 3,
      columnWidth: '6px',
      distributed: false,
      rangeBarOverlap: true,
      rangeBarGroupRows: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    show: false,
  },
  stroke: {
    show: false,
  },
  yaxis: {
    title: {
      text: 'Growth',
    },
    labels: {
      show: true,
      style: {
        fontSize: '12px',
      },
    },
  },
  xaxis: {
    title: {
      text: '',
    },
    labels: {
      show: true,
      style: {
        fontSize: '11px',
      },
    },
    axisBorder: {
      color: 'transparent',
    },
    axisTicks: {
      color: 'transparent',
    },
    categories: [
      '2001',
      '2002',
      '2004',
      '2006',
      '2008',
      '2010',
      '2012',
      '2014',
      '2016',
      '2018',
      '2020',
    ],
  },
  colors: ['#7517F8'],
  fill: {
    color: 'red',
    opacity: 1,
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      type: 'vertical',
      gradientToColors: ['#E323FF'],
      shade: 'dark',
    },
  },
  tooltip: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 1610,
      options: {
        chart: {
          height: 270,
          width: 750,
        },
        legend: {
          show: false,
        },
      },
    },
    {
      breakpoint: 1450,
      options: {
        chart: {
          height: 270,
          width: 750,
        },
        legend: {
          show: false,
        },
      },
    },
    {
      breakpoint: 1370,
      options: {
        chart: {
          height: 205,
          width: 600,
        },
        legend: {
          show: false,
        },
      },
    },
  ],
};

export const HistoricalDataColumnChart = ({
  series,
  categories,
  yLegend = '',
  xLegend = '',
}: HistoricalDataColumnChartProps) => {
  return (
    <Chart
      type="bar"
      height={320}
      width={750}
      options={{
        ...options,
        yaxis: {
          ...options.yaxis,
          title: {
            text: yLegend,
          },
        },
        xaxis: {
          ...options.xaxis,
          title: {
            text: xLegend,
          },
          categories,
        },
      }}
      series={series}
    />
  );
};
