import styled from 'styled-components/native';
import { Platform } from 'react-native';

export const Container = styled.View`
  border-radius: 20px;
  width: 58px;
  height: 58px;
  background: ${({ theme }) => theme.purpleBlue};
  font-size: 12px;
  color: ${({ theme }) => theme.white};
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const Text = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '2.8rem' : '28px'};
  line-height: ${Platform.OS === 'web' ? '3.2rem' : '32px'};
  font-weight: 600;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_700Bold'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.015rem' : '-0.15px'};
`;
