import { Control, Controller } from 'react-hook-form';
import { MainCouranteInfoFormDatePicker } from '@atoms/MainCouranteInfoFormDatePicker';
import { MainCouranteInfoFormPickLocation } from '@atoms/MainCouranteInfoFormPickLocation';
import { MainCouranteInfoFormTimePicker } from '@atoms/MainCouranteInfoFormTimePicker';
import { UpdateMainCouranteActionFormData } from '@organisms/MainCouranteInfoDetail';
import { Container } from './styles';

interface MainCouranteInfoDetailLocationAndTimeFormProps {
  formControl: Control<UpdateMainCouranteActionFormData>;
  date: string;
  locationName: string;
  onPickLocationPress: () => void;
}

export const MainCouranteInfoDetailLocationAndTimeForm = ({
  locationName,
  date,
  formControl,
  onPickLocationPress,
}: MainCouranteInfoDetailLocationAndTimeFormProps) => {
  return (
    <Container>
      <Controller
        control={formControl}
        render={({ field: { value } }) => (
          <MainCouranteInfoFormPickLocation
            onPress={onPickLocationPress}
            style={{ maxWidth: 160 }}
            location={value}
          />
        )}
        name="location_name"
        defaultValue={locationName}
      />

      <Controller
        control={formControl}
        render={({ field: { onChange, value } }) => (
          <MainCouranteInfoFormDatePicker
            date={value}
            onChange={onChange}
            style={{ maxWidth: 90, marginLeft: 5 }}
          />
        )}
        name="date"
        defaultValue={new Date(date)}
      />

      <Controller
        control={formControl}
        render={({ field: { onChange, value } }) => (
          <MainCouranteInfoFormTimePicker
            date={value}
            onChange={onChange}
            style={{ maxWidth: 70, marginLeft: 5 }}
          />
        )}
        name="hour"
        defaultValue={new Date(date)}
      />
    </Container>
  );
};
