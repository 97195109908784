import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@contexts/ToastContext';
import { queryClient } from '@services/queryClient';
import { api } from '@services/userManagementApi';

interface CreateWorkspaceMutation {
  workspace_id: string;
}

export async function deletePage(page_id: string) {
  await api.delete(`/v1/pages/${page_id}`);
}

export const useMutationDeletePage = ({
  workspace_id,
}: CreateWorkspaceMutation) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async (page_id: string) => {
      const newPage = await deletePage(page_id);
      return newPage;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['workspace', workspace_id]);
        dispatchToast({
          message: t('success_delete_page'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_delete_page'),
          type: 'error',
        });
      },
    },
  );
};
