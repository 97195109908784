import styled from 'styled-components/native';
import { Button } from '@atoms/Button';

export const ButtonsContainer = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-top: 16px;
`;

export const ActionButton = styled(Button)`
  margin-top: 16px;
  margin-right: 16px;
  width: 136px;
  height: 40px;
  align-self: flex-end;
`;
