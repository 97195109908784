import { useState, useEffect } from 'react';
import { Spinner } from '@atoms/Spinner';
import { GridSelector } from '@atoms/GridSelector';
import { SearchInput } from '@atoms/SearchInput';
import { WorkspacePageAddNewWidgetModalCard } from '@atoms/WorkspacePageAddNewWidgetModalCard';
import { WorkspacePageAddNewWidgetModalList } from '@atoms/WorkspacePageAddNewWidgetModalList';
import { H6, Body1 } from '@nucleus/Typography';
import { WidgetType } from '@services/hooks/useQueryWidgetTypes';
import { useDebouncedCallback } from 'use-debounce';
import {
  WidgetTypesContainer,
  ContentContainer,
  SubTitleContainer,
  WidgetTypesListContainer,
} from './styles';

interface WorkspacePageAddNewWidgetModalStep1Props {
  widgetTypes: WidgetType[];
  isloading?: boolean;
  onSelectType: (widgetType: WidgetType) => void;
}

export const WorkspacePageAddNewWidgetModalStep1 = ({
  isloading,
  widgetTypes,
  onSelectType,
}: WorkspacePageAddNewWidgetModalStep1Props) => {
  const [gridFormat, setGridFormat] = useState<'grid' | 'list'>('grid');
  const [value, setValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filtredWidgetTypes, setFiltredWidgetTypes] = useState<WidgetType[]>(
    [],
  );
  const setSearchValueWithDebounce = useDebouncedCallback(
    (newValue: string) => {
      setSearchValue(newValue);
    },
    500,
  );

  useEffect(() => {
    setSearchValueWithDebounce(value);
  }, [value, setSearchValueWithDebounce]);

  useEffect(() => {
    if (searchValue.length >= 2) {
      const regex = new RegExp(searchValue, 'gmi');
      const filtredTypes = widgetTypes.filter(({ name }) => name.match(regex));
      setFiltredWidgetTypes(filtredTypes);
      return;
    }
    setFiltredWidgetTypes(widgetTypes);
  }, [searchValue, widgetTypes]);

  return (
    <>
      <Body1>Widgets</Body1>
      <SubTitleContainer>
        <H6>Choose a widget</H6>
        <GridSelector selectedFormat={gridFormat} onSelect={setGridFormat} />
      </SubTitleContainer>
      <SearchInput
        onChangeText={setValue}
        value={value}
        style={{ width: '100%', maxWidth: '800px', marginTop: '16px' }}
      />

      <ContentContainer>
        {isloading ? (
          <Spinner />
        ) : (
          <>
            {gridFormat === 'grid' ? (
              <WidgetTypesContainer>
                {filtredWidgetTypes.map(widgetType => (
                  <WorkspacePageAddNewWidgetModalCard
                    name={widgetType.name}
                    onPress={() => onSelectType(widgetType)}
                  />
                ))}
              </WidgetTypesContainer>
            ) : (
              <WidgetTypesListContainer>
                {filtredWidgetTypes.map(widgetType => (
                  <WorkspacePageAddNewWidgetModalList
                    name={widgetType.name}
                    description={widgetType.description}
                    onPress={() => onSelectType(widgetType)}
                  />
                ))}
              </WidgetTypesListContainer>
            )}
          </>
        )}
      </ContentContainer>
    </>
  );
};
