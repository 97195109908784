import { useRef } from 'react';
import { Image } from '@nucleus/Image';
import { Body1, Caption } from '@nucleus/Typography';
import { useHover } from 'react-native-web-hooks';
import { Container, InfoContainer } from './styles';
import previewWorkspace from '../../../assets/preview_workspace.png';

interface WorkspaceAreaAddButtonProps {
  onPress: () => void;
  name: string;
  formatedDate: string;
}

export const WorkspacesAreaCard = ({
  onPress,
  name,
  formatedDate,
}: WorkspaceAreaAddButtonProps) => {
  const contianerRef = useRef(null);
  const isHovered = useHover(contianerRef);
  return (
    <Container ref={contianerRef} isHovered={isHovered} onPress={onPress}>
      <Image
        source={previewWorkspace as string}
        style={{
          width: '100%',
          height: '75%',
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          filter: 'brightness(60%)',
          boxShadow: '0px 16px 15px rgba(0, 0, 0, 0.25)',
        }}
      />
      <InfoContainer>
        <Body1>{name}</Body1>
        <Caption
          style={{ marginTop: 4 }}
        >{`Last edited ${formatedDate}`}</Caption>
      </InfoContainer>
    </Container>
  );
};
