import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 60px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
`;
