import React, { useState, useEffect, ReactNode, useCallback } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { useTabsControlContext } from './TabsControlContext';
import { updateTabData, getTabData } from '../utils/tabApi';

interface EventsContextData {
  eventListOpened: boolean;
  updateEventListOpened: (opened: boolean) => void;

  activatedEventId: string | null | undefined;
  updateActivatedEventId: (eventId?: string | null) => void;

  createNewEventOpen: boolean;
  setCreateNewEventOpen: (opened: boolean) => void;
}

const EventsContext = createContext<EventsContextData>({} as EventsContextData);

interface EventsProviderProps {
  children: ReactNode;
}

const EventsContextProvider = ({ children }: EventsProviderProps) => {
  const { selectedTab } = useTabsControlContext();
  const [createNewEventOpen, setCreateNewEventOpen] = useState(false);
  const [eventListOpened, setEventListOpened] = useState(true);
  const [activatedEventId, setActivatedEventId] = useState<string | null>();

  const updateEventListOpened = useCallback(
    async (opened: boolean) => {
      setEventListOpened(opened);
      await updateTabData(selectedTab, {
        activatedEventId,
        eventListOpened: opened,
      });
    },
    [activatedEventId, selectedTab],
  );

  const updateActivatedEventId = useCallback(
    async (eventId?: string | null) => {
      setActivatedEventId(eventId);
      await updateTabData(selectedTab, {
        activatedEventId: eventId,
        eventListOpened,
      });
    },
    [eventListOpened, selectedTab],
  );

  useEffect(() => {
    async function loadData() {
      const tab = await getTabData(selectedTab);
      if (!tab) return;
      setEventListOpened(tab.eventListOpened);
      setActivatedEventId(tab.activatedEventId);
    }
    loadData();
  }, [selectedTab]);

  return (
    <EventsContext.Provider
      value={{
        eventListOpened,
        activatedEventId,
        updateEventListOpened,
        updateActivatedEventId,
        createNewEventOpen,
        setCreateNewEventOpen,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

function useEventListContext() {
  const eventListOpened = useContextSelector(
    EventsContext,
    eventsContext => eventsContext.eventListOpened,
  );
  const updateEventListOpened = useContextSelector(
    EventsContext,
    eventsContext => eventsContext.updateEventListOpened,
  );

  return {
    eventListOpened,
    updateEventListOpened,
  };
}

function useEventCreateContext() {
  const createNewEventOpen = useContextSelector(
    EventsContext,
    eventsContext => eventsContext.createNewEventOpen,
  );
  const setCreateNewEventOpen = useContextSelector(
    EventsContext,
    eventsContext => eventsContext.setCreateNewEventOpen,
  );

  return {
    createNewEventOpen,
    setCreateNewEventOpen,
  };
}

function useEventsContext() {
  const activatedEventId = useContextSelector(
    EventsContext,
    eventsContext => eventsContext.activatedEventId,
  );
  const updateActivatedEventId = useContextSelector(
    EventsContext,
    eventsContext => eventsContext.updateActivatedEventId,
  );

  return {
    activatedEventId,
    updateActivatedEventId,
  };
}

export {
  EventsContextProvider,
  useEventsContext,
  useEventCreateContext,
  useEventListContext,
};
