import React from 'react';
import { Marker } from 'react-map-gl';
import { MapMakerProps } from './types';

export const MarkerView = ({ children, ...rest }: MapMakerProps) => {
  return (
    <Marker data-testid="maker" {...rest}>
      {children}
    </Marker>
  );
};
