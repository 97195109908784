import styled from 'styled-components';

const WidgetEditModeContainer = styled.div`
  position: fixed;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  z-index: 100;
  transform: translateX(-100%);
`;

export { WidgetEditModeContainer };
