import { useState, useEffect } from 'react';
import { useTheme } from 'styled-components/native';
import { CreateLocationCustomAddressDrawModeSelector } from '@molecules/CreateLocationCustomAddressDrawModeSelector';
import { CreateLocationCustomAddressConfigureZone } from '@molecules/CreateLocationCustomAddressConfigureZone';
import { useZonesContext } from '@contexts/ZonesContext';
import { Location } from '@contexts/CreateLocationContext';
import { Container } from './styles';

interface CreateLocationCustomAddressProps {
  locationZoneType: 'main_courante' | 'main_courante_action';
  locationId: string;
  onCreateLocation: (location: Location) => void;
}

export function CreateLocationCustomAddress({
  locationZoneType,
  locationId,
  onCreateLocation,
}: CreateLocationCustomAddressProps) {
  const theme = useTheme();
  const [locationName, setLocationName] = useState('');

  const {
    isCreatingZone,
    isEditingZone,
    // selectedZoneIndex,
    setDrawMode,
    activeNoInteractionMode,
    removeCurrentModeLastCoordinates,
    // deleteZone,
    currentZoneType,
    getZoneById,
    deleteZoneById,
    currentZoneColor,
    changeCurrentZoneColor,
  } = useZonesContext();

  function handleConfirm() {
    const zone = getZoneById(locationId);
    if (!zone) return;
    onCreateLocation({
      zone,
      location_name: locationName,
      location_color: currentZoneColor,
    });
    activeNoInteractionMode();
  }

  function handleSelectPolygon() {
    setDrawMode({
      zoneType: locationZoneType,
      zoneId: locationId,
      drawType: 'Polygon',
    });
  }

  function handleSelectLine() {
    setDrawMode({
      zoneType: locationZoneType,
      zoneId: locationId,
      drawType: 'LineString',
    });
  }

  function handleSelectPoint() {
    setDrawMode({
      zoneType: locationZoneType,
      zoneId: locationId,
      drawType: 'Point',
    });
  }

  useEffect(() => {
    if (currentZoneType === 'main_courante') {
      changeCurrentZoneColor(theme.yellow);
    }
    if (currentZoneType === 'main_courante_action') {
      changeCurrentZoneColor(theme.eletricGreen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZoneType, changeCurrentZoneColor]);

  return (
    <Container>
      {(isCreatingZone || isEditingZone) &&
      (currentZoneType === 'main_courante' ||
        currentZoneType === 'main_courante_action') ? (
        <CreateLocationCustomAddressConfigureZone
          locationName={locationName}
          setLocationName={setLocationName}
          currentZoneColor={currentZoneColor}
          changeCurrentZoneColor={changeCurrentZoneColor}
          onDeleteZonePress={() => deleteZoneById(locationId)}
          onUndoZonePress={removeCurrentModeLastCoordinates}
          onConfirmZonePress={handleConfirm}
          isEditingZone={isEditingZone}
        />
      ) : (
        <CreateLocationCustomAddressDrawModeSelector
          onSelectPolygonModePress={handleSelectPolygon}
          onSelectLineModePress={handleSelectLine}
          onSelectPointPress={handleSelectPoint}
        />
      )}
    </Container>
  );
}
