import { useRef } from 'react';
import { TouchableOpacityProps } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { Icon } from '@nucleus/Icon';
import plusIcon from '../../../assets/icons/plus.svg';
import { Container } from './styles';

export interface AddButtonProps extends TouchableOpacityProps {
  size?: 'small' | 'large';
}

export const AddButton = ({ size = 'small', ...rest }: AddButtonProps) => {
  const containerRef = useRef(null);
  const isHovered = useHover(containerRef);

  return (
    <Container ref={containerRef} isHovered={isHovered} size={size} {...rest}>
      <Icon
        as={plusIcon}
        viewBox="0 0 24 24"
        width={size === 'small' ? '20' : '24'}
        height={size === 'small' ? '20' : '24'}
      />
    </Container>
  );
};
