import React from 'react';
import { IWidget } from '@contexts/WidgetGridContext';
import { WidgetContent } from '../../molecules/WidgetContent';

interface WidgetDragPreviewProps extends IWidget {
  isColliding: boolean;
}

const WidgetDragPreview = ({
  width,
  height,
  isColliding,
}: WidgetDragPreviewProps) => {
  return (
    <WidgetContent
      width={width}
      height={height}
      isDragPreview
      isColliding={isColliding}
    />
  );
};

export default WidgetDragPreview;
