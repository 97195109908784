/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ImageProps } from './types';

export function Image({ source, ...rest }: ImageProps) {
  function addDefaultSrc(event: any) {
    event.target.src =
      'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/image_not_available.png';
  }
  return (
    <img
      data-testid="image-component"
      src={source}
      alt="invalid"
      onError={addDefaultSrc}
      {...rest}
    />
  );
}

export default Image;
