import styled from 'styled-components/native';
import { shade } from 'polished';
import { Body1, Body2, ButtonPrimary } from '../Typography';

export const Container = styled.View`
  width: 95%;
  max-width: 260px;
  padding: 4px;
  position: relative;
`;

export const ContentContainer = styled.View`
  margin-top: 8px;
  width: 100%;
  height: 120px;
  background-color: #6a6a9f33;
  padding: 8px;
  border-radius: 20px;
`;

export const ItensContainer = styled.View`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Label = styled(Body1)`
  color: ${({ theme }) => theme.white};
`;

export const Placeholder = styled(Body2)`
  color: ${({ theme }) => theme.purpleBlue};
`;

export const PreviewContainer = styled.TouchableOpacity`
  position: relative;
  padding: 4px;
  width: 70px;
`;

interface RemoveFileProps {
  isHovered?: boolean;
}
export const RemoveFile = styled.TouchableOpacity<RemoveFileProps>`
  position: absolute;
  top: 0;
  right: 0;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${({ theme, isHovered }) =>
    isHovered ? shade(0.2, theme.red) : theme.red};
  align-items: center;
  justify-content: center;
`;

export const ErrorContainer = styled.View`
  top: 158px;
  left: 20px;
  flex-direction: row;
  position: absolute;
`;

export const ErrorText = styled(ButtonPrimary)`
  color: ${({ theme }) => theme.red};
  margin-left: 4px;
  font-weight: 400;
`;
