import { ReactElement, ReactNode, useState } from 'react';
import { Caption } from '@nucleus/Typography';
import { usePopper } from 'react-popper';
import { Portal } from '@gorhom/portal';
import { Container, TooltipContent, DeltaBottom, DeltaTop } from './styles';

interface TooltipProps {
  children: ReactElement;
  content: string | ReactNode;
  place?: 'top' | 'bottom';
}

export const Tooltip = ({ children, content, place = 'top' }: TooltipProps) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [isHovered, setIsHovered] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: place,
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  });

  return (
    <>
      <Container
        ref={setReferenceElement}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        {children}
      </Container>
      {isHovered && (
        <Portal>
          <TooltipContent
            data-placement={place}
            ref={setPopperElement}
            style={{
              ...styles.popper,
            }}
            {...attributes.popper}
          >
            {typeof content === 'string' ? (
              <Caption>{content}</Caption>
            ) : (
              content
            )}

            {place === 'top' && (
              <DeltaTop
                ref={setArrowElement}
                style={{
                  ...styles.arrow,
                }}
              />
            )}
            {place === 'bottom' && (
              <DeltaBottom
                ref={setArrowElement}
                style={{
                  ...styles.arrow,
                }}
              />
            )}
          </TooltipContent>
        </Portal>
      )}
    </>
  );
};
