import styled from 'styled-components/native';
import { transparentize } from 'polished';
import { IconButton } from '@atoms/IconButton';

export const Container = styled.View`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => transparentize(0.3, theme.black)};
  margin: auto;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.View`
  position: relative;
  max-width: 60%;
  border-radius: 20px;
  padding: 8px;
`;

export const ButtonsContainer = styled.View`
  flex-direction: row;
  margin-top: 40px;
  width: 100%;
  justify-content: space-evenly;
`;

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: -32px;
  top: -32px;
`;
