import styled from 'styled-components';
import { Dimensions } from 'react-native';

export const Container = styled.aside`
  display: flex;
  width: ${() => {
    const { width } = Dimensions.get('window');
    if (width < 1440) {
      return '80px';
    }
    if (width < 1680) {
      return '90px';
    }
    return '100px';
  }};
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.blueishBlack};
  section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  section:not(:first-child):not(:last-child) {
    margin-top: -17vh;
    height: 30%;
    justify-content: space-around;
  }
`;
