import { useTranslation } from 'react-i18next';
import { Linking, ActivityIndicator } from 'react-native';
import { useTheme } from 'styled-components/native';
import { EventInfoDetailTitleAndSourceImage } from '@molecules/EventInfoDetailTitleAndSourceImage';
import { EventInfoDetailInfos } from '@molecules/EventInfoDetailInfos';
import { EventInfoDetailMediaList } from '@molecules/EventInfoDetailMediaList';
import { H6 } from '@nucleus/Typography';
import { Container, MoreDetailsButton } from './styles';

import { useQueryContentInfo } from '../../../services/hooks/useQueryContentInfo';

interface EventInfoDetailProps {
  contentId: string;
}

export const EventInfoDetail = ({ contentId }: EventInfoDetailProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { data, isLoading } = useQueryContentInfo(contentId);
  return (
    <Container>
      <H6>{t('details')}</H6>
      {isLoading ? (
        <ActivityIndicator color={theme.purple} size="large" />
      ) : (
        data && (
          <>
            <EventInfoDetailTitleAndSourceImage
              title={data.text}
              sourceImage={data.source_image || undefined}
            />
            <EventInfoDetailInfos
              date={data.date}
              desc={data.desc}
              sourceName={data.source_name || undefined}
            />

            <MoreDetailsButton onPress={() => Linking.openURL(data.url)}>
              {t('more_details')}
            </MoreDetailsButton>

            <EventInfoDetailMediaList
              image={data.image}
              photos={data.photos}
              videos={data.videos}
            />
          </>
        )
      )}
    </Container>
  );
};
