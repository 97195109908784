import { StyleProp, ViewStyle, TouchableOpacity } from 'react-native';
import { useState, useRef, ComponentType } from 'react';
import { Icon } from '@nucleus/Icon';
import { useTranslation } from 'react-i18next';
import { Portal } from '@gorhom/portal';
import { SvgProps, NumberProp, Color } from 'react-native-svg';
import expandMoreIcon from '../../../assets/icons/expand_more.svg';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

import {
  Container,
  DropDownButton,
  DropDownMasterWraper,
  DropDownItem,
  DropDownItemText,
} from './styles';

export interface Option {
  value: string;
  label: string;
  icon?: ComponentType<SvgProps>;
  iconWidth?: NumberProp;
  iconHeight?: NumberProp;
  iconViewBox?: string;
  iconFill?: Color;
}

interface SelectProps {
  options: Option[];
  onSelect: (value: string) => void;
  style?: StyleProp<ViewStyle>;
  icon?: ComponentType<SvgProps>;
  iconWidth?: NumberProp;
  iconHeight?: NumberProp;
  iconViewBox?: string;
  iconFill?: Color;
}

export const DropDown = ({
  options,
  onSelect,
  style,
  icon,
  iconWidth,
  iconHeight,
  iconViewBox,
  iconFill,
}: SelectProps) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const wrapperRef = useRef<TouchableOpacity>(null);
  const dropButtonRef = useRef<TouchableOpacity>(null);

  useOutsideClick({
    componentRef: wrapperRef,
    yOffset: 20,
    onOutsideClick: () => setIsOpened(false),
  });

  return (
    <Container style={style}>
      <DropDownButton
        ref={dropButtonRef}
        onPress={() => setIsOpened(oldState => !oldState)}
      >
        <Icon
          as={icon || expandMoreIcon}
          height={iconHeight}
          width={iconWidth}
          viewBox={iconViewBox}
          fill={iconFill}
        />
      </DropDownButton>
      {isOpened && dropButtonRef.current && (
        <Portal>
          <DropDownMasterWraper
            parentElement={dropButtonRef.current}
            ref={wrapperRef}
          >
            {options.map(option => (
              <DropDownItem
                key={option.value}
                onPress={() => {
                  setIsOpened(false);
                  onSelect(option.value);
                }}
              >
                {option.icon && (
                  <Icon
                    as={option.icon}
                    height={option.iconHeight}
                    width={option.iconWidth}
                    viewBox={option.iconViewBox}
                    fill={option.iconFill}
                    style={{ marginRight: 8 }}
                  />
                )}
                <DropDownItemText>{t(option.label)}</DropDownItemText>
              </DropDownItem>
            ))}
          </DropDownMasterWraper>
        </Portal>
      )}
    </Container>
  );
};
