import Tabs from 'rc-tabs';

import styled from 'styled-components';

export const EventTab = styled(Tabs)`
  font-family: 'Open Sans';
  font-size: 14px;
  background-color: #212337;
  border: none;
  height: 100%;

  .rc-tabs-nav {
    background-color: ${({ theme }) => theme.background};
  }

  .rc-tabs-content-top {
    background-color: ${({ theme }) => theme.purpleBlack};
  }

  .rc-tabs-extra-content {
    background-color: ${({ theme }) => theme.background} !important;
    margin-left: 5px;
  }

  .rc-tabs-ink-bar {
    background-color: transparent;
  }

  .rc-tabs-tab {
    background-color: transparent !important;
    border: none !important;
    clip-path: polygon(0 0, 95% 0, 100% 100%, 0 100%);
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-right: 8px !important;
  }

  .rc-tabs-tab svg {
    margin-top: 3px !important;
  }

  .rc-tabs-tab svg:hover {
    fill: white !important;
  }

  .rc-tabs-nav-wrap {
    background-color: ${({ theme }) => theme.background} !important;
    height: 32px;
    margin-top: 8px;
  }

  .rc-tabs-tab-active {
    background-color: ${({ theme }) => theme.purpleBlack} !important;
    font-weight: 400 !important;
    font-family: 'Open Sans' !important;
    color: #fff;
    clip-path: polygon(0 0, 90% 0, 100% 100%, 0 100%);
    padding-right: 16px !important;
    before: none;
  }

  .rc-tabs-tab svg {
    color: ${({ theme }) => theme.purpleBlue} !important;
  }

  .rc-tabs-nav::before {
    border-bottom: none !important;
  }

  .rc-tabs-nav-add {
    margin-right: 5px;
    background-color: transparent !important;
    border: none !important;
  }

  .rc-tabs-tab-btn {
    font-size: 14px;
  }

  .rc-tabs-tab-active .rc-tabs-tab-btn {
    color: ${({ theme }) => theme.white};
  }

  .rc-tabs-nav-add svg {
    color: ${({ theme }) => theme.purpleBlue} !important;
    fill: ${({ theme }) => theme.purpleBlue} !important;
  }

  .rc-tabs-nav-operations {
    background: ${({ theme }) => theme.background} !important;
  }

  .rc-tabs-nav-more {
    border: none !important;
    border-color: transparent !important;
  }

  .rc-tabs-nav-add svg:hover {
    color: white !important;
  }

  .rc-tabs-nav-operations {
    margin-top: 8px;
    height: 32px;
  }
  .rc-tabs-nav-operations .rc-tabs-nav-more {
    border: none !important;
    background: ${({ theme }) => theme.background} !important;
  }

  .rc-tabs-nav-operations button {
    background: transparent !important;
  }

  .rc-tabs-nav-operations .rc-tabs-nav-more svg {
    color: ${({ theme }) => theme.purpleBlue} !important;
    fill: ${({ theme }) => theme.purpleBlue} !important;
  }

  .rc-tabs-nav-operations .rc-tabs-nav-add {
    background-color: ${({ theme }) => theme.purpleBlack} !important;
  }

  .rc-tabs-nav-add svg:hover {
    fill: white !important;
  }

  .rc-tabs-nav-add {
    margin: 0 6px 0 6px !important;
  }

  .rc-tabs-nav-more {
    margin: 0 6px 0 6px !important;
  }
  .rc-tabs-nav-more svg:hover {
    fill: white !important;
  }

  .rc-tabs-dropdown-menu {
    background-color: ${({ theme }) => theme.purpleBlack} !important;
  }

  .rc-tabs-dropdown-menu-item {
    background: ${({ theme }) => theme.purpleBlack} !important;
    color: ${({ theme }) => theme.white} !important;
  }

  .rc-tabs-dropdown-menu-item:hover {
    background: ${({ theme }) => theme.purpleBlue} !important;
  }

  .rc-tabs-nav-wrap-ping-left::before {
    border: none !important;
  }

  .rc-tabs-nav-wrap-ping-right::after {
    border: none !important;
  }

  .rc-tabs-tabpane {
    height: 100px;
  }

  .rc-tabs-tabpane-active {
    height: 100%;
  }

  .rc-tabs-content {
    height: 100%;
  }

  .mapboxgl-map {
    border-top-left-radius: 15px;
  }

  .rc-tabs-content-holder {
    height: calc(100vh - 57px);
  }
`;
