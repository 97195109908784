import styled from 'styled-components';
import { Rnd } from 'react-rnd';

export const Container = styled(Rnd)`
  z-index: 1;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px #05050f;
  position: relative;
`;
