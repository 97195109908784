import { ComponentType, useRef } from 'react';
import { Icon } from '@nucleus/Icon';
import { SvgProps } from 'react-native-svg';
import { useHover } from 'react-native-web-hooks';
import { useTheme } from 'styled-components/native';
import { darken } from 'polished';

import { Container, Border } from './styles';

export interface SideMenuButtonProps {
  handlePress: () => void;
  icon: ComponentType<SvgProps>;
  isActive?: boolean;
}

export const SideMenuButton = ({
  isActive,
  handlePress,
  icon,
}: SideMenuButtonProps) => {
  const ref = useRef(null);
  const theme = useTheme();
  const isHovered = useHover(ref);

  function getIconColor() {
    if (isActive) {
      if (isHovered) {
        return darken(0.2, theme.purple);
      }
      return theme.purple;
    }
    if (isHovered) {
      return darken(0.2, theme.purpleBlue);
    }
    return theme.purpleBlue;
  }

  return (
    <Container ref={ref} onPress={handlePress}>
      {isActive && <Border isHovered={isHovered} />}
      <Icon
        as={icon}
        width={28}
        height={28}
        viewBox="0 0 24 24"
        fill={getIconColor()}
      />
    </Container>
  );
};
