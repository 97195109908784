import { useState } from 'react';
import { LayoutChangeEvent } from 'react-native';
import { CreateLocation } from '@templates/CreateLocation';
import { WebLayout } from '@templates/WebLayout';
import { EventsTabs } from '@templates/EventsTab';
import { EventsMap } from '@templates/EventsMap';
import { EventInfo } from '@templates/EventInfo';
import { MainCouranteInfo } from '@templates/MainCouranteInfo';
import { EventsFilter } from '@templates/EventsFilter';
import { Size } from 'utils/tabApi';
import { EventList } from '@templates/EventList';
import { EventCreate } from '../../components/EventCreate';
import { CartographicSelector } from '../../components/CartographicSelector';
import { MapContainer, EventContainer } from './styles.web';

export const Dashboard = () => {
  const [parentSize, setParentSize] = useState<Size>();
  const handleLayout = (e: LayoutChangeEvent) => {
    setParentSize({
      width: e.nativeEvent.layout.width,
      height: e.nativeEvent.layout.height,
    });
  };
  return (
    <WebLayout>
      <EventsTabs>
        <EventContainer>
          <EventList />
          <MapContainer onLayout={handleLayout}>
            <>
              {parentSize && (
                <>
                  <EventsMap parentSize={parentSize} />
                  <EventsFilter parentSize={parentSize} />
                  <EventInfo parentSize={parentSize} />
                  <MainCouranteInfo parentSize={parentSize} />
                  <EventCreate parentSize={parentSize} />
                  <CreateLocation parentSize={parentSize} />
                  <CartographicSelector parentSize={parentSize} />
                </>
              )}
            </>
          </MapContainer>
        </EventContainer>
      </EventsTabs>
    </WebLayout>
  );
};
