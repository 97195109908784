import { useEffect } from 'react';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { Platform } from 'react-native';
import { Icon } from '@nucleus/Icon';
import { useToastContext } from '@contexts/ToastContext';
import { Container } from './styles';
import addFileIcon from '../../../assets/icons/add_file.svg';

export interface FileInfo {
  uri: string;
  name: string;
  type: string;
}

interface AddFileButtonProps {
  onChange: (files: FileInfo) => void;
}

export const AddFileButton = ({ onChange }: AddFileButtonProps) => {
  const { dispatchToast } = useToastContext();
  useEffect(() => {
    (async () => {
      if (Platform.OS !== 'web') {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== 'granted') {
          dispatchToast({
            message:
              "'Sorry, we need camera roll permissions to make this work!'",
            type: 'error',
          });
        }
      }
    })();
  }, [dispatchToast]);

  const pickImage = async () => {
    const result = await DocumentPicker.getDocumentAsync({
      type: 'image/*, video/*',
    });
    if (result.type === 'success' && result.file) {
      onChange({
        uri: result.uri,
        name: result.file.name,
        type: result.file.type,
      });
    }
  };

  return (
    <Container onPress={pickImage}>
      <Icon as={addFileIcon} width={12} viewBox="0 0 51 57" fill="#fff" />
    </Container>
  );
};
