import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import { Icon } from '@nucleus/Icon';
import SVG from 'react-inlinesvg';
import { Portal } from '@gorhom/portal';
import { usePrevious } from '../../../hooks/usePrevious';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import expandMoreIcon from '../../../assets/icons/expand_more.svg';
import expandLessIcon from '../../../assets/icons/expand_less.svg';

import {
  Container,
  Label,
  DropDownButton,
  DropDownMasterWraper,
  DropDownWraper,
  DropDownItem,
  DropDownItemText,
  TypeEventItem,
  IconContainer,
  TypeEventItemOverlay,
  TypeEvent,
  DropDownItemContainer,
  Placeholder,
} from './styles';

export interface Category {
  id: number;
  name: string;
  color: string;
  subcategories: {
    id: number;
    name: string;
    icon: string;
    color: string;
    description: string;
    kw_in: never[];
    kw_ex: never[];
    classifier_score: number | null;
    geolocation: null;
    parent_id: number;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: null;
  }[];
}

interface CategoriesSelectorProps {
  style?: StyleProp<ViewStyle>;
  selectedSubcategories: number[];
  onChange: (value: number[]) => void;
  onBlur?: () => void;
  listOfCategories: Category[];
}

export const CategoriesSelector = ({
  style,
  selectedSubcategories,
  onChange,
  onBlur,
  listOfCategories,
}: CategoriesSelectorProps) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const [categories, setCategories] = useState(
    listOfCategories.map(category => ({ ...category, isOpened: false })),
  );

  const wrapperRef = useRef<TouchableOpacity>(null);
  const dropButtonRef = useRef<TouchableOpacity>(null);

  const prevIsOpened = usePrevious(isOpened);

  useOutsideClick({
    componentRef: wrapperRef,
    yOffset: 20,
    onOutsideClick: () => setIsOpened(false),
  });

  function openOpenedCategory(categoryName: string) {
    setCategories(oldCategories =>
      oldCategories.map(oldCategorie => {
        if (oldCategorie.name === categoryName) {
          return {
            ...oldCategorie,
            isOpened: true,
          };
        }
        return oldCategorie;
      }),
    );
  }

  function closeOpenedCategory(categoryName: string) {
    setCategories(oldCategories =>
      oldCategories.map(oldCategorie => {
        if (oldCategorie.name === categoryName) {
          return {
            ...oldCategorie,
            isOpened: false,
          };
        }
        return oldCategorie;
      }),
    );
  }

  function activeAllSubcategoriesCategory(categoryId: number) {
    const selectedCategory = categories.find(cat => cat.id === categoryId);
    if (!selectedCategory) return;
    const subcategoriesIds = selectedCategory.subcategories.map(
      subCategory => subCategory.id,
    );
    if (subcategoriesIds.every(id => selectedSubcategories.includes(id))) {
      onChange(
        selectedSubcategories.filter(id => !subcategoriesIds.includes(id)),
      );
      return;
    }
    onChange([...new Set([...selectedSubcategories, ...subcategoriesIds])]);
  }

  function toggleActiveSubcategorie(subCategoryId: number) {
    if (selectedSubcategories.includes(subCategoryId)) {
      return onChange(selectedSubcategories.filter(id => id !== subCategoryId));
    }
    return onChange([...selectedSubcategories, subCategoryId]);
  }

  function getActivedCategories() {
    return categories.reduce((acc, category) => {
      return [
        ...acc,
        ...category.subcategories
          .filter(subcategory => selectedSubcategories.includes(subcategory.id))
          .map(subcategory => t(subcategory.name)),
      ];
    }, [] as string[]);
  }

  useEffect(() => {
    if (!isOpened && prevIsOpened) {
      if (onBlur) {
        onBlur();
      }
    }
  }, [isOpened, onBlur, prevIsOpened]);

  return (
    <Container style={style}>
      {getActivedCategories().length > 0 && <Label>{t('event_type')}</Label>}
      <DropDownButton
        ref={dropButtonRef}
        isOpened={isOpened}
        onPress={() => setIsOpened(oldState => !oldState)}
      >
        {getActivedCategories().length > 0 ? (
          <DropDownItemText ellipsizeMode="tail" numberOfLines={1}>
            {getActivedCategories().join(',')}
          </DropDownItemText>
        ) : (
          <Placeholder>{t('event_type')}</Placeholder>
        )}

        <Icon as={expandMoreIcon} height={20} />
      </DropDownButton>
      {isOpened && dropButtonRef.current && (
        <Portal>
          <DropDownMasterWraper
            ref={wrapperRef}
            parentElement={dropButtonRef.current}
          >
            {categories.map(category => (
              <DropDownItem key={category.id}>
                <DropDownItemContainer color={category.color}>
                  <TouchableOpacity
                    style={{ flexGrow: 1 }}
                    onPress={() => {
                      activeAllSubcategoriesCategory(category.id);
                      if (!category.isOpened) {
                        openOpenedCategory(category.name);
                      }
                    }}
                  >
                    <DropDownItemText>{t(category.name)}</DropDownItemText>
                  </TouchableOpacity>
                  {category.isOpened ? (
                    <TouchableOpacity
                      onPress={() => closeOpenedCategory(category.name)}
                    >
                      <Icon as={expandLessIcon} height={20} />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      onPress={() => openOpenedCategory(category.name)}
                    >
                      <Icon as={expandMoreIcon} height={20} />
                    </TouchableOpacity>
                  )}
                </DropDownItemContainer>
                {category.isOpened && (
                  <DropDownWraper>
                    {category.subcategories.map(subcategorie => (
                      <TypeEventItem
                        key={subcategorie.id}
                        onPress={() =>
                          toggleActiveSubcategorie(subcategorie.id)
                        }
                      >
                        {selectedSubcategories.includes(subcategorie.id) && (
                          <TypeEventItemOverlay />
                        )}
                        <TypeEvent color={subcategorie.color}>
                          <IconContainer>
                            <SVG
                              src={subcategorie.icon}
                              width={12}
                              fill={subcategorie.color}
                              height="100%"
                              title="category_icon"
                            />
                          </IconContainer>
                        </TypeEvent>
                        <DropDownItemText>
                          {t(subcategorie.name)}
                        </DropDownItemText>
                      </TypeEventItem>
                    ))}
                  </DropDownWraper>
                )}
              </DropDownItem>
            ))}
          </DropDownMasterWraper>
        </Portal>
      )}
    </Container>
  );
};
