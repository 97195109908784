import { ReactNode } from 'react';
import { EventsListFrameHeader } from '@molecules/EventsListFrameHeader';
import { Container } from './styles';

interface EventsListFrameProps {
  children: ReactNode;
  onClosePress: () => void;
}

export const EventsListFrame = ({
  children,
  onClosePress,
}: EventsListFrameProps) => {
  return (
    <Container>
      <EventsListFrameHeader onClosePress={onClosePress} />
      {children}
    </Container>
  );
};
