/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { api } from '../eventApi';
import { queryClient } from '../queryClient';
import { useToastContext } from '../../contexts/ToastContext';

interface CloseMainCouranteMutationProps {
  main_courante_id: string;
  tabId: string;
  filters: any;
}

interface CloseMainCouranteMutationData {
  main_courante_action_id: string;
  closed: boolean;
}

export const useMutationCloseMainCouranteAction = ({
  main_courante_id,
}: CloseMainCouranteMutationProps) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({
      main_courante_action_id,
      closed,
    }: CloseMainCouranteMutationData) => {
      const { data } = await api.put(
        `/v1/main_courante_actions/closed_status/${main_courante_action_id}`,
        { closed },
      );

      return data;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['mainCourante', main_courante_id]);
        dispatchToast({
          message: t('closed_status_changed_success'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('closed_status_changed_fail'),
          type: 'error',
        });
      },
    },
  );
};
