import { ComponentType, useEffect, useState, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AutoCompleteInput, Option } from '@atoms/AutoCompleteInput';
import { api } from '@services/geocodingApi';
import { StyleProp, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { usePrevious } from '../../../hooks/usePrevious';
import { useDidMountEffect } from '../../../hooks/useDidMountEffect';

export interface Location {
  bbox: number[];
  place_name: string;
  text?: string;
  geometry: {
    type: 'Point';
    coordinates: [number, number] | [number, number, number];
  };
}

interface ExistedAddressContext {
  id: string;
  text: string;
  wikidata?: string;
  short_code?: string;
}

export interface ExistedAddressFeature {
  bbox: number[];
  center: [number, number] | [number, number, number];
  context: ExistedAddressContext[];
  geometry: {
    type: 'Point';
    coordinates: [number, number] | [number, number, number];
  };
  id: string;
  place_name: string;
  place_type: string[];
  properties: { accuracy: string };
  relevance: number;
  text: string;
  type: 'Feature';
}

interface AddressFilterInputProps {
  onSelectAddress: (data?: Location) => void;
  icon?: ComponentType<SvgProps>;
  style?: StyleProp<ViewStyle>;
  onBlur?: () => void;
  val?: Location;
  placeholder?: string;
}

export const AddressFilterInput = ({
  onSelectAddress,
  icon,
  style,
  onBlur,
  val,
  placeholder,
}: AddressFilterInputProps) => {
  const firstRender = useRef(true);
  const [value, setValue] = useState(val ? val.place_name : '');
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<Option<ExistedAddressFeature>[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const prevIsOpened = usePrevious(isOpen);
  const prevValue = usePrevious(value);

  const setSearchValueWithDebounce = useDebouncedCallback(
    (newValue: string) => {
      setSearchValue(newValue);
    },
    500,
  );

  useEffect(() => {
    setSearchValueWithDebounce(value);
  }, [value, setSearchValueWithDebounce]);

  useEffect(() => {
    async function requestOptions() {
      const response = await api.get(`/mapbox.places/${searchValue}.json`, {
        params: {
          access_token:
            'pk.eyJ1Ijoiam9hb2duIiwiYSI6ImNrbGw3bGI5NTNmdWYyenRrd3dwZGh3Z2kifQ.SHH9ded8nfmYQFJw1hJ1Jg',
          types:
            'country,region,district,place,neighborhood,address,poi,locality,postcode',
          limit: 5,
        },
      });
      if (response.data.features && response.data.features.length > 0) {
        setOptions(
          response.data.features.map((feature: ExistedAddressFeature) => ({
            label: feature.place_name,
            value: feature.id,
            data: feature,
          })),
        );
      }
    }

    if (searchValue !== '' && firstRender.current) {
      firstRender.current = false;
    } else if (searchValue.length > 2) {
      requestOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if (options.length > 0) {
      setIsOpen(true);
      return;
    }
    setIsOpen(false);
  }, [options]);

  useEffect(() => {
    if (!isOpen && prevIsOpened) {
      if (onBlur) {
        onBlur();
      }
    }
  }, [isOpen, onBlur, prevIsOpened]);

  useEffect(() => {
    setValue(val ? val.place_name : '');
  }, [val]);

  useDidMountEffect(() => {
    if (value.length < 2 && prevValue && prevValue.length >= 2) {
      onSelectAddress(undefined);
    }
  }, [value]);

  function onSelectOption(newValue: ExistedAddressFeature) {
    setValue(newValue.place_name);
    onSelectAddress(newValue);
    firstRender.current = true;
    setIsOpen(false);
  }

  return (
    <AutoCompleteInput
      placeholder={placeholder}
      style={style}
      value={value}
      onChangeText={newValue => setValue(newValue)}
      options={options}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onSelectOption={onSelectOption}
      icon={icon}
      onBlur={onBlur}
    />
  );
};
