import { Svg, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

interface PriorityViewProps {
  intensity: number;
}

export const IntensityView = ({ intensity }: PriorityViewProps) => {
  const theme = useTheme();

  function getColorRect2() {
    switch (intensity) {
      case 1:
        return theme.purpleBlue;
      case 2:
        return theme.yellow;
      case 3:
        return theme.red;
      default:
        return theme.purpleBlue;
    }
  }

  return (
    <Svg width="16" height="17" viewBox="0 0 16 17" fill="none">
      <Rect
        y="7.1579"
        width="2.68421"
        height="9.8421"
        fill={intensity === 3 ? theme.red : theme.yellow}
      />
      <Rect
        x="6.26318"
        y="4.47369"
        width="2.68421"
        height="12.5263"
        fill={getColorRect2()}
      />
      <Rect
        x="12.5264"
        width="2.68421"
        height="17"
        fill={intensity === 3 ? theme.red : theme.purpleBlue}
      />
    </Svg>
  );
};
