import { useRef } from 'react';
import { TouchableOpacityProps, ActivityIndicator } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { useTheme } from 'styled-components/native';
import { Container, ButtonText } from './styles';

export interface ButtonProps extends TouchableOpacityProps {
  children: string;
  loading?: boolean;
  outline?: boolean;
}

export const Button = ({
  children,
  loading,
  outline,
  ...rest
}: ButtonProps) => {
  const theme = useTheme();
  const ref = useRef(null);
  const isHovered = useHover(ref);

  return (
    <Container ref={ref} isHovered={isHovered} outline={outline} {...rest}>
      {loading ? (
        <ActivityIndicator color={outline ? theme.blue : theme.white} />
      ) : (
        <ButtonText outline={outline} isHovered={isHovered}>
          {children}
        </ButtonText>
      )}
    </Container>
  );
};
