import styled, { css } from 'styled-components';

interface StyledWidgetContentProps {
  isDragPreview: boolean;
  isColliding: boolean;
  isEditMode: boolean;
}

const StyledWidgetContent = styled.div<StyledWidgetContentProps>`
  position: relative;
  border-radius: 8px;
  padding: 8px;
  ${({ theme, isColliding, isDragPreview }) =>
    isDragPreview
      ? css`
          background: ${isColliding ? theme.red : theme.blue};
          opacity: 0.6;
          ${isColliding && 'cursor : no-drop !important;'}
        `
      : css`
          background: ${theme.background};
        `};

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    cursor: ${({ isEditMode }) => (isEditMode ? 'grab' : 'auto')};
  }
`;

export { StyledWidgetContent };
