import styled from 'styled-components/native';
import { IconButton } from '@atoms/IconButton';

export const Container = styled.View`
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
`;

export const Button = styled(IconButton)`
  justify-content: space-evenly;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.purpleBlue};
  justify-content: center;
  align-items: center;
`;
