import { Modal } from '@atoms/Modal';
import { useTranslation } from 'react-i18next';
import { Body1 } from '@nucleus/Typography';

import { Container, ButtonsContainer, Button } from './styles';

interface ConfirmCancelModalProps {
  title: string;
  isVisible?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmCancelModal = ({
  title,
  isVisible,
  onConfirm,
  onCancel,
}: ConfirmCancelModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal visible={isVisible}>
      <Container>
        <Body1>{title}</Body1>
        <ButtonsContainer>
          <Button outline onPress={onCancel}>
            {t('modal_cancel')}
          </Button>
          <Button onPress={onConfirm}>{t('modal_confirm')}</Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};
