import { TextInputProps } from 'react-native';
import { Icon } from '@nucleus/Icon';
import searchIcon from '../../../assets/icons/new_search.svg';
import { Container, InputText, IconsContainer } from './styles';

export const SearchInput = ({ style, ...rest }: TextInputProps) => {
  return (
    <Container style={style}>
      <InputText {...rest} />

      <IconsContainer>
        <Icon as={searchIcon} />
      </IconsContainer>
    </Container>
  );
};
