import React, { ComponentType, useRef } from 'react';
import { SvgProps } from 'react-native-svg';
import { Icon } from '@nucleus/Icon';
import { TouchableOpacityProps } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useHover } from 'react-native-web-hooks';
import { Container } from './styles';

export interface ButtonProps extends TouchableOpacityProps {
  icon: ComponentType<SvgProps>;
}

export const EventsMapButton = ({ icon, ...rest }: ButtonProps) => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const isHovered = useHover(containerRef);

  return (
    <Container ref={containerRef} {...rest}>
      {isHovered ? (
        <Icon as={icon} fill={theme.blueishBlack} stroke={theme.blueishBlack} />
      ) : (
        <Icon as={icon} />
      )}
    </Container>
  );
};
