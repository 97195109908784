import { TextInputProps } from 'react-native';
import { useTheme } from 'styled-components/native';
import ErrorIcon from '../../assets/icons/error_outline.svg';

import {
  Container,
  InputText,
  Label,
  ErrorContainer,
  ErrorText,
} from './styles';

export interface InputProps extends TextInputProps {
  label?: string;
  error?: string;
}

export const MultiLineInput = ({
  label,
  style,
  error,
  ...rest
}: InputProps) => {
  const theme = useTheme();
  return (
    <Container style={style}>
      {label && <Label>{label}</Label>}
      <InputText multiline placeholderTextColor={theme.purpleBlue} {...rest} />
      {error && (
        <ErrorContainer>
          <ErrorIcon
            testID="input-error-icon"
            fill={theme.red}
            width={16}
            height={16}
            viewBox="0 0 24 24"
          />
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
      )}
    </Container>
  );
};
