import styled from 'styled-components/native';

export const ContentContainer = styled.View`
  display: flex;
  width: 100%;
  height: calc(100% - 78px);
  align-items: center;
  justify-content: center;
  margin-top: -8px;
`;

export const WidgetTypesContainer = styled.View`
  max-width: 800px;
  width: 100%;
  max-height: 700px;
  height: 95%;
  border-color: ${({ theme }) => theme.purpleBlue};
  border-radius: 8px;
  border-width: 2px;
  padding: 16px;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
`;

export const WidgetTypesListContainer = styled.View`
  max-width: 800px;
  width: 100%;
  max-height: 700px;
  height: 95%;
  border-color: ${({ theme }) => theme.purpleBlue};
  border-radius: 8px;
  border-width: 2px;
  padding: 16px;
  justify-content: flex-start;
  overflow-y: scroll;
`;

export const SubTitleContainer = styled.View`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
