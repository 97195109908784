import styled from 'styled-components/native';
import { Body2 } from '../../components/Typography';

export const Container = styled.View`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

interface LoginFormProps {
  keyboardUp?: boolean;
}

export const LoginForm = styled.View<LoginFormProps>`
  margin-top: 3.3%;
  width: 90%;
  max-width: 730px;
  height: ${({ keyboardUp }) => (keyboardUp ? '70%' : '60%')};
  max-height: 520px;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 20px;
  justify-content: space-evenly;
  align-items: center;
`;

export const SubLogoContainer = styled.View`
  width: 272px;
  height: 62px;
  max-height: 62px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.white};
  justify-content: center;
  align-items: center;
`;

export const CheckBoxContainer = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const SignInText = styled(Body2)`
  color: ${({ theme }) => theme.purpleBlue};
  margin-left: 5px;
`;
