/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import { v4 as uuid } from 'uuid';
import { useAuthContext } from '@contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { updateTabData, Filters } from '../utils/tabApi';
import { getState, storeState } from '../utils/asyncStorage';
import { useMutationTabs } from '../services/hooks/useMutationTabs';

export const defaultFilters = {
  categories: [],
  search: [],
  any_terms: [],
  all_terms: [],
  exclude_terms: [],
  location: undefined,
  relevancies: [],
  start_date: undefined,
  end_date: undefined,
  mainCourante: [],
  zones: [],
} as Filters;

export const defaultMap = 'mapbox://styles/mapbox/satellite-v9';

interface TabsContextData {
  selectedTab: string;
  createNewTab: () => Promise<void>;
  removeTab: (tabId: string) => Promise<void>;
  changeTabName: (tabId: string, name: string) => Promise<void>;
  updateSelectedTab: (tabId: string) => Promise<void>;
  updateTab: (tabId: string, data: any) => Promise<void>;
}

const TabsContext = createContext<TabsContextData>({} as TabsContextData);

interface TabsProviderProps {
  children: ReactNode;
}

const TabsControlProvider = ({ children }: TabsProviderProps) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const [selectedTab, setSelectedTab] = useState<string>('');

  const useQuery = useMutationTabs();

  const updateSelectedTab = useCallback(async (newSelectedTab: string) => {
    await storeState('selectedTab', newSelectedTab);
    setSelectedTab(newSelectedTab);
  }, []);

  const createNewTab = useCallback(async () => {
    const newTabId = uuid();
    const newTab = {
      id: newTabId,
      name: t('new_tab'),
      eventListOpened: true,
      activatedEventId: null,
      activatedActionId: null,
      eventDetailScreenSize: { width: 400, height: 300 },
      eventDetailScreenPosition: { x: 0, y: 0 },
      cartographicSelectorOpened: false,
      cartographicSelectorPosition: { x: 0, y: 0 },
      mapCartographicStyle:
        user?.organization.setup?.defaultMapStyle || defaultMap,
      filterScreenOpen: false,
      filters: defaultFilters,
    };
    await useQuery.mutateAsync({ newTab, type: 'create' });
    updateSelectedTab(newTabId);
  }, [
    t,
    user?.organization.setup?.defaultMapStyle,
    useQuery,
    updateSelectedTab,
  ]);

  const removeTab = useCallback(
    async (tabId: string) => {
      await useQuery.mutateAsync({ tabId, type: 'delete' });
    },
    [useQuery],
  );

  const changeTabName = useCallback(
    async (tabId: string, name: string) => {
      await useQuery.mutateAsync({
        tabId,
        newTabName: name,
        type: 'updateName',
      });
    },
    [useQuery],
  );

  const updateTab = useCallback(
    async (tabId: string, data: any) => {
      if (!selectedTab) return;
      await updateTabData(tabId, { ...data });
    },
    [selectedTab],
  );

  useEffect(() => {
    async function loadData() {
      const tabId = await getState('selectedTab');
      setSelectedTab(tabId);
    }
    loadData();
  }, []);

  return (
    <TabsContext.Provider
      value={{
        selectedTab,
        createNewTab,
        removeTab,
        changeTabName,
        updateSelectedTab,
        updateTab,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

function useTabsControlContext(): TabsContextData {
  const context = useContext(TabsContext);

  return context;
}

export { TabsControlProvider, useTabsControlContext };
