import { Board } from '@atoms/Board';
import { UserAvatar } from '@atoms/UserAvatar';
import { Caption } from '@nucleus/Typography';
import { useTranslation } from 'react-i18next';

interface MainCouranteInfoOverviewActorBoardProps {
  authorAvatar?: string;
  authorFallbackName: string;
}

export const MainCouranteInfoOverviewActorBoard = ({
  authorAvatar,
  authorFallbackName,
}: MainCouranteInfoOverviewActorBoardProps) => {
  const { t } = useTranslation();
  return (
    <Board style={{ width: '40%', maxWidth: 200 }}>
      <Caption style={{ marginLeft: 12 }}>{t('author')}</Caption>
      <UserAvatar
        fallbackName={authorFallbackName}
        avatar={authorAvatar}
        style={{ alignSelf: 'center' }}
      />
    </Board>
  );
};
