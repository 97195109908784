import { ActionButton } from '@atoms/ActionButton';
import { useTheme } from 'styled-components/native';
import confirmIcon from '../../../assets/icons/confirm.svg';
import createZoneIcon from '../../../assets/icons/create_zone.svg';

interface CreateLocationExistedAddressSelectCustomAndConfirmButtonsProps {
  onSelectCustomPress: () => void;
  onConfirmPress: () => void;
  hasSelectedAddress: boolean;
}

export const CreateLocationExistedAddressSelectCustomAndConfirmButtons = ({
  onSelectCustomPress,
  onConfirmPress,
  hasSelectedAddress,
}: CreateLocationExistedAddressSelectCustomAndConfirmButtonsProps) => {
  const theme = useTheme();
  return (
    <>
      <ActionButton
        icon={createZoneIcon}
        onPress={onSelectCustomPress}
        backgroundColor={theme.purpleBlue}
        iconColor={theme.white}
        size="medium"
        iconViewBox="0 0 17 17"
        style={{ marginRight: 8 }}
      />
      {hasSelectedAddress && (
        <ActionButton
          icon={confirmIcon}
          onPress={onConfirmPress}
          backgroundColor={theme.purpleBlue}
          iconColor={theme.white}
          size="medium"
          iconViewBox="0 0 17 13"
        />
      )}
    </>
  );
};
