import styled, { css } from 'styled-components/native';
import { Body1 } from '@nucleus/Typography';
import { Dimensions } from 'react-native';

interface ContainerProps {
  isRatio?: boolean;
}

export const Container = styled.View<ContainerProps>`
  align-items: center;
  justify-content: center;
  margin: 0;
  ${({ isRatio }) => {
    const { width } = Dimensions.get('window');
    if (isRatio) {
      return css`
        width: auto;
      `;
    }
    if (width < 1300) {
      return css`
        width: 100px;
      `;
    }
    if (width < 1400) {
      return css`
        width: 110px;
      `;
    }
    if (width < 1500) {
      return css`
        width: 115px;
      `;
    }
    if (width < 1700) {
      return css`
        width: 140px;
      `;
    }
    return css`
      width: 160px;
    `;
  }}
`;

interface ItemTitleProps {
  color?: string;
}

export const ItemTitle = styled(Body1)<ItemTitleProps>`
  text-align: center;
  color: ${({ theme, color }) => color || theme.purpleBlue};
  margin-bottom: 32px;
  ${() => {
    const { width } = Dimensions.get('window');
    if (width < 1500) {
      return css`
        margin-bottom: 16px;
        line-height: 8px;
        font-size: 10px;
      `;
    }
    if (width < 1700) {
      return css`
        margin-bottom: 24px;
        font-size: 12px;
      `;
    }
    return css``;
  }}
`;
