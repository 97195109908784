/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect } from 'react';
import { useWidgetGridContext, IWidget } from '@contexts/WidgetGridContext';
import { H6 } from '@nucleus/Typography';
import 'react-resizable/css/styles.css';
import { useMutationDeleteWidget } from '@services/hooks/useMutationDeleteWidget';
import { DropDown } from '@atoms/DropDown';
import { IconButton } from '@atoms/IconButton';
import { RenameInput } from '@atoms/RenameInput';
import { Container } from './styles';
import gridIcon from '../../../assets/icons/grid_3x3.svg';
import filterIcon from '../../../assets/icons/filter_alt.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import editIcon from '../../../assets/icons/edit.svg';
import tuneIcon from '../../../assets/icons/tune.svg';
import moreIcon from '../../../assets/icons/more_vert.svg';

interface WidgetHeaderProps {
  widgetData: IWidget;
  onRename: (newName: string) => void;
}

export const WidgetHeader = ({ widgetData, onRename }: WidgetHeaderProps) => {
  const [renameActivated, setRenameActivated] = useState(false);
  const [titleValue, setTitleValue] = useState(widgetData.name);
  const { setIsEditMode, setSidePanelData } = useWidgetGridContext();

  const mutationDeleteWidget = useMutationDeleteWidget({
    page_id: widgetData.page_id,
  });

  const handleDropDownSelect = (value: string) => {
    if (value === 'delete') {
      mutationDeleteWidget.mutateAsync(widgetData.id);
      return;
    }
    if (value === 'rename') {
      setRenameActivated(true);
    }
  };

  useEffect(() => {
    setTitleValue(widgetData.name);
  }, [widgetData.name]);

  return (
    <Container>
      {renameActivated ? (
        <RenameInput
          style={{ fontSize: 18 }}
          value={titleValue}
          onBlur={() => {
            onRename(titleValue);
            setRenameActivated(false);
          }}
          onChangeText={newName => {
            setTitleValue(newName);
          }}
        />
      ) : (
        <H6>{titleValue}</H6>
      )}

      <div className="options">
        <IconButton
          icon={gridIcon}
          onPress={() => setIsEditMode(true)}
          fill="white"
        />

        <IconButton
          icon={filterIcon}
          onPress={() =>
            setSidePanelData(draft => ({
              isOpened: !draft.isOpened,
              widget: widgetData,
            }))
          }
          fill="white"
        />

        <DropDown
          icon={moreIcon}
          iconFill="white"
          options={[
            {
              label: 'Rename Widget',
              value: 'rename',
              icon: editIcon,
              iconWidth: 14,
              iconHeight: 14,
              iconViewBox: '0 0 12 12',
            },
            {
              label: 'Configure Widget',
              value: 'configure',
              icon: tuneIcon,
              iconWidth: 16,
              iconHeight: 16,
              iconViewBox: '0 0 24 24',
              iconFill: 'white',
            },
            {
              label: 'Delete Widget',
              value: 'delete',
              icon: deleteIcon,
              iconWidth: 14,
              iconHeight: 14,
              iconViewBox: '0 0 20 20',
            },
          ]}
          onSelect={handleDropDownSelect}
        />
      </div>
    </Container>
  );
};
