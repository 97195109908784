import { useRef, useState, useEffect } from 'react';
import { ColorResult } from 'react-color';
import { Portal } from '@gorhom/portal';
import { useWindowDimensions } from 'react-native';
import {
  Container,
  ColorPicker,
  Color,
  ColorContainer,
  ColorContainerBoudings,
} from './styles.web';
import { ColorSelectorProps } from './types';

export const ColorSelector = ({
  color,
  onChange,
  isOpen,
  setIsOpen,
  position = 'top-left',
}: ColorSelectorProps) => {
  const { width } = useWindowDimensions();
  const [colorContainerBoudings, setColorContainerBoudings] =
    useState<ColorContainerBoudings>();
  const colorContainerRef = useRef<HTMLDivElement>(null);
  function handleColorChange(colorResult: ColorResult) {
    onChange(colorResult.hex);
    setIsOpen(false);
  }

  useEffect(() => {
    const boudings = colorContainerRef.current?.getBoundingClientRect();
    if (!boudings) return;
    setColorContainerBoudings({
      left: boudings.left,
      top: boudings.top,
    });
  }, [width]);

  return (
    <Container onClick={() => setIsOpen(!isOpen)}>
      <ColorContainer ref={colorContainerRef}>
        <Color style={{ background: color }} />
      </ColorContainer>
      {isOpen && colorContainerBoudings && (
        <Portal>
          <ColorPicker
            colorContainerBoudings={colorContainerBoudings}
            position={position}
            color={color}
            onChangeComplete={handleColorChange}
          />
        </Portal>
      )}
    </Container>
  );
};
