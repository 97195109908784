import React from 'react';
import { useTheme } from 'styled-components/native';
import { ActionButton } from '@atoms/ActionButton';
import PlusIcon from '../../../assets/icons/plus.svg';
import ListIcon from '../../../assets/icons/list.svg';
import FilterIcon from '../../../assets/icons/filter.svg';
import MapIcon from '../../../assets/icons/map.svg';
import {
  useEventListContext,
  useEventCreateContext,
} from '../../../contexts/EventsContext';
import { useCartographySelectorContext } from '../../../contexts/CartographyContext';
import { useFilterScreenContext } from '../../../contexts/FiltersContext';

import { Container } from './styles';

export const EventsTabActions = () => {
  const theme = useTheme();

  const { eventListOpened, updateEventListOpened } = useEventListContext();

  const { setCreateNewEventOpen } = useEventCreateContext();

  const { filterScreenOpen, updateFilterScreenOpen } = useFilterScreenContext();

  const { cartographicSelectorOpened, updateCartographicSelectorOpened } =
    useCartographySelectorContext();

  return (
    <Container>
      <ActionButton
        withGradient
        icon={PlusIcon}
        iconColor={theme.white}
        onPress={() => setCreateNewEventOpen(true)}
      />
      <ActionButton
        icon={ListIcon}
        isActive={eventListOpened}
        onPress={() => updateEventListOpened(!eventListOpened)}
      />
      <ActionButton
        icon={FilterIcon}
        isActive={filterScreenOpen}
        onPress={() => updateFilterScreenOpen(!filterScreenOpen)}
      />
      <ActionButton
        icon={MapIcon}
        isActive={cartographicSelectorOpened}
        onPress={() =>
          updateCartographicSelectorOpened(!cartographicSelectorOpened)
        }
      />
    </Container>
  );
};
