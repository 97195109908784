import { useRef } from 'react';
import { TouchableOpacity } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { Text } from './styles';

interface SelectableTitleProps {
  text: string;
  selected?: boolean;
  onPress: () => void;
}

export const SelectableTextOverline = ({
  text,
  selected,
  onPress,
}: SelectableTitleProps) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <TouchableOpacity onPress={onPress}>
      <Text
        selected={selected}
        isHovered={isHovered}
        numberOfLines={1}
        ref={ref}
      >
        {text}
      </Text>
    </TouchableOpacity>
  );
};
