import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { Caption } from '@nucleus/Typography';
import { ColorSelector } from '@atoms/ColorSelector';
import {
  Container,
  ButtonsWrapper,
  CreateZoneButton,
  NameColorWrapper,
  NameInput,
} from './styles';
import CreatePolygonIcon from '../../../assets/icons/create_polygon.svg';
import CreateLineIcon from '../../../assets/icons/create_line.svg';
import DeleteIcon from '../../../assets/icons/delete.svg';
import UndoIcon from '../../../assets/icons/undo.svg';
import ConfirmIcon from '../../../assets/icons/confirm.svg';

import { useZonesContext } from '../../../contexts/ZonesContext';
import { useMutationZones } from '../../../services/hooks/useMutationZones';

interface EventsFilterCreateZoneProps {
  onCreateNewZone: (newZoneId: string) => void;
}

export const EventsFilterCreateZone = ({
  onCreateNewZone,
}: EventsFilterCreateZoneProps) => {
  const [colorSelectorOpen, setColorSelectorOpen] = useState(false);
  const { t } = useTranslation();
  const useQuery = useMutationZones();
  const {
    isCreatingZone,
    isEditingZone,
    currentZoneName,
    currentZoneColor,
    setDrawMode,
    activeNoInteractionMode,
    removeCurrentModeLastCoordinates,
    changeCurrentZoneName,
    changeCurrentZoneColor,
    currentZoneType,
    currentZoneId,
    getZoneById,
    deleteZoneById,
  } = useZonesContext();

  const [zoneName, setZoneName] = useState(currentZoneName);
  const [zoneId, setZoneId] = useState(uuid());

  async function onCreateZone() {
    activeNoInteractionMode();
    if (!currentZoneId) return;
    const newZone = getZoneById(currentZoneId);
    if (!newZone) return;
    await useQuery.mutateAsync({ newZone, type: 'create' });
    deleteZoneById(currentZoneId);
    onCreateNewZone(currentZoneId);
  }

  useEffect(() => setZoneId(uuid()), []);

  return (
    <Container isOpen={colorSelectorOpen}>
      <Caption>{t('create_a_zone')}</Caption>

      {(isCreatingZone || isEditingZone) && currentZoneType === 'normal' ? (
        <>
          <NameColorWrapper>
            <NameInput
              value={zoneName}
              onChangeText={setZoneName}
              onBlur={() => changeCurrentZoneName(zoneName)}
              placeholder="name"
            />
            <ColorSelector
              isOpen={colorSelectorOpen}
              setIsOpen={setColorSelectorOpen}
              color={currentZoneColor}
              onChange={changeCurrentZoneColor}
            />
          </NameColorWrapper>
          <ButtonsWrapper>
            <CreateZoneButton
              onPress={() => {
                deleteZoneById(zoneId);
                activeNoInteractionMode();
              }}
            >
              <DeleteIcon fill="#fff" />
            </CreateZoneButton>
            {!isEditingZone && (
              <CreateZoneButton onPress={removeCurrentModeLastCoordinates}>
                <UndoIcon fill="#fff" />
              </CreateZoneButton>
            )}
            {isEditingZone && (
              <CreateZoneButton onPress={onCreateZone}>
                <ConfirmIcon fill="#fff" />
              </CreateZoneButton>
            )}
          </ButtonsWrapper>
        </>
      ) : (
        <ButtonsWrapper>
          <CreateZoneButton
            onPress={() => {
              setDrawMode({
                drawType: 'Polygon',
                zoneType: 'normal',
                zoneId,
              });
            }}
          >
            <CreatePolygonIcon height={24} fill="#fff" />
          </CreateZoneButton>
          <CreateZoneButton
            onPress={() => {
              setDrawMode({
                drawType: 'LineString',
                zoneType: 'normal',
                zoneId,
              });
            }}
          >
            <CreateLineIcon height={24} fill="#fff" />
          </CreateZoneButton>
        </ButtonsWrapper>
      )}
    </Container>
  );
};
