import { StyleProp, ViewStyle, TouchableOpacity } from 'react-native';
import { useState, useRef, useEffect } from 'react';
import { Icon } from '@nucleus/Icon';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';
import { Portal } from '@gorhom/portal';
import expandMoreIcon from '../../../assets/icons/expand_more.svg';
import errorIcon from '../../../assets/icons/error_outline.svg';
import { usePrevious } from '../../../hooks/usePrevious';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

import {
  Container,
  Label,
  DropDownButton,
  DropDownMasterWraper,
  DropDownItem,
  DropDownItemOverlay,
  DropDownItemText,
  Placeholder,
  ErrorContainer,
  ErrorText,
} from './styles';

export interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  selectedOptions: string[];
  isMulti?: boolean;
  options: Option[];
  placeholder?: string;
  onChange: (value: string[]) => void;
  onBlur?: () => void;
  style?: StyleProp<ViewStyle>;
  error?: string;
}

export const Select = ({
  selectedOptions,
  isMulti,
  options,
  placeholder,
  onChange,
  onBlur,
  style,
  error,
}: SelectProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const prevIsOpened = usePrevious(isOpened);
  const wrapperRef = useRef<TouchableOpacity>(null);
  const dropButtonRef = useRef<TouchableOpacity>(null);

  function handleActiveOption(value: string) {
    setIsOpened(false);
    if (selectedOptions.includes(value)) {
      return onChange([]);
    }
    return onChange([value]);
  }

  function handleToggleOption(value: string) {
    if (selectedOptions.includes(value)) {
      return onChange(selectedOptions.filter(val => val !== value));
    }
    return onChange([...selectedOptions, value]);
  }

  function getActivedOptions() {
    return options
      .filter(option => selectedOptions.includes(option.value))
      .map(option => t(option.label));
  }

  useEffect(() => {
    if (!isOpened && prevIsOpened) {
      if (onBlur) {
        onBlur();
      }
    }
  }, [isOpened, onBlur, prevIsOpened]);

  useOutsideClick({
    componentRef: wrapperRef,
    yOffset: 20,
    onOutsideClick: () => setIsOpened(false),
  });

  return (
    <Container style={style}>
      {selectedOptions.length > 0 && <Label>{placeholder}</Label>}
      <DropDownButton
        ref={dropButtonRef}
        isOpened={isOpened}
        onPress={() => setIsOpened(oldState => !oldState)}
      >
        {selectedOptions.length > 0 ? (
          <DropDownItemText ellipsizeMode="tail" numberOfLines={1}>
            {getActivedOptions().join(',')}
          </DropDownItemText>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}
        <Icon as={expandMoreIcon} height={20} />
      </DropDownButton>
      {error && !isOpened && (
        <ErrorContainer>
          <Icon
            as={errorIcon}
            testID="input-error-icon"
            fill={theme.red}
            width={16}
            height={16}
            viewBox="0 0 24 24"
          />
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
      )}
      {isOpened && dropButtonRef.current && (
        <Portal>
          <DropDownMasterWraper
            parentElement={dropButtonRef.current}
            ref={wrapperRef}
          >
            {options.map(option => (
              <DropDownItem
                key={option.value}
                onPress={() =>
                  isMulti
                    ? handleToggleOption(option.value)
                    : handleActiveOption(option.value)
                }
              >
                {selectedOptions.includes(option.value) && (
                  <DropDownItemOverlay />
                )}
                <DropDownItemText>{t(option.label)}</DropDownItemText>
              </DropDownItem>
            ))}
          </DropDownMasterWraper>
        </Portal>
      )}
    </Container>
  );
};
