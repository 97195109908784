/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const MediaListContainer = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 8px;
`;
