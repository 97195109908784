/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { api } from '../eventApi';

export interface MainCouranteFlowRatioRequestProps {
  main_courante_id: string;
  input_ids: string[];
  output_ids: string[];
}

export interface ActionTypeTotal {
  id: number;
  name: string;
  total: number;
}

export interface MainCouranteFlowRatioResponseProps {
  ratio: number;
  inputs: ActionTypeTotal[];
  outputs: ActionTypeTotal[];
}

export async function getMainCouranteFlowRatio({
  main_courante_id,
  input_ids,
  output_ids,
}: MainCouranteFlowRatioRequestProps): Promise<MainCouranteFlowRatioResponseProps> {
  const { data } = await api.get<MainCouranteFlowRatioResponseProps>(
    `/v1/main_courantes/analyzer/input_output_ratio`,
    {
      params: {
        main_courante_id,
        input_action_type_ids: input_ids.join(','),
        output_action_type_ids: output_ids.join(','),
      },
    },
  );

  return data;
}

export function useQueryMainCouranteFlowRatio({
  main_courante_id,
  input_ids,
  output_ids,
}: MainCouranteFlowRatioRequestProps) {
  return useQuery(
    ['main_courante_flow_ration', main_courante_id, input_ids, output_ids],
    () =>
      getMainCouranteFlowRatio({
        main_courante_id,
        input_ids,
        output_ids,
      }),
    {
      refetchInterval: 30000, // 30 seconds,
    },
  );
}
