import styled, { css } from 'styled-components/native';
import { darken } from 'polished';
import { Gradient } from '@nucleus/Gradient';

interface PropsContainer {
  size?: 'large' | 'medium';
  isActive?: boolean;
  isHovered?: boolean;
  backgroundColor?: string;
  activeBackgroundColor?: string;
  floating?: boolean;
}

export const GradientContainer = styled(Gradient)<PropsContainer>`
  max-width: ${({ size }) => (size === 'medium' ? '32px' : '40px')};
  max-height: ${({ size }) => (size === 'medium' ? '32px' : '40px')};
  width: ${({ size }) => (size === 'medium' ? '32px' : '40px')};
  height: ${({ size }) => (size === 'medium' ? '32px' : '40px')};
  border-radius: 8px;
  justify-content: center;
  align-items: center;

  ${({ floating, size }) =>
    floating &&
    css`
      max-width: ${size === 'medium' ? '32px' : '40px'};
      max-height: ${size === 'medium' ? '32px' : '40px'};
      border-radius: 20px;
      z-index: 1;
      elevation: 6;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    `}
`;

export const Container = styled.TouchableOpacity<PropsContainer>`
  max-width: ${({ size }) => (size === 'medium' ? '32px' : '40px')};
  max-height: ${({ size }) => (size === 'medium' ? '32px' : '40px')};
  width: ${({ size }) => (size === 'medium' ? '32px' : '40px')};
  height: ${({ size }) => (size === 'medium' ? '32px' : '40px')};
  border-radius: 8px;
  justify-content: center;
  align-items: center;

  ${({ floating, size }) =>
    floating &&
    css`
      max-width: ${size === 'medium' ? '32px' : '40px'};
      max-height: 40px;
      border-radius: 20px;
      z-index: 1;
      elevation: 6;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
    `}

  ${({
    theme,
    isHovered,
    isActive,
    backgroundColor,
    activeBackgroundColor,
  }) => {
    if (isActive) {
      if (isHovered) {
        return css`
          background-color: ${darken(
            0.02,
            activeBackgroundColor || theme.purpleBlue,
          )};
        `;
      }
      return css`
        background-color: ${activeBackgroundColor || theme.purpleBlue};
      `;
    }
    if (isHovered) {
      return css`
        background-color: ${darken(
          0.02,
          backgroundColor || theme.blueishBlack,
        )};
      `;
    }
    return css`
      background-color: ${backgroundColor || theme.blueishBlack}};
    `;
  }}
`;
