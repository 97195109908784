interface BarChartFormat {
  series: {
    data: number[];
  }[];
  categories: string[];
  yLegend: string;
  xLegend?: string;
  title: string;
}

interface RoundDiagramFormat {
  size: number;
  series: number[];
  labels: string[];
  title: string;
}

export interface HistoricalData {
  [key: string]: {
    barChart?: BarChartFormat;
    roundDiagramLeft?: RoundDiagramFormat;
    roundDiagramRight?: RoundDiagramFormat;
  };
}

export const historicalData: HistoricalData = {
  feux_de_forêts: {
    barChart: {
      series: [
        {
          data: [
            6856750, 15635648, 27472531, 2406862, 3377233, 1598482, 4404910,
            3414945, 1198762, 588100, 3110801, 2510330, 295235, 1447660,
            5002477, 5456265, 6400311, 656076, 6548068, 1640600, 834470,
          ],
        },
      ],
      categories: [
        '2001',
        '2002',
        '2003',
        '2004',
        '2005',
        '2006',
        '2007',
        '2008',
        '2009',
        '2010',
        '2011',
        '2012',
        '2013',
        '2014',
        '2015',
        '2016',
        '2017',
        '2018',
        '2019',
        '2020',
        '2021',
      ],
      yLegend: 'Surface parcourue',
      title:
        "Surface parcourue par les feux de forêts sur le territoire des Alpes-Maritimes (Source : Prométhée A partir du : 01/01/2001, jusqu'au : 30/11/2021)",
    },
    roundDiagramLeft: {
      size: 38,
      series: [0.4, 1.6, 81, 2, 6.4, 8.36],
      labels: [
        'Moyen aerien',
        'Patrouille',
        'Population',
        'Police Gendarmerie',
        'Vigie',
        'Autre',
      ],
      title: `Les origines de l’alerte ( Source : Prométhée) `,
    },
  },
  risques_sisimiques: {
    barChart: {
      series: [
        {
          data: [8, 2, 1, 4, 6],
        },
      ],
      yLegend: "Séismes d'intensité 3 ou supérieure",
      categories: ['2016', '2017', '2018', '2019', '2020'],
      title:
        "Nombre de Séisme d'intensité supérieure à 3 au cours des 5 dernières années dans le Département des Alpes-Maritimes (Source : SismoAzur)",
    },
  },
  mouvements_de_terrain: {
    roundDiagramLeft: {
      size: 57,
      series: [54.7, 33.1, 11.1, 1],
      labels: ['Aléa a priori nul', 'Aléa faible', 'Aléa moyen', 'Aléa fort'],
      title: `Pourcentage du territoire des Alpes-Maritime soumis à l'aléa "retrait-gonflement" (Source: Observatoire Régional Risques PACA)`,
    },
    roundDiagramRight: {
      size: 48,
      series: [382, 263, 18, 14, 12],
      labels: [
        'Glissement',
        'Eboulement',
        'Coulée',
        'Affaissement',
        'Erosion de berges',
      ],

      title: `Type de mouvements de terrains sur le Département des Alpes-Maritimes depuis 1991 (Source : https://www.georisques.gouv.fr/)`,
    },
  },
  inondations: {
    barChart: {
      series: [
        {
          data: [205, 200, 258, 215, 161, 253, 195, 513],
        },
      ],
      categories: [
        '25/09/1981-Vence',
        '31/10/2003-Tende',
        '2/12/2005-L’Escarène',
        '11/12/2008-Breil sur Roya',
        '15 au 18/09/2009-Cannes',
        '3/10/2015-Cannes',
        '2/10/2020-St Martin-Vésubie',
      ],
      title: 'Pluies extrêmes dans les Alpes-Maritimes (Source: MétéoFrance)',
      yLegend: 'Quantité de pluie (mm) en 1 jour',
    },
  },
};
