import { ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { Container } from './styles';

interface BoardProps {
  children: ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const Board = ({ children, style }: BoardProps) => {
  return <Container style={style}>{children}</Container>;
};
