import { useQuery } from 'react-query';
import { api } from '../userManagementApi';

export interface WidgetType {
  types: WidgetType[];
  id: string;
  name: string;
  slug: string;
  description: string;
  is_configurable: boolean;
  created_at: string;
  updated_at: string;
}

export async function getWigetTypes(): Promise<WidgetType[]> {
  const getWorkspaceResponse = await api.get(`/v1/widget/types`);
  return getWorkspaceResponse.data;
}

export function useQueryWidgetTypes() {
  return useQuery(['widgetTypes'], () => getWigetTypes());
}
