import styled from 'styled-components/native';
import { Dimensions } from 'react-native';
import { darken } from 'polished';

export const Container = styled.TouchableOpacity`
  width: ${() => {
    const { width } = Dimensions.get('window');
    if (width < 1440) {
      return '80px';
    }
    if (width < 1680) {
      return '90px';
    }
    return '100px';
  }};
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

interface BorderProps {
  isHovered?: boolean;
}

export const Border = styled.View<BorderProps>`
  position: absolute;
  left: 0;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  width: 4px;
  height: 50px;
  background-color: ${({ theme, isHovered }) =>
    isHovered ? darken(0.2, theme.purple) : theme.purple};
`;
