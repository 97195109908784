import styled from 'styled-components/native';
import { Caption } from '@nucleus/Typography';

export const Container = styled.View`
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
`;

export const CityContainer = styled.View`
  flex-direction: row;
  margin-right: 10px;
  align-items: center;
`;

export const TimeText = styled(Caption)`
  color: ${({ theme }) => theme.purpleBlue};
`;
