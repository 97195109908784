import styled from 'styled-components/native';

export const Container = styled.View`
  max-width: 736px;
  width: 90%;
  max-height: 800px;
  height: 95%;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 8px;
  padding: 16px 24px;
  justify-content: flex-start;
  margin-top: -50px;
`;
