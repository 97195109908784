/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import ErrorIcon from '../../../assets/icons/error_outline.svg';
import { Container, CreateButton, ErrorContainer, ErrorText } from './styles';

interface CreateLocationButtonProps {
  setCreateLocationOpened: (value: boolean) => void;
  selectedLocation?: any;
  error?: string;
}

export const CreateLocationButton = ({
  error,
  selectedLocation,
  setCreateLocationOpened,
}: CreateLocationButtonProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Container>
      <CreateButton onPress={() => setCreateLocationOpened(true)}>
        {!selectedLocation ? t('find_the_location') : t('change_the_location')}
      </CreateButton>
      {error && (
        <ErrorContainer>
          <ErrorIcon
            testID="input-error-icon"
            fill={theme.red}
            width={16}
            height={16}
            viewBox="0 0 24 24"
          />
          <ErrorText>{t('location_required')}</ErrorText>
        </ErrorContainer>
      )}
    </Container>
  );
};
