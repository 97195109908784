import styled from 'styled-components/native';
import { Gradient } from '@nucleus/Gradient';

export const Container = styled(Gradient)`
  width: 180px;
  padding: 8px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: row;
`;
