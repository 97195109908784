import React, { useState, useCallback, ReactNode, useEffect } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { useTabsControlContext, defaultMap } from './TabsControlContext';
import { updateTabData, getTabData } from '../utils/tabApi';

interface CartographyContextData {
  cartographicSelectorOpened: boolean;
  mapCartographicStyle: string;
  updateCartographicSelectorOpened: (opened: boolean) => void;
  updateMapCartographicStyle: (style: string) => void;
}

const CartographyContext = createContext<CartographyContextData>(
  {} as CartographyContextData,
);

interface CartographyProviderProps {
  children: ReactNode;
}

const CartographyContextProvider = ({ children }: CartographyProviderProps) => {
  const { selectedTab } = useTabsControlContext();
  const [cartographicSelectorOpened, setCartographicSelectorOpened] =
    useState(false);

  const [mapCartographicStyle, setMapCartographicStyle] = useState(defaultMap);

  const updateCartographicSelectorOpened = useCallback(
    async (opened: boolean) => {
      setCartographicSelectorOpened(opened);
      await updateTabData(selectedTab, {
        mapCartographicStyle,
        cartographicSelectorOpened: opened,
      });
    },
    [mapCartographicStyle, selectedTab],
  );

  const updateMapCartographicStyle = useCallback(
    async (style: string) => {
      setMapCartographicStyle(style);
      await updateTabData(selectedTab, {
        cartographicSelectorOpened,
        mapCartographicStyle: style,
      });
    },
    [selectedTab, cartographicSelectorOpened],
  );

  useEffect(() => {
    async function loadData() {
      const tab = await getTabData(selectedTab);
      if (!tab) return;
      setMapCartographicStyle(tab.mapCartographicStyle);
      setCartographicSelectorOpened(tab.cartographicSelectorOpened);
    }
    loadData();
  }, [selectedTab]);

  return (
    <CartographyContext.Provider
      value={{
        cartographicSelectorOpened,
        updateCartographicSelectorOpened,
        mapCartographicStyle,
        updateMapCartographicStyle,
      }}
    >
      {children}
    </CartographyContext.Provider>
  );
};

function useCartographySelectorContext() {
  const cartographicSelectorOpened = useContextSelector(
    CartographyContext,
    cartographyContext => cartographyContext.cartographicSelectorOpened,
  );
  const updateCartographicSelectorOpened = useContextSelector(
    CartographyContext,
    cartographyContext => cartographyContext.updateCartographicSelectorOpened,
  );

  return {
    cartographicSelectorOpened,
    updateCartographicSelectorOpened,
  };
}

function useCartographyContext() {
  const mapCartographicStyle = useContextSelector(
    CartographyContext,
    cartographyContext => cartographyContext.mapCartographicStyle,
  );
  const updateMapCartographicStyle = useContextSelector(
    CartographyContext,
    cartographyContext => cartographyContext.updateMapCartographicStyle,
  );

  return {
    mapCartographicStyle,
    updateMapCartographicStyle,
  };
}

export {
  CartographyContextProvider,
  useCartographyContext,
  useCartographySelectorContext,
};
