import { useQuery } from 'react-query';

import { api } from '../userManagementApi';

export interface WeatherAlert {
  tags: string[];
  event: string;
  source: string;
  color: string;
  description: string;
}

export interface Weather {
  alerts: WeatherAlert[];
  unit: string;
  source: string;
  source_url: string;
  temp: number;
  max_temp: number;
  min_temp: number;
  pressure: number;
  wind_deg: number;
  wind_speed: number;
  wind_gust: number;
  visibility: number;
  humidity: number;
  rain_cumul_1h: number;
  rain_cumul_day: number;
  last_update: Date;
}

export interface GetWeatherInfoParams {
  lat?: number;
  lng?: number;
}

export async function getWeatherInfo({
  lat,
  lng,
}: GetWeatherInfoParams): Promise<Weather> {
  const response = await api.get(`/v1/weather`, {
    params: {
      lat,
      lng,
    },
  });

  return {
    ...response.data,
    last_update: new Date(),
  };
}

export function useQueryWeatherInfo(params: GetWeatherInfoParams) {
  return useQuery(['weather', params], () => getWeatherInfo(params), {
    refetchOnWindowFocus: true,
    enabled: !!(params.lat && params.lng),
  });
}
