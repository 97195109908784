/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { api } from '../eventApi';

interface MainCourante {
  id: string;
  name: string;
  closed: boolean;
  desc: string;
  date: string;
  priority: number;
  team_id: string;
  location_name: string;
  location_coordinates: any;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export async function getMainCourantes(
  team_id?: string,
): Promise<MainCourante[]> {
  if (!team_id) return [];
  try {
    const response = await api.get('/v1/main_courantes', {
      params: {
        team_id,
      },
    });

    return response.data;
  } catch {
    return [];
  }
}

export function useQueryMainCourantes(team_id?: string) {
  return useQuery(
    ['main_courantes', team_id],
    () => getMainCourantes(team_id),
    {
      enabled: !!team_id,
    },
  );
}
