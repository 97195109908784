import styled from 'styled-components/native';
import { Platform } from 'react-native';

interface ContainerProps {
  color: string;
}

export const Container = styled.View<ContainerProps>`
  padding: 0 2px;
  height: 14px;
  min-width: 12px;
  border-radius: 3px;
  background-color: ${({ color }) => color};
  justify-content: center;
  align-items: center;
`;

export const BadgeText = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: 9px;
  line-height: 16px;
  font-weight: 600;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_700Bold'};
  letter-spacing: 0.1px;
`;
