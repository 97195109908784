import styled from 'styled-components';

export const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  height: calc(100% - 30px);
  width: 100%;
  padding: 8px;
  padding-bottom: 16px;
`;
