import { Geometry, Point } from '@nebula.gl/edit-modes';
import centroid from '@turf/centroid';

export function centerLocation(
  location_coordinates?: Geometry,
): Point | undefined {
  let newLocationCoordinates;

  if (location_coordinates) {
    newLocationCoordinates =
      location_coordinates.type === 'Point'
        ? location_coordinates
        : centroid(location_coordinates).geometry;
  }

  return newLocationCoordinates as Point | undefined;
}
