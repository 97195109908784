import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: auto;
  margin-right: 4px;
  p {
    font-size: 14px;
    font-family: 'Open Sans';
  }

  p:hover {
    color: ${({ theme }) => theme.white};
  }
`;

export const Input = styled.input`
  width: 105px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: 'Open Sans';
  letter-spacing: -0.44px;
  padding: 0;
  padding-left: 2px;
  color: ${({ theme }) => theme.white};
  background-color: transparent;
  border: none;
  border: 1px solid ${({ theme }) => theme.blue};
  &:focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => theme.blue};
  }
`;
