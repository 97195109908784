import { H6 } from '@nucleus/Typography';
import { OpenCloseButton } from '@atoms/OpenCloseButton';
import { IconButton } from '@atoms/IconButton';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';
import deleteIcon from '../../../assets/icons/delete.svg';
import { Container } from './styles';

interface MainCouranteInfoDetailTopProps {
  closed: boolean;
  openCloseLoading: boolean;
  onOpenAction: () => void;
  onCloseAction: () => void;
  onDeletePress: () => void;
}

export const MainCouranteInfoDetailTop = ({
  closed,
  openCloseLoading,
  onOpenAction,
  onCloseAction,
  onDeletePress,
}: MainCouranteInfoDetailTopProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Container>
      <H6 style={{ marginRight: 16 }}>{t('details_of_the_action')}</H6>
      <OpenCloseButton
        closed={closed}
        loading={openCloseLoading}
        onOpenPress={onOpenAction}
        onClosePress={onCloseAction}
      />
      <IconButton
        style={{ marginLeft: 16 }}
        icon={deleteIcon}
        fill={theme.purpleBlue}
        onPress={onDeletePress}
      />
    </Container>
  );
};
