/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { Point } from '@nebula.gl/edit-modes';
import { format, parseISO } from 'date-fns';
import { Zone } from '@contexts/ZonesContext';
import centroid from '@turf/centroid';
import { getDateFnsLocale } from '../../utils/getDateFnsLocale';
import { api } from '../eventApi';

export interface Action {
  id: number;
  name: string;
  team_id: string;
  created_at: string;
  updated_at: string;
}

export interface MainCouranteAction {
  id: string;
  title: string;
  location_coordinates?: Point;
  location_name?: string;
  location_color?: string;
  desc: string;
  date: string;
  formatted_date: string;
  media: string[];
  user_id: string;
  closed: boolean;
  created_at: string;
  updated_at: string;
  main_courante_id: string;
  main_courante?: Omit<MainCourante, 'main_courante_actions'>;
  action_type_id: number;
  action_type: Action;
  zone?: Zone;
}

export interface MainCourante {
  id: string;
  name: string;
  closed: boolean;
  priority: number;
  location_coordinates: {
    type: string;
    coordinates: number[];
  };
  location_name: string;
  description: string;
  date: string;
  team_id: string;
  media: string[];
  title: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  main_courante_actions: MainCouranteAction[];
}

export async function getMainCouranteInfo(
  main_courante_id: string,
): Promise<MainCourante> {
  const response = await api.get(`/v1/main_courantes/${main_courante_id}`);

  const locale = getDateFnsLocale();

  const normalizeData = {
    ...response.data,
    date: format(parseISO(response.data.date), "dd MMMM HH'h'mm OOOO", {
      locale,
    }),
    main_courante_actions: response.data.main_courante_actions.map(
      (main_courante_action: MainCouranteAction) => {
        let location_coordinates;

        if (main_courante_action.location_coordinates) {
          location_coordinates =
            main_courante_action.location_coordinates.type === 'Point'
              ? main_courante_action.location_coordinates
              : centroid(main_courante_action.location_coordinates).geometry;
        }

        const zone =
          main_courante_action.location_coordinates &&
          main_courante_action.location_coordinates.type !== 'Point'
            ? ({
                id: main_courante_action.id,
                type: 'Feature' as const,
                properties: {
                  main_courante_id: main_courante_action.main_courante_id,
                  name: 'main_courante_action_event',
                  type: 'main_courante_action',
                  color: main_courante_action.location_color
                    ? main_courante_action.location_color
                    : main_courante_action.closed
                    ? '#FF2D2E'
                    : '#5EFF5A',
                  editable: false,
                },
                geometry: main_courante_action.location_coordinates,
              } as Zone)
            : undefined;

        return {
          ...main_courante_action,
          location_coordinates,
          zone,
          formatted_date: format(
            parseISO(main_courante_action.date),
            "dd MMMM HH'h'mm OOOO",
            {
              locale,
            },
          ),
        };
      },
    ),
  };

  return normalizeData;
}

export function useQueryMainCouranteInfo(main_courante_id: string) {
  return useQuery(
    ['mainCourante', main_courante_id],
    () => getMainCouranteInfo(main_courante_id),
    {
      enabled: !!main_courante_id,
      refetchOnWindowFocus: false,
    },
  );
}
