// Using react player while wait fix expo-av  https://github.com/expo/expo/pull/14575

// import { Video as ExpoVideo, VideoProps as ExpoVideoProps } from 'expo-av';
import ReactPlayer from 'react-player';

interface VideoProps {
  url: string;
}

export const Video = ({ url }: VideoProps) => {
  return <ReactPlayer url={url} width="100%" height="auto" controls />;
};

/*  <Video
      source={{
        uri: url,
      }}
    /> */
