import styled from 'styled-components/native';

interface MarkerProps {
  size: number;
  closed: boolean;
}

export const Container = styled.TouchableOpacity<MarkerProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: ${({ size }) => `${size / 5}px`};
  background-color: ${({ theme, closed }) =>
    closed ? theme.red : theme.eletricGreen};
  justify-content: center;
  align-items: center;
`;
