export default {
  translation: {
    login: 'Login',
    password: 'Password',
    stay_connected: 'Stay connected',
    log_in: 'Log In',
    forgot_password: 'Forgot your password?',
    valid_field: 'This field is required',
    valid_email: 'Must be a valid email',
    events_list: 'Events list',
    all: 'All',
    media: 'Media',
    last_updated: 'Last updated',
    modal_confirm: 'Confirm',
    modal_cancel: 'Cancel ',
    close_tab_modal_title: 'Are you sure you want to close the tab?',
    overview: 'Overview',
    details: 'Details',
    actors_concerned: 'Actors concerned',
    photos_and_videos: 'Photos and videos',
    additional_content: 'Additional content',
    more_details: 'More details',
    start: 'Start',
    end: 'End',
    cartographic_elements: 'Cartographic elements',
    basemaps: 'Basemaps',
    map_layers: 'Map layers',
    dark: 'Dark',
    clear: 'Clear',
    satellite: 'Satellite',
    streets: 'Streets',
    landforms: 'Landforms',
    borders: 'Borders',
    movements: 'Movements',
    highway_06: 'Highway 06',
    flood_zones: 'Flood zones',
    clear_filters: 'Clear filters',
    filters: 'Filters',
    basics: 'Basics',
    advanced: 'Advanced',
    event_type: 'Event type',
    search: 'Search',
    any_term: 'Any term',
    all_of_these_terms: 'All of these terms',
    exclude_these_terms: 'Exclude these terms',
    date: 'Date',
    location: 'Location',
    in_progress: 'In progress',
    finished: 'Finished',
    sector_location: 'Sector location',
    relevance: 'Relevance',
    date_of_detection: 'Date of detection',
    start_date: 'Start date',
    end_date: 'End date',
    main_courante: 'Main Courante',
    zones_created: 'Zones created',
    create_a_zone: 'Create a zone',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    email: 'E-mail',
    send_email: 'Send Email',
    cancel: 'Cancel',
    confirm: 'Confirm',
    password_confirmation: 'Password Confirmation',
    save: 'Save',
    success_email_sent: 'Email sent verify your inbox',
    success_password_sent: 'Successful Password change',
    generic_request_error: 'Error on request try again',
    step_1_create_main_courante: 'Step 1: Create or choose your main courante',
    create_new_main_courante: 'Create a new main courante',
    name_of_main_courante: 'Name of the main courante ',
    main_courante_title: 'Main courante title',
    step_2_create_main_courante: 'Step 2: Create a main courante',
    hour: 'Hour',
    existing_address: 'Existing address',
    custom_address: 'Custom address',
    shared_with: 'Shared with',
    priority: 'Priority',
    more_information: 'More information',
    click_to_add_media: 'Click on the button to add new media',
    step_2_create_action: 'Step 2: Create an action',
    title_of_the_action: 'Title of the action',
    type_of_action: 'Type of action',
    create_a_type: 'Create a type',
    close_main_courante: 'Do you want to close this main courante?',
    yes: 'Yes',
    no: 'No',
    selected_main_courante: 'Selected main courante',
    create_action_type: 'Create an action type',
    name_of_the_action: 'Name of the action',
    create_action_type_placeholder: 'Road closed',
    Floods: 'Floods',
    Wildfires: 'Wildfires',
    Earthquake: 'Earthquakes',
    'Natural hazards': 'Natural hazards',
    All: 'All',
    Media: 'Media',
    'Social networks': 'Social networks',
    'Official sources': 'Official sources',
    want_rename_file: 'Do you want to rename it?',
    dynamic_data: 'Dynamic data',
    historical_data: 'Historical data',
    risk_statistics: 'Risk statistics',
    name: 'Name',
    data: 'Data',
    unit: 'Unit',
    source: 'Source',
    my_documents: 'My Documents',
    file_name: 'File Name',
    delete_document_confirmation: 'You sure, you want delete this document',
    delete_document_error: ' Erro on download document',
    choose_team: 'Choose Team',
    name_required: 'name is a required field',
    priority_required: 'priority is a required field',
    time_required: 'time is a required field',
    date_required: 'date is a required field',
    description_required: 'description is a required field',
    location_required: 'location is a required field',
    action_required: 'action is a required field',
    media_required: 'media is a required field',
    erro_updated_file: 'Erro on updated file',
    erro_delete_file: 'Erro on delete file',
    action_type_created: 'Action type created',
    error_create_action_type: 'Error on create action type',
    error_created_document: 'Error on created document',
    document_created: 'Document created',
    document_updated: 'Document updated',
    document_deleted: 'Document deleted',
    main_courante_created: 'Main courante created',
    error_create_main_courante: 'Error on create main courante',
    error_create_tab: 'Error on create tab',
    error_create_zone: 'Error on create zone',
    type_address: 'Address',
    type_main_courante_name: 'Main courante name',
    type_main_courante_title: 'Main courante title',
    type_action_title: 'Action title',
    type_additional_information: 'Additional information',
    zone_name: 'Zone name',
    content_text_error: `Sorry, the content cannot be loaded at the moment, click on "More details" to access the source`,
    opened: 'opened',
    closed: 'closed',
    openeds: 'opened',
    closeds: 'closed',
    number_of_actions: 'Number of actions',
    the_actions: 'The actions',
    details_of_the_action: 'Details of the action',
    to_choose_existing_main_courante: 'Choose an existing main courante',
    choose_existing_main_courante: 'Choose an existing main courante',
    want_to_be_able_to: 'I want to be able to:',
    give_new_main_courante_title: 'Give the new main courante a title',
    choose_team_to_share_new_main_courante:
      'Choose a team to share the new main courante',
    team_name: 'Team name',
    find_the_location: 'Find the location',
    change_the_location: 'Change the location',
    closed_status_changed_success: 'Closed status has been changed',
    closed_status_changed_fail: 'Fail to change closed stauts',
    sure_want_close_main_courante:
      'Are you sure you want to close the main courante?',
    main_courante_flow_ratio: 'Main courante flow ratio',
    inputs: 'Inputs',
    outputs: 'Outputs',
    ratio: 'Ratio',
    select_action_type: 'Select Action Type',
    update: 'Update',
    author: 'Author',
    success_update_main_courante_action: 'Main courante action updated',
    error_update_main_courante_action: 'Error on updated main courante action',
    success_update_main_courante: 'Main courante updated',
    error_update_main_courante: 'Error on updated main courante',
    sure_want_delete_main_courante:
      'Are you sure you want delete the main courante?',
    success_delete_main_courante: 'Main courante deleted',
    error_delete_main_courante: 'Error on delete main courante',
    sure_want_delete_main_courante_action:
      'Are you sure you want delete the main courante action?',
    success_delete_main_courante_action: 'Main courante action deleted',
    error_delete_main_courante_action: 'Error on delete main courante action',
    min: 'Min',
    max: 'Max',
    day: 'Day',
    temperature: 'Temperature',
    wind_force: 'Wind force',
    maximum_burst: 'Maximum burst',
    forces_with_bursts: 'Forces with bursts',
    maximum_accumulation_hour: 'Max cumul in 1 hour',
    day_accumulation: 'Cumul of the day',
    humidity: 'Humidity',
    visibility: 'Visibility',
    pressure: 'Pressure',
    hydrometeorology: 'Hydrometeorology',
    thunderstorms: 'Thunderstorms',
    heat_wave: 'Heat wave',
    wind: 'Wind',
    submersion_waves: 'Submersion waves',
    rains: 'Rains',
    floods: 'Floods',
    snow: 'Snow',
    upstream_floods: 'Upstream floods',
    downstream_floods: 'Downstream floods',
    vigilance: 'Vigilance',
    vigilance_red_alert_message:
      'Absolute vigilance is required. Dangerous phenomena of exceptional intensity are foreseen. Keep yourself regularly informed of developments in the situation and absolutely comply with the safety instructions issued by the public authorities.',
    vigilance_yellow_alert_message:
      'Be very vigilant. Dangerous phenomena are expected. Keep abreast of developments and follow the safety advice issued by the public authorities.',
    vigilance_light_yellow_alert_message:
      'If you practice activities sensitive to meteorological risk or exposed to floods, phenomena that are usual in the region but occasionally and locally dangerous (eg mistral, summer storm, rising water levels) are indeed expected. developments in the situation. ',
    vigilance_green_alert_message: 'No particular vigilance.',
    new_tab: 'New Tab',
  },
};
