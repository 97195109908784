import { WorkspacePageAddNewWidgetModalList } from '@atoms/WorkspacePageAddNewWidgetModalList';
import { WorkspacePageAddNewWidgetModalConfig } from '@atoms/WorkspacePageAddNewWidgetModalConfig';
import { H6, Body1 } from '@nucleus/Typography';
import { IconButton } from '@atoms/IconButton';
import { Spinner } from '@atoms/Spinner';
import { useQueryWidgetConfigs } from '@services/hooks/useQueryWidgetConfigs';
import backIcon from '../../../assets/icons/back.svg';
import { TitleContainer, WidgetTypesListContainer } from './styles';

interface WorkspacePageAddNewWidgetModalStep3Props {
  widgetTypeName: string;
  widgetTypeId: string;
  onBackPress?: () => void;
  onSelectConfig: (widgetConfigId: string | undefined) => void;
}

export const WorkspacePageAddNewWidgetModalStep3 = ({
  widgetTypeName,
  widgetTypeId,
  onSelectConfig,
  onBackPress,
}: WorkspacePageAddNewWidgetModalStep3Props) => {
  const widgetConfigsQuery = useQueryWidgetConfigs({ type_id: widgetTypeId });

  return (
    <>
      <TitleContainer>
        <IconButton icon={backIcon} onPress={onBackPress} fill="#fff" />
        <Body1 style={{ marginLeft: 8 }}>{widgetTypeName}</Body1>
      </TitleContainer>
      <H6 style={{ marginTop: 8 }}>Choose data input</H6>
      <WidgetTypesListContainer>
        <WorkspacePageAddNewWidgetModalList
          name="Configure the widget directly"
          description="Configure a custom event configuration"
          onPress={() => onSelectConfig(undefined)}
        />
        <Body1 style={{ fontWeight: '700', marginTop: 24 }}>
          Choose an existing saved event config
        </Body1>
        {widgetConfigsQuery.data ? (
          widgetConfigsQuery.data.map(widgetConfig => (
            <WorkspacePageAddNewWidgetModalConfig
              name={widgetConfig.name}
              onPress={() => onSelectConfig(widgetConfig.id)}
            />
          ))
        ) : (
          <Spinner size="large" />
        )}
      </WidgetTypesListContainer>
    </>
  );
};
