import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: calc(100% - 250px);
  border-top: 1.5px solid ${({ theme }) => theme.background};
  padding-left: 8px;
  padding-right: 8px;
  overflow-y: scroll;
`;

export const Content = styled.View`
  display: flex;
  align-items: center;
  margin-top: 8px;
  flex-direction: row;
`;

export const ContentAvatar = styled.View`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #c4c4c4;
`;

export const ContentDetail = styled.View`
  display: flex;
  margin-left: 8px;
`;

export const ContentDataSource = styled.View`
  display: flex;
  flex-direction: row;
`;

export const TagContainer = styled.View`
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;
