import { transparentize } from 'polished';
import styled from 'styled-components';
import { gridConstants } from '../../../hooks/useGridHelper';

const {
  GRID_CELL_COUNT,
  GRID_PADDING,
  GRID_CELL_PADDING,
  SIDE_PANEL_WIDTH,
  SCROLLBAR_WIDTH,
} = gridConstants;

const GridContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
`;

const gridWrapperHeight = (
  lowestPoint: number,
  panelIsOpen: boolean,
  innerWidth: number,
) => {
  const gridCellSize =
    ((1 - (panelIsOpen ? SIDE_PANEL_WIDTH : 0)) * innerWidth -
      GRID_PADDING * 2 +
      GRID_CELL_PADDING) /
    GRID_CELL_COUNT;

  return gridCellSize * (Math.max(lowestPoint, 12) + 4);
};

const GridWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  padding: ${GRID_PADDING}px;
  padding-bottom: 0;
`;

interface GridProps {
  sidePanelIsOpen: boolean;
  isEditMode: boolean;
  lowestWidgetPoint: number;
  innerWidth: number;
}

const Grid = styled.div<GridProps>`
  height: ${({ lowestWidgetPoint, sidePanelIsOpen, innerWidth }) =>
    gridWrapperHeight(lowestWidgetPoint, sidePanelIsOpen, innerWidth)}px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-image: linear-gradient(
      to right,
      ${({ isEditMode, theme }) =>
          isEditMode ? transparentize(0.75, theme.blue) : 'transparent'}
        ${GRID_CELL_PADDING}px,
      transparent ${GRID_CELL_PADDING}px
    ),
    linear-gradient(
      to bottom,
      ${({ isEditMode, theme }) =>
          isEditMode ? transparentize(0.75, theme.blue) : 'transparent'}
        ${GRID_CELL_PADDING}px,
      transparent ${GRID_CELL_PADDING}px
    );
  background-size: calc(
      (
          ${({ innerWidth }) => innerWidth}px -
            ${GRID_PADDING * 2 + GRID_CELL_PADDING + SCROLLBAR_WIDTH}px
        ) / ${GRID_CELL_COUNT}
    )
    calc(
      (
          ${({ innerWidth }) => innerWidth}px -
            ${GRID_PADDING * 2 + GRID_CELL_PADDING + SCROLLBAR_WIDTH}px
        ) / ${GRID_CELL_COUNT}
    );
`;

export { GridContainer, GridWrapper, Grid };
