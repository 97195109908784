import { ActionTypeTag } from '@atoms/ActionTypeTag';
import { AddButton } from '@atoms/AddButton';
import { Body1 } from '@nucleus/Typography';
import { Container } from './styles';

export interface ActionData {
  color: string;
  action_type: {
    value: string;
    label: string;
  };
}

interface AnalyticsTabsMainCouranteFlowRatioConfigureFormActionTypeListsProps {
  listOfActionTypes: ActionData[];
  label: string;
  onRemoveActionFromList: (value: string) => void;
  onAddActionFromList: () => void;
  limitOfActionsOnList: number;
}

export const AnalyticsTabsMainCouranteFlowRatioConfigureFormActionTypeLists = ({
  listOfActionTypes,
  label,
  onRemoveActionFromList,
  onAddActionFromList,
  limitOfActionsOnList,
}: AnalyticsTabsMainCouranteFlowRatioConfigureFormActionTypeListsProps) => {
  return (
    <Container>
      <Body1 style={{ marginRight: 8 }}>{label}</Body1>
      {listOfActionTypes.map(input => (
        <ActionTypeTag
          name={String(input.action_type.label)}
          color={input.color}
          onClosePress={() => onRemoveActionFromList(input.action_type.value)}
        />
      ))}
      {listOfActionTypes.length < limitOfActionsOnList && (
        <AddButton onPress={onAddActionFromList} />
      )}
    </Container>
  );
};
