import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  width: 100%;
  height: 64px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.blueishBlack};
  padding-right: 32px;
  padding-left: 8px;
  section {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  section:not(:first-child):not(:last-child) {
    justify-content: flex-start;
    margin-left: 20%;
    width: 80%;
  }
`;

export const Menu = styled.nav`
  display: flex;
  margin-left: 10%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 64px;
`;
