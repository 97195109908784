import styled from 'styled-components/native';

export const Container = styled.View`
  margin-top: 10px;
  flex-direction: row;
`;

export const ActionType = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
`;
