import styled from 'styled-components/native';
import { Title, H6 } from '@nucleus/Typography';

export const Container = styled.View`
  width: 100%;
  padding: 32px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ConfigureContainer = styled.View`
  width: 100%;
`;

export const ContainerTitle = styled(H6)`
  margin-bottom: 16px;
`;

export const TableTitle = styled(Title)`
  color: ${({ theme }) => theme.purpleBlue};
`;

export const SideBySide = styled.View`
  margin-top: 16px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 500px;
`;

interface NamePointerColor {
  color: string;
}

export const NamePointer = styled.View<NamePointerColor>`
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ color }) => color};
  border: ${({ color }) => color};
  margin-right: 16px;
`;

export const TableNameCell = styled.View`
  width: 250;
  padding: 20px 0;
`;
