import { useState } from 'react';
import { Modal } from '@atoms/Modal';
import { Input } from '@atoms/Input';
import { IconButton } from '@atoms/IconButton';
import { Body1 } from '@nucleus/Typography';
import { Container, ButtonsContainer, Button } from './styles';
import closeIcon from '../../../assets/icons/close.svg';

interface WorkspacePageAddNewWidgetModalNameModalProps {
  isVisible?: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
}

export const WorkspacePageAddNewWidgetModalNameModal = ({
  onClose,
  isVisible,
  onSave,
}: WorkspacePageAddNewWidgetModalNameModalProps) => {
  const [name, setName] = useState('');

  return (
    <Modal visible={isVisible}>
      <IconButton
        icon={closeIcon}
        viewBox="0 0 30 30"
        width={24}
        height={24}
        style={{ position: 'absolute', right: 8, top: 8 }}
        onPress={onClose}
      />
      <Container>
        <Body1>Type widget name</Body1>
        <Input value={name} onChangeText={setName} />
        <ButtonsContainer>
          <Button onPress={onClose}>Cancel</Button>
          <Button onPress={() => onSave(name)}>Create</Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};
