import { Caption } from '@nucleus/Typography';
import { Container, CityContainer, TimeText } from './styles';

interface MainCouranteCardCityAndTimeProps {
  locationName: string;
  date: string;
}

export const MainCouranteCardCityAndTime = ({
  locationName,
  date,
}: MainCouranteCardCityAndTimeProps) => {
  return (
    <Container>
      <CityContainer>
        <Caption>{locationName ? locationName.slice(0, 40) : ''}</Caption>
      </CityContainer>
      <TimeText>{date}</TimeText>
    </Container>
  );
};
