import React, { createContext, ReactNode, useContext, useState } from 'react';
import { PreviewModal } from '@atoms/PreviewModal';

interface OpenPreview {
  source: string;
  type: 'image' | 'video' | undefined;
}

interface PreviewContextData {
  openPreview(data: OpenPreview): void;
}

interface PreviewContextProps {
  children: ReactNode;
}

const PreviewContext = createContext({} as PreviewContextData);

function PreviewContextProvider({ children }: PreviewContextProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<string>();
  const [previewType, setPreviewType] = useState<
    'image' | 'video' | undefined
  >();

  function openPreview({ source, type }: OpenPreview) {
    setModalVisible(true);
    setModalContent(source);
    setPreviewType(type);
  }

  return (
    <PreviewContext.Provider
      value={{
        openPreview,
      }}
    >
      <PreviewModal
        visible={modalVisible}
        source={modalContent}
        onClose={() => setModalVisible(false)}
        type={previewType}
      />

      {children}
    </PreviewContext.Provider>
  );
}

function usePreviewContext(): PreviewContextData {
  const context = useContext(PreviewContext);

  return context;
}

export { PreviewContextProvider, usePreviewContext };
