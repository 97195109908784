/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';

export const Container = styled.View`
  margin: 8px 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;
