/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import { RndDragEvent, DraggableData } from 'react-rnd';
import CloseIcon from '../../assets/icons/close.svg';
import { H6, Body2, Body1 } from '../Typography';
import { CheckBox } from '../CheckBox';
import {
  useCartographyContext,
  useCartographySelectorContext,
} from '../../contexts/CartographyContext';
import { Image } from '../Image';
import map_dark from '../../assets/map_dark.png';
import map_clear from '../../assets/map_clear.png';
import map_satellite from '../../assets/map_satellite.png';
import map_streets from '../../assets/map_streets.png';
import map_landforms from '../../assets/map_landforms.png';

import {
  Container,
  Header,
  ContentContainer,
  LeftContainer,
  MapContainer,
  BottomContainer,
  SaveButton,
} from './styles';

interface Size {
  width: number;
  height: number;
}

interface CartographicSelectorProps {
  parentSize: Size;
}

export const CartographicSelector = ({
  parentSize,
}: CartographicSelectorProps) => {
  const { t } = useTranslation();
  const { mapCartographicStyle, updateMapCartographicStyle } =
    useCartographyContext();

  const { cartographicSelectorOpened, updateCartographicSelectorOpened } =
    useCartographySelectorContext();

  const [initialMapStyle, setInitialMapStyle] = useState<string>();
  const [mapStyles, setMapStyles] = useState([
    {
      type: 'dark',
      style: 'mapbox://styles/joaogn/ckq2kyiwc2g1k18qvfownydky',
      active: true,
      image: map_dark,
    },
    {
      type: 'clear',
      style: 'mapbox://styles/mapbox/light-v10',
      active: false,
      image: map_clear,
    },
    {
      type: 'satellite',
      style: 'mapbox://styles/mapbox/satellite-v9',
      active: false,
      image: map_satellite,
    },
    {
      type: 'streets',
      style: 'mapbox://styles/mapbox/streets-v11',
      active: false,
      image: map_streets,
    },
    {
      type: 'landforms',
      style: 'mapbox://styles/mapbox/outdoors-v11',
      active: false,
      image: map_landforms,
    },
  ]);
  const [cartographicSelectorPosition, setCartographicSelectorPosition] =
    useState({ x: 0, y: 0 });

  const activeMapByStyle = useCallback(
    (style: string) => {
      setMapStyles(oldMapStyles =>
        oldMapStyles.map(oldMap => {
          if (oldMap.style === style) {
            updateMapCartographicStyle(oldMap.style);
            return {
              ...oldMap,
              active: true,
            };
          }
          return {
            ...oldMap,
            active: false,
          };
        }),
      );
    },
    [updateMapCartographicStyle],
  );

  function clearFilters() {
    if (!initialMapStyle) {
      return;
    }
    activeMapByStyle(initialMapStyle);
  }

  function onStyleChange(type: string) {
    setMapStyles(oldMapStyles =>
      oldMapStyles.map(oldMap => {
        if (oldMap.type === type) {
          updateMapCartographicStyle(oldMap.style);
          return {
            ...oldMap,
            active: true,
          };
        }
        return {
          ...oldMap,
          active: false,
        };
      }),
    );
  }

  useEffect(() => {
    activeMapByStyle(mapCartographicStyle);
    setInitialMapStyle(mapCartographicStyle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCartographicSelectorPosition({
      x: parentSize.width / 2 - 690 / 2,
      y: 0,
    });
  }, [parentSize]);

  if (!cartographicSelectorOpened) return null;

  return (
    <Container
      bounds="parent"
      maxWidth={690}
      maxHeight={410}
      minWidth={690}
      minHeight={410}
      position={cartographicSelectorPosition}
      enableResizing={false}
      onDragStop={(e: RndDragEvent, d: DraggableData) => {
        setCartographicSelectorPosition({ x: d.x, y: d.y });
      }}
    >
      <Header>
        <H6>{t('cartographic_elements')}</H6>
        <TouchableOpacity
          onPress={() => updateCartographicSelectorOpened(false)}
          style={{ right: 0, position: 'absolute' }}
        >
          <CloseIcon />
        </TouchableOpacity>
      </Header>
      <ContentContainer>
        <Body1>{t('basemaps')}</Body1>
        <LeftContainer>
          {mapStyles.map(mapStyle => (
            <MapContainer key={mapStyle.type}>
              <CheckBox
                size={16}
                value={mapStyle.active}
                onValueChange={() => onStyleChange(mapStyle.type)}
              />
              <Image source={mapStyle.image} />
              <Body2>{t(mapStyle.type)}</Body2>
            </MapContainer>
          ))}
        </LeftContainer>

        <BottomContainer>
          <SaveButton onPress={clearFilters}>{t('clear_filters')}</SaveButton>
        </BottomContainer>
      </ContentContainer>
    </Container>
  );
};

// import map_borders from '../../assets/map_borders.png';
// import map_movements from '../../assets/map_movements.png';
// import map_highway_06 from '../../assets/map_highway_06.png';
// import map_flood_zones from '../../assets/map_flood_zones.png';

/*
  const [mapOverlays, setMapOverlays] = useState([
    {
      type: 'borders',
      active: false,
      image: map_borders,
    },
    {
      type: 'movements',
      active: false,
      image: map_movements,
    },
    {
      type: 'highway_06',
      active: false,
      image: map_highway_06,
    },
    {
      type: 'flood_zones',
      active: false,
      image: map_flood_zones,
    },
  ]);

  function onOverlayChange(type: string) {
    setMapOverlays(oldMapOverlays =>
      oldMapOverlays.map(oldMap => {
        if (oldMap.type === type) {
          return {
            ...oldMap,
            active: !oldMap.active,
          };
        }
        return oldMap;
      }),
    );
  }
*/

/*
        <RightContainer>
          <Body1>{t('map_layers')}</Body1>
          {mapOverlays.map(mapOverlay => (
            <MapContainer key={mapOverlay.type}>
              <CheckBox
                size={16}
                value={mapOverlay.active}
                onValueChange={() => onOverlayChange(mapOverlay.type)}
              />
              <Image source={mapOverlay.image} />
              <Body2>{t(mapOverlay.type)}</Body2>
            </MapContainer>
          ))}
        </RightContainer>
        */
