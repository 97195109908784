import { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { PreviewItem } from '@atoms/PreviewItem';
import { AddFileButton } from '@atoms/AddFileButton';
import { useToastContext } from '@contexts/ToastContext';
import { usePreviewContext } from '@contexts/PreviewContext';
import { getUriType } from '../../../utils/getUriType';
import { api } from '../../../services/userManagementApi';
import { dataUriToBuffer } from '../../../utils/uriToBuffer';

import { Container, TitleContainer, Label, MediaListContainer } from './styles';

export interface FileInfo {
  uri: string;
  name: string;
  type: string;
}

export interface UploadedFileInfo extends FileInfo {
  document_id: string;
}

export interface MainCouranteInfoFormAttachmentsInputProps {
  defaultFiles: UploadedFileInfo[];
  teamId: string;
  eventId: string;
  onChange: (value: string[]) => void;
}

export const MainCouranteInfoFormAttachmentsInput = ({
  teamId,
  eventId,
  onChange,
  defaultFiles,
}: MainCouranteInfoFormAttachmentsInputProps) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<UploadedFileInfo[]>(defaultFiles);
  const { dispatchToast } = useToastContext();

  const { openPreview } = usePreviewContext();

  async function handleAddNewFile(newFile: FileInfo) {
    if (newFile) {
      try {
        const response = await api.post('/v1/documents', {
          document_name: newFile.name,
          team_id: teamId,
          main_courante_id: eventId,
        });
        const document_id = response.data.document.id;
        try {
          const buffer = dataUriToBuffer(newFile.uri);
          await axios.put(response.data.pre_signed_url, buffer);
          setFiles(oldFiles => {
            const newFiles = [...oldFiles, { ...newFile, document_id }];
            onChange(newFiles.map(file => file.document_id));
            return newFiles;
          });
        } catch (err) {
          await api.delete(`/v1/documents/${document_id}`);
          dispatchToast({ message: t('erro_updated_file'), type: 'error' });
        }
      } catch {
        dispatchToast({ message: t('erro_updated_file'), type: 'error' });
      }
    }
  }

  async function handleRemoveFile(documentId: string) {
    try {
      await api.delete(`/v1/documents/${documentId}`);
      setFiles(oldFiles => {
        const newFiles = oldFiles.filter(
          file => file.document_id !== documentId,
        );
        onChange(newFiles.map(file => file.document_id));
        return newFiles;
      });
    } catch {
      dispatchToast({ message: t('erro_delete_file'), type: 'error' });
    }
  }

  return (
    <Container>
      <TitleContainer>
        <Label>{t('photos_and_videos')}</Label>
        <AddFileButton onChange={handleAddNewFile} />
      </TitleContainer>
      <MediaListContainer>
        {files.map(file => (
          <PreviewItem
            openPreview={() =>
              openPreview({
                source: file.uri,
                type: getUriType(file.uri),
              })
            }
            style={{ width: '45%', padding: 5 }}
            key={file.document_id}
            type={getUriType(file.uri)}
            source={file.uri}
            onRemove={() => handleRemoveFile(file.document_id)}
          />
        ))}
      </MediaListContainer>
    </Container>
  );
};
