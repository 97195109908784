import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  padding-top: 6px;
  z-index: 2;
`;

export const SideBySideContent = styled.View`
  width: 100%;
  margin: 6px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
`;
