import { useMemo } from 'react';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { AnalyticsTabsDynamicDataCardTemperatureLabel } from '@molecules/AnalyticsTabsDynamicDataCardTemperatureLabel';
import { AnalyticsTabsDynamicDataCardTitle } from '@molecules/AnalyticsTabsDynamicDataCardTitle';
import { AnalyticsTabsDynamicDataCardValue } from '@molecules/AnalyticsTabsDynamicDataCardValue';
import { AnalyticsTabTooltipContent } from '@molecules/AnalyticsTabTooltipContent';
import { Tooltip } from '@atoms/Tooltip';
import { useWindowDimensions } from 'react-native';
import { Container, TemperaturesContainer } from './styles';
import temperatureIcon from '../../../assets/icons/temperature.svg';

interface Temperature {
  unit: string;
  data: string;
  source: string;
  lastUpdateFormatted: string;
  sourceUrl: string;
}

interface AnalyticsTabsDynamicDataTemperatureCardProps {
  currentTemperature?: Temperature;
  maxTemperature?: Temperature;
  minTemperature?: Temperature;
}

export const AnalyticsTabsDynamicDataTemperatureCard = ({
  currentTemperature,
  maxTemperature,
  minTemperature,
}: AnalyticsTabsDynamicDataTemperatureCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { width } = useWindowDimensions();

  const isSmall = useMemo(() => width < 1700, [width]);

  return (
    <Container small={isSmall}>
      <AnalyticsTabsDynamicDataCardTitle
        icon={temperatureIcon}
        title={t('temperature')}
      />

      <TemperaturesContainer small={isSmall}>
        {currentTemperature && (
          <Tooltip
            content={
              <AnalyticsTabTooltipContent
                text={`Source: ${currentTemperature.source}`}
                lastUpdateFormatted={currentTemperature.lastUpdateFormatted}
              />
            }
            place="bottom"
          >
            <AnalyticsTabsDynamicDataCardValue
              sourceUrl={currentTemperature.sourceUrl}
              color={theme.purple}
              value={currentTemperature.data}
              unit={currentTemperature.unit}
              label={
                <AnalyticsTabsDynamicDataCardTemperatureLabel
                  label={t('day').toUpperCase()}
                />
              }
              small={isSmall}
            />
          </Tooltip>
        )}

        {minTemperature && (
          <Tooltip
            content={
              <AnalyticsTabTooltipContent
                text={`Source: ${minTemperature.source}`}
                lastUpdateFormatted={minTemperature.lastUpdateFormatted}
              />
            }
            place="bottom"
          >
            <AnalyticsTabsDynamicDataCardValue
              sourceUrl={minTemperature.sourceUrl}
              color={theme.blue}
              value={minTemperature.data}
              unit={minTemperature.unit}
              label={
                <AnalyticsTabsDynamicDataCardTemperatureLabel
                  label={t('min').toUpperCase()}
                />
              }
              small={isSmall}
            />
          </Tooltip>
        )}
        {maxTemperature && (
          <Tooltip
            content={
              <AnalyticsTabTooltipContent
                text={`Source: ${maxTemperature.source}`}
                lastUpdateFormatted={maxTemperature.lastUpdateFormatted}
              />
            }
            place="bottom"
          >
            <AnalyticsTabsDynamicDataCardValue
              sourceUrl={maxTemperature.sourceUrl}
              color={theme.yellow}
              value={maxTemperature.data}
              unit={maxTemperature.unit}
              label={
                <AnalyticsTabsDynamicDataCardTemperatureLabel
                  label={t('max').toUpperCase()}
                />
              }
              small={isSmall}
            />
          </Tooltip>
        )}
      </TemperaturesContainer>
    </Container>
  );
};
