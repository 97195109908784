import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 60px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
`;

export const Button = styled.TouchableOpacity`
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.purpleBlue};
  justify-content: center;
  align-items: center;
`;
