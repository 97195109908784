import { ComponentType, useState, useRef, useEffect } from 'react';
import {
  TouchableOpacity,
  TextInput,
  StyleProp,
  ViewStyle,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  Platform,
} from 'react-native';
import { SvgProps } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { useActive } from 'react-native-web-hooks';
import { Caption } from '@nucleus/Typography';
import { Icon } from '@nucleus/Icon';
import { Tooltip } from '@atoms/Tooltip';
import errorIcon from '../../../assets/icons/error_outline.svg';
import CloseCircleIcon from '../../../assets/icons/close_circle.svg';

import {
  Container,
  Label,
  ContentContainer,
  InputContainer,
  TagContainer,
  InputText,
  IconsContainer,
} from './styles';

export interface InputTagProps {
  placeholder?: string;
  error?: string;
  icon?: ComponentType<SvgProps>;
  tags: string[];
  onChange: (value: string[]) => void;
  style?: StyleProp<ViewStyle>;
  onBlur: () => void;
}

export const InputTag = ({
  icon,
  error,
  tags,
  placeholder,
  onChange,
  onBlur,
  style,
}: InputTagProps) => {
  const theme = useTheme();
  const [hasFocus, setHasFocus] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const ref = useRef(null);
  const inputRef = useRef<TextInput>(null);
  const isActive = useActive(ref);
  if (showInput && inputRef) {
    inputRef.current?.focus();
  }

  useEffect(() => {
    if (isActive) {
      setShowInput(true);
    }
  }, [isActive]);

  function handleDeleteTag(index: number) {
    const newTags = tags.filter((_, i) => i !== index);
    onChange(newTags);
  }

  function handleKeyPress(
    e: NativeSyntheticEvent<TextInputKeyPressEventData> | KeyboardEvent,
  ) {
    if (!inputRef.current) return;
    if (Platform.OS === 'web') {
      const event = e as KeyboardEvent;
      const element = inputRef.current as unknown as HTMLInputElement;
      if (!event) return;
      const { value } = element;
      if (event.key === 'Enter' && value) {
        const existedTag = tags.find(tag => tag === value);
        if (existedTag) return;
        onChange([...tags, value]);
        inputRef.current?.clear();
      } else if (event.key === 'Backspace' && !value) {
        handleDeleteTag(tags.length - 1);
      }
      inputRef.current?.focus();
    }
  }

  return (
    <Container ref={ref} style={style}>
      {placeholder && tags.length > 0 && <Label>{placeholder}</Label>}
      <ContentContainer withIcon={!!Icon} error={!!error && !hasFocus}>
        <InputContainer>
          {tags.map((tag, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TagContainer key={`${tag}_${index}`}>
              <TouchableOpacity onPress={() => handleDeleteTag(index)}>
                <CloseCircleIcon style={{ marginRight: 4 }} />
              </TouchableOpacity>
              <Caption>{tag}</Caption>
            </TagContainer>
          ))}

          {(showInput || tags.length === 0) && (
            <InputText
              ref={inputRef}
              testID="tag-input-element"
              withIcon={!!Icon}
              error={!!error && !hasFocus}
              hasFocus={hasFocus}
              placeholderTextColor={theme.purpleBlue}
              onBlur={() => {
                setHasFocus(false);
                setShowInput(false);
                onBlur();
              }}
              onFocus={() => setHasFocus(true)}
              placeholder={tags.length > 0 ? undefined : placeholder}
              onKeyPress={handleKeyPress}
            />
          )}
        </InputContainer>
        {((!!error && !hasFocus) || !!Icon) && (
          <IconsContainer withIcon={!!Icon} error={!!error && !hasFocus}>
            {error && !hasFocus && (
              <>
                <Tooltip place="top" content={error}>
                  <Icon
                    as={errorIcon}
                    fill={theme.red}
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                  />
                </Tooltip>
              </>
            )}
            {icon && (
              <Icon
                as={icon}
                testID="input-icon"
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill={theme.white}
              />
            )}
          </IconsContainer>
        )}
      </ContentContainer>
    </Container>
  );
};
