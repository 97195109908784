import styled from 'styled-components/native';

export const Container = styled.View`
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.View`
  width: 45%;
  height: 50%;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 20px;
  padding: 24px;
  justify-content: center;
`;
