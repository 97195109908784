import { useState, useEffect } from 'react';
import { RndDragEvent, DraggableData } from 'react-rnd';
import { CreateLocationHeader } from '@organisms/CreateLocationHeader';
import { CreateLocationExistedAddress } from '@organisms/CreateLocationExistedAddress';
import { CreateLocationCustomAddress } from '@organisms/CreateLocationCustomAddress';
import { useZonesContext } from '@contexts/ZonesContext';
import {
  useCreateLocationContext,
  Location,
} from '@contexts/CreateLocationContext';
import { Container } from './styles';

export interface Size {
  width: number;
  height: number;
}

export interface CreateLocationProps {
  parentSize: Size;
}

export function CreateLocation({ parentSize }: CreateLocationProps) {
  const {
    setSelectedLocation,
    setCreateLocationOpened,
    locationId,
    locationZoneType,
    selectedAddress,
    setSelectedAddress,
    createLocationOpened,
  } = useCreateLocationContext();

  const { activeNoInteractionMode, deleteZoneById } = useZonesContext();

  const [createLocationPosition, setCreateLocationPosition] = useState({
    x: parentSize.width - 285,
    y: 0,
  });

  const [selectedMode, setSelectedMode] = useState<'existed' | 'custom'>(
    'existed',
  );

  function handleConfirm(location: Location) {
    setSelectedLocation(location);
    setCreateLocationOpened(false);
  }

  function handleClose() {
    setSelectedAddress(undefined);
    if (locationId) {
      deleteZoneById(locationId);
    }
    activeNoInteractionMode();
    setCreateLocationOpened(false);
  }

  useEffect(() => {
    if (locationId) {
      setSelectedAddress(undefined);
      deleteZoneById(locationId);
      activeNoInteractionMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMode]);

  useEffect(() => {
    return () => {
      setSelectedMode('existed');
    };
  }, []);

  if (!createLocationOpened) return null;
  if (locationId && locationZoneType) {
    return (
      <Container
        cancel="input"
        bounds="parent"
        maxWidth={285}
        maxHeight={110}
        minWidth={285}
        minHeight={110}
        position={createLocationPosition}
        enableResizing={false}
        onDragStop={(e: RndDragEvent, d: DraggableData) => {
          setCreateLocationPosition({ x: d.x, y: d.y });
        }}
      >
        <CreateLocationHeader
          mode={selectedMode}
          onPressBack={() => setSelectedMode('existed')}
          onPressClose={handleClose}
        />
        {selectedMode === 'existed' ? (
          <CreateLocationExistedAddress
            locationZoneType={locationZoneType}
            locationId={locationId}
            onConfirm={handleConfirm}
            setSelectMode={setSelectedMode}
            setSelectedAddress={setSelectedAddress}
            selectedAddress={selectedAddress}
          />
        ) : (
          <CreateLocationCustomAddress
            locationZoneType={locationZoneType}
            locationId={locationId}
            onCreateLocation={handleConfirm}
          />
        )}
      </Container>
    );
  }
  return null;
}
