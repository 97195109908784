/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { api } from '../userManagementApi';
import { getDateFnsLocale } from '../../utils/getDateFnsLocale';

export interface WorkspaceResponse {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  formatted_updated_at: string;
}
export interface Workspace extends WorkspaceResponse {
  formatted_updated_at: string;
}

export async function getWorkspaces(): Promise<Workspace[]> {
  const locale = getDateFnsLocale();

  const workspacesResponse = await api.get('/v1/workspaces');

  return workspacesResponse.data.map((workspace: WorkspaceResponse) => ({
    ...workspace,
    formatted_updated_at: formatDistanceToNow(parseISO(workspace.updated_at), {
      locale,
      addSuffix: true,
    }),
  }));
}

export function useQueryWorkpaces() {
  return useQuery(['workspaces'], () => getWorkspaces());
}
