import { Icon } from '@nucleus/Icon';
import { MarkerView } from '@atoms/MakerView';
import mainCouranteIcon from '../../../assets/icons/main_courante_icon.svg';
import { Container } from './styles';

interface EventsMapMainCouranteActionMakerProps {
  id: string;
  latitude: number;
  longitude: number;
  onPress: () => void;
  size: number;
  closed: boolean;
}

export const EventsMapMainCouranteActionMaker = ({
  id,
  latitude,
  longitude,
  size,
  closed,
  onPress,
}: EventsMapMainCouranteActionMakerProps) => {
  return (
    <MarkerView
      id={id}
      latitude={latitude}
      longitude={longitude}
      offsetLeft={-size / 2}
      offsetTop={-size / 2}
    >
      <Container onPress={onPress} size={size} closed={closed}>
        <Icon
          as={mainCouranteIcon}
          width={size / 2.8}
          fill="#fff"
          viewBox="0 0 16 26"
        />
      </Container>
    </MarkerView>
  );
};
