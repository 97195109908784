import { Caption } from '@nucleus/Typography';
import { OpenCloseButton } from '@atoms/OpenCloseButton';
import { SelectableTitle } from '@atoms/SelectableTitle';
import { TimeLabel } from '@atoms/TimeLabel';
import { InfoAvatar } from '@atoms/InfoAvatar';

import {
  Container,
  ContentDetail,
  TitleContainer,
  TitleWrapper,
  ContentDataSource,
} from './styles';

interface MainCouranteInfoActionPreviewProps {
  id: string;
  title: string;
  date: string;
  closed: boolean;
  location_name?: string;
  openCloseLoading: boolean;
  selected?: boolean;
  onActionPress: () => void;
  handleOpenAction: (actionId: string) => void;
  handleCloseAction: (actionId: string) => void;
}

export const MainCouranteInfoActionPreview = ({
  id,
  title,
  date,
  closed,
  location_name,
  openCloseLoading,
  selected,
  onActionPress,
  handleOpenAction,
  handleCloseAction,
}: MainCouranteInfoActionPreviewProps) => {
  return (
    <Container>
      <InfoAvatar />
      <ContentDetail>
        <TitleContainer>
          <TitleWrapper>
            <SelectableTitle
              title={title}
              onPress={onActionPress}
              selected={selected}
            />
          </TitleWrapper>
          <OpenCloseButton
            loading={openCloseLoading}
            closed={closed}
            onOpenPress={() => handleOpenAction(id)}
            onClosePress={() => handleCloseAction(id)}
          />
        </TitleContainer>
        <ContentDataSource>
          <TimeLabel formattedDate={date} />
          <Caption style={{ marginLeft: 8, maxWidth: 250 }} numberOfLines={1}>
            {`${location_name || ''}`}
          </Caption>
        </ContentDataSource>
      </ContentDetail>
    </Container>
  );
};
