/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useCallback, useMemo } from 'react';
import * as DocumentPicker from 'expo-document-picker';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from 'react-native';
import { Image } from '@nucleus/Image';
import {
  Container,
  Header,
  // HeaderControl,
  TopContainer,
  ChooseTeamContainer,
  TopTitle,
  DocumentsList,
} from './styles';
// import CloseIcon from '../../assets/icons/close.svg';
// import MaximizeIcon from '../../assets/icons/maximize.svg';
import { H5, Body1 } from '../Typography';
import { ConfirmCancelModal } from '../ConfirmCancelModal';
import { Document } from './Document';
import { RenameModal } from './RenameModal';
import { Select } from '../Select';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQueryDocuments } from '../../services/hooks/useQueryDocuments';
import { useMutationDocuments } from '../../services/hooks/useMutationDocuments';
import { api } from '../../services/userManagementApi';
import { useToastContext } from '../../contexts/ToastContext';
import { AddButton } from '../AddButton';
import securityImage from '../../assets/insurance.png';

interface File {
  uri: string;
  name: string;
}

interface EditDocument {
  id: string;
  name: string;
}

export const MyDocuments = () => {
  const theme = useTheme();
  const [deleteDocument, setDeleteDocument] = useState<string>();
  const [editDocument, setEditDocument] = useState<EditDocument>();
  const [file, setFile] = useState<File>();
  const { user } = useAuthContext();
  const [selectedTeam, setSelectedTeam] = useState(user?.teams[0].id);
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  const teamOptions = useMemo(() => {
    return user
      ? user.teams.map(team => ({ value: team.id, label: team.name }))
      : [];
  }, [user]);

  const useQuery = useMutationDocuments(selectedTeam || '');

  const { data, isLoading } = useQueryDocuments(selectedTeam || '');

  async function pickDocumentFile() {
    const pickedFile = await DocumentPicker.getDocumentAsync();

    if (pickedFile.type === 'success') {
      setFile({ uri: pickedFile.uri, name: pickedFile.name });
    }
  }

  async function onConfirmDeleteDocument(documentId?: string) {
    if (!documentId) return;
    await useQuery.mutateAsync({ document_id: documentId, type: 'delete' });
    setDeleteDocument(undefined);
  }

  const onCreate = useCallback(async () => {
    if (!file) return;
    await useQuery.mutateAsync({ ...file, type: 'create' });
    setFile(undefined);
  }, [file, useQuery]);

  const onEdit = useCallback(async () => {
    if (!editDocument) return;
    await useQuery.mutateAsync({
      document_id: editDocument.id,
      name: editDocument.name,
      type: 'update',
    });
    setEditDocument(undefined);
  }, [editDocument, useQuery]);

  const onDownload = useCallback(
    async (documentId: string) => {
      try {
        const response = await api.get('/v1/documents/download', {
          params: { document_id: documentId },
        });
        if (!response.data?.pre_signed_url) return;
        window.open(response.data.pre_signed_url);
      } catch (err) {
        dispatchToast({ message: t('delete_document_error'), type: 'error' });
      }
    },
    [dispatchToast, t],
  );

  return (
    <>
      <Container>
        <Header>
          <H5>{t('my_documents')}</H5>
          <Image
            source={securityImage as string}
            style={{ position: 'absolute', width: 40, right: 16, top: 8 }}
          />
          {/*   <HeaderControl>
          <MaximizeIcon />
          <CloseIcon />
        </HeaderControl> */}
        </Header>
        <ChooseTeamContainer>
          <Body1 style={{ marginRight: 16 }}>{t('choose_team')}</Body1>
          <Select
            selectedOptions={selectedTeam ? [selectedTeam] : []}
            style={{ zIndex: 1, width: 200 }}
            options={teamOptions}
            onChange={value => setSelectedTeam(value[0])}
          />
        </ChooseTeamContainer>

        <TopContainer>
          <TopTitle>{t('file_name')}</TopTitle>
          <AddButton size="large" onPress={pickDocumentFile} />
        </TopContainer>
        <DocumentsList>
          {isLoading ? (
            <ActivityIndicator size="large" color={theme.purple} />
          ) : (
            data &&
            data.map(document => (
              <Document
                title={document.document_name}
                onDownload={() => onDownload(document.id)}
                onDelete={() => setDeleteDocument(document.id)}
                onEdit={() =>
                  setEditDocument({
                    id: document.id,
                    name: document.document_name,
                  })
                }
              />
            ))
          )}
        </DocumentsList>
        {file && (
          <RenameModal
            name={file.name}
            onConfirm={onCreate}
            onCancel={() => setFile(undefined)}
            onChangeName={newName =>
              setFile(oldFile =>
                oldFile ? { ...oldFile, name: newName } : undefined,
              )
            }
          />
        )}
        {editDocument && (
          <RenameModal
            name={editDocument.name}
            onConfirm={onEdit}
            onCancel={() => setEditDocument(undefined)}
            onChangeName={newName =>
              setEditDocument(oldEdit =>
                oldEdit ? { ...oldEdit, name: newName } : undefined,
              )
            }
          />
        )}
      </Container>
      <ConfirmCancelModal
        isVisible={!!deleteDocument}
        title={t('delete_document_confirmation')}
        onCancel={() => setDeleteDocument(undefined)}
        onConfirm={() => onConfirmDeleteDocument(deleteDocument)}
      />
    </>
  );
};
