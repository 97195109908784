import styled from 'styled-components/native';
import { lighten } from 'polished';

import { Body1 } from '../Typography';

export const Container = styled.View`
  width: 98%;
  height: 95%;
  border-radius: 20px;
  background: ${({ theme }) => theme.purpleBlack};
`;

export const Header = styled.View`
  padding: 8px 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderControl = styled.View`
  width: 60px;
  flex-direction: row;
  justify-content: space-between;
`;

export const TopContainer = styled.View`
  margin: 8px 32px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ChooseTeamContainer = styled.View`
  margin: 8px 32px;
  flex-direction: row;
`;

export const TopTitle = styled(Body1)`
  color: ${({ theme }) => theme.purpleBlue};
`;

interface AddButtonProps {
  isHovered?: boolean;
}

export const AddButton = styled.TouchableOpacity<AddButtonProps>`
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: ${({ theme, isHovered }) =>
    isHovered ? lighten(0.05, theme.purple) : theme.purple};
  justify-content: center;
  align-items: center;
`;
export const DocumentsList = styled.View`
  padding: 8px 32px;
  width: 100%;
  flex: 1;
`;
