import styled, { css } from 'styled-components/native';
import { darken } from 'polished';
import { Body2 } from '@nucleus/Typography';

interface ContainerProps {
  isHovered?: boolean;
  outline?: boolean;
}

export const Container = styled.TouchableOpacity<ContainerProps>`
  width: 95%;
  max-width: 195px;
  height: 48px;
  background-color: ${({ theme, isHovered }) =>
    isHovered ? darken(0.05, theme.blue) : theme.blue};
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  ${({ outline, isHovered }) =>
    outline &&
    css`
      border-color: ${({ theme }) =>
        isHovered ? darken(0.05, theme.blue) : theme.blue};
      border-width: 1px;
      background-color: transparent;
    `}
`;

export const ButtonText = styled(Body2)<ContainerProps>`
  color: ${({ theme, outline, isHovered }) => {
    if (outline) {
      if (isHovered) {
        return darken(0.05, theme.blue);
      }
      return theme.blue;
    }
    return theme.white;
  }};
`;
