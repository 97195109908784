/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, ReactNode, useContext, useState } from 'react';

import { Zone } from '@contexts/ZonesContext';

export interface Location {
  location_name: string;
  zone: Zone;
  location_color?: string;
}

interface CreateLocationContextData {
  locationZoneType?: 'main_courante' | 'main_courante_action';
  setLocationZoneType: (
    value: 'main_courante' | 'main_courante_action',
  ) => void;
  selectedLocation?: Location;
  setSelectedLocation: (location: Location) => void;
  selectedAddress?: Location;
  setSelectedAddress: (address?: Location) => void;
  createLocationOpened: boolean;
  setCreateLocationOpened: (value: boolean) => void;
  locationId?: string;
  setLocationId: (value: string) => void;
  clearLocation: () => void;
}

interface CreateLocationContextProps {
  children: ReactNode;
}

const CreateLocationContext = createContext({} as CreateLocationContextData);

function CreateLocationContextProvider({
  children,
}: CreateLocationContextProps) {
  const [locationZoneType, setLocationZoneType] = useState<
    'main_courante' | 'main_courante_action'
  >();
  const [selectedAddress, setSelectedAddress] = useState<Location>();
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [createLocationOpened, setCreateLocationOpened] = useState(false);
  const [locationId, setLocationId] = useState<string>();

  const clearLocation = () => {
    setSelectedAddress(undefined);
    setSelectedLocation(undefined);
    setCreateLocationOpened(false);
    setLocationId(undefined);
  };

  return (
    <CreateLocationContext.Provider
      value={{
        locationZoneType,
        setLocationZoneType,
        selectedLocation,
        setSelectedLocation,
        createLocationOpened,
        setCreateLocationOpened,
        locationId,
        setLocationId,
        selectedAddress,
        setSelectedAddress,
        clearLocation,
      }}
    >
      {children}
    </CreateLocationContext.Provider>
  );
}

function useCreateLocationContext(): CreateLocationContextData {
  const context = useContext(CreateLocationContext);

  return context;
}

export { CreateLocationContextProvider, useCreateLocationContext };
