/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { useMutation } from 'react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { api } from '../userManagementApi';
import { queryClient } from '../queryClient';
import { useToastContext } from '../../contexts/ToastContext';
import { dataUriToBuffer } from '../../utils/uriToBuffer';

interface File {
  name: string;
  uri: string;
}

interface MutationData {
  name?: string;
  uri?: string;
  type: 'create' | 'delete' | 'update';
  document_id?: string;
}

export const deleteDocument = async (document_id: string) => {
  const response = await api.delete(`/v1/documents/${document_id}`);
  return response.data.doc;
};

export const createDocument = async (team_id: string, file: File) => {
  const response = await api.post('/v1/documents', {
    document_name: file.name,
    team_id,
  });
  try {
    const buffer = dataUriToBuffer(file.uri);
    await axios.put(response.data.pre_signed_url, buffer);
  } catch (err) {
    await deleteDocument(response.data.document.id);
    return err;
  }
  return response.data.document;
};

export const editDocument = async (
  document_id: string,
  new_file_name: string,
) => {
  const response = await api.patch(
    `/v1/documents/${document_id}?new_file_name=${new_file_name}`,
  );
  return response.data.doc;
};

export const useMutationDocuments = (team_id: string) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({ name, uri, type, document_id }: MutationData) => {
      if (type === 'create' && name && uri) {
        const result = await createDocument(team_id, { name, uri });
        if (result instanceof Error) {
          return new Error('error');
        }
        return { result, type };
      }
      if (type === 'delete' && document_id) {
        const result = await deleteDocument(document_id);
        return { result, type };
      }
      if (type === 'update' && document_id && name) {
        const result = await editDocument(document_id, name);
        return { result, type };
      }
    },
    {
      onSuccess: (response: any) => {
        const { type } = response;
        queryClient.invalidateQueries(['documents', team_id]);

        if (response instanceof Error) {
          dispatchToast({
            message: t('error_created_document'),
            type: 'error',
          });
          return;
        }
        dispatchToast({
          message:
            type === 'create'
              ? t('document_created')
              : type === 'delete'
              ? t('document_deleted')
              : t('document_updated'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_created_document'),
          type: 'error',
        });
      },
    },
  );
};
