import { useTranslation } from 'react-i18next';
import { H6 } from '@nucleus/Typography';
import { IconButton } from '@atoms/IconButton';
import { Container } from './styles';
import closeIcon from '../../../assets/icons/close.svg';

interface EventsListFrameHeaderProps {
  onClosePress: () => void;
}

export const EventsListFrameHeader = ({
  onClosePress,
}: EventsListFrameHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <H6>{t('events_list')}</H6>
      <IconButton icon={closeIcon} onPress={onClosePress} />
    </Container>
  );
};
