import styled, { css } from 'styled-components';

import { SketchPicker } from 'react-color';

export const Container = styled.div`
  position: relative;
  z-index: 11;
`;

export const ColorContainer = styled.div`
  padding: 5px;
  background: ${({ theme }) => theme.purpleBlue};
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const Color = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background: ${({ theme }) => theme.eletricGreen};
`;

export interface ColorContainerBoudings {
  top: number;
  left: number;
}

interface ColorPickerProps {
  position: 'top-left' | 'center-down';
  colorContainerBoudings: ColorContainerBoudings;
}

export const ColorPicker = styled(SketchPicker)<ColorPickerProps>`
  ${({ position, colorContainerBoudings }) => {
    if (position === 'center-down') {
      return css`
        top: ${`${colorContainerBoudings.top + 24}px`};
        left: ${`${colorContainerBoudings.left}px`};
        transform: translateX(-38%);
      `;
    }
    return css`
      top: ${`${colorContainerBoudings.top}px`};
      transform: translateY(-100%) translateX(-100%);
      left: ${`${colorContainerBoudings.left}px`};
    `;
  }}
  position: absolute;

  background: ${({ theme }) => theme.purpleBlue} !important;
  input {
    background: ${({ theme }) => theme.purpleBlack} !important;
    color: ${({ theme }) => theme.white} !important;
    box-shadow: none !important;
  }
  label {
    color: ${({ theme }) => theme.white} !important;
  }

  .flexbox-fix {
    border-color: ${({ theme }) => theme.purpleBlack} !important;
  }
`;
