/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Hoverable } from 'react-native-web-hooks';
import { useAuthContext } from '../../../contexts/AuthContext';
import { Body2, Caption } from '../../Typography';
import {
  Container,
  SideBySide,
  InputContainer,
  ConfirmButton,
  CheckBoxButton,
} from './styles';
import { Select } from '../../Select';
import { Input } from '../../Input';
import { useQueryMainCourantes } from '../../../services/hooks/useQueryMainCourantes';
import { CheckBox } from '../../CheckBox';

interface NewEvent {
  name: string;
  team_id: string | null;
  event_id: string | null;
}

interface ChoiseeEventProps {
  onConfirm: () => void;
  newEvent: NewEvent;
  setNewEvent: (value: NewEvent) => void;
  setSeletedEvent: (event: {
    name: string;
    id: string;
    team_id: string;
  }) => void;
}

export const ChoiseeEvent = ({
  onConfirm,
  newEvent,
  setNewEvent,
  setSeletedEvent,
}: ChoiseeEventProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [choiseSelected, setChoiseSelected] = useState<'edit' | 'new'>();

  const { user } = useAuthContext();

  const { data: mainCourantes, isLoading } = useQueryMainCourantes(
    user?.teams && user?.teams.length > 0 ? user?.teams[0].id : undefined,
  );

  const mainCourantesOptions = useMemo(() => {
    return mainCourantes
      ? mainCourantes.map(mainCourante => ({
          value: mainCourante.id,
          label: mainCourante.name,
        }))
      : [];
  }, [mainCourantes]);

  const teamOptions = useMemo(() => {
    return user
      ? user.teams.map(team => ({ value: team.id, label: team.name }))
      : [];
  }, [user]);

  function onSelectMainCourante(mainCouranteId: string) {
    const selectedMainCourante = mainCourantes?.find(
      mainCourante => mainCourante.id === mainCouranteId,
    );
    if (!selectedMainCourante) return;
    setSeletedEvent({
      name: selectedMainCourante.name,
      id: selectedMainCourante.id,
      team_id: selectedMainCourante.team_id,
    });
  }

  return (
    <Container>
      {isLoading ? (
        <ActivityIndicator
          color={theme.purple}
          size="large"
          style={{ marginTop: 20 }}
        />
      ) : (
        <>
          <SideBySide>
            <Hoverable>
              {isHovered => (
                <CheckBoxButton
                  isHovered={isHovered}
                  isActive={choiseSelected === 'edit'}
                  onPress={() => setChoiseSelected('edit')}
                >
                  <CheckBox
                    value={choiseSelected === 'edit'}
                    onValueChange={() => setChoiseSelected('edit')}
                    size={14}
                  />
                  <Caption
                    style={{
                      alignSelf: 'center',
                      marginTop: 8,
                      textAlign: 'center',
                    }}
                  >
                    {t('to_choose_existing_main_courante')}
                  </Caption>
                </CheckBoxButton>
              )}
            </Hoverable>
            <Hoverable>
              {isHovered => (
                <CheckBoxButton
                  isHovered={isHovered}
                  style={{ marginLeft: 48 }}
                  isActive={choiseSelected === 'new'}
                  onPress={() => setChoiseSelected('new')}
                >
                  <CheckBox
                    value={choiseSelected === 'new'}
                    onValueChange={() => setChoiseSelected('new')}
                    size={14}
                  />
                  <Caption
                    style={{
                      alignSelf: 'center',
                      marginTop: 8,
                      textAlign: 'center',
                    }}
                  >
                    {t('create_new_main_courante')}
                  </Caption>
                </CheckBoxButton>
              )}
            </Hoverable>
          </SideBySide>
          <InputContainer>
            {choiseSelected === 'edit' && (
              <>
                <Body2 style={{ marginBottom: 16 }}>
                  {t('choose_existing_main_courante')}
                </Body2>
                <Select
                  selectedOptions={[]}
                  placeholder={t('name_of_main_courante')}
                  options={mainCourantesOptions}
                  onChange={value => onSelectMainCourante(value[0])}
                  style={{ maxWidth: '250px' }}
                />
              </>
            )}

            {choiseSelected === 'new' && (
              <>
                <Body2>{t('give_new_main_courante_title')}</Body2>
                <Input
                  style={{
                    maxWidth: '250px',
                    marginTop: 8,
                  }}
                  placeholder={t('name_of_main_courante')}
                  value={newEvent.name}
                  onChangeText={value =>
                    setNewEvent({
                      ...newEvent,
                      name: value,
                    })
                  }
                />
                <Body2 style={{ marginTop: 24 }}>
                  {t('choose_team_to_share_new_main_courante')}
                </Body2>
                <Select
                  selectedOptions={newEvent.team_id ? [newEvent.team_id] : []}
                  placeholder={t('team_name')}
                  style={{ zIndex: 1, maxWidth: 250, marginTop: 8 }}
                  options={teamOptions}
                  onChange={value =>
                    setNewEvent({
                      ...newEvent,
                      team_id: value[0],
                    })
                  }
                />
              </>
            )}
          </InputContainer>
          <ConfirmButton onPress={onConfirm}>{t('confirm')}</ConfirmButton>
        </>
      )}
    </Container>
  );
};
