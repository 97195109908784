import { ReactNode } from 'react';
import { Container } from './styles';

interface WorkspaceAreaGridProps {
  children: ReactNode;
}

export const WorkspacesAreaGrid = ({ children }: WorkspaceAreaGridProps) => {
  return <Container>{children}</Container>;
};
