import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  Container,
  ContentContainer,
  ItensContainer,
  Label,
  Placeholder,
  ErrorContainer,
  ErrorText,
} from './styles';
import { PreviewMiniature } from '../PreviewMiniature';
import { AddButton } from './AddButton';
import { getUriType } from '../../utils/getUriType';
import ErrorIcon from '../../assets/icons/error_outline.svg';
import { api } from '../../services/userManagementApi';
import { dataUriToBuffer } from '../../utils/uriToBuffer';
import { useToastContext } from '../../contexts/ToastContext';

export interface FileInfo {
  uri: string;
  name: string;
  type: string;
}

export interface UploadedFileInfo extends FileInfo {
  document_id: string;
}

export interface AttachmentsInputProps {
  label?: string;
  error?: string;
  teamId: string;
  eventId: string;
  onChange: (value: string[]) => void;
}

export const AttachmentsInput = ({
  label,
  error,
  teamId,
  eventId,
  onChange,
}: AttachmentsInputProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [files, setFiles] = useState<UploadedFileInfo[]>([]);
  const { dispatchToast } = useToastContext();

  async function handleAddNewFile(newFile: FileInfo) {
    if (newFile) {
      try {
        const response = await api.post('/v1/documents', {
          document_name: newFile.name,
          team_id: teamId,
          main_courante_id: eventId,
        });
        const document_id = response.data.document.id;
        try {
          const buffer = dataUriToBuffer(newFile.uri);
          await axios.put(response.data.pre_signed_url, buffer);
          setFiles(oldFiles => {
            const newFiles = [...oldFiles, { ...newFile, document_id }];
            onChange(newFiles.map(file => file.document_id));
            return newFiles;
          });
        } catch (err) {
          await api.delete(`/v1/documents/${document_id}`);
          dispatchToast({ message: t('erro_updated_file'), type: 'error' });
        }
      } catch {
        dispatchToast({ message: t('erro_updated_file'), type: 'error' });
      }
    }
  }

  async function handleRemoveFile(documentId: string) {
    try {
      await api.delete(`/v1/documents/${documentId}`);
      setFiles(oldFiles => {
        const newFiles = oldFiles.filter(
          file => file.document_id !== documentId,
        );
        onChange(newFiles.map(file => file.document_id));
        return newFiles;
      });
    } catch {
      dispatchToast({ message: t('erro_delete_file'), type: 'error' });
    }
  }

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <ContentContainer>
        <ItensContainer>
          {files.length > 0 ? (
            <>
              {files.map(file => (
                <PreviewMiniature
                  key={file.document_id}
                  type={getUriType(file.uri)}
                  source={file.uri}
                  onRemove={() => handleRemoveFile(file.document_id)}
                />
              ))}
            </>
          ) : (
            <Placeholder>{t('click_to_add_media')}</Placeholder>
          )}
        </ItensContainer>
      </ContentContainer>
      <AddButton onChange={handleAddNewFile} />
      {error && (
        <ErrorContainer>
          <ErrorIcon
            testID="input-error-icon"
            fill={theme.red}
            width={16}
            height={16}
            viewBox="0 0 24 24"
          />
          <ErrorText>{t('media_required')}</ErrorText>
        </ErrorContainer>
      )}
    </Container>
  );
};
