import styled, { css } from 'styled-components/native';
import { transparentize } from 'polished';

interface ContainerProps {
  isHovered: boolean;
}

export const ContainerOverlay = styled.View`
  position: absolute;
  max-width: 516px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: ${transparentize(0.73, '#C4C4C4')};
  z-index: 2;
`;

export const Container = styled.TouchableOpacity<ContainerProps>`
  max-width: 516px;
  width: 98%;
  height: 190px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.blueishBlack};
  flex-direction: row;
  ${({ isHovered, theme }) =>
    isHovered &&
    css`
      box-shadow: 0px 0px 12px ${transparentize(0.55, theme.purple)};
    `}
`;

export const ContentContainer = styled.View`
  flex: 1;
  justify-content: space-between;
  transform: rotate(0deg) translateX(0px) translateY(0px);
  padding-right: 8px;
  padding-left: 8px;
`;

export const TypeSourcesContainer = styled.View`
  margin-top: 10px;
  flex-direction: row;
`;
