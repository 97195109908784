import { useTranslation } from 'react-i18next';
import { Body1 } from '@nucleus/Typography';
import { PreviewItem } from '@atoms/PreviewItem';
import { usePreviewContext } from '@contexts/PreviewContext';
import { Container, MediaContainer } from './styles';

interface EventInfoDetailMediaListProps {
  image: string;
  photos: string[];
  videos: string[];
}

export const EventInfoDetailMediaList = ({
  image,
  photos,
  videos,
}: EventInfoDetailMediaListProps) => {
  const { openPreview } = usePreviewContext();
  const { t } = useTranslation();
  return (
    <Container>
      <Body1>{t('photos_and_videos')}</Body1>
      <MediaContainer>
        {image && (
          <PreviewItem
            openPreview={() =>
              openPreview({
                source: image,
                type: 'image',
              })
            }
            type="image"
            source={image}
            style={{ width: '50%', padding: 5 }}
          />
        )}
        {photos.map(photo => (
          <PreviewItem
            openPreview={() =>
              openPreview({
                source: photo,
                type: 'image',
              })
            }
            type="image"
            source={photo}
            style={{ width: '50%', padding: 5 }}
          />
        ))}
        {videos.map(video => (
          <PreviewItem
            openPreview={() =>
              openPreview({
                source: video,
                type: 'video',
              })
            }
            type="video"
            source={video}
            style={{ width: '50%', padding: 5 }}
          />
        ))}
      </MediaContainer>
    </Container>
  );
};
