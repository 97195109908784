import { ComponentType } from 'react';
import { Body1 } from '@nucleus/Typography';
import { Icon } from '@nucleus/Icon';
import { SvgProps } from 'react-native-svg';
import { Container } from './styles';

interface AnalyticsTabsDynamicDataCardTitleProps {
  icon: ComponentType<SvgProps>;
  title: string;
}

export const AnalyticsTabsDynamicDataCardTitle = ({
  icon,
  title,
}: AnalyticsTabsDynamicDataCardTitleProps) => {
  return (
    <Container>
      <Icon as={icon} style={{ marginRight: 8 }} />
      <Body1>{title}</Body1>
    </Container>
  );
};
