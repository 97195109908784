import styled from 'styled-components/native';

export const Container = styled.View`
  flex-direction: row;
  width: 100%;
  height: 20px;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.purpleBlue};
  margin-top: 8px;
`;

export const ButtonsContainer = styled.View`
  flex-direction: row;
  svg {
    cursor: pointer !important;
    background-color: green;
  }
`;
