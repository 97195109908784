import styled from 'styled-components/native';

export const Container = styled.View`
  width: 30%;
  min-width: 430px;
  max-width: 530px;
  height: auto;
  margin-right: 8px;
  margin-bottom: 8px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.background};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
