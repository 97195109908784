import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { EventsFilterHeader } from '@organisms/EventsFilterHeader';
import { EventsFilterTypeSelector } from '@organisms/EventsFilterTypeSelector';
import { RndDragEvent, DraggableData } from 'react-rnd';
import {
  EventsFilterBasicFilters,
  BasicFilterFormData,
} from '@organisms/EventsFilterBasicFilters';
import {
  EventsFilterAdvancedFilters,
  AdvancedFilterFormData,
} from '@organisms/EventsFilterAdvancedFilters';
import {
  useFiltersContext,
  useFilterScreenContext,
} from '@contexts/FiltersContext';
import { defaultFilters } from '@contexts/TabsControlContext';
import { useQueryZones } from '@services/hooks/useQueryZones';
import { useQueryMainCourantes } from '@services/hooks/useQueryMainCourantes';
import { useAuthContext } from '@contexts/AuthContext';
import { useZonesContext } from '@contexts/ZonesContext';
import { useWindowDimensions } from 'react-native';
import { EventsFilterProps } from './types';
import { categories as existedCategories } from '../../../utils/categories';
import { Container, ContentContainer, ClearButton } from './styles.web';

const BASICS_FILTERS = 'basics';
const ADVANCED_FILTERS = 'advanced';
const DEFAULT_HEIGHT = 720;
const DEFAULT_WIDTH = 265;

const relevances = [
  { value: '1', label: 'low' },
  { value: '2', label: 'medium' },
  { value: '3', label: 'high' },
];

export const EventsFilter = ({ parentSize }: EventsFilterProps) => {
  const { filters, updateFilters } = useFiltersContext();
  const { updateFilterScreenOpen, filterScreenOpen } = useFilterScreenContext();
  const { t } = useTranslation();
  const [filterType, setFilterType] = useState(BASICS_FILTERS);
  const { data: zones } = useQueryZones();
  const { user } = useAuthContext();
  const [containerWidth, setContainerWidth] = useState(DEFAULT_WIDTH);
  const { activeNoInteractionMode } = useZonesContext();
  const [position, setPosition] = useState({
    x: parentSize.width - DEFAULT_WIDTH,
    y: 0,
  });
  const { width } = useWindowDimensions();

  const defaultCaategories =
    user?.organization.setup?.categories || existedCategories;

  const { data: mainCourantes } = useQueryMainCourantes(
    user?.teams && user?.teams.length > 0 ? user?.teams[0].id : undefined,
  );

  const mainCourantesOptions = useMemo(() => {
    return mainCourantes
      ? mainCourantes.map(mainCourante => ({
          value: mainCourante.id,
          label: mainCourante.name,
        }))
      : [];
  }, [mainCourantes]);

  const zoneOptions = useMemo(() => {
    return zones
      ? zones.map(zone => {
          return {
            value: zone.id as string,
            label: zone.properties?.name as string,
          };
        })
      : [];
  }, [zones]);

  const basicFilterForm = useForm<BasicFilterFormData>();
  const advancedFilterForm = useForm<AdvancedFilterFormData>();

  const onSubmitBasicFilter = (data: BasicFilterFormData) => {
    updateFilters({
      ...filters,
      categories: data.categories,
      relevancies: data.relevancies,
      location: data.location,
      mainCourante: data.mainCourante,
    });
  };

  const onSubmitAdvancedFilter = (data: AdvancedFilterFormData) => {
    updateFilters({
      ...filters,
      start_date: data.start_date,
      end_date: data.end_date,
      any_terms: data.any_terms,
      all_terms: data.all_terms,
      exclude_terms: data.exclude_terms,
      zones: data.zones,
    });
  };

  const clearFilters = () => {
    updateFilters(defaultFilters);
    basicFilterForm.setValue('categories', defaultFilters.categories);
    basicFilterForm.setValue('relevancies', defaultFilters.relevancies);
    basicFilterForm.setValue('location', defaultFilters.location);
    basicFilterForm.setValue('mainCourante', defaultFilters.mainCourante);
    advancedFilterForm.setValue('start_date', defaultFilters.start_date);
    advancedFilterForm.setValue('end_date', defaultFilters.end_date);
    advancedFilterForm.setValue('any_terms', defaultFilters.any_terms);
    advancedFilterForm.setValue('all_terms', defaultFilters.all_terms);
    advancedFilterForm.setValue('exclude_terms', defaultFilters.exclude_terms);
    advancedFilterForm.setValue('zones', defaultFilters.zones);
  };

  const handleClose = () => {
    activeNoInteractionMode();
    updateFilterScreenOpen(false);
  };

  useEffect(() => {
    if (width < 1300) {
      setContainerWidth(220);
      return;
    }
    if (width < 1500) {
      setContainerWidth(245);
      return;
    }
    setContainerWidth(DEFAULT_WIDTH);
  }, [width]);

  useEffect(() => {
    setPosition({
      x: parentSize.width - DEFAULT_WIDTH,
      y: 0,
    });
  }, [parentSize, containerWidth]);
  if (!filterScreenOpen) return null;

  return (
    <Container
      cancel="input"
      bounds="parent"
      default={position}
      position={position}
      onDragStop={(e: RndDragEvent, d: DraggableData) => {
        setPosition({ x: d.x, y: d.y });
      }}
      size={{
        width: containerWidth,
        height:
          parentSize.height < DEFAULT_HEIGHT
            ? parentSize.height
            : DEFAULT_HEIGHT,
      }}
      enableResizing={false}
    >
      <EventsFilterHeader onClosePress={handleClose} />
      <EventsFilterTypeSelector
        filterType={filterType}
        onAdvancedFilterPress={() => setFilterType(ADVANCED_FILTERS)}
        onBasicFilterPress={() => setFilterType(BASICS_FILTERS)}
      />

      <ContentContainer>
        {filterType === BASICS_FILTERS ? (
          <EventsFilterBasicFilters
            listOfCategories={defaultCaategories}
            filters={filters}
            onBlurFields={basicFilterForm.handleSubmit(onSubmitBasicFilter)}
            control={basicFilterForm.control}
            relevancesOptions={relevances}
            mainCourantesOptions={mainCourantesOptions}
          />
        ) : (
          <EventsFilterAdvancedFilters
            control={advancedFilterForm.control}
            filters={filters}
            onBlurFields={advancedFilterForm.handleSubmit(
              onSubmitAdvancedFilter,
            )}
            zoneOptions={zoneOptions}
            onCreateNewZone={newZoneId => {
              updateFilters({
                ...filters,
                zones: [newZoneId],
              });
              advancedFilterForm.setValue('zones', [newZoneId]);
            }}
          />
        )}
        <ClearButton onPress={clearFilters}>{t('clear_filters')}</ClearButton>
      </ContentContainer>
    </Container>
  );
};
