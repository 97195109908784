import { useState, useEffect } from 'react';
import { MainCouranteInfoOverviewTop } from '@molecules/MainCouranteInfoOverviewTop';
import { MainCouranteInfoOverviewInfos } from '@molecules/MainCouranteInfoOverviewInfos';
import {
  MainCouranteInfoOverviewInfosForm,
  UpdateMainCouranteFormData,
} from '@molecules/MainCouranteInfoOverviewInfosForm';
import { MainCouranteInfoOverviewActorBoard } from '@molecules/MainCouranteInfoOverviewActorBoard';
import { MainCouranteInfoOverviewSharedBoard } from '@molecules/MainCouranteInfoOverviewSharedBoard';
import { useCreateLocationContext } from '@contexts/CreateLocationContext';
import { Control } from 'react-hook-form';
import { api } from '../../../services/userManagementApi';

import { Container, BoardsContainer } from './styles';

interface MainCouranteInfoOverviewProps {
  id: string;
  formControl: Control<UpdateMainCouranteFormData>;
  actor_id?: string;
  name: string;
  intensity: number;
  location_name: string;
  date: string;
  formatted_date: string;
  closed: boolean;
  editMode: boolean;
  openCloseMainCouranteLoading: boolean;
  onCloseMainCourantePress: () => void;
  onOpenMainCourantePress: () => void;
}

export const MainCouranteInfoOverview = ({
  id,
  formControl,
  editMode,
  intensity,
  name,
  formatted_date,
  date,
  location_name,
  closed,
  actor_id,
  onCloseMainCourantePress,
  onOpenMainCourantePress,
  openCloseMainCouranteLoading,
}: MainCouranteInfoOverviewProps) => {
  const [authorAvatar, setAuthorAvatar] = useState<string>();
  const [authorName, setAuthorName] = useState<string>();

  const { setLocationZoneType, setCreateLocationOpened, setLocationId } =
    useCreateLocationContext();

  function handleClickOnPickerLocation() {
    setLocationId(id);
    setLocationZoneType('main_courante');
    setCreateLocationOpened(true);
  }

  useEffect(() => {
    async function getActorInfo(get_actor_id: string) {
      try {
        const response = await api(`/v1/public/users/${get_actor_id}`);
        if (response.data.avatar) {
          setAuthorAvatar(response.data.avatar);
          return;
        }
        if (response.data.name) {
          setAuthorName(response.data.name);
          return;
        }
      } catch {
        setAuthorAvatar(undefined);
        setAuthorName(undefined);
      }
    }
    if (actor_id) {
      getActorInfo(actor_id);
    }
  }, [actor_id]);

  return (
    <Container>
      <MainCouranteInfoOverviewTop
        intensity={intensity}
        closed={closed}
        openCloseLoading={openCloseMainCouranteLoading}
        onOpenMainCourantePress={onOpenMainCourantePress}
        onCloseMainCourantePress={onCloseMainCourantePress}
      />
      {editMode ? (
        <MainCouranteInfoOverviewInfosForm
          formControl={formControl}
          name={name}
          date={date}
          locationName={location_name}
          onPickLocationPress={handleClickOnPickerLocation}
        />
      ) : (
        <MainCouranteInfoOverviewInfos
          name={name}
          location_name={location_name}
          formatted_date={formatted_date}
        />
      )}

      <BoardsContainer>
        {authorAvatar ||
          (authorName && (
            <MainCouranteInfoOverviewActorBoard
              authorAvatar={authorAvatar}
              authorFallbackName={authorName}
            />
          ))}
        <MainCouranteInfoOverviewSharedBoard sharedUsers={[]} />
      </BoardsContainer>
    </Container>
  );
};
