import { H6 } from '@nucleus/Typography';
import { IconButton } from '@atoms/IconButton';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../assets/icons/close.svg';

import { Container } from './styles';

interface EventsFilterHeaderProps {
  onClosePress: () => void;
}

export const EventsFilterHeader = ({
  onClosePress,
}: EventsFilterHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <H6>{t('filters')}</H6>
      <IconButton icon={closeIcon} onPress={onClosePress} />
    </Container>
  );
};
