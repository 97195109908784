import styled from 'styled-components/native';
import { Caption } from '@nucleus/Typography';

export const Container = styled.View`
  display: flex;
  align-items: center;
  margin-top: 8px;
  flex-direction: row;
  width: 96%;
`;

export const Detail = styled.View`
  display: flex;
  margin-left: 8px;
  max-width: 90%;
`;

export const DetailInfo = styled.View`
  display: flex;
  flex-direction: row;
`;

export const SourceName = styled(Caption)`
  color: ${({ theme }) => theme.yellow};
  margin-left: 8px;

  background-color: 'red';
`;
