import styled from 'styled-components';
import { Rnd } from 'react-rnd';

export const Container = styled(Rnd)`
  z-index: 3;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px #05050f;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  padding-bottom: 16px;
`;

export const LeftContainer = styled.div`
  width: 50%;
  height: 100%;
  border-right: 1.5px solid ${({ theme }) => theme.background};
`;

export const RightContainer = styled.div`
  width: 50%;
  height: 100%;
  border-left: 1.5px solid ${({ theme }) => theme.background};
`;
