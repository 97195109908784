import styled from 'styled-components';
import styledNative from 'styled-components/native';
import DatePicker from 'react-datepicker';
import { ButtonPrimary } from '../Typography';

export const Container = styled.div`
  width: 95%;
  max-width: 272px;
  border-bottom: 2px solid ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  z-index: 20;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2px 6px 4px 4px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  top: 38px;
  flex-direction: row;
  position: absolute;
  color: ${({ theme }) => theme.red};
`;

export const ErrorText = styledNative(ButtonPrimary)`
  color: ${({ theme }) => theme.red};
  margin-left: 4px;
  font-weight: 400;
`;

export const ReactDatePicker = styled(DatePicker)`
  background: transparent;
  width: 100%;
  border: none;
  color: ${({ theme }) => theme.white};
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 400;
  font-family: Open Sans;
  letter-spacing: -0.044rem;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 1.4rem;
    color: ${({ theme }) => theme.purpleBlue};
    opacity: 1; /* Firefox */
  }
`;
