import { Image } from '@nucleus/Image';
import { Container, Text } from './styles';

interface SideMenuProfileAvatarProps {
  avatar?: string;
  fallbackName: string;
}

export const SideMenuProfileAvatar = ({
  avatar,
  fallbackName,
}: SideMenuProfileAvatarProps) => {
  function getInitials(name: string) {
    const names = name.split(' ');

    if (names.length > 1) {
      return `${names[0].substring(0, 1).toUpperCase()}${names[names.length - 1]
        .substring(0, 1)
        .toUpperCase()}`;
    }
    return names[0].substring(0, 1).toUpperCase();
  }

  return (
    <Container>
      {avatar ? (
        <Image source={avatar} />
      ) : (
        <Text>{getInitials(fallbackName)}</Text>
      )}
    </Container>
  );
};
