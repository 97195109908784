import { useRoute } from '@react-navigation/native';
import { SideMenuButton } from '@atoms/SideMenuButton';
import { SideMenuProfileAvatar } from '@atoms/SideMenuProfileAvatar';
import { Image } from '@nucleus/Image';
import logo from '../../../assets/logo.png';
import dashboardIcon2 from '../../../assets/icons/dashboard.svg';
import analyticsIcon2 from '../../../assets/icons/analytics.svg';
import logoutIcon2 from '../../../assets/icons/logout.svg';
import { Container } from './styles';

interface SideMenuProps {
  withAnalytics: boolean;
  onDashboardPress: () => void;
  onAnalyticsPress: () => void;
  onLogoutPress: () => void;
  userAvatar?: string;
  userName: string;
}

const SideMenu = ({
  withAnalytics,
  onDashboardPress,
  onAnalyticsPress,
  onLogoutPress,
  userAvatar,
  userName,
}: SideMenuProps) => {
  const route = useRoute();

  return (
    <Container>
      <section>
        <Image source={logo as string} style={{ marginTop: '1vh' }} />
      </section>
      <section>
        <SideMenuButton
          icon={dashboardIcon2}
          handlePress={onDashboardPress}
          isActive={route.name === 'Dashboard'}
        />
        {withAnalytics && (
          <SideMenuButton
            icon={analyticsIcon2}
            handlePress={onAnalyticsPress}
            isActive={route.name === 'Analytics'}
          />
        )}
      </section>
      <section
        style={{
          marginBottom: '9vh',
        }}
      >
        <SideMenuProfileAvatar avatar={userAvatar} fallbackName={userName} />
        <SideMenuButton icon={logoutIcon2} handlePress={onLogoutPress} />
      </section>
    </Container>
  );
};

export default SideMenu;
