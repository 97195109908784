import styled from 'styled-components/native';

export const Container = styled.View`
  align-items: center;
  justify-content: center;
  width: 20px;
`;

interface DirectionIconContainerProps {
  direction: number;
}

export const DirectionIconContainer = styled.View<DirectionIconContainerProps>`
  margin-top: 4px;
  align-items: center;
  transform: ${({ direction }) => `rotate(${direction}deg)`};
`;
