import SVG from 'react-inlinesvg';
import { MarkerView } from '@atoms/MakerView';

import {
  Container,
  MarkerContainer,
  Delta,
  CategoryColorContainer,
} from './styles';

interface EventsMapEventMarkerProps {
  id: string;
  icon?: string;
  color: string;
  latitude: number;
  longitude: number;
  size: number;
  onPress: () => void;
}

export const EventsMapEventMarker = ({
  id,
  icon,
  color,
  onPress,
  size,
  latitude,
  longitude,
}: EventsMapEventMarkerProps) => {
  return (
    <MarkerView
      id={id}
      latitude={latitude}
      longitude={longitude}
      offsetLeft={-(size / 2)}
      offsetTop={-size / 2 - size / 2 - 6}
    >
      <Container onPress={onPress}>
        <MarkerContainer size={size}>
          <CategoryColorContainer size={size} color={color}>
            {icon && (
              <SVG
                src={icon}
                width={size / 2.8}
                height="100%"
                fill="#fff"
                title="category_icon"
              />
            )}
          </CategoryColorContainer>
          <Delta size={size} />
        </MarkerContainer>
      </Container>
    </MarkerView>
  );
};
