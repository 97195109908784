import { Board } from '@atoms/Board';
import { Badge } from '@atoms/Badge';
import { UserAvatar } from '@atoms/UserAvatar';
import { Caption } from '@nucleus/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';

interface UserAvatar {
  authorAvatar?: string;
  authorFallbackName: string;
}

interface MainCouranteInfoOverviewSharedBoardProps {
  sharedUsers: UserAvatar[];
}

export const MainCouranteInfoOverviewSharedBoard = ({
  sharedUsers,
}: MainCouranteInfoOverviewSharedBoardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Board style={{ width: '55%', maxWidth: 1200 }}>
      <Badge
        text={String(sharedUsers.length)}
        color={theme.purple}
        style={{ position: 'absolute', right: -4, top: -4 }}
      />
      <Caption style={{ marginLeft: 12 }}>{t('shared_with')}</Caption>
      {sharedUsers.map(({ authorFallbackName, authorAvatar }) => (
        <UserAvatar
          key={authorAvatar || authorFallbackName}
          fallbackName={authorFallbackName}
          avatar={authorAvatar}
          style={{ alignSelf: 'center' }}
        />
      ))}
    </Board>
  );
};
