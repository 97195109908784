import { Hoverable } from 'react-native-web-hooks';
import { darken } from 'polished';
import { TouchableOpacity } from 'react-native';
import { Body1 } from '../../Typography';
import { Container, ButtonsContainer } from './styles';
import TrashIcon from '../../../assets/icons/trash.svg';
import EditIcon from '../../../assets/icons/edit.svg';
import DownloadIcon from '../../../assets/icons/download.svg';

interface DocumentProps {
  onDownload: () => void;
  onEdit: () => void;
  onDelete: () => void;
  title: string;
}

export const Document = ({
  title,
  onDelete,
  onEdit,
  onDownload,
}: DocumentProps) => {
  return (
    <Container>
      <Body1>{title}</Body1>
      <ButtonsContainer>
        <Hoverable>
          {isHovered => (
            <TouchableOpacity onPress={onEdit}>
              <EditIcon
                style={{ marginRight: 8 }}
                fill={isHovered ? darken(0.2, '#ffff') : '#ffff'}
              />
            </TouchableOpacity>
          )}
        </Hoverable>
        <Hoverable>
          {isHovered => (
            <TouchableOpacity onPress={onDelete}>
              <TrashIcon
                style={{ marginRight: 8 }}
                fill={isHovered ? darken(0.2, '#ffff') : '#ffff'}
              />
            </TouchableOpacity>
          )}
        </Hoverable>
        <Hoverable>
          {isHovered => (
            <TouchableOpacity onPress={onDownload}>
              <DownloadIcon
                fill="transparent"
                stroke={isHovered ? darken(0.2, '#ffff') : '#ffff'}
              />
            </TouchableOpacity>
          )}
        </Hoverable>
      </ButtonsContainer>
    </Container>
  );
};
