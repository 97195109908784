import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';
import { Caption } from '@nucleus/Typography';
import { Board } from '@atoms/Board';
import { Badge } from '@atoms/Badge';
import { Spinner } from '@atoms/Spinner';
import { UserAvatar } from '@atoms/UserAvatar';

interface UserAvatar {
  authorAvatar?: string;
  authorFallbackName: string;
}

interface EventInfoOverviewActorsBoardProps {
  isLoading: boolean;
  actors: UserAvatar[];
}

export const EventInfoOverviewActorsBoard = ({
  isLoading,
  actors,
}: EventInfoOverviewActorsBoardProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Board style={{ height: 100 }}>
      <Caption style={{ marginLeft: 12 }}>{t('actors_concerned')}</Caption>
      {isLoading ? (
        <Spinner color={theme.purple} size={20} />
      ) : (
        <>
          <Badge
            text={String(actors.length)}
            color={theme.purple}
            style={{ position: 'absolute', right: -4, top: -4 }}
          />
          {actors.map(({ authorFallbackName, authorAvatar }) => (
            <UserAvatar
              key={authorAvatar || authorFallbackName}
              fallbackName={authorFallbackName}
              avatar={authorAvatar}
              style={{ alignSelf: 'center' }}
            />
          ))}
        </>
      )}
    </Board>
  );
};
