import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';
import { Body2, ButtonPrimary } from '@nucleus/Typography';

export const Container = styled.View`
  width: 95%;
  max-width: 272px;
  border-bottom-width: 2px;
  border-color: ${({ theme }) => theme.white};
  padding: 4px;
`;

export const ErrorContainer = styled.View`
  top: 36px;
  left: 0;
  flex-direction: row;
  position: absolute;
`;

export const ErrorText = styled(ButtonPrimary)`
  color: ${({ theme }) => theme.red};
  margin-left: 4px;
  font-weight: 400;
`;

export const InputContainer = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2px;
`;

interface IconsContainerProps {
  error?: boolean;
  hasFocus?: boolean;
  withIcon?: boolean;
}
export const IconsContainer = styled.View<IconsContainerProps>`
  flex-direction: row;
  justify-content: ${({ error, withIcon }) => {
    if (error && withIcon) {
      return 'space-between';
    }
    if (error || withIcon) {
      return 'flex-end';
    }
    return 'flex-end';
  }};
  align-items: center;
  padding-left: 5px;

  width: ${({ error, withIcon }) => {
    if (error && withIcon) {
      return '20%';
    }
    if (error || withIcon) {
      return '10%';
    }
    return '0';
  }};
`;

export const Label = styled(Body2)`
  color: ${({ theme }) => theme.white};
`;

interface InputProps {
  error?: boolean;
  hasFocus?: boolean;
  withIcon?: boolean;
}

export const InputText = styled.TextInput<InputProps>`
  width: ${({ error, withIcon }) => {
    if (Platform.OS === 'web') {
      return '100%';
    }
    if (error && withIcon) {
      return '80%';
    }
    if (error || withIcon) {
      return '90%';
    }
    return '100%';
  }};
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.4rem' : '14px'};
  line-height: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.044rem' : '-0.44px'};
  color: ${({ theme }) => theme.white};

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.red};
    `}

  ${({ hasFocus }) =>
    hasFocus &&
    css`
      color: ${({ theme }) => theme.white};
    `}
`;
