import { IntensityView } from '@atoms/IntensityView';
import { OpenCloseButton } from '@atoms/OpenCloseButton';
import { Container, LeftContainer, RigthContainer } from './styles';

interface MainCouranteCardHeaderProps {
  intensity: number;
  closed: boolean;
}

export const MainCouranteCardHeader = ({
  intensity,
  closed,
}: MainCouranteCardHeaderProps) => {
  return (
    <Container>
      <LeftContainer>
        <IntensityView intensity={intensity} />
        <OpenCloseButton closed={closed} />
      </LeftContainer>
      <RigthContainer />
    </Container>
  );
};
