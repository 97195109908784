import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;
