import { useState, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@atoms/Spinner';
import { TabPane } from 'rc-tabs';
import { EditableConfig } from 'rc-tabs/lib/interface';
import { EditableText } from '@atoms/EditableText';
import { useQueryTabs } from '@services/hooks/useQueryTabs';
import { useTabsControlContext } from '@contexts/TabsControlContext';
import { EventsTabActions } from '@organisms/EventsTabActions';
import { ConfirmCancelModal } from '@molecules/ConfirmCancelModal';
import { Icon } from '@nucleus/Icon';
import moreIcon from '../../../assets/icons/more.svg';
import closeIcon from '../../../assets/icons/close.svg';
import plusIcon from '../../../assets/icons/plus.svg';
import { EventTab } from './styles';

interface EventsTabsProps {
  children: ReactNode;
}

export const EventsTabs = ({ children }: EventsTabsProps) => {
  const { t } = useTranslation();
  const [closeTabModalOpened, setCloseTabModalOpened] = useState(false);
  const [closeModalTabKey, setCloseModalTabKey] = useState<string>();

  const {
    selectedTab,
    updateSelectedTab,
    createNewTab,
    removeTab,
    changeTabName,
  } = useTabsControlContext();

  const { data: tabs, isLoading } = useQueryTabs();

  async function changeName(name: string, tabKey: string) {
    await changeTabName(tabKey, name);
  }

  async function onChange(key: string) {
    await updateSelectedTab(key);
  }

  function onCancelRemove() {
    setCloseTabModalOpened(false);
  }

  async function onConfirmRemove(key?: string) {
    if (!key) return;
    if (!tabs) return;

    const totalTabs = tabs.length;
    const removeTabIndex = tabs.findIndex(
      (tab: { id: string }) => tab.id === key,
    );
    if (totalTabs === 1) {
      await removeTab(key);
      setCloseTabModalOpened(false);
      setCloseModalTabKey(undefined);
      return;
    }
    if (totalTabs - 1 === removeTabIndex) {
      const nextTab = tabs[removeTabIndex - 1].id;
      await removeTab(key);
      await updateSelectedTab(nextTab);
      setCloseTabModalOpened(false);
      setCloseModalTabKey(undefined);
      return;
    }
    const nextTab = tabs[removeTabIndex + 1].id;
    await removeTab(key);
    await updateSelectedTab(nextTab);
    setCloseTabModalOpened(false);
    setCloseModalTabKey(undefined);
  }

  async function handleRemove(key: string) {
    setCloseModalTabKey(key);
    setCloseTabModalOpened(true);
  }

  async function handleAdd() {
    await createNewTab();
  }

  const editable: EditableConfig = {
    onEdit: (action, e) => {
      if (action === 'add') {
        handleAdd();
      } else {
        handleRemove(String(e.key));
      }
    },
    removeIcon: (
      <Icon as={closeIcon} width={20} height={20} viewBox="0 0 30 30" />
    ),
    addIcon: (
      <Icon
        as={plusIcon}
        width={20}
        height={20}
        fill="red"
        viewBox="0 0 24 24"
      />
    ),
    showAdd: true,
  };

  useEffect(() => {
    async function createFirstTab() {
      await createNewTab();
    }

    if (tabs) {
      if (tabs.length === 0) {
        createFirstTab();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  if (isLoading) {
    <Spinner />;
  }

  return (
    <>
      <EventTab
        editable={editable}
        activeKey={selectedTab}
        moreIcon={
          <Icon
            as={moreIcon}
            width={16}
            height={16}
            fill="red"
            viewBox="0 0 24 24"
          />
        }
        onChange={onChange}
        tabBarExtraContent={{
          right: <EventsTabActions />,
        }}
      >
        {tabs &&
          tabs.map(tab => (
            <TabPane
              key={tab.id}
              tab={
                <EditableText
                  text={tab.name}
                  onChangeText={newText => changeName(newText, tab.id)}
                />
              }
            >
              {children}
            </TabPane>
          ))}
      </EventTab>
      <ConfirmCancelModal
        isVisible={closeTabModalOpened}
        title={t('close_tab_modal_title')}
        onCancel={onCancelRemove}
        onConfirm={() => onConfirmRemove(closeModalTabKey)}
      />
    </>
  );
};
