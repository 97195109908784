/* eslint-disable consistent-return */

import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { queryClient } from '../queryClient';
import { useToastContext } from '../../contexts/ToastContext';
import {
  createZone,
  deleteZone,
  updateZoneData,
  Zone,
} from '../../utils/zoneApi';

interface MutationData {
  newZone?: Zone;
  zoneId?: string;
  type: 'create' | 'delete' | 'update';
  updateZone?: Partial<Zone>;
}

export const useMutationZones = () => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({ newZone, type, zoneId, updateZone }: MutationData) => {
      if (type === 'create' && newZone) {
        const result = await createZone(newZone);
        return { result, type };
      }
      if (type === 'delete' && zoneId) {
        const result = await deleteZone(zoneId);
        return { result, type };
      }
      if (type === 'update' && zoneId && updateZone) {
        const result = await updateZoneData(zoneId, updateZone);
        return { result, type };
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['zones']);
      },
      onError: () => {
        dispatchToast({
          message: t('error_create_zone'),
          type: 'error',
        });
      },
    },
  );
};
