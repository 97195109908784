import { TimeLabel } from '@atoms/TimeLabel';
import { Body2 } from '@nucleus/Typography';
import { Container, SourceText } from './styles';

interface EventInfoDetailInfosProps {
  date: string;
  desc: string;
  sourceName?: string;
}

export const EventInfoDetailInfos = ({
  date,
  desc,
  sourceName,
}: EventInfoDetailInfosProps) => {
  return (
    <Container>
      <TimeLabel formattedDate={date} style={{ marginTop: 8 }} />
      <Body2 style={{ marginTop: 8 }}>{desc}</Body2>
      {sourceName && <SourceText>{`Source: ${sourceName}`}</SourceText>}
    </Container>
  );
};
