import styled, { css } from 'styled-components/native';
import { transparentize } from 'polished';
import { Caption, Body2, ButtonPrimary } from '@nucleus/Typography';
import { NativeMethods } from 'react-native';

export const Container = styled.View`
  display: inline-block;
  position: relative;
`;

export const Label = styled(Caption)`
  color: ${({ theme }) => theme.purpleBlue};
`;

export const ErrorContainer = styled.View`
  margin-top: 4px;
  flex-direction: row;
  position: absolute;
`;

export const ErrorText = styled(ButtonPrimary)`
  color: ${({ theme }) => theme.red};
  margin-left: 4px;
  font-weight: 400;
`;

export const DropDownButton = styled.TouchableOpacity`
  font-size: 16px;
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
`;

interface DropDownMasterWraperProps {
  parentElement: HTMLElement | NativeMethods;
}

export const DropDownMasterWraper = styled.View<DropDownMasterWraperProps>`
  ${({ parentElement }) => {
    if (parentElement instanceof HTMLElement) {
      const { top, left, width } = parentElement.getBoundingClientRect();
      return css`
        left: ${left};
        top: ${top + 24};
        width: ${width};
      `;
    }
    return css``;
  }};
  width: auto;
  position: absolute;
  background: #212337;
  max-height: 300px;
  overflow: auto;
  border-width: 1px;
  border-color: #6a6a9f;
`;

export const DropDownItem = styled.TouchableOpacity`
  position: relative;
  padding: 8px;
  width: auto;
  height: 30px;
  justify-content: flex-start;
  align-items: center;
  border-bottom-width: 1px;
  border-color: #6a6a9f;
  flex-direction: row;
  z-index: 2;
`;

export const DropDownItemOverlay = styled.View`
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${transparentize(0.73, '#C4C4C4')};
`;

export const DropDownItemText = styled(Caption)`
  width: auto;
`;

export const Placeholder = styled(Body2)`
  color: ${({ theme }) => theme.purpleBlue};
`;
