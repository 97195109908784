import styled, { css } from 'styled-components/native';

interface ContainerProps {
  small?: boolean;
}

export const Container = styled.View<ContainerProps>`
  height: 158px;
  width: 48%;
  max-width: 480px;
  border-radius: 20px;
  padding: 16px;
  background-color: ${({ theme }) => theme.background};
  ${({ small }) =>
    small &&
    css`
      height: 128px;
      width: 42%;
    `}
`;

export const WindsContainer = styled.View<ContainerProps>`
  flex-direction: row;
  justify-self: flex-start;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ small }) => (small ? '8px' : '16px')};
`;
