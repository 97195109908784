import styled, { css } from 'styled-components/native';
import { TouchableOpacity } from 'react-native';
import { darken } from 'polished';
import { Body1 } from '@nucleus/Typography';

interface ContainerProps {
  isActive?: boolean;
}

export const Container = styled(TouchableOpacity)<ContainerProps>`
  width: 82px;
  justify-content: center;
  align-items: center;
  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom-width: 3px;
      border-color: ${({ theme }) => theme.blue};
    `}
`;

interface TextProps {
  isHovered?: boolean;
  isActive?: boolean;
}

export const Text = styled(Body1)<TextProps>`
  ${({ isActive, isHovered, theme }) => {
    if (isActive && isHovered) {
      return css`
        color: ${darken(0.1, theme.white)};
      `;
    }
    if (isActive) {
      return css`
        color: ${theme.white};
      `;
    }
    if (isHovered) {
      return css`
        color: ${darken(0.1, theme.purpleBlue)};
      `;
    }
    return css`
      color: ${theme.purpleBlue};
    `;
  }};
`;
