import { ActionButton } from '@atoms/ActionButton';
import { useTheme } from 'styled-components/native';
import createPolygonIcon from '../../../assets/icons/create_polygon.svg';
import createLineIcon from '../../../assets/icons/create_line.svg';
import createPointIcon from '../../../assets/icons/bx-map.svg';
import { Container } from './styles';

interface CreateLocationCustomAddressDrawModeSelectorProps {
  onSelectPolygonModePress: () => void;
  onSelectLineModePress: () => void;
  onSelectPointPress: () => void;
}

export const CreateLocationCustomAddressDrawModeSelector = ({
  onSelectPolygonModePress,
  onSelectLineModePress,
  onSelectPointPress,
}: CreateLocationCustomAddressDrawModeSelectorProps) => {
  const theme = useTheme();
  return (
    <Container>
      <ActionButton
        icon={createPolygonIcon}
        onPress={onSelectPolygonModePress}
        backgroundColor={theme.purpleBlue}
        iconColor={theme.white}
        size="medium"
      />
      <ActionButton
        icon={createLineIcon}
        onPress={onSelectLineModePress}
        backgroundColor={theme.purpleBlue}
        iconColor={theme.white}
        size="medium"
      />
      <ActionButton
        icon={createPointIcon}
        onPress={onSelectPointPress}
        backgroundColor={theme.purpleBlue}
        iconColor={theme.white}
        size="medium"
      />
    </Container>
  );
};
