import React, { useRef } from 'react';
import { useHover } from 'react-native-web-hooks';

import { Container, Text } from './styles';

interface EventsFilterTypeSelectorButtonProps {
  name: string;
  isActive?: boolean;
  onPress: () => void;
}

export const EventsFilterTypeSelectorButton = ({
  name,
  isActive,
  onPress,
}: EventsFilterTypeSelectorButtonProps) => {
  const buttonRef = useRef(null);
  const isHovered = useHover(buttonRef);
  return (
    <Container ref={buttonRef} onPress={onPress} isActive={isActive}>
      <Text isActive={isActive} isHovered={isHovered}>
        {name}
      </Text>
    </Container>
  );
};
