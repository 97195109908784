import 'react-native-gesture-handler';
import 'react-native-get-random-values';
import { AppRegistry, Platform, LogBox } from 'react-native';
import { App } from './App';
import { name as appName } from '../app.json';

LogBox.ignoreLogs(['Constants.manifest', 'Possible Unhandled Promise']);

AppRegistry.registerComponent(appName, () => App);
if (Platform.OS === 'web') {
  AppRegistry.runApplication(appName, {
    rootTag: document.getElementById('root'),
  });
}
