import React from 'react';
import { useTheme } from 'styled-components';
import { Container, CategoryName } from './styles';

export const MainCouranteCategory = () => {
  const theme = useTheme();
  return (
    <Container gradient={theme.linearYellow}>
      <CategoryName>MAIN COURANTE</CategoryName>
    </Container>
  );
};
