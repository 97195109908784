import { useTheme } from 'styled-components/native';
import { Icon } from '@nucleus/Icon';
import { MarkerView } from '@atoms/MakerView';
import mainCouranteIcon from '../../../assets/icons/main_courante_icon.svg';
import {
  Container,
  MarkerContainer,
  Delta,
  CategoryColorContainer,
} from './styles';

interface EventsMapMainCouranteMakerProps {
  id: string;
  latitude: number;
  longitude: number;
  size: number;
  onPress: () => void;
}

export const EventsMapMainCouranteMaker = ({
  id,
  latitude,
  longitude,
  onPress,
  size,
}: EventsMapMainCouranteMakerProps) => {
  const theme = useTheme();
  return (
    <MarkerView
      id={id}
      latitude={latitude}
      longitude={longitude}
      offsetLeft={-(size / 2)}
      offsetTop={-size / 2 - size / 2 - 6}
    >
      <Container onPress={onPress}>
        <MarkerContainer size={size}>
          <CategoryColorContainer size={size} gradient={theme.linearYellow}>
            <Icon
              as={mainCouranteIcon}
              width={size / 2.8}
              fill="#fff"
              viewBox="0 0 16 26"
            />
          </CategoryColorContainer>
          <Delta size={size} />
        </MarkerContainer>
      </Container>
    </MarkerView>
  );
};
