/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ActivityIndicator } from 'react-native';
import { getMinutes, getHours, setMinutes, setHours } from 'date-fns';
import {
  Container,
  SideBySide,
  ConfirmButton,
  CloseMainCouranteContainer,
  CheckBoxContainer,
  YesText,
  NoText,
  CreateTypeButton,
  CreateTypeButtonContainer,
} from './styles';
import { Body1 } from '../../Typography';
import { Input } from '../../Input';
import { DatePicker } from '../../DatePicker';
import { TimePicker } from '../../TimePicker';
import { Select } from '../../Select';
import { MultiLineInput } from '../../MultiLineInput';
import { AttachmentsInput } from '../../AttachmentsInput';
import { CheckBox } from '../../CheckBox';
import { useQueryActionTypes } from '../../../services/hooks/useQueryActionTypes';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useToastContext } from '../../../contexts/ToastContext';
import { api } from '../../../services/eventApi';
import { queryClient } from '../../../services/queryClient';
import { useCreateLocationContext } from '../../../contexts/CreateLocationContext';
import { CreateLocationButton } from '../CreateLocationButton';

interface NewActionProps {
  newActionId: string;
  openCreateTypeModal: () => void;
  seletedEvent: { name: string; id: string; team_id: string };
  onClose: () => void;
}

interface Location {
  location_color?: string;
  location_name?: string;
  location_coordinates: any;
}

interface CreateActionFormData {
  title: string;
  desc: string;
  date: Date;
  time: Date;
  location: Location;
  action: number;
  media: string[];
}

export const NewAction = ({
  newActionId,
  onClose,
  openCreateTypeModal,
  seletedEvent,
}: NewActionProps) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();
  const { user } = useAuthContext();

  const {
    setCreateLocationOpened,
    selectedLocation,
    setLocationId,
    setLocationZoneType,
    clearLocation,
  } = useCreateLocationContext();

  const [toClose, setToClose] = useState(false);

  const createActionFormValidation = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t('name_required')),
        time: yup.date().required(t('time_required')),
        date: yup.date().required(t('date_required')),
        desc: yup.string(),
        location: yup.object().shape({
          location_name: yup.string(),
          location_coordinates: yup.object().shape({
            type: yup.string(),
            coordinates: yup.mixed().when('type', {
              is: 'Point',
              then: yup.array().of(yup.number()),
              otherwise: yup.mixed().when('type', {
                is: 'LineString',
                then: yup.array().of(yup.array(yup.number())),
                otherwise: yup
                  .array()
                  .of(yup.array().of(yup.array().of(yup.number()))),
              }),
            }),
          }),
        }),
        action: yup.number().required(t('action_required')),
      }),
    [t],
  );

  const { data: actions, isLoading } = useQueryActionTypes(user?.teams[0].id);

  const actionsOption = useMemo(() => {
    return actions
      ? actions.map(action => ({
          value: String(action.id),
          label: action.name,
        }))
      : [];
  }, [actions]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CreateActionFormData>({
    resolver: yupResolver(createActionFormValidation),
  });

  const onSubmit = async ({
    title,
    desc,
    date,
    time,
    location,
    action,
    media,
  }: CreateActionFormData) => {
    if (!user) return;
    const hour = getHours(time);
    const minute = getMinutes(time);
    let dateWithTime = setMinutes(date, minute);
    dateWithTime = setHours(dateWithTime, hour);
    try {
      await api.post('/v1/main_courante_actions', {
        id: newActionId,
        title,
        desc,
        date: dateWithTime,
        location_color: location.location_color,
        location_name: location.location_name,
        location_coordinates: location.location_coordinates,
        action_type_id: action,
        main_courante_id: seletedEvent.id,
        media,
        closed: toClose,
        user_id: user.id,
      });
      dispatchToast({
        message: 'Action created',
        type: 'success',
      });
      queryClient.invalidateQueries(['mainCourante', seletedEvent.id]);
      onClose();
    } catch (err) {
      dispatchToast({
        message: 'Error on create action',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      setValue('location', {
        location_color: selectedLocation.location_color,
        location_name: selectedLocation.location_name,
        location_coordinates: selectedLocation.zone.geometry,
      });
    }
  }, [selectedLocation, setValue]);

  useEffect(() => {
    if (newActionId && setLocationId) {
      setLocationId(`${newActionId}_creating`);
      setLocationZoneType('main_courante_action');
    }
  }, [newActionId, setLocationId, setLocationZoneType]);

  useEffect(() => {
    return () => {
      clearLocation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      {isLoading ? (
        <ActivityIndicator />
      ) : (
        <>
          <Body1 style={{ marginTop: 8 }}>{`${t('selected_main_courante')}: ${
            seletedEvent?.name || ''
          }`}</Body1>
          <SideBySide style={{ zIndex: 3 }}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  value={value}
                  onChangeText={onChange}
                  placeholder={t('type_action_title')}
                  style={{ maxWidth: 275 }}
                  error={errors.title?.message}
                />
              )}
              name="title"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  style={{ maxWidth: 200 }}
                  placeholder={t('date')}
                  date={value}
                  onChange={onChange}
                  error={errors.date?.message}
                />
              )}
              defaultValue={new Date()}
              name="date"
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <TimePicker
                  placeholder={t('hour')}
                  onChange={onChange}
                  value={value ? moment(value) : undefined}
                  error={errors.time?.message}
                />
              )}
              defaultValue={new Date()}
              name="time"
            />
          </SideBySide>
          <SideBySide style={{ zIndex: 2, marginTop: 24 }}>
            <CreateLocationButton
              selectedLocation={selectedLocation}
              setCreateLocationOpened={setCreateLocationOpened}
              error={errors.location && t('location_required')}
            />
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  style={{ maxWidth: 200, zIndex: 1 }}
                  placeholder={t('type_of_action')}
                  options={actionsOption}
                  selectedOptions={value ? [String(value)] : []}
                  onChange={acts => onChange(Number(acts[0]))}
                  error={errors.action?.message}
                />
              )}
              name="action"
            />
            <CreateTypeButtonContainer>
              <CreateTypeButton onPress={openCreateTypeModal}>
                {t('create_a_type')}
              </CreateTypeButton>
            </CreateTypeButtonContainer>
          </SideBySide>
          <SideBySide style={{ marginTop: 24 }}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <MultiLineInput
                  style={{ maxWidth: 550, width: '60%' }}
                  label={t('more_information')}
                  placeholder={t('type_additional_information')}
                  value={value}
                  onChangeText={onChange}
                  error={errors.desc?.message}
                />
              )}
              name="desc"
            />
            {newActionId && (
              <Controller
                control={control}
                render={({ field: { onChange } }) => (
                  <AttachmentsInput
                    label={t('media')}
                    teamId={seletedEvent.team_id}
                    eventId={newActionId}
                    onChange={onChange}
                  />
                )}
                name="media"
              />
            )}
          </SideBySide>
          <SideBySide style={{ alignItems: 'center' }}>
            <CloseMainCouranteContainer>
              <Body1>{t('close_main_courante')}</Body1>
              <CheckBoxContainer>
                <CheckBox
                  value={toClose}
                  onValueChange={() => setToClose(true)}
                />
                <YesText>{t('yes')}</YesText>
              </CheckBoxContainer>
              <CheckBoxContainer>
                <CheckBox
                  value={!toClose}
                  onValueChange={() => setToClose(false)}
                />
                <NoText>{t('no')}</NoText>
              </CheckBoxContainer>
            </CloseMainCouranteContainer>
            <ConfirmButton
              onPress={handleSubmit(onSubmit)}
              loading={isSubmitting}
            >
              {t('confirm')}
            </ConfirmButton>
          </SideBySide>
        </>
      )}
    </Container>
  );
};
