import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@contexts/ToastContext';
import { queryClient } from '@services/queryClient';
import { api } from '@services/userManagementApi';

interface DeleteWidgetMutation {
  page_id: string;
}

export async function deleteWidget(widget_id: string) {
  await api.delete(`/v1/widgets/${widget_id}`);
}

export const useMutationDeleteWidget = ({ page_id }: DeleteWidgetMutation) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async (widget_id: string) => {
      await deleteWidget(widget_id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['page', page_id]);
        dispatchToast({
          message: t('success_delete_page'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_delete_page'),
          type: 'error',
        });
      },
    },
  );
};
