import { useMemo } from 'react';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { AnalyticsTabsDynamicDataCardTitle } from '@molecules/AnalyticsTabsDynamicDataCardTitle';
import { AnalyticsTabsDynamicDataCardValue } from '@molecules/AnalyticsTabsDynamicDataCardValue';
import { Tooltip } from '@atoms/Tooltip';
import { useWindowDimensions } from 'react-native';
import { AnalyticsTabTooltipContent } from '@molecules/AnalyticsTabTooltipContent';
import { Container, HydrometeorologiesContainer } from './styles';
import hydrometeorologyIcon from '../../../assets/icons/hydrometeorology.svg';

interface Data {
  unit: string;
  data: string;
  source: string;
  name: string;
  lastUpdateFormatted: string;
  sourceUrl: string;
}

interface AnalyticsTabsDynamicDataHydrometeorologyCardProps {
  humidity?: Data;
  pressure?: Data;
  visibility?: Data;
  maxAccumulationHour?: Data;
  dayAccumulation?: Data;
}

export const AnalyticsTabsDynamicDataHydrometeorologyCard = ({
  humidity,
  pressure,
  visibility,
  maxAccumulationHour,
  dayAccumulation,
}: AnalyticsTabsDynamicDataHydrometeorologyCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { width } = useWindowDimensions();

  const isSmall = useMemo(() => width < 1700, [width]);

  return (
    <Container small={isSmall}>
      <AnalyticsTabsDynamicDataCardTitle
        icon={hydrometeorologyIcon}
        title={t('hydrometeorology')}
      />

      <HydrometeorologiesContainer small={isSmall}>
        {maxAccumulationHour && (
          <Tooltip
            content={
              <AnalyticsTabTooltipContent
                text={`Source: ${maxAccumulationHour.source}`}
                lastUpdateFormatted={maxAccumulationHour.lastUpdateFormatted}
              />
            }
            place="bottom"
          >
            <AnalyticsTabsDynamicDataCardValue
              sourceUrl={maxAccumulationHour.sourceUrl}
              color={theme.purple}
              value={maxAccumulationHour.data}
              unit={maxAccumulationHour.unit}
              small={isSmall}
              topLabel={t('maximum_accumulation_hour').toUpperCase()}
            />
          </Tooltip>
        )}

        {dayAccumulation && (
          <Tooltip
            content={
              <AnalyticsTabTooltipContent
                text={`Source: ${dayAccumulation.source}`}
                lastUpdateFormatted={dayAccumulation.lastUpdateFormatted}
              />
            }
            place="bottom"
          >
            <AnalyticsTabsDynamicDataCardValue
              sourceUrl={dayAccumulation.sourceUrl}
              color={theme.purple}
              value={dayAccumulation.data}
              unit={dayAccumulation.unit}
              small={isSmall}
              topLabel={t('day_accumulation').toUpperCase()}
            />
          </Tooltip>
        )}

        {humidity && (
          <Tooltip
            content={
              <AnalyticsTabTooltipContent
                text={`Source: ${humidity.source}`}
                lastUpdateFormatted={humidity.lastUpdateFormatted}
              />
            }
            place="bottom"
          >
            <AnalyticsTabsDynamicDataCardValue
              sourceUrl={humidity.sourceUrl}
              color={theme.purple}
              value={humidity.data}
              unit={humidity.unit}
              small={isSmall}
              topLabel={t('humidity').toUpperCase()}
            />
          </Tooltip>
        )}

        {visibility && (
          <Tooltip
            content={
              <AnalyticsTabTooltipContent
                text={`Source: ${visibility.source}`}
                lastUpdateFormatted={visibility.lastUpdateFormatted}
              />
            }
            place="bottom"
          >
            <AnalyticsTabsDynamicDataCardValue
              sourceUrl={visibility.sourceUrl}
              color={theme.purple}
              value={visibility.data}
              unit={visibility.unit}
              small={isSmall}
              topLabel={t('visibility').toUpperCase()}
            />
          </Tooltip>
        )}

        {pressure && (
          <Tooltip
            content={
              <AnalyticsTabTooltipContent
                text={`Source: ${pressure.source}`}
                lastUpdateFormatted={pressure.lastUpdateFormatted}
              />
            }
            place="bottom"
          >
            <AnalyticsTabsDynamicDataCardValue
              sourceUrl={pressure.sourceUrl}
              color={theme.purple}
              value={pressure.data}
              unit={pressure.unit}
              small={isSmall}
              topLabel={t('pressure').toUpperCase()}
            />
          </Tooltip>
        )}
      </HydrometeorologiesContainer>
    </Container>
  );
};
