import { TimeLabel } from '@atoms/TimeLabel';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';

interface EventCardFooterProps {
  updatedFormattedDate: string;
}

export const EventCardFooter = ({
  updatedFormattedDate,
}: EventCardFooterProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <TimeLabel
        prefix={t('last_updated')}
        formattedDate={updatedFormattedDate}
        withIcon
      />
    </Container>
  );
};
