import styled from 'styled-components/native';

export const Container = styled.View`
  margin-top: 8px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const LeftContainer = styled.View`
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const RigthContainer = styled.View`
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-right: 12px;
`;
