/* eslint-disable import/no-duplicates */
import fr from 'date-fns/locale/fr';
import enUS from 'date-fns/locale/en-US';
import * as RNLocalize from 'react-native-localize';

const locales = {
  'fr-FR': fr,
  'en-US': enUS,
};

export const getDateFnsLocale = () => {
  const browserLocale = RNLocalize.getLocales()[0].languageTag as
    | 'fr-FR'
    | 'en-US';

  return locales[browserLocale] || undefined;
};
