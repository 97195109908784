import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@contexts/ToastContext';
import { queryClient } from '@services/queryClient';
import { api } from '@services/userManagementApi';

export interface WidgetConfig<C> {
  id: string;
  name: string;
  type_id: string;
  user_id: string;
  config: C;
}

interface WidgetData {
  page_id: string;
  type_id: string;
  config_id?: string;
  grid_left: number;
  grid_top: number;
  grid_width: number;
  grid_height: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

interface CreateWidgetMutation {
  page_id: string;
}

export async function createWidget(data: WidgetData) {
  const response = await api.post<WidgetData>(`/v1/widgets`, data);
  return response.data;
}

export const useMutationCreateWidget = ({ page_id }: CreateWidgetMutation) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async (data: WidgetData) => {
      return createWidget(data);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['page', page_id]);
        dispatchToast({
          message: t('success_create_widget'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_create_widget'),
          type: 'error',
        });
      },
    },
  );
};
