import { Caption } from '@nucleus/Typography';
import { TimeLabel } from '@atoms/TimeLabel';
import { Container } from './styles';

interface MainCouranteInfoDetailLocationAndTimeProps {
  location_name: string;
  date: string;
}

export const MainCouranteInfoDetailLocationAndTime = ({
  location_name,
  date,
}: MainCouranteInfoDetailLocationAndTimeProps) => {
  return (
    <Container>
      <Caption style={{ marginRight: 16 }} numberOfLines={1}>
        {location_name}
      </Caption>
      <TimeLabel formattedDate={date} />
    </Container>
  );
};
