import styled, { css } from 'styled-components/native';

export const Container = styled.View`
  position: absolute;
  height: 150px;
  width: 100%;
  max-width: 200px;
  flex-direction: row;
  background-color: ${({ theme }) => theme.purpleBlack};
  border: 1px solid ${({ theme }) => theme.purpleBlue};
`;

export const HourContainer = styled.ScrollView`
  height: 100%;
  width: 33%;
  border-right-style: solid;
  border-right-color: ${({ theme }) => theme.purpleBlue};
  border-right-width: 1px;
  overflow-y: scroll;
`;

export const MinuteContainer = styled.ScrollView`
  height: 100%;
  width: 33%;
  border-right-style: solid;
  border-right-color: ${({ theme }) => theme.purpleBlue};
  border-right-width: 1px;
  overflow-y: scroll;
`;

export const AmPmContainer = styled.View`
  height: 100%;
  width: 34%;
`;

interface ItemSelectorProps {
  isHovered?: boolean;
  isSelected?: boolean;
}

export const ItemSelector = styled.TouchableOpacity<ItemSelectorProps>`
  height: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;

  ${({ theme, isHovered, isSelected }) => {
    if (isHovered) {
      return css`
        background-color: ${theme.purpleBlue};
      `;
    }
    if (isSelected) {
      return css`
        background-color: ${theme.blue};
      `;
    }
    return css`
      background-color: transparent;
    `;
  }}
`;
