import React, { ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastProvider } from 'react-native-fast-toast';
import { SizeContextProvider } from './SizeContext';
import { AuthContextProvider } from './AuthContext';
import { ToastContextProvider } from './ToastContext';
import { TabsControlProvider } from './TabsControlContext';
import { EventsContextProvider } from './EventsContext';
import { FiltersContextProvider } from './FiltersContext';
import { CartographyContextProvider } from './CartographyContext';
import { ZonesContextProvider } from './ZonesContext';
import { PreviewContextProvider } from './PreviewContext';
import { CreateLocationContextProvider } from './CreateLocationContext';
import { ActivatedMainCouranteActionsContextProvider } from './ActivatedMainCouranteActions';
import { WidgetGridContextProvider } from './WidgetGridContext';

interface AppProviderProps {
  children: ReactNode;
}

const AppContextsProvider = ({ children }: AppProviderProps) => (
  <DndProvider backend={HTML5Backend}>
    <SizeContextProvider>
      <ToastProvider placement="bottom">
        <ToastContextProvider>
          <AuthContextProvider>
            <WidgetGridContextProvider>
              <PreviewContextProvider>
                <TabsControlProvider>
                  <EventsContextProvider>
                    <ZonesContextProvider>
                      <FiltersContextProvider>
                        <CartographyContextProvider>
                          <CreateLocationContextProvider>
                            <ActivatedMainCouranteActionsContextProvider>
                              {children}
                            </ActivatedMainCouranteActionsContextProvider>
                          </CreateLocationContextProvider>
                        </CartographyContextProvider>
                      </FiltersContextProvider>
                    </ZonesContextProvider>
                  </EventsContextProvider>
                </TabsControlProvider>
              </PreviewContextProvider>
            </WidgetGridContextProvider>
          </AuthContextProvider>
        </ToastContextProvider>
      </ToastProvider>
    </SizeContextProvider>
  </DndProvider>
);

export default AppContextsProvider;
