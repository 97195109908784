import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';
import { H6 } from '@nucleus/Typography';
import { Spinner } from '@atoms/Spinner';
import { EventInfoContentsTypeSelector } from '@molecules/EventInfoContentsTypeSelector';
import { EventInfoContentsPreview } from '@molecules/EventInfoContentsPreview';
import { SourceCount } from '@services/hooks/useQueryEventInfo';

import { Container, TagContainer } from './styles';

interface EventInfoContentsProps {
  isLoading: boolean;
  sources_count?: SourceCount[];
  onContentSelect: (contentId?: string) => void;
  selectedContent?: string;
}

export const EventInfoContents = ({
  isLoading,
  sources_count,
  onContentSelect,
  selectedContent,
}: EventInfoContentsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedSource, setSelectedSource] = useState('All');

  const contents = useMemo(() => {
    if (!sources_count) return [];
    const source = sources_count.find(
      sourceItem => sourceItem.name === selectedSource,
    );
    if (!source) return [];
    return source.contents || [];
  }, [selectedSource, sources_count]);

  useEffect(() => {
    if (sources_count) {
      if (contents.length > 0) {
        onContentSelect(contents[0].id);
        return;
      }
    }
    onContentSelect(undefined);
  }, [contents, onContentSelect, sources_count]);

  return (
    <Container>
      <H6>{t('additional_content')}</H6>
      {isLoading ? (
        <Spinner color={theme.purple} size="large" />
      ) : (
        sources_count && (
          <>
            <TagContainer>
              {sources_count.map(
                source =>
                  source.name !== null && (
                    <EventInfoContentsTypeSelector
                      text={t(source.name)}
                      badgeText={String(source.count)}
                      badgeColor={
                        source.name === 'All' ? theme.yellow : theme.purple
                      }
                      selected={source.name === selectedSource}
                      key={`source_detail_${source.name}`}
                      onPress={() => setSelectedSource(source.name)}
                    />
                  ),
              )}
            </TagContainer>
            {contents &&
              contents.map(content => (
                <EventInfoContentsPreview
                  key={content.id}
                  sourceAvatar={content.source_image || undefined}
                  onSelect={() => onContentSelect(content.id)}
                  isSelected={selectedContent === content.id}
                  title={content.text}
                  date={content.date}
                  sourceName={content.source_name || ''}
                />
              ))}
          </>
        )
      )}
    </Container>
  );
};
