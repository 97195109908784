import { TopMenuTextButton } from '@atoms/TopMenuTextButton';
import { Image } from '@nucleus/Image';
import { Icon } from '@nucleus/Icon';
import { useNavigation } from '@react-navigation/native';
import { SearchInput } from '@atoms/SearchInput';
import logo from '../../../assets/logo.png';
import alertIcon from '../../../assets/icons/alert.svg';
import perfilIcon from '../../../assets/icons/perfil.svg';
import { Container, Menu } from './styles';

const TopMenu = () => {
  const { navigate } = useNavigation();
  return (
    <Container>
      <Image source={logo as string} style={{ width: 72, height: 72 }} />

      <section>
        <SearchInput />
        <Menu>
          <TopMenuTextButton
            text="Workspaces"
            onPress={() => navigate('Workspaces')}
            isSelected
          />
        </Menu>
      </section>
      <section>
        <Icon as={alertIcon} />
        <Icon as={perfilIcon} style={{ marginLeft: 32 }} />
      </section>
    </Container>
  );
};

export default TopMenu;
