import { Caption } from '@nucleus/Typography';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

interface AnalyticsTabsDynamicDataCardVigilanceTooltipTextProps {
  message: string;
  source: string;
  lastUpdateFormatted: string;
}

export const AnalyticsTabsDynamicDataCardVigilanceTooltipText = ({
  message,
  source,
  lastUpdateFormatted,
}: AnalyticsTabsDynamicDataCardVigilanceTooltipTextProps) => {
  const { t } = useTranslation();
  return (
    <View>
      <Caption>
        {`${message} `}
        <Caption
          style={{ textDecorationLine: 'underline' }}
        >{`Source: ${source}`}</Caption>
      </Caption>
      <Caption>{`${t('last_updated')}: ${lastUpdateFormatted}`}</Caption>
    </View>
  );
};
