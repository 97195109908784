import { StyleProp, ViewStyle } from 'react-native';
import { Image } from '@nucleus/Image';
import { Container } from './styles';

interface InfoAvatarProps {
  style?: StyleProp<ViewStyle>;
  avatar?: string;
}

export const InfoAvatar = ({ style, avatar }: InfoAvatarProps) => {
  return (
    <Container style={style}>
      {avatar && (
        <Image
          source={avatar}
          style={{
            width: 45,
            height: 45,
            borderRadius: '50%',
          }}
        />
      )}
    </Container>
  );
};
