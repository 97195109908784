import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  width: 100%;
  height: 100%;
  display: grid;
  align-content: stretch;
  grid-template-columns: repeat(6, 1fr);
  justify-content: flex-start;
  overflow-y: scroll;
  @media (max-width: 2260px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 2260px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1530px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
