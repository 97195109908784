import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';

interface ContainerProps {
  isHovered: boolean;
}

export const Container = styled(TouchableOpacity)<ContainerProps>`
  background-color: ${({ theme }) => theme.blueishBlack};
  border-color: ${({ theme, isHovered }) =>
    isHovered ? theme.white : theme.purpleBlue};
  border-width: 2px;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  align-items: flex-start;
  justify-content: center;
  padding: 0 16px;
  margin: 8px 0;
`;
