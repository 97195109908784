/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';
import ExpandMoreIcon from '../../assets/icons/expand_more.svg';
import ErrorIcon from '../../assets/icons/error_outline.svg';

import {
  Container,
  Label,
  DropDownButton,
  DropDownMasterWraper,
  DropDownItem,
  DropDownItemOverlay,
  DropDownItemText,
  Placeholder,
  ErrorContainer,
  ErrorText,
} from './styles';

export interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  selectedOptions: string[];
  isMulti?: boolean;
  options: Option[];
  placeholder?: string;
  onChange: (value: string[]) => void;
  onBlur?: () => void;
  style?: any;
  error?: string;
}

function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const Select = ({
  selectedOptions,
  isMulti,
  options,
  placeholder,
  onChange,
  onBlur,
  style,
  error,
}: SelectProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);
  const prevIsOpened = usePrevious(isOpened);
  const wrapperRef = useRef<any>();
  const dropButtonRef = useRef<any>();

  function handleActiveOption(value: string) {
    setIsOpened(false);
    if (selectedOptions.includes(value)) {
      return onChange([]);
    }
    return onChange([value]);
  }

  function handleToggleOption(value: string) {
    if (selectedOptions.includes(value)) {
      return onChange(selectedOptions.filter(val => val !== value));
    }
    return onChange([...selectedOptions, value]);
  }

  function getActivedOptions() {
    return options
      .filter(option => selectedOptions.includes(option.value))
      .map(option => t(option.label));
  }

  useEffect(() => {
    if (!isOpened && prevIsOpened) {
      if (onBlur) {
        onBlur();
      }
    }
  }, [isOpened, onBlur, prevIsOpened]);

  function useOutsideClick(nref: any, bref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (nref.current && bref.current.contains(event.target)) {
          return;
        }
        if (nref.current && !nref.current.contains(event.target)) {
          setIsOpened(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [nref, bref]);
  }

  useOutsideClick(wrapperRef, dropButtonRef);

  return (
    <Container style={style}>
      {selectedOptions.length > 0 && <Label>{placeholder}</Label>}
      <DropDownButton
        ref={dropButtonRef}
        isOpened={isOpened}
        onPress={() => setIsOpened(oldState => !oldState)}
      >
        {selectedOptions.length > 0 ? (
          <DropDownItemText ellipsizeMode="tail" numberOfLines={1}>
            {getActivedOptions().join(',')}
          </DropDownItemText>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}

        <ExpandMoreIcon height={20} />
      </DropDownButton>
      {error && !isOpened && (
        <ErrorContainer>
          <ErrorIcon
            testID="input-error-icon"
            fill={theme.red}
            width={16}
            height={16}
            viewBox="0 0 24 24"
          />
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
      )}
      {isOpened && (
        <DropDownMasterWraper ref={wrapperRef}>
          {options.map(option => (
            <DropDownItem
              key={option.value}
              onPress={() =>
                isMulti
                  ? handleToggleOption(option.value)
                  : handleActiveOption(option.value)
              }
            >
              {selectedOptions.includes(option.value) && (
                <DropDownItemOverlay />
              )}
              <DropDownItemText>{t(option.label)}</DropDownItemText>
            </DropDownItem>
          ))}
        </DropDownMasterWraper>
      )}
    </Container>
  );
};
