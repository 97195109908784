import { useRoute, RouteProp } from '@react-navigation/native';

type ParamList = {
  WidgetConfig: {
    id: string;
  };
};

export function WidgetConfig() {
  const route = useRoute<RouteProp<ParamList, 'WidgetConfig'>>();
  return <h1>{route.params.id}</h1>;
}
