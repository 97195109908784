import styled from 'styled-components';
import styledNative from 'styled-components/native';
import RcTimePicker from 'rc-time-picker';
import { ButtonPrimary } from '../Typography';

export const Container = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //border-bottom: 2px solid #fff !important;
`;

export const ErrorContainer = styled.div`
  display: flex;
  top: 38px;
  flex-direction: row;
  position: absolute;
  color: ${({ theme }) => theme.red};
`;

export const ErrorText = styledNative(ButtonPrimary)`
  color: ${({ theme }) => theme.red};
  margin-left: 4px;
  font-weight: 400;
`;

export const Picker = styled(RcTimePicker)`
  width: 100%;
  .rc-time-picker-panel {
    background-color: red !important;
  }
  .rc-time-picker-input {
    background: transparent !important;
    border: none !important;
    color: #fff !important;
    font-size: 1.4rem;
    width: calc(100% - 20px);
  }

  .rc-time-picker-input::placeholder {
    font-size: 1.4rem !important;
    color: #6a6a9f;
  }

  .rc-time-picker-panel-input-wrap {
    border-bottom: 1px solid #6a6a9f;
  }

  .rc-time-picker-panel-inner {
    background-color: #212337 !important;
    border: 1px solid #6a6a9f;
    box-shadow: none;

    .rc-time-picker-panel-select {
      background-color: #212337 !important;
      border: 1px solid #6a6a9f;
      border-bottom: 0;
    }
  }

  .rc-time-picker-panel-combobox {
    background-color: #212337 !important;
  }

  .rc-time-picker-panel-input {
    background-color: #212337 !important;
    color: #fff !important;
  }

  .rc-time-picker-panel-select-option-selected {
    background-color: #023aff !important;
  }

  .rc-time-picker-panel-select li:hover {
    background-color: #6a6a9f !important;
  }
`;
