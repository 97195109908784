import { useState, useEffect } from 'react';
import { useQueryPage } from '@services/hooks/useQueryPage';
import { Spinner } from '@atoms/Spinner';
import { WorkspacePageAddNewWidgetButton } from '@molecules/WorkspacePageAddNewWidgetButton';
import { WorkspacePageAddNewWidgetModal } from '@organisms/WorkspacePageAddNewWidgetModal';
import { ActionButton } from '@atoms/ActionButton';
import { useTheme } from 'styled-components/native';
import { WidgetGrid } from '@organisms/WidgetGrid';

import { useWidgetGridContext, IWidget } from '@contexts/WidgetGridContext';
import { useMutationUpdateWidgets } from '@services/hooks/useMutationUpdateWidgets';
import { Container, WidgetsContainer } from './styles';
import PlusIcon from '../../../assets/icons/plus.svg';

interface WorkspacePageProps {
  pageId: string;
}

export const WorkspacePage = ({ pageId }: WorkspacePageProps) => {
  const theme = useTheme();
  const pageQuery = useQueryPage(pageId);
  const updateWidgetsMutation = useMutationUpdateWidgets();
  const [openCreateWidgetModal, setOpenCreateWidgetModal] = useState(false);
  const { widgets, updateWidgetList } = useWidgetGridContext();

  useEffect(() => {
    updateWidgetList(
      pageQuery.data
        ? pageQuery.data.widgets.map(widget => ({
            ...widget,
            left: 0,
            top: 0,
            width: 0,
            height: 0,
          }))
        : [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageQuery.data]);

  const handleSaveWidgets = (updatedWidgets: IWidget[]) => {
    updateWidgetsMutation.mutateAsync(updatedWidgets);
  };

  return (
    <Container>
      <ActionButton
        gradientColor={theme.purpleGradient}
        withGradient
        icon={PlusIcon}
        iconColor={theme.white}
        backgroundColor={theme.purpleBlue}
        onPress={() => setOpenCreateWidgetModal(true)}
        size="large"
      />
      <WidgetsContainer>
        {pageQuery.data ? (
          <>
            {widgets.length ? (
              <WidgetGrid onSaveWidgets={handleSaveWidgets} />
            ) : (
              <>
                <WorkspacePageAddNewWidgetButton
                  onAddPress={() => setOpenCreateWidgetModal(true)}
                />
              </>
            )}
          </>
        ) : (
          <>
            <Spinner size="large" />
          </>
        )}
        {openCreateWidgetModal && (
          <WorkspacePageAddNewWidgetModal
            pageId={pageId}
            isVisible={openCreateWidgetModal}
            onClose={() => setOpenCreateWidgetModal(false)}
          />
        )}
      </WidgetsContainer>
    </Container>
  );
};
