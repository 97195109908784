/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { RENDER_STATE } from 'react-map-gl-draw';
import { ZoneTypes } from '@contexts/ZonesContext';

export function getEditHandleStyle(
  { feature, state }: any,
  zoneCreatingType: ZoneTypes,
) {
  const defaultColor =
    zoneCreatingType === 'main_courante'
      ? 'url(#main_courante)'
      : zoneCreatingType === 'main_courante_action'
      ? '#5EFF5A'
      : '#991BFA';
  switch (state) {
    case RENDER_STATE.SELECTED:
    case RENDER_STATE.HOVERED:
    case RENDER_STATE.UNCOMMITTED:
      return {
        fill: feature.properties?.color || defaultColor,
        fillOpacity: 1,
        stroke: 'rgb(255, 255, 255)',
        strokeWidth: 2,
        r: 8,
      };

    default:
      return {
        fill: feature.properties?.color || defaultColor,
        fillOpacity: 1,
        stroke: feature.properties?.color || defaultColor,
        strokeWidth: 2,
        r: 8,
      };
  }
}

export function getFeatureStyle(
  { feature, state }: any,
  zoneCreatingType: ZoneTypes,
) {
  const defaultColor =
    zoneCreatingType === 'main_courante'
      ? 'url(#main_courante)'
      : zoneCreatingType === 'main_courante_action'
      ? '#5EFF5A'
      : '#991BFA';
  switch (state) {
    case RENDER_STATE.SELECTED:
      return {
        stroke: feature.properties?.color || defaultColor,
        strokeWidth: feature.geometry.type === 'LineString' ? 10 : 2,
        fill: feature.properties?.color || defaultColor,
        fillOpacity: feature.geometry.type === 'LineString' ? 0 : 0.25,
        strokeDasharray:
          feature.properties.editable === undefined ||
          feature.properties.editable
            ? '4,2'
            : '0',
        r: 4,
      };
    case RENDER_STATE.HOVERED:
      return {
        stroke: feature.properties?.color || defaultColor,
        strokeWidth: feature.geometry.type === 'LineString' ? 8 : 2,
        fill: feature.properties?.color || defaultColor,
        fillOpacity: feature.geometry.type === 'LineString' ? 0 : 0.15,
        r: 4,
      };
    case RENDER_STATE.UNCOMMITTED:
    case RENDER_STATE.CLOSING:
      return {
        stroke: feature.properties?.color || defaultColor,
        strokeWidth: feature.geometry.type === 'LineString' ? 6 : 2,
        fill: feature.properties?.color || defaultColor,
        fillOpacity: feature.geometry.type === 'LineString' ? 0 : 0.3,
        strokeDasharray: '4,2',
        r: 4,
      };

    default:
      return {
        stroke: feature.properties?.color || defaultColor,
        strokeWidth: feature.geometry.type === 'LineString' ? 6 : 1,
        fill: feature.properties?.color || defaultColor,
        fillOpacity: feature.geometry.type === 'LineString' ? 0 : 0.1,
        r: 4,
      };
  }
}
