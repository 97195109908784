import React from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import { EditableConfig } from 'rc-tabs/lib/interface';
import { useTheme } from 'styled-components';
import CloseIcon from '../../assets/icons/close.svg';
import PlusIcon from '../../assets/icons/plus.svg';
import MoreIcon from '../../assets/icons/more.svg';
import { TabsViewProps } from './types';

function TabsView({
  children,
  onAdd,
  onChange,
  onRemove,
  isEditable,
  activeTabKey,
  ...rest
}: TabsViewProps) {
  const theme = useTheme();

  function handleChange(activeKey: string) {
    onChange(activeKey);
  }

  function handleAdd() {
    if (!onAdd) {
      return;
    }
    onAdd();
  }

  function handleRemove(targetKey: string) {
    if (!onRemove) {
      return;
    }
    onRemove(targetKey);
  }

  const editable: EditableConfig = {
    onEdit: (action, e) => {
      if (action === 'add') {
        handleAdd();
      } else {
        handleRemove(String(e.key));
      }
    },
    removeIcon: <CloseIcon width={20} height={20} viewBox="0 0 30 30" />,
    addIcon: (
      <PlusIcon
        width={20}
        height={20}
        fill={theme.purpleBlue}
        viewBox="0 0 24 24"
      />
    ),
    showAdd: true,
  };

  return (
    <Tabs
      editable={isEditable ? editable : undefined}
      onChange={handleChange}
      activeKey={activeTabKey}
      moreIcon={
        <MoreIcon
          width={16}
          height={16}
          fill={theme.purpleBlue}
          viewBox="0 0 24 24"
        />
      }
      {...rest}
    >
      {children}
    </Tabs>
  );
}

export { TabsView, TabPane };
