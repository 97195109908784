import styled, { css } from 'styled-components/native';
import { transparentize } from 'polished';
import { Caption, Body2 } from '@nucleus/Typography';
import { NativeMethods, Dimensions } from 'react-native';

export const Container = styled.View`
  width: 95%;
  display: inline-block;
`;
interface DropDownButtonProps {
  isOpened?: boolean;
}

export const Label = styled(Caption)`
  color: ${({ theme }) => theme.purpleBlue};
`;

export const DropDownButton = styled.TouchableOpacity<DropDownButtonProps>`
  color: white;
  font-size: 16px;
  padding: 4px;
  border: none;
  cursor: pointer;
  border-bottom-color: ${({ isOpened, theme }) =>
    !isOpened ? theme.white : 'transparent'};
  border-bottom-width: 2px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface DropDownItemButtonProps {
  color: string;
}

export const DropDownItemContainer = styled.View<DropDownItemButtonProps>`
  width: 100%;
  height: 30px;
  background-color: ${({ color }) => color};
  color: white;
  font-size: 16px;
  border-top-width: 0;
  justify-content: center;
  padding: 4px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface DropDownMasterWraperProps {
  parentElement: HTMLElement | NativeMethods;
}

export const DropDownMasterWraper = styled.View<DropDownMasterWraperProps>`
  ${({ parentElement }) => {
    if (parentElement instanceof HTMLElement) {
      const { top, left, width } = parentElement.getBoundingClientRect();
      return css`
        left: ${left};
        top: ${top + 24};
        width: ${width};
      `;
    }
    return css``;
  }};
  position: absolute;
  background: #212337;
  max-height: 300px;
  overflow: auto;
  border-width: 1px;
  border-color: #6a6a9f;
`;

export const DropDownWraper = styled.View`
  width: 100%;
  background: #212337;
  overflow: auto;
`;

export const DropDownItem = styled.View`
  position: relative;
`;

export const TypeEventItem = styled.TouchableOpacity`
  width: 100%;
  height: 30px;
  justify-content: flex-start;
  align-items: center;
  border-bottom-width: 1px;
  border-color: #6a6a9f;
  flex-direction: row;
`;

export const IconContainer = styled.View`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.white};
  align-items: center;
  justify-content: center;
`;

export const TypeEventItemOverlay = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${transparentize(0.73, '#C4C4C4')};
`;

interface TypeEventProps {
  color: string;
}

export const TypeEvent = styled.View<TypeEventProps>`
  width: 30px;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  margin-right: 16px;
`;

export const DropDownItemText = styled(Caption)`
  text-overflow: ellipsis;
  width: 95%;
  max-width: 190px;
  ${() => {
    const { width } = Dimensions.get('window');
    if (width < 1300) {
      return css`
        font-size: 8;
      `;
    }
    if (width < 1700) {
      return css`
        font-size: 9;
      `;
    }

    return css``;
  }}
`;

export const Placeholder = styled(Body2)`
  color: ${({ theme }) => theme.purpleBlue};
`;
