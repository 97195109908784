import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
`;

export const MediaContainer = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
`;
