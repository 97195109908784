/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect } from 'react';
import { WorkspaceLayout } from '@templates/WorkspaceLayout';
import { useRoute, RouteProp } from '@react-navigation/native';
import { WorkspaceTab } from '@templates/WorkspaceTab';
import { WorkspacePage } from '@templates/WorkspacePage';
import { useQueryWorkspace } from '@services/hooks/useQueryWorkspace';
import { useMutationCreatePage } from '@services/hooks/useMutationCreatePage';
import { useMutationDeletePage } from '@services/hooks/useMutationDeletePage';
import { useMutationUpdatePage } from '@services/hooks/useMutationUpdatePage';

type ParamList = {
  Workspace: {
    id: string;
  };
};

const Workspace = () => {
  const route = useRoute<RouteProp<ParamList, 'Workspace'>>();
  const [selectedPage, setSelectedPage] = useState<string>();

  const workspaceQuery = useQueryWorkspace(route.params.id);
  const mutationCreatePage = useMutationCreatePage({
    workspace_id: route.params.id,
  });

  const mutationDeletePage = useMutationDeletePage({
    workspace_id: route.params.id,
  });

  const mutationUpdatePage = useMutationUpdatePage({
    workspace_id: route.params.id,
  });

  const handleCreatePage = async () => {
    const newPage = await mutationCreatePage.mutateAsync({
      name: 'New Page',
      workspace_id: route.params.id,
    });
    setSelectedPage(newPage.id);
  };

  const handleRemovePage = async (id: string) => {
    await mutationDeletePage.mutateAsync(id);
  };

  const handleChangePageName = async (newName: string, pageId: string) => {
    await mutationUpdatePage.mutateAsync({
      page_id: pageId,
      data: { name: newName },
    });
  };

  useEffect(() => {
    if (workspaceQuery.data) {
      if (workspaceQuery.data.pages.length > 0) {
        setSelectedPage(workspaceQuery.data.pages[0].id);
      }
    }
  }, [workspaceQuery.data]);

  return (
    <WorkspaceLayout>
      {workspaceQuery.data ? (
        <WorkspaceTab
          tabs={workspaceQuery.data.pages}
          selectedTab={selectedPage}
          onChangeTabName={(name: string, tabKey: string) =>
            handleChangePageName(name, tabKey)
          }
          onChangeSelecteTab={(key: string) => setSelectedPage(key)}
          onRemoveTab={(key: string) => handleRemovePage(key)}
          onCreateTab={handleCreatePage}
        >
          {selectedPage && <WorkspacePage pageId={selectedPage} />}
        </WorkspaceTab>
      ) : (
        <>Loading...</>
      )}
    </WorkspaceLayout>
  );
};

export default Workspace;
