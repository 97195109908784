import styled from 'styled-components';
import { Rnd } from 'react-rnd';
import { Button } from '../Button';

export const Container = styled(Rnd)`
  z-index: 4;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px #05050f;
`;

export const Header = styled.div`
  width: 100%;
  height: 30px;
  border-bottom: 3px solid ${({ theme }) => theme.background};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
  svg:last-child {
    margin-right: 10px;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 360px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: space-between;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  // border-right: 1.5px solid ${({ theme }) => theme.background};
`;

export const RightContainer = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  border-left: 1.5px solid ${({ theme }) => theme.background};
`;

export const MapContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 16px;
  width: 45%;
  img {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const BottomContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
`;

export const SaveButton = styled(Button)`
  width: 236px;
  height: 48px;
`;
