import { ComponentType, useState, useRef } from 'react';
import {
  TextInputProps,
  TextInput,
  Platform,
  View,
  TouchableOpacity,
} from 'react-native';
import { Icon } from '@nucleus/Icon';
import { SvgProps } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { Portal } from '@gorhom/portal';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

import {
  Container,
  Label,
  InputContainer,
  InputText,
  DropDownMasterWraper,
  DropDownItem,
  DropDownItemText,
} from './styles';

export interface Option<T> {
  label: string;
  value: string;
  data: T;
}

export interface AutoCompleteInputProps<T> extends TextInputProps {
  label?: string;
  error?: string;
  icon?: ComponentType<SvgProps>;
  options: Option<T>[];
  onSelectOption: (data: T) => void;
  isOpen?: boolean;
  setIsOpen: (value: boolean) => void;
  defaultValue?: string;
}

export const AutoCompleteInput = <T,>({
  icon,
  label,
  error,
  style,
  options,
  onSelectOption,
  isOpen,
  setIsOpen,
  defaultValue,
  ...rest
}: AutoCompleteInputProps<T>) => {
  const inputRef = useRef<TextInput>(null);
  const containerRef = useRef<View>(null);
  const wrapperRef = useRef<TouchableOpacity>(null);
  const theme = useTheme();
  const [hasFocus, setHasFocus] = useState(false);

  useOutsideClick({
    componentRef: wrapperRef,
    yOffset: 20,
    onOutsideClick: () => setIsOpen(false),
  });

  function handleSelectOption(option: Option<T>) {
    if (!inputRef.current) return;
    if (Platform.OS === 'web') {
      const element = inputRef.current as unknown as HTMLInputElement;
      element.defaultValue = option.label;
      element.value = option.label;
      onSelectOption(option.data);
      setIsOpen(false);
    }
  }
  return (
    <Container style={style}>
      {label && <Label>{label}</Label>}
      <InputContainer ref={containerRef}>
        <InputText
          defaultValue={defaultValue}
          ref={inputRef}
          testID="input-element"
          withIcon={!!Icon}
          error={!!error && !hasFocus}
          hasFocus={hasFocus}
          placeholderTextColor={theme.purpleBlue}
          onBlur={() => setHasFocus(false)}
          onFocus={() => setHasFocus(true)}
          {...rest}
        />
        {icon && (
          <Icon
            as={icon}
            testID="input-icon"
            width={22}
            height={22}
            viewBox="0 0 20 20"
            fill={theme.white}
          />
        )}
      </InputContainer>
      {isOpen && containerRef.current && (
        <Portal>
          <DropDownMasterWraper
            ref={wrapperRef}
            parentElement={containerRef.current}
          >
            {options.map(option => (
              <DropDownItem
                key={`auto_complete_${option.value}`}
                onPress={() => handleSelectOption(option)}
              >
                <DropDownItemText>{option.label}</DropDownItemText>
              </DropDownItem>
            ))}
          </DropDownMasterWraper>
        </Portal>
      )}
    </Container>
  );
};
