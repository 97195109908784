import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  #root { display:flex; height:100%; }

  *::-webkit-scrollbar {
    width: 4px;
  }

  *::-webkit-scrollbar-track {
    opacity: 0;
  }

  *::-webkit-scrollbar-thumb {
    opacity: 0;
    background-color: ${({ theme }) => theme.purpleBlue};
  }

  html, body { height: 100%;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

  body {
    overflow: hidden;
    color: ${props => props.theme.white};
    font-family: 'Open Sans', sans-serif ;
    background:${props => props.theme.background};
  }

  button {
    cursor: pointer;
  }
  a {
    color: inherit;
    text-decoration: none;
  }


  html {
    font-size: 62.5%; /* 1rem = 10px */
  }

  @media (max-width: 1450px){
    html {
      font-size: 58%;
    }
  }

  @media (max-width: 1367px){
    html {
      font-size: 56%;
    }
  }

  @media (max-width: 720px){
    html {
      font-size: 54%;
    }
  }

  .teste {
    position: fixed;
    background: #00e1ff !important;
    transform: translateX(-50%) !important;
    top:0;
    left:0;
    z-index: 1000 !important;
  }

`;
