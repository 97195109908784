import styled from 'styled-components';

export const Container = styled.div`
  width: 65%;
  height: 95%;
  border-radius: 20px;
  background: ${({ theme }) => theme.blueishBlack};
  .rc-tabs {
    font-family: 'Open Sans';
    font-size: 14px;
    background-color: #212337;
    border: none !important;
    height: 100%;
    border-radius: 20px;
  }

  .rc-tabs-nav {
    background-color: #111121 !important;
  }

  .rc-tabs-content-top {
    background-color: #212337 !important;
  }

  .rc-tabs-extra-content {
    background-color: #212337 !important;
  }

  .rc-tabs-ink-bar {
    background-color: transparent;
  }

  .rc-tabs-tab {
    background-color: #111121 !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .rc-tabs-tab-active {
    background-color: #212337 !important;
    border: 15px !important;
    font-weight: 400 !important;
    font-family: 'Open Sans' !important;
    color: #fff;
    padding-left: 16px !important;
    padding-right: 16px !important;
    box-shadow: 0px 0px 1px #191932;
  }

  .rc-tabs-tab svg {
    margin-top: 3px !important;
  }

  .rc-tabs-tab svg:hover {
    fill: white !important;
  }

  .rc-tabs-nav-wrap {
    background-color: #212337 !important;
    height: 32px;
  }

  .rc-tabs-tab svg {
    color: #6a6a9f !important;
  }

  .rc-tabs-nav::before {
    border-bottom: none !important;
  }

  .rc-tabs-nav-add {
    margin-right: 5px;
    background-color: transparent !important;
    border: none !important;
  }

  .rc-tabs-tab-btn {
    font-size: 14px;
  }

  .rc-tabs-tab-active .rc-tabs-tab-btn {
    color: #fff;
  }

  .rc-tabs-nav-add svg {
    color: #6a6a9f !important;
    fill: #6a6a9f !important;
  }

  .rc-tabs-nav-operations {
    background: #111121 !important;
  }

  .rc-tabs-nav-more {
    border: none !important;
    border-color: transparent !important;
  }

  .rc-tabs-nav-add svg:hover {
    color: white !important;
  }

  .rc-tabs-nav-operations {
    margin-top: 24px;
    height: 32px;
  }
  .rc-tabs-nav-operations .rc-tabs-nav-more {
    border: none !important;
    background: #111121 !important;
  }

  .rc-tabs-nav-operations button {
    background: transparent !important;
  }

  .rc-tabs-nav-operations .rc-tabs-nav-more svg {
    color: #6a6a9f !important;
    fill: #6a6a9f !important;
  }

  .rc-tabs-nav-operations .rc-tabs-nav-add {
    background-color: #111121 !important;
  }

  .rc-tabs-nav-add svg:hover {
    fill: white !important;
  }

  .rc-tabs-nav-add {
    margin: 0 6px 0 6px !important;
  }

  .rc-tabs-nav-more {
    margin: 0 6px 0 6px !important;
  }
  .rc-tabs-nav-more svg:hover {
    fill: white !important;
  }

  .rc-tabs-dropdown-menu {
    background-color: #212337 !important;
  }

  .rc-tabs-dropdown-menu-item {
    background: #212337 !important;
    color: #fff !important;
  }

  .rc-tabs-dropdown-menu-item:hover {
    background: #6a6a9f !important;
  }

  .rc-tabs-nav-wrap-ping-left::before {
    border: none !important;
  }

  .rc-tabs-nav-wrap-ping-right::after {
    border: none !important;
  }

  .rc-tabs-tabpane {
    height: 100px;
  }

  .rc-tabs-tabpane-active {
    height: 100%;
  }

  .rc-tabs-content {
    height: 100%;
  }

  .mapboxgl-map {
    border-top-left-radius: 15px;
  }

  .rc-tabs-content-holder {
    height: calc(100vh - 57px);
  }
`;

export const Control = styled.div`
  display: flex;
  width: 76px;
  height: 100%;
  background: transparent;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
`;
