import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 100%;
  padding: 12px 8px;
`;

export const ContentContainer = styled.View`
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% - 60px);
  padding: 8px;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: scroll;
`;

export const SpinnerContainer = styled.View`
  width: 100%;
  height: calc(100% - 60px);
  padding: 8px;
  justify-content: center;
  align-items: center;
`;
