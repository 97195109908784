import styled from 'styled-components/native';
import { IconButton } from '@atoms/IconButton';
import { transparentize } from 'polished';

export const Container = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

interface AddButtonProps {
  isHovered: boolean;
}

export const AddButton = styled(IconButton)<AddButtonProps>`
  width: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, isHovered }) =>
    isHovered
      ? transparentize(0.2, theme.background)
      : transparentize(0.8, theme.background)};
  border-color: ${({ theme, isHovered }) =>
    isHovered ? transparentize(0.1, theme.purpleBlue) : theme.purpleBlue};
  border-width: 2px;
  border-radius: 10px;
  margin-right: 16px;
`;
