/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Point } from '@nebula.gl/edit-modes';
import { SearchParamsData } from '@contexts/FiltersContext';
import { Zone } from '@contexts/ZonesContext';
import { Filters } from 'utils/tabApi';
import { centerLocation } from '../../utils/centerLocation';
import { api } from '../eventApi';
import { getDateFnsLocale } from '../../utils/getDateFnsLocale';

export interface MainCourante {
  type: 'main_courante';
  id: string;
  name: string;
  closed: boolean;
  priority: number;
  location_coordinates: Point;
  location_color?: string;
  location_name: string;
  description: string;
  date: string;
  team_id: string;
  media: string[];
  title: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  main_courante_actions_total: number;
  main_courante_actions_opened: number;
  main_courante_actions_closed: number;
  formatted_date: string;
  formatted_created_at: string;
  formatted_updated_at: string;
  zone?: Zone;
}

export async function getFiltredMainCourantes({
  bodyParams,
  queryParams,
}: SearchParamsData): Promise<MainCourante[]> {
  const locale = getDateFnsLocale();

  const response = await api.post(
    'v1/search/main_courantes',
    {
      coordinates: bodyParams.coordinates,
      main_courante_ids: bodyParams.main_courante_ids,
    },
    {
      params: { ...queryParams, categories: undefined },
    },
  );

  const mainCourantes = response.data.map((mainCouranteEvent: any) => {
    const zone =
      mainCouranteEvent.location_coordinates.type !== 'Point'
        ? ({
            id: mainCouranteEvent.id,
            type: 'Feature' as const,
            properties: {
              name: 'main_courante_event',
              type: 'main_courante',
              color: mainCouranteEvent.location_color
                ? mainCouranteEvent.location_color
                : 'url(#main_courante)',
              editable: false,
            },
            geometry: mainCouranteEvent.location_coordinates,
          } as Zone)
        : undefined;

    return {
      type: 'main_courante',
      id: mainCouranteEvent.id,
      name: mainCouranteEvent.name || '',
      title: mainCouranteEvent.title,
      closed: mainCouranteEvent.closed,
      priority: mainCouranteEvent.priority,
      location_coordinates: centerLocation(
        mainCouranteEvent.location_coordinates,
      ),
      location_color: mainCouranteEvent.location_color,
      location_name: mainCouranteEvent.location_name,
      description: mainCouranteEvent.desc,
      date: mainCouranteEvent.date,
      team_id: mainCouranteEvent.team_id,
      media: mainCouranteEvent.media,
      user_id: mainCouranteEvent.user_id,
      created_at: mainCouranteEvent.created_at,
      updated_at: mainCouranteEvent.updated_at,
      main_courante_actions_total:
        mainCouranteEvent.main_courante_actions_total,
      main_courante_actions_opened:
        mainCouranteEvent.main_courante_actions_opened,
      main_courante_actions_closed:
        mainCouranteEvent.main_courante_actions_closed,
      formatted_date: formatDistanceToNow(parseISO(mainCouranteEvent.date), {
        locale,
        addSuffix: true,
      }),
      formatted_created_at: formatDistanceToNow(
        parseISO(mainCouranteEvent.created_at),
        {
          locale,
          addSuffix: true,
        },
      ),
      formatted_updated_at: formatDistanceToNow(
        parseISO(mainCouranteEvent.updated_at),
        {
          locale,
          addSuffix: true,
        },
      ),
      zone,
    };
  });

  return mainCourantes;
}

export function useQueryFiltredMainCourantes(
  tabId: string | undefined,
  filters: Filters,
  getFiltersInParams: (filters: Filters) => SearchParamsData,
) {
  return useQuery(
    ['filtredMainCourantes', tabId, filters],
    () => getFiltredMainCourantes(getFiltersInParams(filters)),
    {
      enabled: !!tabId,
      refetchOnWindowFocus: false,
      refetchInterval: 5 * 60000, // 5 minutes,
      refetchIntervalInBackground: true,
    },
  );
}
