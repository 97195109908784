import { useState } from 'react';
import { Modal } from '@atoms/Modal';
import { Input } from '@atoms/Input';
import { useTranslation } from 'react-i18next';
import { H5 } from '@nucleus/Typography';

import { Container, ButtonsContainer, Button, FormContainer } from './styles';

interface WorkspaceAreaAddModalProps {
  isVisible?: boolean;
  isCreating?: boolean;
  onConfirm: (newWorkspaceName: string) => void;
  onCancel: () => void;
}

export const WorkspacesAreaAddModal = ({
  isVisible,
  onConfirm,
  onCancel,
  isCreating,
}: WorkspaceAreaAddModalProps) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');

  return (
    <Modal visible={isVisible}>
      <Container>
        <H5>Name your workspace</H5>
        <FormContainer>
          <Input
            value={name}
            onChangeText={setName}
            placeholder="Name of the workspace"
            style={{ maxWidth: 198, marginTop: 8 }}
          />
        </FormContainer>
        <ButtonsContainer>
          <Button outline onPress={onCancel}>
            {t('modal_cancel')}
          </Button>
          <Button loading={isCreating} onPress={() => onConfirm(name)}>
            {t('modal_confirm')}
          </Button>
        </ButtonsContainer>
      </Container>
    </Modal>
  );
};
