import styled, { css } from 'styled-components/native';

interface ContainerProps {
  withMore?: boolean;
  showAll?: boolean;
}

export const Container = styled.View<ContainerProps>`
  width: calc(100% - 10px);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  overflow-x: hidden;
  ${({ withMore, showAll }) => {
    if (withMore && !showAll) {
      return css`
        overflow-y: hidden;
        height: 26px;
      `;
    }
    return css``;
  }}
`;
