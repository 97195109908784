import { useQuery } from 'react-query';
import { api } from '../userManagementApi';

export interface WidgetConfig {
  id: string;
  name: string;
  type_id: string;
  user_id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any;
}

export interface GetWigetConfigsParams {
  type_id: string;
}

export async function getWigetConfigs({
  type_id,
}: GetWigetConfigsParams): Promise<WidgetConfig[]> {
  const getWorkspaceResponse = await api.get(`/v1/widget/configs`, {
    params: { type_id },
  });
  return getWorkspaceResponse.data;
}

export function useQueryWidgetConfigs({ type_id }: GetWigetConfigsParams) {
  return useQuery(['widgetConfigs', type_id], () =>
    getWigetConfigs({ type_id }),
  );
}
