import styled, { css } from 'styled-components/native';
import { Caption } from '@nucleus/Typography';

export const Container = styled.View`
  flex-direction: row;
`;
interface TextProps {
  small?: boolean;
}

export const Text = styled(Caption)<TextProps>`
  color: ${({ theme }) => theme.purpleBlue};
  ${({ small }) =>
    small &&
    css`
      font-size: 9px;
    `}
`;
