import { ComponentType, useRef } from 'react';
import { SvgProps } from 'react-native-svg';
import { TouchableOpacityProps } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { useTheme } from 'styled-components/native';
import { darken } from 'polished';

import { Container, GradientContainer } from './styles';

export interface ActionButtonProps extends TouchableOpacityProps {
  icon: ComponentType<SvgProps>;
  gradientColor?: string;
  isActive?: boolean;
  backgroundColor?: string;
  iconColor?: string;
  activeBackgroundColor?: string;
  activeIconColor?: string;
  floating?: boolean;
  withGradient?: boolean;
  size?: 'large' | 'medium';
  iconViewBox?: string;
}

export const ActionButton = ({
  icon: Icon,
  isActive,
  backgroundColor,
  iconColor,
  activeBackgroundColor,
  activeIconColor,
  floating,
  withGradient,
  size = 'large',
  iconViewBox,
  gradientColor,
  ...rest
}: ActionButtonProps) => {
  const containerRef = useRef(null);
  const isHovered = useHover(containerRef);
  const theme = useTheme();

  function getIconColor() {
    if (isActive) {
      if (isHovered) {
        return darken(0.02, activeIconColor || theme.white);
      }
      return activeIconColor || theme.white;
    }
    if (isHovered) {
      return darken(0.02, iconColor || theme.purpleBlue);
    }
    return iconColor || theme.purpleBlue;
  }
  return (
    <Container
      testID="action-button-container"
      ref={containerRef}
      isActive={isActive}
      isHovered={isHovered}
      backgroundColor={backgroundColor}
      activeBackgroundColor={activeBackgroundColor}
      floating={floating}
      size={size}
      {...rest}
    >
      {withGradient ? (
        <GradientContainer
          gradient={gradientColor || theme.linearYellow}
          size={size}
        >
          <Icon
            testID="action-button-icon"
            width={floating ? 20 : 24}
            height={floating ? 20 : 24}
            viewBox="0 0 24 24"
            fill={getIconColor()}
          />
        </GradientContainer>
      ) : (
        <Icon
          testID="action-button-icon"
          width={size === 'medium' ? 20 : 24}
          height={size === 'medium' ? 20 : 24}
          viewBox={iconViewBox || '0 0 24 24'}
          fill={getIconColor()}
        />
      )}
    </Container>
  );
};
