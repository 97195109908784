import { useRef, useEffect, useMemo } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { useEventsContext, useEventListContext } from '@contexts/EventsContext';
import { useFiltersContext } from '@contexts/FiltersContext';
import { useTabsControlContext } from '@contexts/TabsControlContext';
import { EventsListFrame } from '@organisms/EventsListFrame';
import { MainCouranteCard } from '@organisms/MainCouranteCard';
import { EventCard } from '@organisms/EventCard';
import { differenceInSeconds } from 'date-fns';
import {
  Event,
  useQueryFiltredEvents,
} from '@services/hooks/useQueryFiltredEvents';
import {
  MainCourante,
  useQueryFiltredMainCourantes,
} from '@services/hooks/useQueryFiltredMainCourantes';
import { EventsContainer } from './styles.web';

export interface Size {
  width: number;
  height: number;
}

interface RowProps {
  events: (Event | MainCourante)[];
  activatedEventId: string | null | undefined;
  updateActivatedEventId: (eventId: string) => void;
}

export const Row = ({
  data,
  index,
  style,
}: ListChildComponentProps<RowProps>) => {
  const { events, activatedEventId, updateActivatedEventId } = data;
  const event = events[index];

  return (
    <div key={String(event.id)} style={style}>
      {event.type === 'main_courante' ? (
        <MainCouranteCard
          isActive={activatedEventId === String(event.id)}
          mainCourante={event}
          onPress={() => updateActivatedEventId(String(event.id))}
        />
      ) : (
        <EventCard
          isActive={activatedEventId === String(event.id)}
          event={event}
          onPress={() => updateActivatedEventId(String(event.id))}
        />
      )}
    </div>
  );
};

export const EventList = () => {
  const { selectedTab } = useTabsControlContext();
  const { filters, getFiltersInParams } = useFiltersContext();

  const { data: events } = useQueryFiltredEvents(
    selectedTab,
    filters,
    getFiltersInParams,
  );

  const { data: mainCourantes } = useQueryFiltredMainCourantes(
    selectedTab,
    filters,
    getFiltersInParams,
  );

  const listOfEvents = useMemo(
    () =>
      [...(events || []), ...(mainCourantes || [])].sort((first, second) => {
        const firstDate =
          first.type === 'main_courante'
            ? new Date(first.date)
            : new Date(first.created_at);
        const secondDate =
          second.type === 'main_courante'
            ? new Date(second.date)
            : new Date(second.created_at);

        return differenceInSeconds(secondDate, firstDate);
      }),
    [events, mainCourantes],
  );

  const { activatedEventId, updateActivatedEventId } = useEventsContext();

  const { eventListOpened, updateEventListOpened } = useEventListContext();
  const listRef = useRef<FixedSizeList>(null);

  useEffect(() => {
    if (activatedEventId && listRef.current) {
      if (!events) return;
      const eventIndex = events.findIndex(
        event => String(event.id) === activatedEventId,
      );
      listRef.current.scrollToItem(eventIndex, 'smart');
    }
  }, [activatedEventId, events]);

  if (!eventListOpened) return null;
  return (
    <EventsListFrame onClosePress={() => updateEventListOpened(false)}>
      <ReactResizeDetector>
        {({ width, height }: Size) => (
          <EventsContainer>
            {width && height && listOfEvents.length > 0 ? (
              <FixedSizeList
                ref={listRef}
                height={height}
                itemCount={listOfEvents.length}
                itemSize={200}
                width={width}
                itemData={{
                  events: listOfEvents,
                  activatedEventId,
                  updateActivatedEventId,
                }}
              >
                {Row}
              </FixedSizeList>
            ) : null}
          </EventsContainer>
        )}
      </ReactResizeDetector>
    </EventsListFrame>
  );
};

/*
           
*/
