import { useTranslation } from 'react-i18next';
import { IntensityView } from '@atoms/IntensityView';
import { TimeLabel } from '@atoms/TimeLabel';
import { Container } from './styles';

interface EventInfoOverviewIntensityAndDateProps {
  intensity: number;
  formattedDate: string;
}

export const EventInfoOverviewIntensityAndDate = ({
  intensity,
  formattedDate,
}: EventInfoOverviewIntensityAndDateProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <IntensityView intensity={intensity} />
      <TimeLabel
        withIcon
        prefix={t('last_updated')}
        formattedDate={formattedDate}
      />
    </Container>
  );
};
