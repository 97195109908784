/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { api } from '../userManagementApi';

export interface WidgetConfig<C> {
  id: string;
  name: string;
  type_id: string;
  user_id: string;
  config: C;
}

export interface WidgetType {
  id: string;
  name: string;
  slug: string;
  description: string;
}

export interface Widget<D = any, C = any> {
  id: string;
  name: string;
  type_id: string;
  page_id: string;
  config_id: string;
  config: WidgetConfig<C>;
  type: WidgetType;
  grid_left: number;
  grid_top: number;
  grid_width: number;
  grid_height: number;
  data: D;
}

export interface Page {
  widgets: Widget[];
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export async function getPage(page_id: string): Promise<Page> {
  const getPageResponse = await api.get(`/v1/pages/${page_id}`);

  return getPageResponse.data;
}

export function useQueryPage(page_id: string) {
  return useQuery(['page', page_id], () => getPage(page_id));
}
