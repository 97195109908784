import { Caption } from '@nucleus/Typography';
import { Badge } from '@atoms/Badge';
import { Container } from './styles';

interface EventCardTypeSourceProps {
  name: string;
  badgeValue: string;
  badgeColor: string;
}

export const EventCardTypeSource = ({
  name,
  badgeValue,
  badgeColor,
}: EventCardTypeSourceProps) => {
  return (
    <Container>
      <Caption>{name}</Caption>
      <Badge color={badgeColor} text={badgeValue} style={{ marginLeft: 8 }} />
    </Container>
  );
};
