import { StyleProp, ViewStyle } from 'react-native';
import { Container, Text } from './styles';

interface UserAvatarProps {
  avatar?: string;
  fallbackName: string;
  style?: StyleProp<ViewStyle>;
}

export const UserAvatar = ({
  avatar,
  fallbackName,
  style,
}: UserAvatarProps) => {
  function getInitials(name: string) {
    const names = name.split(' ');

    if (names.length > 1) {
      return `${names[0].substring(0, 1).toUpperCase()}${names[names.length - 1]
        .substring(0, 1)
        .toUpperCase()}`;
    }
    return names[0].substring(0, 1).toUpperCase();
  }

  return (
    <>
      {avatar ? (
        <Container style={style}>
          <Text>{getInitials(fallbackName)}</Text>
        </Container>
      ) : (
        <Container style={style}>
          <Text>{getInitials(fallbackName)}</Text>
        </Container>
      )}
    </>
  );
};
