/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react';

import { Container, Input } from './styles.web';
import { EditableTextProps } from './types';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

export const EditableText = ({ text, onChangeText }: EditableTextProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(text);
  const [editActivated, setEditActivated] = useState(false);

  function handleChangeName(newText: string) {
    onChangeText(newText);
  }

  function handleKeyPress(event: any) {
    if (event.code === 'Space') {
      setValue(oldValue => `${oldValue}${event.key}`);
    }
    if (event.code === 'Enter') {
      handleChangeName(value);
      setEditActivated(false);
      return false;
    }
    return true;
  }

  function outsideClick() {
    handleChangeName(value);
    setEditActivated(false);
  }

  useOutsideClick({
    componentRef: inputRef,
    onOutsideClick: outsideClick,
    yOffset: 0,
  });

  useEffect(() => {
    if (editActivated) {
      inputRef?.current?.select();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editActivated]);

  useEffect(() => {
    setValue(text);
  }, [text]);

  return (
    <Container>
      {editActivated ? (
        <Input
          data-testid="editable-text-input"
          ref={inputRef}
          type="text"
          value={value}
          onChange={e => setValue(e.target.value)}
          onDoubleClick={() => setEditActivated(false)}
          onKeyDown={event => handleKeyPress(event)}
        />
      ) : (
        <p
          style={{ margin: 'auto' }}
          onDoubleClick={() => setEditActivated(true)}
        >
          {value}
        </p>
      )}
    </Container>
  );
};
