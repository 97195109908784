import styled from 'styled-components/native';

export const Container = styled.View`
  margin-top: 10px;
  margin-bottom: 5px;
  padding-right: 12px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;
