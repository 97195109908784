import styled from 'styled-components/native';

export const Container = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.purpleBlue};
  width: 46px;
  height: 46px;
  elevation: 6;
  border-radius: 8px;
`;
