import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import { Select, Option } from '@atoms/Select';
import { InputTag } from '@atoms/InputTag';
import { DatePicker } from '@atoms/DatePicker';
import { EventsFilterCreateZone } from '@molecules/EventsFilterCreateZone';

import { Container, Stack } from './styles';

import searchIcon from '../../../assets/icons/search.svg';

export interface AdvancedFilterFormData {
  start_date?: Date;
  end_date?: Date;
  any_terms: string[];
  all_terms: string[];
  exclude_terms: string[];
  zones: string[];
}

interface EventsFilterAdvancedFiltersProps {
  control: Control<AdvancedFilterFormData>;
  filters: AdvancedFilterFormData;
  onBlurFields: () => void;
  zoneOptions: Option[];
  onCreateNewZone: (newZoneId: string) => void;
}

export const EventsFilterAdvancedFilters = ({
  control,
  filters,
  zoneOptions,
  onBlurFields,
  onCreateNewZone,
}: EventsFilterAdvancedFiltersProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <InputTag
              tags={value || []}
              placeholder={t('any_term')}
              icon={searchIcon}
              onBlur={onBlurFields}
              onChange={onChange}
            />
          </>
        )}
        name="any_terms"
        defaultValue={filters.any_terms}
      />
      <Stack />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputTag
            tags={value || []}
            placeholder={t('all_of_these_terms')}
            icon={searchIcon}
            onBlur={onBlurFields}
            onChange={onChange}
          />
        )}
        name="all_terms"
        defaultValue={filters.all_terms}
      />
      <Stack />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputTag
            tags={value || []}
            placeholder={t('exclude_these_terms')}
            icon={searchIcon}
            onBlur={onBlurFields}
            onChange={onChange}
          />
        )}
        name="exclude_terms"
        defaultValue={filters.exclude_terms}
      />
      <Stack />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            placeholder={t('start_date')}
            date={value}
            onBlur={onBlurFields}
            onChange={date => onChange(date)}
          />
        )}
        name="start_date"
        defaultValue={
          filters.start_date ? new Date(filters.start_date) : undefined
        }
      />
      <Stack />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            date={value}
            placeholder={t('end_date')}
            onBlur={onBlurFields}
            onChange={date => onChange(date)}
          />
        )}
        name="end_date"
        defaultValue={filters.end_date ? new Date(filters.end_date) : undefined}
      />
      <Stack />

      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            selectedOptions={value || []}
            options={zoneOptions}
            placeholder={t('zones_created')}
            onBlur={onBlurFields}
            onChange={onChange}
          />
        )}
        name="zones"
        defaultValue={filters.zones}
      />
      <Stack />
      <EventsFilterCreateZone onCreateNewZone={onCreateNewZone} />
    </Container>
  );
};
