import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 30px;
  padding-left: 16px;
  padding-right: 16px;
  border-bottom: 3px solid ${({ theme }) => theme.background};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;
