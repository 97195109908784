import styled, { css } from 'styled-components';
import styledNative from 'styled-components/native';
import { ButtonPrimary } from '@nucleus/Typography';
import DayPicker from 'react-day-picker';
import { Platform } from 'react-native';

interface PickerProps {
  parentElement: HTMLElement;
}

export const Picker = styled(DayPicker)<PickerProps>`
  position: absolute;
  ${({ parentElement }) => {
    const { top, left, width } = parentElement.getBoundingClientRect();
    return css`
      left: ${`${left}px`};
      top: ${`${top + 24}px`};
      width: ${width > 200 ? `${width}px` : `200px`};
    `;
  }};
  font-family: 'Open Sans';
  background-color: ${({ theme }) => theme.purpleBlack};
  border: 1px solid ${({ theme }) => theme.purpleBlue};
  .DayPicker-Day:hover {
    background-color: ${({ theme }) => theme.purpleBlue} !important;
  }
  .DayPicker-Day--selected {
    background-color: ${({ theme }) => theme.blue} !important;
  }
  .DayPicker-Day--today {
    color: ${({ theme }) => theme.white};
    font-weight: bold;
  }
`;

export const Container = styled.div`
  padding: 0 4px;
  width: 100%;
  max-width: 130px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.blue};
  display: flex;
  flex-direction: column;
`;

export interface ContainerBoudings {
  top: number;
  left: number;
  width: number;
}

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: space-between;
`;

export const InputText = styled.input`
  width: 100%;
  font-size: ${Platform.OS === 'web' ? '1.4rem' : '14px'};
  line-height: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.044rem' : '-0.44px'};
  color: ${({ theme }) => theme.white};
  background-color: transparent;
  border: none;
  ::placeholder {
    color: ${({ theme }) => theme.purpleBlue};
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  top: 38px;
  flex-direction: row;
  position: absolute;
  color: ${({ theme }) => theme.red};
`;

export const ErrorText = styledNative(ButtonPrimary)`
  color: ${({ theme }) => theme.red};
  margin-left: 4px;
  font-weight: 400;
`;
