/* eslint-disable @typescript-eslint/no-explicit-any */
import { TimePickerProps } from 'rc-time-picker';
import { useTheme } from 'styled-components/native';
import { useState } from 'react';
import TimeIcon from '../../assets/icons/time.svg';
import { Container, ErrorContainer, ErrorText, Picker } from './styles';
import ErrorIcon from '../../assets/icons/error_outline.svg';
import { Caption } from '../Typography';

interface TimePickerCustomProps extends TimePickerProps {
  error?: string;
}
// onAmPmChange is missing on original props
const TestPicker: any = Picker;

export const TimePicker = ({
  error,
  value,
  placeholder,
  ...props
}: TimePickerCustomProps) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  return (
    <Container>
      {value && <Caption style={{ color: '#6a6a9f' }}>{placeholder}</Caption>}
      <TestPicker
        placeholder={placeholder}
        value={value}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        showSecond={false}
        format="h:mm a"
        use12Hours
        inputReadOnly
        hideDisabledOptions
        allowEmpty={false}
        inputIcon={<TimeIcon style={{ marginBottom: '-5px' }} />}
        {...props}
        onAmPmChange={() => setOpen(false)}
      />
      {error && (
        <>
          <ErrorContainer>
            <ErrorIcon
              testID="input-error-icon"
              fill={theme.red}
              width={16}
              height={16}
              viewBox="0 0 24 24"
            />
            <ErrorText>{error}</ErrorText>
          </ErrorContainer>
        </>
      )}
    </Container>
  );
};
