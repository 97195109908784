/* eslint-disable no-nested-ternary */
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';
import { H6 } from '@nucleus/Typography';
import { Spinner } from '@atoms/Spinner';
import { MainCouranteActionSelector } from '@molecules/MainCouranteInfoActionSelector';
import { MainCouranteInfoActionPreview } from '@molecules/MainCouranteInfoActionPreview';
import { MainCouranteAction } from '../../../services/hooks/useQueryMainCouranteInfo';
import { useMutationCloseMainCouranteAction } from '../../../services/hooks/useMutationCloseMainCouranteAction';
import { useActivatedMainCouranteActionsContext } from '../../../contexts/ActivatedMainCouranteActions';
import { useTabsControlContext } from '../../../contexts/TabsControlContext';
import { useFiltersContext } from '../../../contexts/FiltersContext';
import { useAuthContext } from '../../../contexts/AuthContext';

import { Container, ActionSelectorContainer } from './styles';

interface AdditionalContentProps {
  isLoading: boolean;
  main_courante_actions?: MainCouranteAction[];
  onActionSelect: (actionId: string | null) => void;
  selectedAction?: string | null;
  eventId: string;
}

export const MainCouranteInfoActions = ({
  isLoading,
  main_courante_actions,
  onActionSelect,
  selectedAction,
  eventId,
}: AdditionalContentProps) => {
  const { t } = useTranslation();
  const { selectedTab } = useTabsControlContext();
  const { filters } = useFiltersContext();
  const { user } = useAuthContext();

  const theme = useTheme();

  const { setListOfActivedMainCouranteActions } =
    useActivatedMainCouranteActionsContext();

  const [selectedActionStatus, setSelectedActionStatus] =
    useState('number_of_actions');

  const actions = useMemo(() => {
    if (!main_courante_actions) return [];
    if (selectedActionStatus === 'number_of_actions') {
      return main_courante_actions;
    }
    if (selectedActionStatus === 'openeds') {
      return main_courante_actions.filter(({ closed }) => !closed);
    }
    if (selectedActionStatus === 'closeds') {
      return main_courante_actions.filter(({ closed }) => closed);
    }

    return [];
  }, [selectedActionStatus, main_courante_actions]);

  const useQuery = useMutationCloseMainCouranteAction({
    main_courante_id: eventId,
    tabId: selectedTab,
    filters,
  });

  const handleCloseAction = useCallback(
    async (main_courante_action_id: string) => {
      await useQuery.mutateAsync({
        main_courante_action_id,
        closed: true,
      });
    },
    [useQuery],
  );

  const handleOpenAction = useCallback(
    async (main_courante_action_id: string) => {
      await useQuery.mutateAsync({
        main_courante_action_id,
        closed: false,
      });
    },
    [useQuery],
  );

  useEffect(() => {
    if (main_courante_actions) {
      if (actions.length > 0) {
        const selected = actions.find(({ id }) => id === selectedAction);
        if (selected) return;
        onActionSelect(actions[0].id);
        return;
      }
    }
    onActionSelect(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions, onActionSelect, main_courante_actions]);

  useEffect(() => {
    if (!user?.organization.setup?.showAllActions) {
      setListOfActivedMainCouranteActions(actions);
    }
  }, [
    setListOfActivedMainCouranteActions,
    actions,
    user?.organization.setup?.showAllActions,
  ]);

  return (
    <Container>
      <H6>{t('the_actions')}</H6>
      {isLoading ? (
        <Spinner color={theme.purple} size="large" />
      ) : main_courante_actions ? (
        <>
          <ActionSelectorContainer>
            <MainCouranteActionSelector
              onPress={() => setSelectedActionStatus('number_of_actions')}
              selected={selectedActionStatus === 'number_of_actions'}
              text={t('number_of_actions')}
              badgeText={String(main_courante_actions?.length) || '0'}
              badgeColor={theme.yellow}
            />
            <MainCouranteActionSelector
              onPress={() => setSelectedActionStatus('openeds')}
              selected={selectedActionStatus === 'openeds'}
              text={t('openeds')}
              badgeText={
                String(
                  main_courante_actions?.filter(({ closed }) => !closed).length,
                ) || '0'
              }
              badgeColor="#46D044"
            />
            <MainCouranteActionSelector
              onPress={() => setSelectedActionStatus('closeds')}
              selected={selectedActionStatus === 'closeds'}
              text={t('closeds')}
              badgeText={
                String(
                  main_courante_actions?.filter(({ closed }) => closed).length,
                ) || '0'
              }
              badgeColor={theme.red}
            />
          </ActionSelectorContainer>
          {actions &&
            actions.map(action => (
              <MainCouranteInfoActionPreview
                key={action.id}
                id={action.id}
                title={action.title}
                date={action.formatted_date}
                closed={action.closed}
                location_name={action.location_name}
                openCloseLoading={
                  useQuery.variables?.main_courante_action_id === action.id &&
                  useQuery.isLoading
                }
                selected={selectedAction === action.id}
                onActionPress={() => onActionSelect(action.id)}
                handleOpenAction={() => handleOpenAction(action.id)}
                handleCloseAction={() => handleCloseAction(action.id)}
              />
            ))}
        </>
      ) : null}
    </Container>
  );
};
