import styled from 'styled-components/native';

export const Container = styled.View`
  max-width: 90%;
  height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.purpleBlue};
  margin-right: 7px;
  margin: 2px;
`;
