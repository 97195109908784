import { Body2, Caption } from '@nucleus/Typography';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native';

interface AnalyticsTabTooltipContentProps {
  text: string;
  lastUpdateFormatted: string;
}

export const AnalyticsTabTooltipContent = ({
  text,
  lastUpdateFormatted,
}: AnalyticsTabTooltipContentProps) => {
  const { t } = useTranslation();
  return (
    <View>
      <Body2>{text}</Body2>
      <Caption>{`${t('last_updated')}: ${lastUpdateFormatted}`}</Caption>
    </View>
  );
};
