import { Caption } from '@nucleus/Typography';
import { TimeLabel } from '@atoms/TimeLabel';
import { Container } from './styles';

interface EventCardCityAndTimeProps {
  locationName?: string;
  formattedDate: string;
}

export const EventCardCityAndTime = ({
  locationName,
  formattedDate,
}: EventCardCityAndTimeProps) => {
  return (
    <Container>
      <Caption style={{ marginRight: 8 }}>
        {locationName ? locationName.slice(0, 40) : ''}
      </Caption>
      <TimeLabel formattedDate={formattedDate} />
    </Container>
  );
};
