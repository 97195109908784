import { MainCouranteBadge } from '@atoms/MainCouranteBadge';
import { IntensityView } from '@atoms/IntensityView';
import { OpenCloseButton } from '@atoms/OpenCloseButton';

import { Container, SideBySideContent } from './styles';

interface MainCouranteInfoOverviewTopProps {
  intensity: number;
  closed: boolean;
  onOpenMainCourantePress: () => void;
  onCloseMainCourantePress: () => void;
  openCloseLoading: boolean;
}

export const MainCouranteInfoOverviewTop = ({
  intensity,
  closed,
  onCloseMainCourantePress,
  onOpenMainCourantePress,
  openCloseLoading,
}: MainCouranteInfoOverviewTopProps) => {
  return (
    <Container>
      <MainCouranteBadge />
      <SideBySideContent>
        <IntensityView intensity={intensity} />
        <OpenCloseButton
          style={{ marginLeft: 8 }}
          closed={closed}
          loading={openCloseLoading}
          onClosePress={onCloseMainCourantePress}
          onOpenPress={onOpenMainCourantePress}
        />
      </SideBySideContent>
    </Container>
  );
};
