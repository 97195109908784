import { Body2, Caption } from '@nucleus/Typography';
import { TimeLabel } from '@atoms/TimeLabel';

import { Container, SideBySideContent } from './styles';

interface MainCouranteInfoOverviewInfosProps {
  name: string;
  location_name: string;
  formatted_date: string;
}

export const MainCouranteInfoOverviewInfos = ({
  name,
  location_name,
  formatted_date,
}: MainCouranteInfoOverviewInfosProps) => {
  return (
    <Container>
      <Body2>{name}</Body2>
      <SideBySideContent>
        <Caption numberOfLines={1}>{location_name}</Caption>
        <TimeLabel formattedDate={formatted_date} style={{ marginLeft: 8 }} />
      </SideBySideContent>
    </Container>
  );
};
