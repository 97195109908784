/* eslint-disable @typescript-eslint/no-explicit-any */
import { storeState, getState } from './asyncStorage';

interface Location {
  bbox: any;
  place_name: string;
  geometry: any;
}

export interface Size {
  width: number;
  height: number;
}

export interface Position {
  x: number;
  y: number;
}

export interface Filters {
  categories: number[];
  any_terms: string[];
  all_terms: string[];
  exclude_terms: string[];
  location?: Location;
  relevancies: number[];
  start_date?: Date;
  end_date?: Date;
  mainCourante: string[];
  zones: string[];
}

export interface Tab {
  id: string;
  name: string;
  filters: Filters;
  filterScreenOpen: boolean;
  eventListOpened: boolean;
  cartographicSelectorOpened: boolean;
  cartographicSelectorPosition: Position;
  mapCartographicStyle: string;
  activatedEventId: string | null;
  activatedActionId: string | null;
  eventDetailScreenSize: Size;
  eventDetailScreenPosition: Position;
}

async function createTab(tab: Tab): Promise<Tab> {
  const tabs = await getState<Tab[]>('tabs');
  if (!tabs) {
    storeState('tabs', [tab]);
    return tab;
  }
  storeState('tabs', [...tabs, tab]);
  return tab;
}

async function getTabData(tabId: string): Promise<Tab | undefined> {
  const tabs = await getState<Tab[]>('tabs');
  if (!tabs) return undefined;
  const selectedTab = tabs.find(tab => tab.id === tabId);
  return selectedTab;
}

async function deleteTab(tabId: string): Promise<void> {
  const tabs = await getState<Tab[]>('tabs');
  if (!tabs) return;

  await storeState(
    'tabs',
    tabs.filter(tab => tab.id !== tabId),
  );
}

async function updateTabData(tabId: string, data: Partial<Tab>): Promise<void> {
  const tabs = await getState<Tab[]>('tabs');
  if (!tabs) return;
  await storeState(
    'tabs',
    tabs.map(tab => {
      if (tab.id === tabId) {
        const tabData = {
          ...tab,
          ...data,
        };
        return tabData;
      }
      return tab;
    }),
  );
}

async function listTabs(): Promise<Tab[]> {
  const tabs = await getState<Tab[]>('tabs');
  return tabs || [];
}

export { createTab, getTabData, updateTabData, listTabs, deleteTab };
