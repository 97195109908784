import { useRef } from 'react';
import { TextInputProps } from 'react-native';
import { useTheme } from 'styled-components/native';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

import { InputText } from './styles';

export interface RenameInputProps extends TextInputProps {
  onBlur: () => void;
}

export const RenameInput = ({
  style,
  value,
  onBlur,
  ...rest
}: RenameInputProps) => {
  const inputRef = useRef(null);
  const theme = useTheme();

  useOutsideClick({
    componentRef: inputRef,
    yOffset: 0,
    onOutsideClick: () => onBlur(),
  });

  return (
    <InputText
      ref={inputRef}
      style={style}
      placeholderTextColor={theme.purpleBlue}
      value={value}
      onBlur={onBlur}
      {...rest}
    />
  );
};
