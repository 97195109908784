import styled, { css } from 'styled-components/native';
import { transparentize } from 'polished';

export const Container = styled.View`
  width: 80px;
  height: 30px;
  flex-direction: row;
  border-radius: 20px;
`;

interface ItensProps {
  isSelected: boolean;
  isHovered: boolean;
}

export const GridContainer = styled.TouchableOpacity<ItensProps>`
  width: 40px;
  height: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.purpleBlue};
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-width: 2px;
  border-right-width: 1px;
  ${({ isSelected, isHovered, theme }) => {
    if (isSelected) {
      return css`
        background-color: ${theme.purpleBlue};
      `;
    }
    if (isHovered) {
      return css`
        background-color: ${transparentize(0.85, theme.purpleBlue)};
      `;
    }
    return css`
      background-color: ${transparentize(0.8, theme.background)};
    `;
  }}
`;

export const ListContainer = styled.TouchableOpacity<ItensProps>`
  width: 40px;
  height: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme }) => theme.purpleBlue};
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-width: 2px;
  border-left-width: 1px;
  ${({ isSelected, isHovered, theme }) => {
    if (isSelected) {
      return css`
        background-color: ${theme.purpleBlue};
      `;
    }
    if (isHovered) {
      return css`
        background-color: ${transparentize(0.85, theme.purpleBlue)};
      `;
    }
    return css`
      background-color: ${transparentize(0.8, theme.background)};
    `;
  }}
`;
