import SVG from 'react-inlinesvg';
import { Container, CategoryName, IconView } from './styles';

interface EventCardCategoryProps {
  name: string;
  color: string;
  icon?: string;
}

const SMALL_NAME_LIMIT = 15;

export const EventCardCategory = ({
  name,
  color,
  icon,
}: EventCardCategoryProps) => {
  return (
    <Container color={color}>
      <CategoryName small={name.length > SMALL_NAME_LIMIT}>
        {name.toLocaleUpperCase().slice(0, 25)}
      </CategoryName>
      {icon && (
        <IconView>
          <SVG
            src={icon}
            width={12}
            fill={color}
            height="100%"
            title="category_icon"
          />
        </IconView>
      )}
    </Container>
  );
};
