import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Geometry as NebulaGeometry } from '@nebula.gl/edit-modes';
import { api } from '@services/eventApi';
import { queryClient } from '@services/queryClient';
import { useToastContext } from '@contexts/ToastContext';

export type Geometry = NebulaGeometry;

interface UpdateMainCouranteActionData {
  title?: string;
  desc?: string;
  date?: Date;
  location_name?: string;
  location_coordinates?: Geometry;
  location_color?: string;
  action_type_id?: number;
  media?: string[];
}

interface MutationUpdateMainCouranteActionData
  extends UpdateMainCouranteActionData {
  main_courante_action_id: string;
}

export async function updateMainCouranteAction(
  main_courante_action_id: string,
  data: UpdateMainCouranteActionData,
) {
  const response = await api.patch(
    `/v1/main_courante_actions/${main_courante_action_id}`,
    data,
  );
  return response.data || response;
}

export const useMutationUpdateMainCouranteAction = (courante_id: string) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({
      main_courante_action_id,
      title,
      desc,
      date,
      location_name,
      location_coordinates,
      location_color,
      action_type_id,
      media,
    }: MutationUpdateMainCouranteActionData) => {
      const updatedMainCouranteAction = await updateMainCouranteAction(
        main_courante_action_id,
        {
          title,
          desc,
          date,
          location_name,
          location_coordinates,
          location_color,
          action_type_id,
          media,
        },
      );
      return updatedMainCouranteAction;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['mainCourante', courante_id]);
        dispatchToast({
          message: t('success_update_main_courante_action'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_update_main_courante_action'),
          type: 'error',
        });
      },
    },
  );
};
