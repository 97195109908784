import styled from 'styled-components/native';
import { Caption } from '@nucleus/Typography';

export const Cointainer = styled.View`
  width: 100%;
  height: 100%;
  padding: 16px;
`;

export const Legends = styled(Caption)`
  color: ${({ theme }) => theme.purpleBlue};
  margin-top: -20px;
  max-width: 600px;
`;

export const ChartsContainer = styled.View`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const TopContainer = styled.View`
  width: 100%;
  height: 40%;
  align-items: center;
  justify-content: center;
`;

export const RiskStatisticsWrapper = styled.View`
  flex-direction: row;
  z-index: 1;
`;

export const BottomContainer = styled.View`
  width: 100%;
  height: 58%;
  flex-direction: row;
  margin-top: 16px;
`;

export const BottomContainerLeft = styled.View`
  width: 50%;
  height: 100%;
  align-items: center;
`;

export const BottomContainerRigth = styled.View`
  width: 50%;
  height: 100%;
  align-items: center;
`;
