import { IconButton } from '@atoms/IconButton';
import closeIcon from '../../../assets/icons/close.svg';
import maximizeIcon from '../../../assets/icons/maximize.svg';
import { Container } from './styles';

interface EventInfoHeaderProps {
  onFullScreenPress: () => void;
  onClosePress: () => void;
}

export const EventInfoHeader = ({
  onFullScreenPress,
  onClosePress,
}: EventInfoHeaderProps) => {
  return (
    <Container>
      <IconButton icon={maximizeIcon} onPress={onFullScreenPress} />
      <IconButton icon={closeIcon} onPress={onClosePress} />
    </Container>
  );
};
