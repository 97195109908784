import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 4px;
  flex-direction: row;
  padding: 0 6px 0 4px;
`;

export const ContentAvatar = styled.View`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #c4c4c4;
`;

export const ContentDetail = styled.View`
  display: flex;
  margin-left: 8px;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 45px);
`;

export const TitleContainer = styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const TitleWrapper = styled.View`
  width: calc(100% - 100px);
`;

export const ContentDataSource = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TagContainer = styled.View`
  width: 100%;
  height: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;
