import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useWindowDimensions, Platform, Keyboard } from 'react-native';
import { isMobile } from 'react-device-detect';

interface SizeContextData {
  isSmallScreen: boolean;
  keyboardUp: boolean;
}
interface StorageStateProviderProps {
  children: ReactNode;
}

const SizeContext = createContext({} as SizeContextData);

function SizeContextProvider({ children }: StorageStateProviderProps) {
  const window = useWindowDimensions();

  const [oldHeight, setOldHeight] = useState(window.height);
  const [keyboardUp, setKeyboardUp] = useState(false);
  const [keyboardMobileUp, setKeyboardMobileUp] = useState(false);

  const keyboardDidShow = () => setKeyboardMobileUp(true);
  const keyboardDidHide = () => setKeyboardMobileUp(false);

  useEffect(() => {
    Keyboard.addListener('keyboardDidShow', keyboardDidShow);
    Keyboard.addListener('keyboardDidHide', keyboardDidHide);

    return () => {
      Keyboard.removeListener('keyboardDidShow', keyboardDidShow);
      Keyboard.removeListener('keyboardDidHide', keyboardDidHide);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      if (oldHeight > window.height) {
        setKeyboardUp(true);
      } else {
        setKeyboardUp(false);
      }
      setOldHeight(window.height);
    }
  }, [oldHeight, window.height]);

  return (
    <SizeContext.Provider
      value={{
        isSmallScreen: window.width < 1200,
        keyboardUp: Platform.OS === 'web' ? keyboardUp : keyboardMobileUp,
      }}
    >
      {children}
    </SizeContext.Provider>
  );
}

function useSizeContext(): SizeContextData {
  const context = useContext(SizeContext);

  return context;
}

export { SizeContextProvider, useSizeContext };
