/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  StatusBar,
  Platform,
  KeyboardAvoidingView,
  SafeAreaView,
} from 'react-native';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  useFonts,
  OpenSans_400Regular,
  OpenSans_600SemiBold,
  OpenSans_700Bold,
  OpenSans_800ExtraBold,
} from '@expo-google-fonts/open-sans';
import AppLoading from 'expo-app-loading';
import { QueryClientProvider } from 'react-query';
import { PortalProvider } from '@gorhom/portal';
import Navigation from './navigation';
import GlobalStyle from './styles/Global';
import ThemeProvider from './styles/ThemeProvider';
import { defaultTheme } from './styles/theme';
import AppContextsProvider from './contexts';
import './languages';
import 'rc-tabs/assets/index.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/reactDatePickerStyleOverwrite.css';
import 'rc-time-picker/assets/index.css';
import './styles/rcTimePickerOverwrite.css';
import 'react-day-picker/lib/style.css';

import { queryClient } from './services/queryClient';

export const App = () => {
  const [fontsLoaded] = useFonts({
    OpenSans_400Regular,
    OpenSans_600SemiBold,
    OpenSans_700Bold,
    OpenSans_800ExtraBold,
  });

  if (!fontsLoaded && Platform.OS !== 'web') {
    return <AppLoading />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AppContextsProvider>
          {Platform.OS === 'web' && <GlobalStyle />}

          <StatusBar
            barStyle="light-content"
            backgroundColor={defaultTheme.background}
          />
          <KeyboardAvoidingView
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            style={{ flex: 1 }}
          >
            <SafeAreaView
              style={{ backgroundColor: defaultTheme.background, flex: 1 }}
            >
              <PortalProvider>
                <Navigation />
              </PortalProvider>
            </SafeAreaView>
          </KeyboardAvoidingView>
        </AppContextsProvider>
      </ThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};
