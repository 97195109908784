/* eslint-disable @typescript-eslint/no-explicit-any */
import { Feature } from '@nebula.gl/edit-modes';
import { storeState, getState } from './asyncStorage';

export type Zone = Feature;

async function createZone(zone: Zone): Promise<Zone> {
  const zones = await getState<Zone[]>('zones');
  if (!zones) {
    storeState('zones', [zone]);
    return zone;
  }
  storeState('zones', [...zones, zone]);
  return zone;
}

async function getZoneData(zoneId: string): Promise<Zone | undefined> {
  const zones = await getState<Zone[]>('zones');
  if (!zones) return undefined;
  const selectedZone = zones.find(zone => zone.id === zoneId);
  return selectedZone;
}

async function deleteZone(zoneId: string): Promise<void> {
  const zones = await getState<Zone[]>('zones');
  if (!zones) return;

  await storeState(
    'zones',
    zones.filter(zone => zone.id !== zoneId),
  );
}

async function updateZoneData(
  zoneId: string,
  data: Partial<Zone>,
): Promise<void> {
  const zones = await getState<Zone[]>('zones');
  if (!zones) return;
  await storeState(
    'zones',
    zones.map(zone => {
      if (zone.id === zoneId) {
        const zoneData = {
          ...zone,
          ...data,
        };
        return zoneData;
      }
      return zone;
    }),
  );
}

async function listZones(): Promise<Zone[]> {
  const zones = await getState<Zone[]>('zones');
  return zones || [];
}

export { createZone, getZoneData, updateZoneData, listZones, deleteZone };
