/* eslint-disable @typescript-eslint/no-empty-function */
import { useTranslation } from 'react-i18next';
import { Container, Content, ButtonsContainer, ActionButton } from './styles';
import { Body1 } from '../../Typography';
import { Input } from '../../Input';

interface RenameModalProps {
  onConfirm: () => void;
  name: string;
  onChangeName: (newName: string) => void;
  onCancel: () => void;
}

export const RenameModal = ({
  onCancel,
  onConfirm,
  name,
  onChangeName,
}: RenameModalProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Content>
        <Body1 />
        <Input
          label={t('want_rename_file')}
          value={name}
          onChangeText={onChangeName}
        />

        <ButtonsContainer>
          <ActionButton outline onPress={onCancel}>
            {t('modal_cancel')}
          </ActionButton>
          <ActionButton onPress={onConfirm}>{t('modal_confirm')}</ActionButton>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};
