import styled from 'styled-components/native';
import { Button } from '@atoms/Button';

export const Container = styled.View`
  width: 100%;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const MoreDetailsButton = styled(Button)`
  max-width: 120px;
  max-height: 28px;
  margin-top: 16px;
  margin-bottom: 16px;
`;
