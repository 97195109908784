import React, { useMemo } from 'react';
import { EventsMapEventMarker } from '@molecules/EventsMapEventMarker';
import { EventsMapMainCouranteMaker } from '@molecules/EventsMapMainCouranteMaker';
import { EventsMapMainCouranteActionMaker } from '@molecules/EventsMapMainCouranteActionMaker';
import { EventsMapMarkerGeneratorProps } from './types';

const areEqual = (
  prevProps: EventsMapMarkerGeneratorProps,
  nextProps: EventsMapMarkerGeneratorProps,
) => {
  if (prevProps.events.length !== nextProps.events.length) return false;
  if (
    prevProps.mainCouranteActions.length !==
    nextProps.mainCouranteActions.length
  )
    return false;
  if (prevProps.markerSize !== nextProps.markerSize) return false;

  return true;
};

export const EventsMapMarkerGenerator = React.memo(
  ({
    mainCouranteActions,
    events,
    updateActivatedEventId,
    updateActivatedActionId,
    markerSize = 60,
    typeMainCouranteActionToShow,
  }: EventsMapMarkerGeneratorProps) => {
    //   const { user } = useAuthContext();
    const filtredEvents = useMemo(
      () =>
        events.filter(
          event =>
            event.location_coordinates?.type === 'Point' &&
            event.location_coordinates?.coordinates,
        ),
      [events],
    );

    const filtredMainCouranteActions = useMemo(
      () =>
        mainCouranteActions.filter(mainCouranteAction => {
          const actionClosedType = mainCouranteAction.closed
            ? 'closed'
            : 'opened';
          if (!(actionClosedType === typeMainCouranteActionToShow))
            return false;
          if (!(mainCouranteAction.location_coordinates?.type === 'Point'))
            return false;
          return true;
        }),
      [mainCouranteActions, typeMainCouranteActionToShow],
    );

    return (
      <>
        {filtredEvents.map(event => (
          <>
            {event.type === 'normal' ? (
              <EventsMapEventMarker
                key={`${event.id}_marker`}
                id={String(event.id)}
                latitude={event.location_coordinates.coordinates[1]}
                longitude={event.location_coordinates.coordinates[0]}
                size={markerSize}
                color={event.category.color}
                icon={event.category.icon}
                onPress={() => updateActivatedEventId(String(event.id))}
              />
            ) : (
              <EventsMapMainCouranteMaker
                key={`${event.id}_marker`}
                id={String(event.id)}
                latitude={event.location_coordinates.coordinates[1] as number}
                longitude={event.location_coordinates.coordinates[0] as number}
                size={markerSize}
                onPress={() => updateActivatedEventId(String(event.id))}
              />
            )}
          </>
        ))}
        {filtredMainCouranteActions.map(mainCouranteAction => {
          return (
            <EventsMapMainCouranteActionMaker
              key={`${mainCouranteAction.id}_marker`}
              id={String(mainCouranteAction.id)}
              latitude={
                mainCouranteAction.location_coordinates?.coordinates[1] || 0
              }
              longitude={
                mainCouranteAction.location_coordinates?.coordinates[0] || 0
              }
              closed={mainCouranteAction.closed}
              size={markerSize / 2.5}
              onPress={() => {
                updateActivatedEventId(
                  String(mainCouranteAction.main_courante_id),
                );
                updateActivatedActionId(String(mainCouranteAction.id));
              }}
            />
          );
        })}
      </>
    );
  },
  areEqual,
);
