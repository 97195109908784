import styled, { css } from 'styled-components/native';
import { TouchableOpacity } from 'react-native';
import { transparentize } from 'polished';

interface ContainerProps {
  isHovered: boolean;
}

export const Container = styled(TouchableOpacity)<ContainerProps>`
  background-color: ${({ theme }) => theme.blueishBlack};
  width: 200px;
  height: 152px;
  border-radius: 8px;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ theme, isHovered }) => {
    if (isHovered) {
      return css`
        box-shadow: 0px 0px 8px ${transparentize(0.4, theme.purpleBlue)};
      `;
    }
    return css`
      box-shadow: 0px 0px 2px ${transparentize(0.55, theme.purpleBlue)};
    `;
  }}

  margin: 8px;
`;

export const InfoContainer = styled.View`
  padding: 8px 16px 8px 16px;
  width: 100%;
  height: 25%;
`;
