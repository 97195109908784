import { ComponentType } from 'react';
import { SvgProps } from 'react-native-svg';

interface IconProps extends SvgProps {
  as: ComponentType<SvgProps>;
}

export const Icon = ({ as: IconItem, ...rest }: IconProps) => {
  return <IconItem {...rest} />;
};
