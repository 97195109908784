import axios from 'axios';
import { config } from '../config';

const api = axios.create({
  baseURL: config.EVENT_API,
});

api.defaults.headers = {
  Authorization: '$2a$08$TiCVOvW2ZRuXq6buL4e47.C3YoTx6I2LUk1c4EoxJcc/kK6shoZgS',
};

export { api };
