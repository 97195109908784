/* eslint-disable import/no-duplicates */
import { useQuery } from 'react-query';
import { format, parseISO } from 'date-fns';
import i18n from 'i18next';
import { api } from '../eventApi';
import { getDateFnsLocale } from '../../utils/getDateFnsLocale';

export interface Content {
  id: string;
  source_name: string | null;
  source_image: string | null;
  text: string;
  desc: string;
  url: string;
  date: string;
  image: string;
  photos: string[];
  videos: string[];
}

export async function getContentInfo(content_id: string): Promise<Content> {
  const response = await api.get(`/v1/contents/${content_id}`);

  const locale = getDateFnsLocale();
  const desc: string = response.data.additional_data?.np3k_content?.text || '';
  return {
    ...response.data,
    desc:
      desc.search('JavaScript is not available') === 0
        ? i18n.t('content_text_error')
        : desc,
    photos: response.data.photos || [],
    videos: response.data.videos || [],
    date: format(parseISO(response.data.date), "dd MMMM HH'h'mm OOOO", {
      locale,
    }),
  };
}

export function useQueryContentInfo(contentId: string) {
  return useQuery(['content', contentId], () => getContentInfo(contentId), {
    enabled: !!contentId,
    refetchOnWindowFocus: false,
  });
}
