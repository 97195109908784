import { Badge } from '@atoms/Badge';
import { Caption } from '@nucleus/Typography';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Container, ActionType } from './styles';

interface MainCouranteCardActionTypeListProps {
  total: number;
  opened: number;
  closed: number;
}

export const MainCouranteCardActionTypeList = ({
  total,
  opened,
  closed,
}: MainCouranteCardActionTypeListProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container>
      <ActionType>
        <Caption>{t('number_of_actions')}</Caption>
        <Badge
          color={theme.yellow}
          text={String(total)}
          style={{ marginLeft: 4 }}
        />
      </ActionType>
      <ActionType>
        <Caption>{t('openeds')}</Caption>
        <Badge
          color="#46D044"
          text={String(opened)}
          style={{ marginLeft: 4 }}
        />
      </ActionType>
      <ActionType>
        <Caption>{t('closeds')}</Caption>
        <Badge
          color={theme.red}
          text={String(closed)}
          style={{ marginLeft: 4 }}
        />
      </ActionType>
    </Container>
  );
};
