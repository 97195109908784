import React, {
  useState,
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import { Feature, ViewMode, ModifyMode } from '@nebula.gl/edit-modes';
import { useTheme } from 'styled-components/native';
import { ExtendedDrawLineStringMode } from '../utils/ExtendedDrawLineStringMode';
import { ExtendedDrawPolygonMode } from '../utils/ExtendedDrawPolygonMode';
import { ExtendedDrawPointMode } from '../utils/ExtendedDrawPointMode';
import { useEventsContext } from './EventsContext';
import { useActivatedMainCouranteActionsContext } from './ActivatedMainCouranteActions';

export type Mode =
  | ExtendedDrawLineStringMode
  | ExtendedDrawPolygonMode
  | ExtendedDrawPointMode
  | ModifyMode
  | ViewMode
  | undefined;

export type ZoneTypes =
  | 'filter'
  | 'normal'
  | 'main_courante'
  | 'main_courante_action';

export type DrawTypes = 'Polygon' | 'LineString' | 'Point';

export interface SetDrawModeParams {
  zoneType: ZoneTypes;
  zoneId: string;
  drawType: DrawTypes;
}

export type Zone = Feature;

interface ZonesContextData {
  mode: Mode;
  zones: Zone[];
  selectedZone: Zone | null;
  selectedZoneIndex: number | null;
  activeViewMode: () => void;
  activeEditMode: () => void;
  activeNoInteractionMode: () => void;
  removeCurrentModeLastCoordinates: () => void;
  isCreatingZone: boolean;
  setIsCreatingZone: (value: boolean) => void;
  isEditingZone: boolean;
  setIsEditingZone: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateZone: (item: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectZone: (options: any) => void;
  currentZoneName: string;
  changeCurrentZoneName: (name: string) => void;
  currentZoneColor: string;
  changeCurrentZoneColor: (color: string) => void;
  currentZoneType: ZoneTypes;
  addNewZone: (zone: Zone) => void;
  deleteZoneById: (zoneId: string) => void;
  currentZoneId?: string;
  setCurrentZoneId: (zoneId: string) => void;
  getZoneById: (zoneId: string) => Zone | undefined;
  removeZones: (type?: ZoneTypes) => void;
  setDrawMode(params: SetDrawModeParams): void;
}

const ZonesContext = createContext<ZonesContextData>({} as ZonesContextData);

interface EventScreenProviderProps {
  children: ReactNode;
}

const defaultZoneName = 'New Zone';
const defaultZoneColor = '#991BFA';
const defaultZoneType = 'normal';

const ZonesContextProvider = ({ children }: EventScreenProviderProps) => {
  const theme = useTheme();
  const { updateActivatedEventId } = useEventsContext();
  const { updateActivatedActionId } = useActivatedMainCouranteActionsContext();
  const [zones, setZones] = useState<Zone[]>([]);
  const [backupZones, setBackupZones] = useState<Zone[]>([]);
  const [mode, setMode] = useState<Mode>(undefined);

  const [selectedZoneIndex, setSelectedZoneIndex] = useState<number | null>(
    null,
  );
  const [selectedZone, setSelectedZone] = useState<Zone | null>(null);

  const [isCreatingZone, setIsCreatingZone] = useState(false);
  const [isEditingZone, setIsEditingZone] = useState(false);

  const [currentZoneName, setCurrentZoneName] = useState(defaultZoneName);
  const [currentZoneColor, setCurrentZoneColor] = useState(defaultZoneColor);
  const [currentZoneId, setCurrentZoneId] = useState<string>();

  const [currentZoneType, setCurrentZoneType] =
    useState<ZoneTypes>(defaultZoneType);

  const removeZones = useCallback(
    (type?: 'filter' | 'normal' | 'main_courante' | 'main_courante_action') => {
      setZones(oldZones => [
        ...oldZones.filter(
          existedZone => existedZone.properties?.type !== type,
        ),
      ]);
    },
    [],
  );

  const addNewZone = useCallback((zone: Feature) => {
    setZones(oldZones => [
      ...oldZones.filter(existedZone => existedZone.id !== zone.id),
      zone,
    ]);
    setSelectedZone(zone);
  }, []);

  const deleteZoneById = useCallback((zoneId: string) => {
    setZones(oldZones => {
      return [...oldZones.filter(existedZone => existedZone.id !== zoneId)];
    });
  }, []);

  const getZoneById = useCallback(
    (zoneId: string) => {
      return zones.find(zone => zone.id === zoneId);
    },
    [zones],
  );

  const removeCurrentModeLastCoordinates = useCallback(() => {
    if (
      mode instanceof ExtendedDrawLineStringMode ||
      mode instanceof ExtendedDrawPointMode ||
      mode instanceof ExtendedDrawPolygonMode
    ) {
      mode.removeLastCoordinates();
    }
  }, [mode]);

  const changeCurrentZoneName = useCallback(
    (name: string) => {
      if (isEditingZone && selectedZoneIndex !== null) {
        setZones(oldZones =>
          oldZones.map((zone, index) => {
            if (index === selectedZoneIndex) {
              return {
                ...zone,
                properties: {
                  ...zone.properties,
                  name,
                },
              };
            }
            return zone;
          }),
        );
      }
      setCurrentZoneName(name);
    },
    [isEditingZone, selectedZoneIndex],
  );

  const changeCurrentZoneColor = useCallback(
    (color: string) => {
      if (isEditingZone && selectedZoneIndex !== null) {
        setZones(oldZones =>
          oldZones.map((zone, index) => {
            if (index === selectedZoneIndex) {
              return {
                ...zone,
                properties: {
                  ...zone.properties,
                  color,
                },
              };
            }
            return zone;
          }),
        );
      }
      setCurrentZoneColor(color);
    },
    [isEditingZone, selectedZoneIndex],
  );

  const changeCurrentZoneType = useCallback(
    (type: ZoneTypes) => {
      if (isEditingZone && selectedZoneIndex !== null) {
        setZones(oldZones =>
          oldZones.map((zone, index) => {
            if (index === selectedZoneIndex) {
              return {
                ...zone,
                properties: {
                  ...zone.properties,
                  type,
                },
              };
            }
            return zone;
          }),
        );
      }

      setCurrentZoneType(type);
    },
    [isEditingZone, selectedZoneIndex],
  );

  const setDrawMode = useCallback(
    ({ zoneType, zoneId, drawType }: SetDrawModeParams) => {
      changeCurrentZoneType(zoneType);
      setCurrentZoneId(zoneId);
      if (zoneType === 'normal') {
        changeCurrentZoneColor(defaultZoneColor);
      }
      if (zoneType === 'main_courante') {
        changeCurrentZoneColor('#FFA63F');
      }
      if (zoneType === 'main_courante_action') {
        changeCurrentZoneColor('#5EFF5A');
      }
      if (drawType === 'Point') {
        setMode(new ExtendedDrawPointMode());
      }
      if (drawType === 'Polygon') {
        setMode(new ExtendedDrawPolygonMode());
      }
      if (drawType === 'LineString') {
        setMode(new ExtendedDrawLineStringMode());
      }
    },
    [changeCurrentZoneColor, changeCurrentZoneType],
  );

  const activeNoInteractionMode = useCallback(() => {
    setMode(undefined);
  }, []);

  const activeEditMode = useCallback(() => {
    setMode(new ModifyMode());
  }, []);

  const activeViewMode = useCallback(() => {
    setMode(new ViewMode());
  }, []);

  const onUpdateZone = useCallback(
    item => {
      if (item.editType === 'addFeature') {
        const newZoneData = item.data[item.data.length - 1];
        const newZone = {
          ...newZoneData,
          id: currentZoneId,
          properties: {
            ...newZoneData.properties,
            name: currentZoneName,
            type: currentZoneType,
            color:
              // eslint-disable-next-line no-nested-ternary
              currentZoneType === 'main_courante'
                ? 'url(#main_courante)'
                : currentZoneType === 'main_courante_action'
                ? theme.eletricGreen
                : currentZoneColor,
            editable:
              currentZoneType !== 'main_courante' &&
              currentZoneType !== 'main_courante_action',
          },
        };

        setZones(oldZones => [...oldZones, newZone]);
        setSelectedZoneIndex(item.data.length - 1);
        activeEditMode();
        return;
      }
      setZones(item.data);
    },
    [
      currentZoneId,
      currentZoneName,
      currentZoneType,
      theme.eletricGreen,
      currentZoneColor,
      activeEditMode,
    ],
  );

  const onSelectZone = useCallback(
    options => {
      if (options.selectedFeature) {
        if (options.selectedFeature.properties.type === 'main_courante') {
          updateActivatedEventId(options.selectedFeature.id);
        }
        if (
          options.selectedFeature.properties.type === 'main_courante_action'
        ) {
          updateActivatedActionId(options.selectedFeature.id);
          updateActivatedEventId(
            options.selectedFeature.properties.main_courante_id,
          );
        }

        setSelectedZone(options.selectedFeature);
      }
      if (!isCreatingZone) {
        setSelectedZoneIndex(options.selectedFeatureIndex);
        if (options.selectedFeature?.properties?.editable) {
          activeEditMode();
        } else {
          activeNoInteractionMode();
        }
      }
    },
    [
      activeEditMode,
      activeNoInteractionMode,
      isCreatingZone,
      updateActivatedActionId,
      updateActivatedEventId,
    ],
  );

  useEffect(() => {
    if (
      mode instanceof ExtendedDrawPolygonMode ||
      mode instanceof ExtendedDrawLineStringMode ||
      mode instanceof ExtendedDrawPointMode
    ) {
      setBackupZones(zones);
      setZones([]);
      setIsCreatingZone(true);
      return;
    }
    if (mode instanceof ModifyMode) {
      setIsEditingZone(true);
      return;
    }
    setZones(backupZones);
    setBackupZones([]);
    setIsCreatingZone(false);
    setIsEditingZone(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <ZonesContext.Provider
      value={{
        mode,
        zones,
        selectedZone,
        selectedZoneIndex,
        activeViewMode,
        activeEditMode,
        activeNoInteractionMode,
        removeCurrentModeLastCoordinates,
        isCreatingZone,
        setIsCreatingZone,
        currentZoneType,
        currentZoneId,
        setCurrentZoneId,
        isEditingZone,
        setIsEditingZone,
        onUpdateZone,
        onSelectZone,
        currentZoneName,
        changeCurrentZoneName,
        currentZoneColor,
        changeCurrentZoneColor,
        addNewZone,
        deleteZoneById,
        getZoneById,
        removeZones,
        setDrawMode,
      }}
    >
      {children}
    </ZonesContext.Provider>
  );
};

function useZonesContext(): ZonesContextData {
  const context = useContext(ZonesContext);

  return context;
}

export { ZonesContextProvider, useZonesContext };
