import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 8px 16px;
`;

export const WidgetsContainer = styled.View`
  width: 100%;
  height: calc(100% - 100px);
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-style: dashed;
  border-color: ${({ theme }) => theme.white};
  border-width: 1px;
  margin-top: 8px;
`;
