import { Icon } from '@nucleus/Icon';
import { Hoverable } from 'react-native-web-hooks';
import { Container, GridContainer, ListContainer } from './styles';
import gridIcon from '../../../assets/icons/grid.svg';
import listIcon from '../../../assets/icons/list_format.svg';

interface GridSelectorProps {
  selectedFormat: 'grid' | 'list';
  onSelect: (format: 'grid' | 'list') => void;
}

export const GridSelector = ({
  selectedFormat,
  onSelect,
}: GridSelectorProps) => {
  return (
    <Container>
      <Hoverable>
        {isHovered => (
          <GridContainer
            isHovered={isHovered}
            isSelected={selectedFormat === 'grid'}
            onPress={() => onSelect('grid')}
          >
            <Icon as={gridIcon} width={20} height={20} viewBox="0 0 24 24" />
          </GridContainer>
        )}
      </Hoverable>
      <Hoverable>
        {isHovered => (
          <ListContainer
            isHovered={isHovered}
            isSelected={selectedFormat === 'list'}
            onPress={() => onSelect('list')}
          >
            <Icon as={listIcon} width={20} height={20} viewBox="0 0 24 24" />
          </ListContainer>
        )}
      </Hoverable>
    </Container>
  );
};
