import {
  StyleProp,
  ViewStyle,
  TouchableOpacity,
  View,
  TextInputKeyPressEventData,
  NativeSyntheticEvent,
} from 'react-native';
import { useTheme } from 'styled-components/native';
import { useState, useRef } from 'react';
import { Portal } from '@gorhom/portal';
import { format } from 'date-fns';
import {
  Container,
  ErrorContainer,
  ErrorText,
  Picker,
  InputContainer,
  InputText,
} from './styles';
import ErrorIcon from '../../../assets/icons/error_outline.svg';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

interface TimePickerCustomProps {
  style?: StyleProp<ViewStyle>;
  date?: Date;
  onChange: (date?: Date) => void;
  error?: string;
  placeholder?: string;
}

export const MainCouranteInfoFormTimePicker = ({
  error,
  date,
  onChange,
  style,
  placeholder,
}: TimePickerCustomProps) => {
  const containerRef = useRef<TouchableOpacity>(null);
  const pickerRef = useRef<View>(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  useOutsideClick({
    componentRef: pickerRef,
    yOffset: 24,
    onOutsideClick: () => setOpen(false),
  });

  const handleKeyPress = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    if (e.nativeEvent.key === 'Backspace') {
      onChange(undefined);
    }
  };

  return (
    <Container style={style}>
      <InputContainer
        ref={containerRef}
        onPress={() => setOpen(oldValue => !oldValue)}
      >
        <InputText
          value={date ? format(date, 'p') : ''}
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
        />
      </InputContainer>
      {containerRef.current && open && (
        <Portal>
          <Picker
            ref={pickerRef}
            date={date}
            onChange={onChange}
            parentElement={containerRef.current as unknown as HTMLElement}
            onAmPmPress={() => setOpen(false)}
          />
        </Portal>
      )}
      {error && (
        <>
          <ErrorContainer>
            <ErrorIcon
              testID="input-error-icon"
              fill={theme.red}
              width={16}
              height={16}
              viewBox="0 0 24 24"
            />
            <ErrorText>{error}</ErrorText>
          </ErrorContainer>
        </>
      )}
    </Container>
  );
};
