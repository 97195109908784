import { TextInputProps } from 'react-native';
import { useTheme } from 'styled-components/native';

import { InputText } from './styles';

export type MainCouranteInfoFormInputProps = TextInputProps;

export const MainCouranteInfoFormInput = ({
  style,
  value,
  ...rest
}: MainCouranteInfoFormInputProps) => {
  const theme = useTheme();

  return (
    <InputText
      style={style}
      placeholderTextColor={theme.purpleBlue}
      value={value}
      {...rest}
    />
  );
};
