import { useTheme } from 'styled-components';
import { Caption } from '@nucleus/Typography';
import { Container } from './styles';

export const MainCouranteBadge = () => {
  const theme = useTheme();
  return (
    <Container gradient={theme.linearYellow}>
      <Caption>MAIN COURANTE</Caption>
    </Container>
  );
};
