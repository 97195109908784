import { useQuery } from 'react-query';
import { listZones, Zone } from '../../utils/zoneApi';

export async function getZones(): Promise<Zone[]> {
  const tabs = await listZones();
  return tabs;
}

export function useQueryZones() {
  return useQuery(['zones'], () => getZones());
}
