import { Badge } from '@atoms/Badge';
import { SelectableTextOverline } from '@atoms/SelectableTextOverline';
import { Container } from './styles';

interface EventInfoContentsTypeSelectorProps {
  text: string;
  badgeText: string;
  badgeColor: string;
  selected?: boolean;
  onPress: () => void;
}

export const EventInfoContentsTypeSelector = ({
  text,
  badgeText,
  badgeColor,
  selected,
  onPress,
}: EventInfoContentsTypeSelectorProps) => {
  return (
    <Container>
      <SelectableTextOverline
        selected={selected}
        text={text}
        onPress={onPress}
      />
      <Badge color={badgeColor} text={badgeText} style={{ marginLeft: 4 }} />
    </Container>
  );
};
