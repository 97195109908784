import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  ReactNode,
} from 'react';
import { useDrag, DragSourceMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { IWidget, useWidgetGridContext } from '@contexts/WidgetGridContext';
import { WidgetHeader } from '@molecules/WidgetHeader';
import { WidgetContent } from '@molecules/WidgetContent';
import { useMutationUpdateWidgets } from '@services/hooks/useMutationUpdateWidgets';
import {
  gridConstants,
  useGridHelper,
  ItemTypes,
} from '../../../hooks/useGridHelper';
import { Container } from './styles';

const { GRID_PADDING, GRID_CELL_PADDING, SCROLLBAR_WIDTH, GRID_CELL_COUNT } =
  gridConstants;

interface WidgetProps {
  widgetData: IWidget;
  children: ReactNode;
}

export const Widget = ({ widgetData, children }: WidgetProps) => {
  const mutationUpdateWidgets = useMutationUpdateWidgets();
  const { id, left, top, width, height } = widgetData;
  const { snapToGridSize } = useGridHelper();
  const {
    setWidgets,
    gridContainer,
    isEditMode,
    isColliding,
    sidePanelData,
    updateWidgetData,
  } = useWidgetGridContext();

  const [{ actualWidth, actualHeight }, setActualSize] = useState({
    actualWidth: width,
    actualHeight: height,
  });

  const [[minSizeX, minSizeY], setMinSize] = useState([0, 0]);

  const savedSizes = useRef({ width: 0, height: 0 });

  // we're keeping a copy of what the current sizes are at the start of resize
  // so we can come back to it if the size when ending the resize creates a collision
  const handleResizeStart = useCallback(() => {
    savedSizes.current = { width: actualWidth, height: actualHeight };
  }, [actualWidth, actualHeight]);

  const handleResize = useCallback((event, { size }) => {
    setActualSize({
      actualWidth: size.width,
      actualHeight: size.height,
    });
  }, []);

  const handleResizeStop = useCallback(
    (_, { size }) => {
      if (gridContainer.current) {
        const snappedSizes = snapToGridSize(
          left,
          top,
          size.width,
          size.height,
          gridContainer.current.getBoundingClientRect().width,
        );

        // we wanna check if the widget we're editing doesnt overlap another with the new size
        if (isColliding({ ...widgetData, ...snappedSizes })) {
          setActualSize({
            actualWidth: savedSizes.current.width,
            actualHeight: savedSizes.current.height,
          });
        } else {
          setActualSize({
            actualWidth: snappedSizes.width,
            actualHeight: snappedSizes.height,
          });

          setWidgets((draft: IWidget[]) => {
            const widget = draft.find((w: IWidget) => w.id === id);
            if (widget) {
              widget.width = snappedSizes.width;
              widget.grid_width = snappedSizes.gridWidth;
              widget.height = snappedSizes.height;
              widget.grid_height = snappedSizes.gridHeight;
            }
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      gridContainer,
      setWidgets,
      setActualSize,
      widgetData,
      id,
      left,
      top,
      sidePanelData,
      isColliding,
    ],
  );

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      // idk how to make it react to state yet
      canDrag: () => true,
      type: ItemTypes.WIDGET,
      item: { id, left, top, width, height },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top, width, height, widgetData],
  );

  const handleRename = async (newName: string) => {
    await mutationUpdateWidgets.mutateAsync([
      {
        id: widgetData.id,
        name: newName,
      },
    ]);

    updateWidgetData(widgetData.id, {
      name: newName,
    });
  };

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    setActualSize({ actualWidth: width, actualHeight: height });
  }, [width, height]);

  useEffect(() => {
    if (gridContainer.current) {
      const gridCellSize =
        (gridContainer.current.getBoundingClientRect().width -
          (GRID_PADDING * 2 + GRID_CELL_PADDING / 2 + SCROLLBAR_WIDTH / 2)) /
        GRID_CELL_COUNT;

      const minSnapped = snapToGridSize(
        left,
        top,
        gridCellSize * 2,
        gridCellSize * 2,
        gridContainer.current.getBoundingClientRect().width,
      );

      setMinSize([minSnapped.width, minSnapped.height]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridContainer, sidePanelData.isOpened]);

  return (
    <Container left={left} top={top} isDragging={isDragging}>
      <WidgetContent
        isColliding={false}
        isDragPreview={false}
        dragRef={drag}
        height={actualHeight}
        width={actualWidth}
        onResizeStart={handleResizeStart}
        onResize={handleResize}
        onResizeStop={handleResizeStop}
        minConstraints={[minSizeX, minSizeY]}
        handleSize={[20, 20]}
        // a way to disable resize
        resizeHandles={isEditMode ? ['se', 's', 'e'] : []}
      >
        <WidgetHeader onRename={handleRename} widgetData={widgetData} />
        {children}
      </WidgetContent>
    </Container>
  );
};
