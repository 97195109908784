import { Body1 } from '@nucleus/Typography';
import { InfoAvatar } from '@atoms/InfoAvatar';
import { Container } from './styles';

interface EventInfoDetailTitleAndSourceImageProps {
  title: string;
  sourceImage?: string;
}

export const EventInfoDetailTitleAndSourceImage = ({
  title,
  sourceImage,
}: EventInfoDetailTitleAndSourceImageProps) => {
  return (
    <Container>
      <Body1 style={{ width: '85%' }}>{title}</Body1>
      <InfoAvatar
        avatar={sourceImage}
        style={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      />
    </Container>
  );
};
