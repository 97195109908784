/* eslint-disable @typescript-eslint/no-empty-function */
import { useMemo } from 'react';
import { MapView } from '@atoms/MapView';
import { EventsMapControlButtons } from '@molecules/EventsMapControlButtons';
import { EventsMapMarkerGenerator } from '@organisms/EventsMapMarkerGenerator';
import { EventsMapZonesController } from '@organisms/EventsMapZonesController';
import { useAuthContext } from '@contexts/AuthContext';
import {
  useEventCreateContext,
  useEventsContext,
} from '@contexts/EventsContext';
import { useZonesContext } from '@contexts/ZonesContext';
import { useCartographyContext } from '@contexts/CartographyContext';
import { useActivatedMainCouranteActionsContext } from '@contexts/ActivatedMainCouranteActions';
import { useCreateLocationContext } from '@contexts/CreateLocationContext';
import { ViewMode } from '@nebula.gl/edit-modes';
import { useQueryFiltredEvents } from '@services/hooks/useQueryFiltredEvents';
import { useQueryFiltredMainCourantes } from '@services/hooks/useQueryFiltredMainCourantes';
import { useTabsControlContext } from '@contexts/TabsControlContext';
import { useFiltersContext } from '@contexts/FiltersContext';
import { useMapController } from '../../../hooks/useMapController';
import { EventListProps } from './types';

export const EventsMap = ({ parentSize }: EventListProps) => {
  const { selectedTab } = useTabsControlContext();
  const { filters, getFiltersInParams } = useFiltersContext();

  const { data: events } = useQueryFiltredEvents(
    selectedTab,
    filters,
    getFiltersInParams,
  );

  const { data: mainCourantes } = useQueryFiltredMainCourantes(
    selectedTab,
    filters,
    getFiltersInParams,
  );

  const listOfEvents = useMemo(
    () => [...(events || []), ...(mainCourantes || [])],
    [events, mainCourantes],
  );

  const { user } = useAuthContext();

  const { selectedAddress } = useCreateLocationContext();
  const { mapCartographicStyle } = useCartographyContext();

  const { createNewEventOpen } = useEventCreateContext();

  const { activatedEventId, updateActivatedEventId } = useEventsContext();
  const {
    listOfActivedMainCouranteActions,
    updateActivatedActionId,
    activatedActionId,
  } = useActivatedMainCouranteActionsContext();

  const {
    mode,
    zones,
    selectedZone,
    onUpdateZone,
    onSelectZone,
    currentZoneType,
    isCreatingZone,
    isEditingZone,
    selectedZoneIndex,
  } = useZonesContext();

  const setup = user?.organization.setup;

  const selectedEvent = useMemo(() => {
    return listOfEvents.find(
      eventItem => String(eventItem.id) === String(activatedEventId),
    );
  }, [activatedEventId, listOfEvents]);

  const selectedAction = useMemo(() => {
    return listOfActivedMainCouranteActions.find(
      actionItem => String(actionItem.id) === String(activatedActionId),
    );
  }, [activatedActionId, listOfActivedMainCouranteActions]);

  const { viewport, onRecenter, onZoomIn, onZoomOut, setViewport } =
    useMapController({
      selectedEvent,
      selectedZone,
      isCreatingZone,
      isEditingZone,
      autoFocusOff: setup?.autoFocusOff,
      centerGeometry: setup?.centerGeometry,
      parentSize,
      selectedAddress,
      selectedAction,
    });

  function generateSizeBasedOnZoom() {
    const BASE_SIZE = 60;
    if (viewport.zoom) {
      if (viewport.zoom >= 10) {
        return BASE_SIZE;
      }
    }
    return BASE_SIZE * 0.4;
  }

  return (
    <>
      <EventsMapControlButtons
        onZoomOutPress={onZoomOut}
        onZoomInPress={onZoomIn}
        onRecenterPress={onRecenter}
      />
      <MapView
        viewport={viewport}
        onViewportChange={setViewport}
        mapStyle={mapCartographicStyle}
      >
        <EventsMapZonesController
          zoneCreatingType={currentZoneType}
          mode={
            createNewEventOpen && mode instanceof ViewMode ? undefined : mode
          }
          zones={zones}
          selectedZoneIndex={selectedZoneIndex}
          onUpdateZone={onUpdateZone}
          onSelectZone={!createNewEventOpen ? onSelectZone : undefined}
        />

        <EventsMapMarkerGenerator
          typeMainCouranteActionToShow="opened"
          updateActivatedActionId={updateActivatedActionId}
          updateActivatedEventId={updateActivatedEventId}
          events={listOfEvents}
          mainCouranteActions={listOfActivedMainCouranteActions}
          markerSize={generateSizeBasedOnZoom()}
        />
      </MapView>
    </>
  );
};
