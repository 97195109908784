import { IntensityView } from '@atoms/IntensityView';
import { Container } from './styles';

interface EventCardHeaderProps {
  intensity: number;
}

export const EventCardHeader = ({ intensity }: EventCardHeaderProps) => {
  return (
    <Container>
      <IntensityView intensity={intensity} />
    </Container>
  );
};
