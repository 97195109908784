import { useRef } from 'react';
import { Body1 } from '@nucleus/Typography';
import { useHover } from 'react-native-web-hooks';
import { Container } from './styles';

interface WorkspacePageAddNewWidgetModalConfigProps {
  onPress: () => void;
  name: string;
}

export const WorkspacePageAddNewWidgetModalConfig = ({
  onPress,
  name,
}: WorkspacePageAddNewWidgetModalConfigProps) => {
  const contianerRef = useRef(null);
  const isHovered = useHover(contianerRef);
  return (
    <Container ref={contianerRef} isHovered={isHovered} onPress={onPress}>
      <Body1>{name}</Body1>
    </Container>
  );
};
