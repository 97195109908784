import { ReactNode, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import SideMenu from '@molecules/SideMenu';
import { useAuthContext } from '@contexts/AuthContext';
import { Container } from './styles';

interface WebLayoutProps {
  children: ReactNode;
}

export const WebLayout = ({ children }: WebLayoutProps) => {
  const navigation = useNavigation();
  const { user, signOut } = useAuthContext();
  const setup = useMemo(() => user?.organization.setup, [user]);

  if (!user) {
    signOut();
    return null;
  }
  return (
    <Container>
      <SideMenu
        onDashboardPress={() => navigation.navigate('Dashboard')}
        onAnalyticsPress={() => navigation.navigate('Analytics')}
        onLogoutPress={signOut}
        userName={user?.name}
        withAnalytics={
          !setup?.extraServices
            ? true
            : !!setup.extraServices.find(
                extraService => extraService === 'analytics',
              )
        }
      />
      <main>{children}</main>
    </Container>
  );
};
