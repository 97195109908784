import styled from 'styled-components/native';
import { darken } from 'polished';

export const Container = styled.View`
  height: 20px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.purpleBlue};
  margin-right: 8px;
  position: relative;
  flex-direction: row;
  z-index: 2;
`;

interface ColorCircleProps {
  color: string;
}

export const ColorCircle = styled.View<ColorCircleProps>`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
  margin-right: 4px;
`;

interface RemoveButtonProps {
  isHovered: boolean;
}

export const RemoveButton = styled.TouchableOpacity<RemoveButtonProps>`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${({ theme, isHovered }) =>
    isHovered ? darken(0.05, theme.red) : theme.red};
  position: absolute;
  top: -6px;
  right: -6px;
  align-items: center;
  justify-content: center;
`;
