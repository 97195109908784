import { ReactNode } from 'react';

import { Container, Content } from './styles';

interface InternalScreenModalProps {
  isOpened?: boolean;
  children: ReactNode;
}

export const InternalScreenModal = ({
  children,
  isOpened = false,
}: InternalScreenModalProps) =>
  isOpened ? (
    <Container>
      <Content>{children}</Content>
    </Container>
  ) : null;
