import styled from 'styled-components/native';

export const EventContainer = styled.View`
  padding-top: 8px;
  padding-left: 8px;
  display: flex;
  background-color: ${({ theme }) => theme.purpleBlack};
  height: 100%;
  width: 100%;
  flex-direction: row;
`;

export const MapContainer = styled.View`
  display: flex;
  background-color: ${({ theme }) => theme.purpleBlack};
  height: 100%;
  flex-grow: 1;
`;
