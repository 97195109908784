/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity } from 'react-native';
import { RndDragEvent, DraggableData } from 'react-rnd';
import { v4 as uuid } from 'uuid';
import CloseIcon from '../../assets/icons/close.svg';
import { ChoiseeEvent } from './ChoiseeEvent';
import { NewEvent } from './NewEvent';
import { NewAction } from './NewAction';
import { H6 } from '../Typography';
import { CreateActionTypeModal } from './CreateActionTypeModal';
import { useEventCreateContext } from '../../contexts/EventsContext';
import { useZonesContext } from '../../contexts/ZonesContext';
import { Container, Header, ContentContainer } from './styles';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMutationMainCourante } from '../../services/hooks/useMutationMainCourante';
import { useCreateLocationContext } from '../../contexts/CreateLocationContext';

interface NewEvent {
  name: string;
  team_id: string | null;
  event_id: string | null;
}
interface Size {
  width: number;
  height: number;
}

interface EventCreateProps {
  parentSize: Size;
}

const spteps = [
  {
    name: 'choisee_event',
    title: 'step_1_create_main_courante',
  },
  {
    name: 'create_event',
    title: 'step_2_create_main_courante',
  },
  {
    name: 'create_action',
    title: 'step_2_create_action',
  },
];

export const EventCreate = ({ parentSize }: EventCreateProps) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { setCreateNewEventOpen, createNewEventOpen } = useEventCreateContext();
  const [step, setStep] = useState(spteps[0]);
  const { deleteZoneById } = useZonesContext();
  const [newActionId, setNewActionId] = useState<string>();
  const { createLocationOpened, clearLocation } = useCreateLocationContext();

  const [seletedEvent, setSeletedEvent] = useState<{
    name: string;
    id: string;
    team_id: string;
  }>();

  const [newEvent, setNewEvent] = useState<NewEvent>({
    name: '',
    team_id: null,
    event_id: null,
  });

  const [createActionTypeModalOpen, setCreateActionTypeModalOpen] =
    useState(false);

  const [eventCreatePosition, setEventCreatePosition] = useState({
    x: 0,
    y: 0,
  });

  const useQuery = useMutationMainCourante(newEvent.team_id);

  async function onConfirmChoseEvent() {
    if (!user) return;
    if (newEvent.name !== '' && newEvent.team_id) {
      const response = await useQuery.mutateAsync({
        createCourante: {
          name: newEvent.name,
          team_id: newEvent.team_id,
          user_id: user.id,
        },
        type: 'create',
      });

      setNewEvent({
        name: response.name,
        team_id: response.team_id,
        event_id: response.id,
      });

      setStep(spteps[1]);
    }
  }

  function handleClose() {
    if (step.name === 'create_event' && newEvent.event_id) {
      deleteZoneById(`${newEvent.event_id}_creating`);
    }
    if (step.name === 'create_action' && newActionId) {
      deleteZoneById(`${newActionId}_creating`);
    }
    setCreateNewEventOpen(false);
  }

  useEffect(() => {
    if (seletedEvent) {
      setStep(spteps[2]);
    }
  }, [newEvent, seletedEvent]);

  useEffect(() => {
    const width = parentSize.width < 900 ? parentSize.width : 900;
    setEventCreatePosition({
      x: parentSize.width / 2 - width / 2,
      y: 0,
    });
  }, [parentSize]);

  useEffect(() => {
    if (!createNewEventOpen) {
      setSeletedEvent(undefined);
      setNewActionId(uuid());
      setStep(spteps[0]);
    }
  }, [createNewEventOpen]);

  useEffect(() => {
    return () => {
      clearLocation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!createNewEventOpen) return null;

  return (
    <Container
      cancel="input"
      bounds="parent"
      maxWidth={parentSize.width < 900 ? parentSize.width : 900}
      minWidth={parentSize.width < 900 ? parentSize.width : 900}
      width={parentSize.width < 900 ? parentSize.width : 900}
      maxHeight={470}
      minHeight={470}
      height={470}
      position={eventCreatePosition}
      enableResizing={false}
      onDragStop={(e: RndDragEvent, d: DraggableData) => {
        setEventCreatePosition({ x: d.x, y: d.y });
      }}
      hidden={!!createLocationOpened}
    >
      <Header>
        <H6>{t(step.title)}</H6>
        <TouchableOpacity onPress={handleClose}>
          <CloseIcon />
        </TouchableOpacity>
      </Header>
      <ContentContainer>
        {step.name === 'choisee_event' && (
          <ChoiseeEvent
            setSeletedEvent={setSeletedEvent}
            onConfirm={onConfirmChoseEvent}
            newEvent={newEvent}
            setNewEvent={setNewEvent}
          />
        )}
        {step.name === 'create_event' &&
          newEvent.name !== '' &&
          newEvent.team_id &&
          newEvent.event_id && (
            <NewEvent
              title={newEvent.name}
              teamId={newEvent.team_id}
              eventId={newEvent.event_id}
              onClose={handleClose}
              setSeletedEvent={setSeletedEvent}
            />
          )}
        {step.name === 'create_action' && seletedEvent && newActionId && (
          <NewAction
            newActionId={newActionId}
            seletedEvent={seletedEvent}
            openCreateTypeModal={() => setCreateActionTypeModalOpen(true)}
            onClose={handleClose}
          />
        )}
      </ContentContainer>
      {createActionTypeModalOpen && user?.teams[0].id && (
        <CreateActionTypeModal
          teamId={user.teams[0].id}
          onClose={() => setCreateActionTypeModalOpen(false)}
        />
      )}
    </Container>
  );
};
