import { Caption } from '@nucleus/Typography';
import { StyleProp, ViewStyle } from 'react-native';
import { Container } from './styles';

interface MainCouranteInfoFormPickLocationProps {
  onPress: () => void;
  location?: string;
  style?: StyleProp<ViewStyle>;
}

export const MainCouranteInfoFormPickLocation = ({
  style,
  location,
  onPress,
}: MainCouranteInfoFormPickLocationProps) => {
  return (
    <Container onPress={onPress} style={style}>
      <Caption numberOfLines={1}>{location || ''}</Caption>
    </Container>
  );
};
