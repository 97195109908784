import React from 'react';
import { EventsMapButton } from '@atoms/EventsMapButton';
import plusIcon from '../../../assets/icons/plus.svg';
import minusIcon from '../../../assets/icons/minus.svg';
import recenterIcon from '../../../assets/icons/recenter.svg';

import { Container, Zoom, PlusButton, MinusButton } from './styles';

interface EventsMapControlButtonsProps {
  onZoomInPress: () => void;
  onZoomOutPress: () => void;
  onRecenterPress: () => void;
}

export const EventsMapControlButtons = React.memo(
  ({
    onZoomInPress,
    onZoomOutPress,
    onRecenterPress,
  }: EventsMapControlButtonsProps) => {
    return (
      <Container>
        <Zoom>
          <PlusButton icon={plusIcon} onPress={onZoomInPress} />
          <MinusButton icon={minusIcon} onPress={onZoomOutPress} />
        </Zoom>
        <EventsMapButton icon={recenterIcon} onPress={onRecenterPress} />
      </Container>
    );
  },
);
