import { Container, Label, ColorCircle } from './styles';

interface AnalyticsTabsDynamicDataCardVigilanceValueProps {
  label: string;
  color: string;
}

export const AnalyticsTabsDynamicDataCardVigilanceValue = ({
  label,
  color,
}: AnalyticsTabsDynamicDataCardVigilanceValueProps) => {
  return (
    <Container>
      <Label>{label}</Label>
      <ColorCircle color={color} />
    </Container>
  );
};
