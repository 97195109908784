import { ComponentType } from 'react';
import { TouchableOpacity, TouchableOpacityProps } from 'react-native';
import { Icon } from '@nucleus/Icon';
import { SvgProps, NumberProp, Color } from 'react-native-svg';

export interface IconButtonProps extends TouchableOpacityProps {
  icon: ComponentType<SvgProps>;
  width?: NumberProp;
  height?: NumberProp;
  viewBox?: string;
  preserveAspectRatio?: string;
  color?: Color;
  fill?: Color;
  title?: string;
}

export const IconButton = ({
  icon,
  width,
  height,
  viewBox,
  preserveAspectRatio,
  color,
  fill,
  title,
  ...rest
}: IconButtonProps) => {
  return (
    <TouchableOpacity {...rest}>
      <Icon
        as={icon}
        width={width}
        height={height}
        viewBox={viewBox}
        preserveAspectRatio={preserveAspectRatio}
        color={color}
        fill={fill}
        title={title}
      />
    </TouchableOpacity>
  );
};
