import { Body1 } from '@nucleus/Typography';
import { IconButton } from '@atoms/IconButton';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import closeIcon from '../../../assets/icons/close.svg';
import backIcon from '../../../assets/icons/back.svg';
import { Container, TitleContainer } from './styles';

interface CreateLocationHeaderProps {
  mode: 'custom' | 'existed';
  onPressBack: () => void;
  onPressClose: () => void;
}

export const CreateLocationHeader = ({
  mode,
  onPressBack,
  onPressClose,
}: CreateLocationHeaderProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container>
      <TitleContainer>
        {mode === 'custom' && (
          <IconButton icon={backIcon} onPress={onPressBack} />
        )}
        <Body1 style={{ color: theme.purpleBlue }}>{t('type_address')}</Body1>
      </TitleContainer>
      <IconButton icon={closeIcon} onPress={onPressClose} />
    </Container>
  );
};
