/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { api } from '../eventApi';
import { queryClient } from '../queryClient';
import { useToastContext } from '../../contexts/ToastContext';

interface MainCouranteMutationData {
  updateCouranteId?: string;
  updateCourante?: {
    name?: string;
    desc?: string;
    date: Date;
    priority?: number;
    team_id?: string;
    location_name?: string;
    location_color?: string;
    media?: string[];
    location_coordinates: any;
  };
  createCourante?: {
    name: string;
    team_id: string;
    user_id: string;
  };
  type: 'create' | 'update';
}

export const useMutationMainCourante = (team_id: string | null) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({
      updateCouranteId,
      createCourante,
      updateCourante,
      type,
    }: MainCouranteMutationData) => {
      if (createCourante && type === 'create') {
        const response = await api.post('/v1/main_courantes', createCourante);
        return response.data;
      }
      if (updateCouranteId && updateCourante && type === 'update') {
        const response = await api.patch(
          `/v1/main_courantes/${updateCouranteId}`,
          updateCourante,
        );
        return response.data;
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['main_courantes', team_id]);
        dispatchToast({
          message: t('main_courante_created'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_create_main_courante'),
          type: 'error',
        });
      },
    },
  );
};
