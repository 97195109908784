import React, { useRef, useEffect } from 'react';
import { Editor } from 'react-map-gl-draw';
import { ZoneTypes, Zone, Mode } from '@contexts/ZonesContext';
import { Feature } from '@nebula.gl/edit-modes';
import { getFeatureStyle, getEditHandleStyle } from './styles';

interface EventsMapZonesControllerProps {
  selectedZoneIndex: number | null;
  zones: Zone[];
  mode: Mode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdateZone: (item: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelectZone?: (options: any) => void;
  zoneCreatingType: ZoneTypes;
}

export const EventsMapZonesController = React.memo(
  ({
    selectedZoneIndex,
    zones,
    mode,
    onUpdateZone,
    onSelectZone,
    zoneCreatingType,
  }: EventsMapZonesControllerProps) => {
    const editorRef = useRef<Editor>(null);

    useEffect(() => {
      if (editorRef !== null) {
        const editorDiv = document.querySelector('#editor > svg');
        editorDiv?.insertAdjacentHTML(
          'afterbegin',
          `<defs>
          <linearGradient id='main_courante' gradientUnits='userSpaceOnUse' x1='100.04%' y1='49.96%' x2='-0.04%' y2='50.04%'>
          <stop stop-color='#FFD422'/>
          <stop offset='.975' stop-color='#FF7D05'/>
          </linearGradient>
          </defs>`,
        );
      }
    }, []);
    return (
      <>
        <Editor
          ref={editorRef}
          selectedFeatureIndex={selectedZoneIndex}
          features={zones}
          style={{ width: '100%', height: '100%' }}
          clickRadius={6}
          mode={mode}
          onUpdate={onUpdateZone}
          onSelect={onSelectZone}
          editHandleShape="circle"
          featureStyle={(feature: Feature) =>
            getFeatureStyle(feature, zoneCreatingType)
          }
          editHandleStyle={(feature: Feature) =>
            getEditHandleStyle(feature, zoneCreatingType)
          }
        />
      </>
    );
  },
);
