import styled from 'styled-components/native';
import { shade } from 'polished';

export const Container = styled.TouchableOpacity`
  position: relative;
  padding: 4px;
  width: 70px;
`;

interface RemoveFileProps {
  isHovered?: boolean;
}
export const RemoveFile = styled.TouchableOpacity<RemoveFileProps>`
  position: absolute;
  top: 0;
  right: 0;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${({ theme, isHovered }) =>
    isHovered ? shade(0.2, theme.red) : theme.red};
  align-items: center;
  justify-content: center;
`;
