import { Body1 } from '@nucleus/Typography';
import { Spinner } from '@atoms/Spinner';
import { PreviewItem } from '@atoms/PreviewItem';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components/native';
import { usePreviewContext } from '@contexts/PreviewContext';
import { Document } from '@services/hooks/useQueryDocuments';
import { Container, MediaListContainer } from './styles';

interface MainCouranteInfoDetailMediaListProps {
  medias: Document[];
  mediasLoading: boolean;
}

export const MainCouranteInfoDetailMediaList = ({
  medias,
  mediasLoading,
}: MainCouranteInfoDetailMediaListProps) => {
  const { openPreview } = usePreviewContext();
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container>
      <Body1 style={{ marginTop: 8 }}>{t('photos_and_videos')}</Body1>
      <MediaListContainer>
        {mediasLoading ? (
          <Spinner color={theme.purple} size={20} />
        ) : (
          <>
            {medias?.map(media => (
              <PreviewItem
                openPreview={() =>
                  openPreview({
                    source: media.pre_signed_url,
                    type: media.type,
                  })
                }
                style={{ width: '45%', padding: 5 }}
                key={media.id}
                source={media.pre_signed_url}
                type={media.type}
              />
            ))}
          </>
        )}
      </MediaListContainer>
    </Container>
  );
};
