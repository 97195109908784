export default process.env;

export const config = {
  MAP_BOX_KEY: process.env.MAP_BOX_KEY,
  USER_MANAGMENT_API: process.env.USER_MANAGMENT_API,
  GEOCODING_API: process.env.GEOCODING_API,
  EVENT_API: process.env.EVENT_API,
  SERVICES_API: process.env.SERVICES_API,
  EVENT_API_AUTHORIZATION: process.env.EVENT_API_AUTHORIZATION,
  APP_URL: process.env.APP_URL,
};
