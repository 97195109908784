import { ReactNode } from 'react';
import { ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import generateGradient from '../../../utils/generateGradient';

export interface GradientProps {
  gradient: string;
  style?: ViewStyle;
  children?: ReactNode;
}

export function Gradient({ gradient, style, children }: GradientProps) {
  const generated = generateGradient(gradient, {
    width: style?.width || 0,
    height: style?.height || 0,
  });
  return (
    <LinearGradient {...generated[0]} style={style}>
      {children}
    </LinearGradient>
  );
}
