import styled, { css } from 'styled-components/native';
import { lighten } from 'polished';

interface AddButtonProps {
  isHovered?: boolean;
  size: 'small' | 'large';
}

export const Container = styled.TouchableOpacity<AddButtonProps>`
  ${({ size }) => {
    if (size === 'large') {
      return css`
        width: 32px;
        height: 32px;
        border-radius: 8px;
      `;
    }
    return css`
      width: 24px;
      height: 24px;
      border-radius: 6px;
    `;
  }}

  background-color: ${({ theme, isHovered }) =>
    isHovered ? lighten(0.05, theme.purple) : theme.purple};
  justify-content: center;
  align-items: center;
`;
