import styled, { css } from 'styled-components/native';
import { Caption } from '@nucleus/Typography';

interface ContainerProps {
  color: string;
}

export const Container = styled.View<ContainerProps>`
  position: relative;
  background: ${({ color }) => color};
  height: 100%;
  width: 30px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  align-items: center;
`;

interface CategoryNameProps {
  small?: boolean;
}

export const CategoryName = styled(Caption)<CategoryNameProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.white};
  width: 180px;
  transform: rotate(-90deg) translateX(0px) translateY(100px);
  ${({ small }) =>
    small &&
    css`
      font-size: 10px;
    `}
`;

export const IconView = styled.View`
  position: absolute;
  top: 10px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.white};
  align-items: center;
  justify-content: center;
`;
