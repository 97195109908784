import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Geometry as NebulaGeometry } from '@nebula.gl/edit-modes';
import { MainCourante } from '@services/hooks/useQueryFiltredMainCourantes';
import { useToastContext } from '@contexts/ToastContext';
import { queryClient } from '@services/queryClient';
import { api } from '@services/eventApi';

export type Geometry = NebulaGeometry;

interface MutationDeleteMainCouranteAction {
  courante_id: string;
}

interface MutationDeleteMainCouranteActionData {
  main_courante_action_id: string;
}

export async function deleteMainCouranteAction(
  main_courante_action_id: string,
) {
  const response = await api.delete<MainCourante>(
    `/v1/main_courante_actions/${main_courante_action_id}`,
  );
  return response.data || response;
}

export const useMutationDeleteMainCouranteAction = ({
  courante_id,
}: MutationDeleteMainCouranteAction) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({
      main_courante_action_id,
    }: MutationDeleteMainCouranteActionData) => {
      await deleteMainCouranteAction(main_courante_action_id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['mainCourante', courante_id]);
        dispatchToast({
          message: t('success_delete_main_courante_action'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_delete_main_courante_action'),
          type: 'error',
        });
      },
    },
  );
};
