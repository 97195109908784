import React from 'react';
import { WorkspaceLayout } from '@templates/WorkspaceLayout';
import { WorkspacesArea } from '@templates/WorkspacesArea';

const Workspace: React.FC = () => {
  return (
    <WorkspaceLayout>
      <WorkspacesArea />
    </WorkspaceLayout>
  );
};

export default Workspace;
