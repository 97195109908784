import styled from 'styled-components/native';
import { Caption } from '@nucleus/Typography';

export const Container = styled.View`
  width: 100%;
`;

export const SourceText = styled(Caption)`
  color: ${({ theme }) => theme.yellow};
  margin-top: 8px;
  margin-right: 16px;
  align-self: flex-end;
`;
