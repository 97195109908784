import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Geometry as NebulaGeometry } from '@nebula.gl/edit-modes';
import { Event } from '@services/hooks/useQueryFiltredEvents';
import { MainCourante } from '@services/hooks/useQueryFiltredMainCourantes';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useToastContext } from '@contexts/ToastContext';
import { queryClient } from '@services/queryClient';
import { api } from '@services/eventApi';
import { Filters } from 'utils/tabApi';
import { getDateFnsLocale } from '../../utils/getDateFnsLocale';

export type Geometry = NebulaGeometry;

interface UpdateMainCouranteData {
  name?: string;
  desc?: string;
  date: Date;
  priority?: number;
  location_name?: string;
  location_color?: string;
  location_coordinates?: Geometry;
}

interface MutationUpdateMainCouranteData extends UpdateMainCouranteData {
  main_courante_id: string;
}

interface UseMutationUpdateMainCouranteProps {
  tabId: string;
  filters: Filters;
}

export async function updateMainCourante(
  main_courante_id: string,
  data: UpdateMainCouranteData,
) {
  const response = await api.patch<MainCourante>(
    `/v1/main_courantes/${main_courante_id}`,
    data,
  );
  return response.data || response;
}

export const useMutationUpdateMainCourante = ({
  tabId,
  filters,
}: UseMutationUpdateMainCouranteProps) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async ({
      main_courante_id,
      name,
      desc,
      date,
      priority,
      location_name,
      location_coordinates,
      location_color,
    }: MutationUpdateMainCouranteData) => {
      const updatedMainCourante = await updateMainCourante(main_courante_id, {
        name,
        desc,
        date,
        priority,
        location_name,
        location_coordinates,
        location_color,
      });
      return updatedMainCourante;
    },
    {
      onSuccess: async updatedMainCourante => {
        const locale = getDateFnsLocale();
        await queryClient.setQueryData(
          ['filtredMainCourantes', tabId, filters],
          oldData => {
            const listOfExistedEvents = oldData as (Event | MainCourante)[];
            const updatedEvents = listOfExistedEvents.map(existedEvent => {
              if (
                existedEvent.id === updatedMainCourante.id &&
                existedEvent.type === 'main_courante'
              ) {
                return {
                  ...existedEvent,
                  ...updatedMainCourante,
                  formatted_date: formatDistanceToNow(
                    parseISO(updatedMainCourante.date),
                    {
                      locale,
                      addSuffix: true,
                    },
                  ),
                  formatted_created_at: formatDistanceToNow(
                    parseISO(updatedMainCourante.created_at),
                    {
                      locale,
                      addSuffix: true,
                    },
                  ),
                  formatted_updated_at: formatDistanceToNow(
                    parseISO(updatedMainCourante.updated_at),
                    {
                      locale,
                      addSuffix: true,
                    },
                  ),
                };
              }
              return existedEvent;
            });

            return updatedEvents;
          },
        );
        dispatchToast({
          message: t('success_update_main_courante'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_update_main_courante'),
          type: 'error',
        });
      },
    },
  );
};
