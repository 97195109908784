import styled from 'styled-components/native';
import { Button as ButtonComponent } from '@atoms/Button';

export const Container = styled.View`
  max-width: 397px;
  width: 90%;
  max-height: 177px;
  height: 30%;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 20px;
  padding: 20px;
`;

export const ButtonsContainer = styled.View`
  flex-direction: row;
  margin-top: 40px;
  width: 100%;
  justify-content: space-evenly;
`;

export const Button = styled(ButtonComponent)`
  width: 137px;
  height: 39px;
`;
