import { Control, Controller } from 'react-hook-form';
import { MainCouranteInfoFormInput } from '@atoms/MainCouranteInfoFormInput';
import { MainCouranteInfoFormDatePicker } from '@atoms/MainCouranteInfoFormDatePicker';
import { MainCouranteInfoFormPickLocation } from '@atoms/MainCouranteInfoFormPickLocation';
import { MainCouranteInfoFormTimePicker } from '@atoms/MainCouranteInfoFormTimePicker';
import { Geometry as NebulaGeometry } from '@nebula.gl/edit-modes';
import { Container, SideBySideContent } from './styles';

export type Geometry = NebulaGeometry;

export interface UpdateMainCouranteFormData {
  name: string;
  location_name?: string;
  location_coordinates?: Geometry;
  location_color?: string;
  date: Date;
  hour: Date;
}

interface MainCouranteInfoOverviewInfosFormProps {
  formControl: Control<UpdateMainCouranteFormData>;
  name: string;
  locationName: string;
  date: string;
  onPickLocationPress: () => void;
}

export const MainCouranteInfoOverviewInfosForm = ({
  formControl,
  name,
  locationName,
  onPickLocationPress,
  date,
}: MainCouranteInfoOverviewInfosFormProps) => {
  return (
    <Container>
      <Controller
        control={formControl}
        render={({ field: { onChange, value } }) => (
          <MainCouranteInfoFormInput
            value={value}
            onChangeText={onChange}
            style={{ maxWidth: 330 }}
          />
        )}
        name="name"
        defaultValue={name}
      />
      <SideBySideContent>
        <Controller
          control={formControl}
          render={({ field: { value } }) => (
            <MainCouranteInfoFormPickLocation
              onPress={onPickLocationPress}
              style={{ maxWidth: 160 }}
              location={value}
            />
          )}
          name="location_name"
          defaultValue={locationName}
        />
        <Controller
          control={formControl}
          render={({ field: { onChange, value } }) => (
            <MainCouranteInfoFormDatePicker
              date={value}
              onChange={onChange}
              style={{ maxWidth: 90, marginLeft: 5 }}
            />
          )}
          name="date"
          defaultValue={new Date(date)}
        />

        <Controller
          control={formControl}
          render={({ field: { onChange, value } }) => (
            <MainCouranteInfoFormTimePicker
              date={value}
              onChange={onChange}
              style={{ maxWidth: 70, marginLeft: 5 }}
            />
          )}
          name="hour"
          defaultValue={new Date(date)}
        />
      </SideBySideContent>
    </Container>
  );
};
