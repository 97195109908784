import styled from 'styled-components/native';
import { Button as ButtonComponent } from '@atoms/Button';

export const Container = styled.View`
  max-width: 397px;
  width: 90%;
  max-height: 198px;
  height: 32%;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 8px;
  padding: 16px;
`;

export const FormContainer = styled.View`
  height: 86px;
  width: 100%;
`;

export const ButtonsContainer = styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
`;

export const Button = styled(ButtonComponent)`
  width: 137px;
  height: 39px;
`;
