import styled from 'styled-components/native';
import { Platform } from 'react-native';

export const H1 = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '7.2rem' : '72px'};
  line-height: ${Platform.OS === 'web' ? '9.0rem' : '90px'};
  font-weight: 800;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_800ExtraBold'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.175rem' : '-1.75px'};
`;
export const H2 = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '6.0rem' : '60px'};
  line-height: ${Platform.OS === 'web' ? '8.0rem' : '80px'};
  font-weight: 700;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_700Bold'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.15rem' : '-1.5px'};
`;
export const H3 = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '4.8rem' : '48px'};
  line-height: ${Platform.OS === 'web' ? '5.6rem' : '56px'};
  font-weight: 700;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_700Bold'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.075' : '-0.75px'};
`;
export const H4 = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '3.4rem' : '34px'};
  line-height: ${Platform.OS === 'web' ? '4.4rem' : '44px'};
  font-weight: 800;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_800ExtraBold'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.044rem' : '-0.44px'};
`;
export const H5 = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '2.4rem' : '24px'};
  line-height: ${Platform.OS === 'web' ? '3.6rem' : '36px'};
  font-weight: 700;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_700Bold'};
  letter-spacing: 0px;
`;
export const H6 = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  line-height: ${Platform.OS === 'web' ? '3.2rem' : '32px'};
  font-weight: 700;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_700Bold'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.015rem' : '-0.15px'};
`;
export const Title = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  line-height: ${Platform.OS === 'web' ? '3.2rem' : '32px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '0.044rem' : '0.44px'};
`;
export const Body1 = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.6rem' : '16px'};
  line-height: ${Platform.OS === 'web' ? '2.4rem' : '24px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.044rem' : '-0.44px'};
`;
export const Body2 = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.4rem' : '14px'};
  line-height: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.044rem' : '-0.44px'};
`;

export const Caption = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.2rem' : '12px'};
  line-height: ${Platform.OS === 'web' ? '1.6rem' : '16px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '0.04rem' : '0.4px'};
`;
export const Overline = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.0rem' : '10px'};
  line-height: ${Platform.OS === 'web' ? '1.6rem' : '16px'};
  font-weight: 700;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_700Bold'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.15rem' : '-1.5px'};
`;
export const ButtonPrimary = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.0rem' : '10px'};
  line-height: ${Platform.OS === 'web' ? '1.6rem' : '16px'};
  font-weight: 700;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_700Bold'};
  letter-spacing: ${Platform.OS === 'web' ? '0.075rem' : '0.75px'};
`;
export const ButtonSecondary = styled.Text`
  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.4rem' : '14px'};
  line-height: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  font-weight: 600;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_600SemiBold'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.075rem' : '-0.75px'};
`;
