import styled from 'styled-components/native';

import { Body1 } from '@nucleus/Typography';

export const Container = styled.View`
  width: 100%;
  padding: 4px;
  position: relative;
`;

export const TitleContainer = styled.View`
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const MediaListContainer = styled.View`
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 8px;
`;

export const Label = styled(Body1)`
  color: ${({ theme }) => theme.white};
  margin-right: 8px;
`;
