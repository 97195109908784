import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigation } from '@react-navigation/native';
import { Image } from '../../components/Image';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import { CheckBox } from '../../components/CheckBox';
import loginLogo from '../../assets/loginLogo.png';
import { useAuthContext } from '../../contexts/AuthContext';
import { useSizeContext } from '../../contexts/SizeContext';

import { Container, LoginForm, CheckBoxContainer, SignInText } from './styles';

interface SignInCredentials {
  email: string;
  password: string;
  stay_connected: boolean;
}

export function SignIn() {
  const { t } = useTranslation();
  const { keyboardUp } = useSizeContext();
  const navigation = useNavigation();
  const { signIn } = useAuthContext();

  const signInSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email(t('valid_email')).required(t('valid_field')),
        password: yup.string().required(t('valid_field')),
        stay_connected: yup.boolean().required(),
      }),
    [t],
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInCredentials>({
    resolver: yupResolver(signInSchema),
  });

  async function onSubmit(data: SignInCredentials) {
    await signIn(data);
  }
  return (
    <Container testID="signin-screen">
      {!keyboardUp && <Image source={loginLogo} style={{ marginTop: '4%' }} />}
      <LoginForm keyboardUp={keyboardUp}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              placeholder="mlp@gmail.com"
              label={t('login')}
              autoCompleteType="email"
              onChangeText={newValue => onChange(newValue)}
              value={value}
              error={errors.email?.message}
            />
          )}
          name="email"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              placeholder="*******"
              label={t('password')}
              autoCompleteType="password"
              secureTextEntry
              onChangeText={newValue => onChange(newValue)}
              value={value}
              error={errors.password?.message}
            />
          )}
          name="password"
          defaultValue=""
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CheckBoxContainer>
              <CheckBox
                value={value}
                onValueChange={(newValue: boolean) => onChange(newValue)}
              />
              <SignInText>{t('stay_connected')}</SignInText>
            </CheckBoxContainer>
          )}
          name="stay_connected"
          defaultValue={false}
        />

        <Button loading={isSubmitting} onPress={handleSubmit(onSubmit)}>
          {t('log_in')}
        </Button>

        <SignInText onPress={() => navigation.navigate('ForgetPassword')}>
          {t('forgot_password')}
        </SignInText>
      </LoginForm>
    </Container>
  );
}
