import { useTranslation } from 'react-i18next';
import { InternalScreenModal } from '@atoms/InternalScreenModal';
import { Body1 } from '@nucleus/Typography';
import { ButtonsContainer, ActionButton } from './styles';

interface MainCouranteInfoDeleteMainCouranteModalProps {
  loading: boolean;
  isOpened: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const MainCouranteInfoDeleteMainCouranteModal = ({
  isOpened,
  loading,
  onConfirm,
  onClose,
}: MainCouranteInfoDeleteMainCouranteModalProps) => {
  const { t } = useTranslation();

  return (
    <InternalScreenModal isOpened={isOpened}>
      <Body1>{t('sure_want_delete_main_courante')}</Body1>

      <ButtonsContainer>
        <ActionButton outline onPress={onClose}>
          {t('modal_cancel')}
        </ActionButton>
        <ActionButton onPress={onConfirm} loading={loading}>
          {t('modal_confirm')}
        </ActionButton>
      </ButtonsContainer>
    </InternalScreenModal>
  );
};
