import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 60px 8px 80px;
  background-color: #212337;
  clip-path: polygon(5% 0, 100% 0, 100% 100%, 0 100%);
  div + div {
    margin-left: 8px;
  }
`;
