/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components/native';
import { Platform } from 'react-native';

const ContainerWeb = styled.View`
  width: 100%;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const ContainerMobile = styled.View`
  width: 100%;
  height: auto;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
`;

export const Container: any =
  Platform.OS === 'web' ? ContainerWeb : ContainerMobile;
