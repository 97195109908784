import { useMemo, useState } from 'react';
import { Modal } from '@atoms/Modal';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Body1 } from '@nucleus/Typography';
import { useQueryActionTypes } from '@services/hooks/useQueryActionTypes';
import { useAuthContext } from '@contexts/AuthContext';
import { Select } from '@atoms/Select';
import { ColorSelector } from '@atoms/ColorSelector';

import {
  ContentContainer,
  ButtonsContainer,
  Button,
  SideBySide,
} from './styles';

interface SelectActionFormData {
  color: string;
  action: string;
}

interface Result {
  color: string;
  action_type: {
    value: string;
    label: string;
  };
}

interface AnalyticsTabsMainCouranteFlowRatioAddActionTypeModalProps {
  isVisible?: boolean;
  onConfirm: (data: Result) => void;
  onCancel: () => void;
}

export const AnalyticsTabsMainCouranteFlowRatioAddActionTypeModal = ({
  isVisible,
  onConfirm,
  onCancel,
}: AnalyticsTabsMainCouranteFlowRatioAddActionTypeModalProps) => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { data: actions } = useQueryActionTypes(user?.teams[0].id);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const actionsOption = useMemo(() => {
    return actions
      ? actions.map(action => ({
          value: String(action.id),
          label: action.name,
        }))
      : [];
  }, [actions]);

  const selectActionFormValidation = useMemo(
    () =>
      yup.object().shape({
        color: yup.string().required(t('color_required')),
        action: yup.number().required(t('action_required')),
      }),
    [t],
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SelectActionFormData>({
    resolver: yupResolver(selectActionFormValidation),
  });

  const onSubmit = async ({ color, action }: SelectActionFormData) => {
    const action_type = actionsOption.find(
      actionOption => actionOption.value === String(action),
    );
    if (!action_type) return;
    onConfirm({ color, action_type });
    onCancel();
  };

  return (
    <Modal visible={isVisible}>
      <ContentContainer>
        <Body1>{t('select_action_type')}</Body1>
        <SideBySide>
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                style={{ maxWidth: 200 }}
                placeholder={t('type_of_action')}
                options={actionsOption}
                selectedOptions={value ? [String(value)] : []}
                onChange={acts => onChange(String(acts[0]))}
                error={errors.action?.message}
              />
            )}
            name="action"
          />
          <Controller
            control={control}
            render={({ field: { onChange, value } }) => (
              <ColorSelector
                position="center-down"
                isOpen={showColorPicker}
                color={value}
                onChange={onChange}
                setIsOpen={setShowColorPicker}
              />
            )}
            defaultValue="#5EFF5A"
            name="color"
          />
        </SideBySide>
        <ButtonsContainer>
          <Button outline onPress={onCancel}>
            {t('modal_cancel')}
          </Button>
          <Button onPress={handleSubmit(onSubmit)} loading={isSubmitting}>
            {t('save')}
          </Button>
        </ButtonsContainer>
      </ContentContainer>
    </Modal>
  );
};
