import { useTheme } from 'styled-components/native';
import { IconButton } from '@atoms/IconButton';
import closeIcon from '../../../assets/icons/close.svg';
import maximizeIcon from '../../../assets/icons/maximize.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import editIcon from '../../../assets/icons/edit.svg';
import { Container } from './styles';

interface MainCouranteInfoHeaderProps {
  onDeletePress: () => void;
  onEditPress: () => void;
  onFullScreenPress: () => void;
  onClosePress: () => void;
}

export const MainCouranteInfoHeader = ({
  onDeletePress,
  onEditPress,
  onFullScreenPress,
  onClosePress,
}: MainCouranteInfoHeaderProps) => {
  const theme = useTheme();
  return (
    <Container>
      <IconButton
        icon={deleteIcon}
        fill={theme.purpleBlue}
        onPress={onDeletePress}
      />

      <IconButton
        icon={editIcon}
        onPress={onEditPress}
        width="20"
        height="20"
        viewBox="0 0 12 12"
        fill={theme.purpleBlue}
      />

      <IconButton icon={maximizeIcon} onPress={onFullScreenPress} />

      <IconButton icon={closeIcon} onPress={onClosePress} />
    </Container>
  );
};
