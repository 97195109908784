import { useState, CSSProperties } from 'react';

export interface ImageProps {
  source: string;
  className?: string;
  style?: CSSProperties;
}

const errorImage =
  'https://s3.eu-central-1.amazonaws.com/nunki.public/icons/image_not_available.png';

export function Image({ source, ...rest }: ImageProps) {
  const [withError, setWithError] = useState(false);
  return (
    <img
      data-testid="image-component"
      src={!withError ? source : errorImage}
      alt="invalid"
      onError={() => setWithError(true)}
      {...rest}
    />
  );
}

export default Image;
