import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.purpleBlue};
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  margin-bottom: 8px;
  .options {
    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
`;
