import { useCallback, useEffect } from 'react';
import { Body1, Body2 } from '@nucleus/Typography';
import { MainCouranteInfoFormTextArea } from '@atoms/MainCouranteInfoFormTextArea';
import { MainCouranteInfoFormInput } from '@atoms/MainCouranteInfoFormInput';
import { InfoAvatar } from '@atoms/InfoAvatar';
import { MainCouranteInfoDetailTop } from '@molecules/MainCouranteInfoDetailTop';
import { MainCouranteInfoDetailActionTypeList } from '@molecules/MainCouranteInfoDetailActionTypeList';
import { MainCouranteInfoDetailMediaList } from '@molecules/MainCouranteInfoDetailMediaList';
import { MainCouranteInfoDetailLocationAndTime } from '@molecules/MainCouranteInfoDetailLocationAndTime';
import { MainCouranteInfoDetailLocationAndTimeForm } from '@molecules/MainCouranteInfoFormDatePicker';
import { MainCouranteInfoFormAttachmentsInput } from '@molecules/MainCouranteInfoFormAttachmentsInput';
import { MainCouranteAction } from '@services/hooks/useQueryMainCouranteInfo';
import { useQueryDocuments } from '@services/hooks/useQueryDocuments';
import { useMutationCloseMainCouranteAction } from '@services/hooks/useMutationCloseMainCouranteAction';
import { useTabsControlContext } from '@contexts/TabsControlContext';
import { useFiltersContext } from '@contexts/FiltersContext';
import { Control, Controller } from 'react-hook-form';
import { useCreateLocationContext } from '@contexts/CreateLocationContext';
import { Geometry as NebulaGeometry } from '@nebula.gl/edit-modes';
import { Container } from './styles';

export type Geometry = NebulaGeometry;

export interface UpdateMainCouranteActionFormData {
  title: string;
  location_name?: string;
  location_coordinates?: Geometry;
  location_color?: string;
  date?: Date;
  hour?: Date;
  desc: string;
  media: string[];
}

interface MainCouranteInfoDetailProps {
  mainCouranteActionInfo: MainCouranteAction;
  team_id: string;
  eventId: string;
  editMode: boolean;
  formControl: Control<UpdateMainCouranteActionFormData>;
  onDeleteAction: () => void;
}

export const MainCouranteInfoDetail = ({
  mainCouranteActionInfo,
  team_id,
  eventId,
  editMode,
  formControl,
  onDeleteAction,
}: MainCouranteInfoDetailProps) => {
  const {
    setLocationZoneType,
    setCreateLocationOpened,

    setLocationId,
  } = useCreateLocationContext();
  const { selectedTab } = useTabsControlContext();
  const { filters } = useFiltersContext();

  const queryDocuments = useQueryDocuments(
    team_id,
    mainCouranteActionInfo?.id,
    true,
  );

  useEffect(() => {
    if (!editMode) {
      queryDocuments.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, queryDocuments.refetch]);

  const closeMainCouranteActionMutation = useMutationCloseMainCouranteAction({
    main_courante_id: eventId,
    tabId: selectedTab,
    filters,
  });

  const handleClickOnPickerLocation = useCallback(() => {
    setLocationId(mainCouranteActionInfo.id);
    setLocationZoneType('main_courante_action');
    setCreateLocationOpened(true);
  }, [
    mainCouranteActionInfo.id,
    setCreateLocationOpened,
    setLocationId,
    setLocationZoneType,
  ]);

  const handleCloseAction = useCallback(
    async (main_courante_action_id: string) => {
      await closeMainCouranteActionMutation.mutateAsync({
        main_courante_action_id,
        closed: true,
      });
    },
    [closeMainCouranteActionMutation],
  );

  const handleOpenAction = useCallback(
    async (main_courante_action_id: string) => {
      await closeMainCouranteActionMutation.mutateAsync({
        main_courante_action_id,
        closed: false,
      });
    },
    [closeMainCouranteActionMutation],
  );

  return (
    <Container>
      <MainCouranteInfoDetailTop
        closed={mainCouranteActionInfo.closed}
        openCloseLoading={queryDocuments.isLoading}
        onOpenAction={() => handleOpenAction(mainCouranteActionInfo.id)}
        onCloseAction={() => handleCloseAction(mainCouranteActionInfo.id)}
        onDeletePress={onDeleteAction}
      />
      <InfoAvatar style={{ position: 'absolute', right: 8, top: 8 }} />

      {editMode ? (
        <>
          <Controller
            control={formControl}
            render={({ field: { onChange, value } }) => (
              <MainCouranteInfoFormInput
                value={value}
                onChangeText={onChange}
                style={{ maxWidth: 330, height: 24, fontSize: 14 }}
              />
            )}
            name="title"
            defaultValue={mainCouranteActionInfo.title}
          />

          <MainCouranteInfoDetailLocationAndTimeForm
            formControl={formControl}
            locationName={mainCouranteActionInfo.location_name || ''}
            date={mainCouranteActionInfo.date}
            onPickLocationPress={handleClickOnPickerLocation}
          />

          <MainCouranteInfoDetailActionTypeList
            actionTypeList={[mainCouranteActionInfo.action_type.name]}
          />

          <Controller
            control={formControl}
            render={({ field: { onChange, value } }) => (
              <MainCouranteInfoFormTextArea
                value={value}
                onChangeText={onChange}
              />
            )}
            name="desc"
            defaultValue={mainCouranteActionInfo.desc}
          />

          {!queryDocuments.isLoading && (
            <Controller
              control={formControl}
              render={({ field: { onChange } }) => (
                <MainCouranteInfoFormAttachmentsInput
                  teamId={team_id}
                  eventId={mainCouranteActionInfo.id}
                  onChange={onChange}
                  defaultFiles={
                    queryDocuments.data?.map(
                      ({ id, pre_signed_url, type }) => ({
                        document_id: id,
                        uri: pre_signed_url || '',
                        name: '',
                        type: type || '',
                      }),
                    ) || []
                  }
                />
              )}
              name="media"
            />
          )}
        </>
      ) : (
        <>
          <Body1 style={{ width: '85%' }}>{mainCouranteActionInfo.title}</Body1>
          <MainCouranteInfoDetailLocationAndTime
            location_name={mainCouranteActionInfo.location_name || ''}
            date={mainCouranteActionInfo.formatted_date}
          />
          <MainCouranteInfoDetailActionTypeList
            actionTypeList={[mainCouranteActionInfo.action_type.name]}
          />

          <Body2 style={{ marginTop: 8 }}>{mainCouranteActionInfo.desc}</Body2>

          <MainCouranteInfoDetailMediaList
            mediasLoading={
              queryDocuments.isLoading || queryDocuments.isFetching
            }
            medias={queryDocuments.data || []}
          />
        </>
      )}
    </Container>
  );
};
