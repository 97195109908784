import { EventInfoOverviewTop } from '@molecules/EventInfoOverviewTop';
import { EventInfoOverviewIntensityAndDate } from '@molecules/EventInfoOverviewIntensityAndDate';
import { EventInfoOverviewInfos } from '@molecules/EventInfoOverviewInfos';
import { EventInfoOverviewTags } from '@molecules/EventInfoOverviewTags';
import { EventInfoOverviewActorsBoard } from '@molecules/EventInfoOverviewActorsBoard';
import { EventInfoOverviewMediaBoard } from '@molecules/EventInfoOverviewMediaBoard';
import { Category } from '@contexts/FiltersContext';
import { Media } from '../../../services/hooks/useQueryEventInfo';
import { Container, BoardsContainer } from './styles';

interface EventInfoOverviewProps {
  media?: Media;
  isLoading: boolean;
  title: string;
  tags: string[];
  intensity: number;
  locationName: string;
  updatedDate: string;
  createdDate: string;
  category: Category;
}

export const EventInfoOverview = ({
  media,
  isLoading,
  category,
  intensity,
  updatedDate,
  createdDate,
  title,
  locationName,
  tags,
}: EventInfoOverviewProps) => {
  return (
    <Container>
      <EventInfoOverviewTop
        categoryColor={category.color}
        categoryIcon={category.icon}
        categoryName={category.name}
      />
      <EventInfoOverviewIntensityAndDate
        intensity={intensity}
        formattedDate={updatedDate}
      />
      <EventInfoOverviewInfos
        title={title}
        locationName={locationName}
        formattedDate={createdDate}
      />
      <EventInfoOverviewTags tags={tags} />

      <BoardsContainer>
        <EventInfoOverviewActorsBoard isLoading={isLoading} actors={[]} />
        <EventInfoOverviewMediaBoard isLoading={isLoading} media={media} />
      </BoardsContainer>
    </Container>
  );
};
