import { useRef, ReactNode } from 'react';
import { useWidgetGridContext } from '@contexts/WidgetGridContext';
import { Resizable, ResizableProps } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { ConnectDragSource } from 'react-dnd';

import { StyledWidgetContent } from './styles';

interface WidgetContentProps extends ResizableProps {
  isDragPreview: boolean;
  isColliding: boolean;
  dragRef?: ConnectDragSource;
  children?: ReactNode;
}

export const WidgetContent = ({
  isDragPreview,
  width,
  height,
  isColliding,
  dragRef,
  children,
  ...rest
}: WidgetContentProps) => {
  const { isEditMode } = useWidgetGridContext();

  const contentRef = useRef<HTMLDivElement>(null);

  return (
    <Resizable {...rest} width={width} height={height}>
      <StyledWidgetContent
        ref={contentRef}
        isEditMode={isEditMode}
        isColliding={isColliding}
        isDragPreview={isDragPreview}
        style={{ width, height }}
      >
        {!isDragPreview && (
          <>
            <div ref={isEditMode ? dragRef || null : null} className="content">
              {children}
            </div>
          </>
        )}
      </StyledWidgetContent>
    </Resizable>
  );
};
