import { Video } from '@nucleus/Video';
import { StyleProp, ViewStyle } from 'react-native';
import { Hoverable } from 'react-native-web-hooks';
import { Image } from '@nucleus/Image';
import { Icon } from '@nucleus/Icon';
import { Container, RemoveFile } from './styles';
import closeIcon from '../../../assets/icons/close.svg';

interface PreviewItemProps {
  source: string;
  type: 'image' | 'video' | undefined;
  onRemove?: () => void;
  openPreview: () => void;
  style?: StyleProp<ViewStyle>;
}

export const PreviewItem = ({
  source,
  type,
  onRemove,
  openPreview,
  style,
}: PreviewItemProps) => {
  return (
    <Container style={style} onPress={openPreview}>
      {type === 'video' ? <Video url={source} /> : <Image source={source} />}

      {onRemove && (
        <Hoverable>
          {isHovered => (
            <RemoveFile onPress={onRemove} isHovered={isHovered}>
              <Icon as={closeIcon} viewBox="0 0 30 30" width="12" fill="#fff" />
            </RemoveFile>
          )}
        </Hoverable>
      )}
    </Container>
  );
};
