import { useQuery } from 'react-query';
import { listTabs, Tab } from '../../utils/tabApi';

export async function getEvents(): Promise<Tab[]> {
  const tabs = await listTabs();
  return tabs;
}

export function useQueryTabs() {
  return useQuery(['tabs'], () => getEvents());
}
