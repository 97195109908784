/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import { InteractiveMap, FlyToInterpolator } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { defaultMap } from '@contexts/TabsControlContext';
import { MapProps } from './types';

(mapboxgl as any).workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const TOKEN = process.env.MAP_BOX_KEY;
// ('pk.eyJ1Ijoiam9hb2duIiwiYSI6ImNrbGw3bGI5NTNmdWYyenRrd3dwZGh3Z2kifQ.SHH9ded8nfmYQFJw1hJ1Jg');

export const MapView = ({
  children,
  viewport,
  onViewportChange,
  mapStyle,
  style,
}: MapProps) => {
  return (
    <InteractiveMap
      transitionDuration={'auto' as any}
      transitionInterpolator={new FlyToInterpolator({ speed: 3 })}
      asyncRender
      doubleClickZoom={false}
      style={style}
      mapStyle={mapStyle || defaultMap}
      mapboxApiAccessToken={TOKEN}
      onViewportChange={onViewportChange}
      {...viewport}
      width="100%"
      height="100%"
    >
      {children}
    </InteractiveMap>
  );
};
