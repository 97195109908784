import { useMemo } from 'react';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { AnalyticsTabsDynamicDataCardTitle } from '@molecules/AnalyticsTabsDynamicDataCardTitle';
import { AnalyticsTabsDynamicDataCardValue } from '@molecules/AnalyticsTabsDynamicDataCardValue';
import { AnalyticsTabsDynamicDataCardWindLabel } from '@molecules/AnalyticsTabsDynamicDataCardWindLabel';
import { AnalyticsTabTooltipContent } from '@molecules/AnalyticsTabTooltipContent';
import { Tooltip } from '@atoms/Tooltip';
import { useWindowDimensions } from 'react-native';
import { getWindDirection } from '../../../utils/getWindDirection';
import { Container, WindsContainer } from './styles';
import windIcon from '../../../assets/icons/wind.svg';

interface Data {
  unit: string;
  data: string;
  source: string;
  name: string;
  lastUpdateFormatted: string;
  sourceUrl: string;
}

interface AnalyticsTabsDynamicDataWindCardProps {
  windForce?: Data;
  maxBurst?: Data;
  windDirection?: Data;
}

export const AnalyticsTabsDynamicDataWindCard = ({
  windForce,
  maxBurst,
  windDirection,
}: AnalyticsTabsDynamicDataWindCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { width } = useWindowDimensions();

  const isSmall = useMemo(() => width < 1700, [width]);

  const windLabel = useMemo(() => {
    return getWindDirection(Number(windDirection?.data));
  }, [windDirection?.data]);

  return (
    <Container small={isSmall}>
      <AnalyticsTabsDynamicDataCardTitle
        icon={windIcon}
        title={t('forces_with_bursts')}
      />

      <WindsContainer small={isSmall}>
        {windForce && (
          <Tooltip
            content={
              <AnalyticsTabTooltipContent
                text={`Source: ${windForce.source}`}
                lastUpdateFormatted={windForce.lastUpdateFormatted}
              />
            }
            place="bottom"
          >
            <AnalyticsTabsDynamicDataCardValue
              sourceUrl={windForce.sourceUrl}
              color={theme.purple}
              value={windForce.data}
              unit={windForce.unit}
              small={isSmall}
              bottomLabel={t('wind_force').toUpperCase()}
              label={
                windDirection?.data && (
                  <AnalyticsTabsDynamicDataCardWindLabel
                    label={windLabel}
                    direction={Number(windDirection?.data)}
                  />
                )
              }
            />
          </Tooltip>
        )}

        {maxBurst && (
          <Tooltip
            content={
              <AnalyticsTabTooltipContent
                text={`Source: ${maxBurst.source}`}
                lastUpdateFormatted={maxBurst.lastUpdateFormatted}
              />
            }
            place="bottom"
          >
            <AnalyticsTabsDynamicDataCardValue
              sourceUrl={maxBurst.sourceUrl}
              color={theme.yellow}
              value={maxBurst.data}
              unit={maxBurst.unit}
              small={isSmall}
              bottomLabel={t('maximum_burst').toUpperCase()}
              label={
                windDirection?.data && (
                  <AnalyticsTabsDynamicDataCardWindLabel
                    label={windLabel}
                    direction={Number(windDirection?.data)}
                  />
                )
              }
            />
          </Tooltip>
        )}
      </WindsContainer>
    </Container>
  );
};
