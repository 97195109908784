import styled, { css } from 'styled-components/native';
import { Caption } from '@nucleus/Typography';

interface ContainerProps {
  color: string;
}

export const Container = styled.View<ContainerProps>`
  padding: 8px;
  height: 20px;
  background: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-direction: row;
`;

interface NameProps {
  small?: boolean;
}
export const Name = styled(Caption)<NameProps>`
  margin-left: 8px;
  ${({ small }) =>
    small &&
    css`
      font-size: 10px;
    `}
`;

export const IconContainer = styled.View`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  align-items: center;
  justify-content: center;
`;
