import SVG from 'react-inlinesvg';
import { Container, IconContainer, Name } from './styles';

interface EventInfoCategoryBadgeProps {
  name: string;
  color: string;
  icon?: string;
}

const SMALL_NAME_LIMIT = 15;

export const EventInfoCategoryBadge = ({
  name,
  color,
  icon,
}: EventInfoCategoryBadgeProps) => {
  return (
    <Container color={color}>
      {icon && (
        <IconContainer>
          <SVG
            src={icon}
            width={10}
            fill={color}
            height="100%"
            title="category_icon"
          />
        </IconContainer>
      )}
      <Name small={name.length > SMALL_NAME_LIMIT}>
        {name.toLocaleUpperCase()}
      </Name>
    </Container>
  );
};
