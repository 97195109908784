import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@contexts/ToastContext';
import { queryClient } from '@services/queryClient';
import { api } from '@services/userManagementApi';

interface PageData {
  name: string;
  workspace_id: string;
}

interface CreateWorkspaceMutation {
  workspace_id: string;
}

interface PageResponse {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export async function createPage(data: PageData): Promise<PageResponse> {
  const response = await api.post(`/v1/pages`, data);
  return response.data;
}

export const useMutationCreatePage = ({
  workspace_id,
}: CreateWorkspaceMutation) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async (data: PageData) => {
      const newPage = await createPage(data);
      return newPage;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['workspace', workspace_id]);
        dispatchToast({
          message: t('success_create_page'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_create_page'),
          type: 'error',
        });
      },
    },
  );
};
