import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AutoCompleteInput } from '@atoms/AutoCompleteInput';
import { api } from '@services/geocodingApi';

interface ExistedAddressContext {
  id: string;
  text: string;
  wikidata?: string;
  short_code?: string;
}

export interface ExistedAddressFeature {
  center: [number, number] | [number, number, number];
  context: ExistedAddressContext[];
  geometry: {
    type: 'Point';
    coordinates: [number, number] | [number, number, number];
  };
  id: string;
  place_name: string;
  place_type: string[];
  properties: { accuracy: string };
  relevance: number;
  text: string;
  type: 'Feature';
}

export interface Option {
  label: string;
  value: string;
  data: ExistedAddressFeature;
}

interface CreateLocationExistedAddressAutoCompleteInputProps {
  onSelectAddress: (data: ExistedAddressFeature) => void;
  placeholder?: string;
}

export const CreateLocationExistedAddressAutoCompleteInput = ({
  onSelectAddress,
  placeholder,
}: CreateLocationExistedAddressAutoCompleteInputProps) => {
  const [value, setValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState<Option[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const setSearchValueWithDebounce = useDebouncedCallback(
    (newValue: string) => {
      setSearchValue(newValue);
    },
    500,
  );

  useEffect(() => {
    if (value.length > 2) {
      setSearchValueWithDebounce(value);
      return;
    }
    setIsOpen(false);
  }, [value, setSearchValueWithDebounce]);

  useEffect(() => {
    async function requestOptions() {
      const response = await api.get(`/mapbox.places/${searchValue}.json`, {
        params: {
          access_token:
            'pk.eyJ1Ijoiam9hb2duIiwiYSI6ImNrbGw3bGI5NTNmdWYyenRrd3dwZGh3Z2kifQ.SHH9ded8nfmYQFJw1hJ1Jg',
          types:
            'country,region,district,place,neighborhood,address,poi,locality,postcode',
          limit: 5,
        },
      });
      if (response.data.features && response.data.features.length > 0) {
        setOptions(
          response.data.features.map((feature: ExistedAddressFeature) => ({
            label: feature.place_name,
            value: feature.id,
            data: feature,
          })),
        );
        setIsOpen(true);
      }
    }
    if (searchValue === '') return;
    requestOptions();
  }, [searchValue]);

  return (
    <AutoCompleteInput<ExistedAddressFeature>
      placeholder={placeholder}
      style={{ width: '100%', maxWidth: '160px', marginRight: 16 }}
      onChangeText={newValue => setValue(newValue)}
      options={options}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onSelectOption={onSelectAddress}
    />
  );
};
