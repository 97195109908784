import { ReactNode } from 'react';
import { H6 } from '@nucleus/Typography';
import { IconButton } from '@atoms/IconButton';
import { Container, Header } from './styles';
import conseIcon from '../../../assets/icons/close.svg';

interface WidgetSidePanelProps {
  onClose: () => void;
  title: string;
  children: ReactNode;
}

export const WidgetSidePanel = ({
  onClose,
  title,
  children,
}: WidgetSidePanelProps) => {
  return (
    <Container>
      <Header>
        <H6>{title}</H6>
        <IconButton
          icon={conseIcon}
          fill="white"
          width={24}
          height={24}
          viewBox="0 0 30 30"
          onPress={onClose}
        />
      </Header>
      {children}
    </Container>
  );
};
