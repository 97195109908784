import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 30px;
  border-bottom: 3px solid ${({ theme }) => theme.background};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
  svg:last-child {
    margin-right: 10px;
  }
`;
