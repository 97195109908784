import styled from 'styled-components/native';

export const TitleContainer = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const WidgetTypesListContainer = styled.View`
  max-width: 800px;
  width: 100%;
  max-height: 700px;
  height: calc(100% - 86px);
  border-color: ${({ theme }) => theme.purpleBlue};
  border-radius: 8px;
  border-width: 2px;
  padding: 16px;
  justify-content: flex-start;
  margin-top: 16px;
  overflow-y: scroll;
`;
