import styled, { css } from 'styled-components/native';
import { TimePicker } from '@nucleus/TimerPicker';
import { ButtonPrimary } from '@nucleus/Typography';
import { Platform } from 'react-native';

export const Container = styled.View`
  padding: 0 4px;
  width: 100%;
  max-width: 130px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.blue};
  display: flex;
  flex-direction: column;
`;

export const ErrorContainer = styled.View`
  display: flex;
  top: 38px;
  flex-direction: row;
  position: absolute;
  color: ${({ theme }) => theme.red};
`;

export const ErrorText = styled(ButtonPrimary)`
  color: ${({ theme }) => theme.red};
  margin-left: 4px;
  font-weight: 400;
`;

export const InputContainer = styled.TouchableOpacity`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: space-between;
`;

export const InputText = styled.TextInput`
  width: 100%;
  font-size: ${Platform.OS === 'web' ? '1.4rem' : '14px'};
  line-height: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.044rem' : '-0.44px'};
  color: ${({ theme }) => theme.white};
  background-color: transparent;
  border: none;
  ::placeholder {
    color: ${({ theme }) => theme.purpleBlue};
  }
`;

interface PickerProps {
  parentElement: HTMLElement;
}

export const Picker = styled(TimePicker)<PickerProps>`
  position: absolute;
  ${({ parentElement }) => {
    const { top, left, width } = parentElement.getBoundingClientRect();
    return css`
      left: ${`${left}px`};
      top: ${`${top + 24}px`};
      width: ${width > 200 ? `${width}px` : `200px`};
    `;
  }};
  .rc-time-picker-panel-inner {
    background-color: red !important;
    background: red !important;
    border-color: red !important;
    .rc-time-picker-panel-input-wrap {
      background-color: red !important;
      background: red !important;
      border-color: red !important;
    }
  }
`;
