import { useTranslation } from 'react-i18next';
import { H6 } from '@nucleus/Typography';
import { EventInfoCategoryBadge } from '@atoms/EventInfoCategoryBadge';
import { Container } from './styles';

interface EventInfoOverviewTopProps {
  categoryIcon?: string;
  categoryColor: string;
  categoryName: string;
}

export const EventInfoOverviewTop = ({
  categoryIcon,
  categoryColor,
  categoryName,
}: EventInfoOverviewTopProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <H6>{t('overview')}</H6>
      <EventInfoCategoryBadge
        name={t(categoryName)}
        color={categoryColor}
        icon={categoryIcon}
      />
    </Container>
  );
};
