import styled, { css } from 'styled-components/native';
import { Dimensions } from 'react-native';

export const Container = styled.View`
  height: 125px;
  border-radius: 20px;
  border-color: ${({ theme }) => theme.purpleBlue};
  border-width: 2px;
  margin-top: 24px;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  flex-direction: row;
  padding-left: 16px;
  ${() => {
    const { width } = Dimensions.get('window');
    if (width < 1700) {
      return css`
        height: 100px;
        margin-top: 16px;
        padding-left: 8px;
      `;
    }
    return css``;
  }}
`;

export const RatioContainer = styled.View`
  width: 100px;
  height: 125px;
  border-radius: 20px;
  border-color: ${({ theme }) => theme.white};
  border-width: 2px;
  top: -2px;
  right: -16px;
  align-items: space-between;
  justify-content: center;
  ${() => {
    const { width } = Dimensions.get('window');
    if (width < 1700) {
      return css`
        height: 100px;
        width: 80px;
      `;
    }
    return css``;
  }}
`;
