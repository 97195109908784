import { useRef, useEffect, useState } from 'react';
import { View } from 'react-native';
import { IconButton } from '@atoms/IconButton';
import { Tag } from '@atoms/Tag';
import { Container } from './styles';
import expandMoreIcon from '../../../assets/icons/expand_more.svg';
import expandLessIcon from '../../../assets/icons/expand_less.svg';

interface EventInfoOverviewTagsProps {
  tags: string[];
}

export const EventInfoOverviewTags = ({ tags }: EventInfoOverviewTagsProps) => {
  const [withMore, setWithMore] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const containerRef = useRef<View>(null);

  useEffect(() => {
    setShowAll(false);
    const element = containerRef.current as unknown as HTMLDivElement;
    if (element.clientHeight > 28) {
      setWithMore(true);
      return;
    }
    setWithMore(false);
  }, [tags]);

  return (
    <Container ref={containerRef} withMore={withMore} showAll={showAll}>
      {tags.map(tag => (
        <Tag key={`detail_tag_${tag}`} tag={tag} />
      ))}
      {withMore && !showAll && (
        <IconButton
          icon={expandMoreIcon}
          onPress={() => setShowAll(true)}
          style={{ position: 'absolute', bottom: 0, right: 0 }}
        />
      )}

      {withMore && showAll && (
        <IconButton
          icon={expandLessIcon}
          onPress={() => setShowAll(false)}
          style={{ position: 'absolute', bottom: 0, right: 0 }}
        />
      )}
    </Container>
  );
};
