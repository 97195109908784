import styled from 'styled-components';
import { Rnd } from 'react-rnd';

interface ContainerProps {
  hidden?: boolean;
}

export const Container = styled(Rnd)<ContainerProps>`
  z-index: 5;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px #05050f;
  position: relative;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;

export const Header = styled.div`
  width: 100%;
  height: 30px;
  border-bottom: 3px solid ${({ theme }) => theme.background};
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
  svg {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
  svg:last-child {
    margin-right: 10px;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  padding-bottom: 16px;
`;
