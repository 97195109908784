import { useRef } from 'react';
import { useHover } from 'react-native-web-hooks';
import { Button, ButtonText } from './styles';

interface TopMenuTextButtonProps {
  isSelected?: boolean;
  text: string;
  onPress: () => void;
}

export const TopMenuTextButton = ({
  isSelected,
  text,
  onPress,
}: TopMenuTextButtonProps) => {
  const touchableRef = useRef(null);
  const isHovered = useHover(touchableRef);
  return (
    <Button ref={touchableRef} isSelected={isSelected} onPress={onPress}>
      <ButtonText isHovered={isHovered}>{text}</ButtonText>
    </Button>
  );
};
