/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { Zone } from '@contexts/ZonesContext';
import { MainCouranteAction } from './useQueryMainCouranteInfo';
import { api } from '../eventApi';
import { centerLocation } from '../../utils/centerLocation';

export interface QueryMainCouranteActionsProps {
  team_id?: string;
  main_courantes: string[];
  closed?: boolean;
  active: boolean;
}

export async function getMainCouranteActions({
  team_id,
  closed,
  main_courantes,
}: Omit<QueryMainCouranteActionsProps, 'active'>): Promise<
  MainCouranteAction[]
> {
  if (!team_id) return [];
  try {
    const response = await api.get(
      `/v1/main_courante_actions/filter_by_team/${team_id}`,
      {
        params: {
          closed,
          main_courantes:
            main_courantes.length > 0 ? main_courantes.join(',') : undefined,
        },
      },
    );

    const mappedActions = response.data.map((mainCouranteAction: any) => {
      const zone =
        mainCouranteAction.location_coordinates &&
        mainCouranteAction.location_coordinates.type !== 'Point'
          ? ({
              id: mainCouranteAction.id,
              type: 'Feature' as const,
              properties: {
                main_courante_id: mainCouranteAction.main_courante_id,
                name: 'main_courante_action_event',
                type: 'main_courante_action',
                color: mainCouranteAction.location_color
                  ? mainCouranteAction.location_color
                  : mainCouranteAction.closed
                  ? '#FF2D2E'
                  : '#5EFF5A',
                editable: false,
              },
              geometry: mainCouranteAction.location_coordinates,
            } as Zone)
          : undefined;
      return {
        ...mainCouranteAction,
        location_coordinates: centerLocation(
          mainCouranteAction.location_coordinates,
        ),
        zone,
      };
    });

    return mappedActions;
  } catch {
    return [];
  }
}

export function useQueryMainCouranteActions({
  team_id,
  main_courantes,
  closed,
  active,
}: QueryMainCouranteActionsProps) {
  return useQuery(
    ['main_courante_actions', team_id, main_courantes],
    () => getMainCouranteActions({ team_id, closed, main_courantes }),
    {
      refetchOnWindowFocus: false,
      enabled: !!team_id && active,
    },
  );
}
