import React from 'react';
import { useTheme } from 'styled-components/native';
import {
  Container,
  ReactDatePicker,
  InputContainer,
  ErrorContainer,
  ErrorText,
} from './styles.web';
import ScheduleIcon from '../../assets/icons/schedule.svg';
import { DatePickerProps } from './types';
import { Caption } from '../Typography';
import ErrorIcon from '../../assets/icons/error_outline.svg';

export const DatePicker = ({
  date,
  placeholder,
  onChange,
  onBlur,
  style,
  error,
}: DatePickerProps) => {
  const theme = useTheme();
  return (
    <Container style={style}>
      {date && <Caption style={{ color: '#6a6a9f' }}>{placeholder}</Caption>}
      <InputContainer>
        <ReactDatePicker
          selected={date ? new Date(date) : undefined}
          onChange={onChange}
          placeholderText={placeholder}
          showPopperArrow={false}
          onCalendarClose={onBlur}
        />
        <ScheduleIcon />
      </InputContainer>
      {error && (
        <ErrorContainer>
          <ErrorIcon
            testID="input-error-icon"
            fill={theme.red}
            width={16}
            height={16}
            viewBox="0 0 24 24"
          />
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
      )}
    </Container>
  );
};
