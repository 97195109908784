/* eslint-disable consistent-return */
import { useEffect, useRef, forwardRef } from 'react';
import { Caption } from '@nucleus/Typography';
import { setHours, setMinutes, getHours, getMinutes } from 'date-fns';
import { Hoverable } from 'react-native-web-hooks';
import { StyleProp, ViewStyle, ScrollView, View } from 'react-native';
import {
  Container,
  HourContainer,
  MinuteContainer,
  AmPmContainer,
  ItemSelector,
} from './styles';

const hourArray = Array(12)
  .fill(null)
  .map((_, i) => i + 1);
const minuteArray = Array(60)
  .fill(null)
  .map((_, i) => i);
const amPmArray = ['am', 'pm'];

interface TimePickerProps {
  style?: StyleProp<ViewStyle>;
  date?: Date;
  onChange: (date: Date) => void;
  onAmPmPress?: () => void;
}

export const TimePicker = forwardRef<View, TimePickerProps>(
  // eslint-disable-next-line react/prop-types
  ({ style, date, onChange, onAmPmPress }, ref) => {
    const hourContainer = useRef<ScrollView>(null);
    const minuteContainer = useRef<ScrollView>(null);

    const handleSelectHour = (hours: number) => {
      const newDate = setHours(date || new Date(), hours);
      onChange(newDate);
    };
    const handleSelectMinutes = (minutes: number) => {
      const newDate = setMinutes(date || new Date(), minutes);
      onChange(newDate);
    };

    const handleAmPm = (value: string) => {
      const customDate = new Date();
      const hour = getHours(date || customDate);
      if (hour === 0 && value === 'pm') {
        const newDate = setHours(date || customDate, 12);
        onChange(newDate);
        if (onAmPmPress) onAmPmPress();
        return;
      }
      if (hour === 12 && value === 'am') {
        const newDate = setHours(date || customDate, 0);
        onChange(newDate);
        if (onAmPmPress) onAmPmPress();
        return;
      }
      if (hour > 0 && hour >= 13 && value === 'am') {
        const newDate = setHours(date || customDate, hour - 12);
        onChange(newDate);
        if (onAmPmPress) onAmPmPress();
        return;
      }
      if (hour > 0 && hour <= 13 && value === 'pm') {
        const newDate = setHours(date || customDate, hour + 12);
        onChange(newDate);
        if (onAmPmPress) onAmPmPress();
        return;
      }
      onChange(date || customDate);
      if (onAmPmPress) onAmPmPress();
    };

    const checkSelectedHour = (): number | undefined => {
      if (!date) return;
      const hour = getHours(date);
      if (hour === 0) return 12;
      if (hour >= 13) return hour - 12;
      return hour;
    };

    const checkAmPm = (): string | undefined => {
      if (!date) return;
      const hour = getHours(date);
      if (hour === 0) return 'am';
      if (hour >= 13) return 'pm';
      return 'am';
    };

    useEffect(() => {
      if (hourContainer.current)
        hourContainer.current?.scrollTo({
          x: 0,
          y: (checkSelectedHour() || 0) * 30 - 30,
          animated: false,
        });
      if (minuteContainer.current)
        minuteContainer.current?.scrollTo({
          x: 0,
          y: getMinutes(date || new Date()) * 30,
          animated: false,
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Container style={style} ref={ref}>
        <HourContainer ref={hourContainer}>
          {hourArray.map(value => (
            <Hoverable>
              {isHovered => (
                <ItemSelector
                  isSelected={checkSelectedHour() === value}
                  isHovered={isHovered}
                  onPress={() => handleSelectHour(value)}
                >
                  <Caption>{value}</Caption>
                </ItemSelector>
              )}
            </Hoverable>
          ))}
        </HourContainer>
        <MinuteContainer ref={minuteContainer}>
          {minuteArray.map(value => (
            <Hoverable>
              {isHovered => (
                <ItemSelector
                  isSelected={date ? getMinutes(date) === value : false}
                  isHovered={isHovered}
                  onPress={() => handleSelectMinutes(value)}
                >
                  <Caption>{value}</Caption>
                </ItemSelector>
              )}
            </Hoverable>
          ))}
        </MinuteContainer>
        <AmPmContainer>
          {amPmArray.map(value => (
            <Hoverable>
              {isHovered => (
                <ItemSelector
                  isSelected={checkAmPm() === value}
                  isHovered={isHovered}
                  onPress={() => handleAmPm(value)}
                >
                  <Caption>{value}</Caption>
                </ItemSelector>
              )}
            </Hoverable>
          ))}
        </AmPmContainer>
      </Container>
    );
  },
);
