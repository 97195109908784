import styled from 'styled-components/native';

export const Container = styled.View`
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  height: calc(100% - 68px);
`;

export const Stack = styled.View`
  height: 32px;
  width: 100%;
`;
