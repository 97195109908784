import styled from 'styled-components/native';

export const Container = styled.View`
  width: 97%;
  justify-content: space-between;
  padding: 2px 7px 5px 7px;
  align-items: center;
  flex-direction: row;
  height: 30px;
`;
