/* eslint-disable @typescript-eslint/no-empty-interface */
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@contexts/ToastContext';
import { api } from '@services/userManagementApi';

export interface UpdateWidgetData {
  id: string;
  name?: string;
  config_id?: string;
  grid_left?: number;
  grid_top?: number;
  grid_width?: number;
  grid_height?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

export async function updateWidgets(data: UpdateWidgetData[]): Promise<void> {
  await api.put(`/v1/widgets/`, data);
}

export const useMutationUpdateWidgets = () => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async (data: UpdateWidgetData[]) => {
      await updateWidgets(data);
    },
    {
      onSuccess: async () => {
        dispatchToast({
          message: t('success_update_widgets'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_update_widgets'),
          type: 'error',
        });
      },
    },
  );
};
