import { ReactNode } from 'react';
import TopMenu from '@molecules/TopMenu';
import { useAuthContext } from '@contexts/AuthContext';
import { Container } from './styles';

interface WebLayoutProps {
  children: ReactNode;
}

export const WorkspaceLayout = ({ children }: WebLayoutProps) => {
  const { user, signOut } = useAuthContext();

  if (!user) {
    signOut();
    return null;
  }
  return (
    <Container>
      <TopMenu />
      <main>{children}</main>
    </Container>
  );
};
