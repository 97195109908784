import styled from 'styled-components';
import { gridConstants } from '../../../hooks/useGridHelper';

export const Container = styled.div`
  display: flex;
  width: ${gridConstants.SIDE_PANEL_WIDTH * 100}%;
  flex-direction: column;
  height: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.black};
  align-items: center;
`;

export const Header = styled.div`
  height: 100%;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px;
  background-color: ${({ theme }) => theme.purpleBlue};
  margin-bottom: 16px;
`;
