import styled from 'styled-components/native';

interface MarkerProps {
  size: number;
}

export const Container = styled.TouchableOpacity`
  align-items: center;
  position: relative;
`;

export const MarkerContainer = styled.View<MarkerProps>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: ${({ size }) => `${size / 5}px`};
  background-color: ${({ theme }) => theme.blueishBlack};
  justify-content: center;
  align-items: center;
`;

interface CategoryColorContainerProps {
  color: string;
  size: number;
}
export const CategoryColorContainer = styled.View<CategoryColorContainerProps>`
  background-color: ${({ color }) => color};
  width: 66.66666666%;
  height: 66.66666666%;
  border-radius: ${({ size }) => `${size / 5}px`};
  justify-content: center;
  align-items: center;
`;

export const Delta = styled.View<MarkerProps>`
  position: absolute;
  top: ${({ size }) => `${size}px`};
  width: 0px;
  height: 0px;
  background-color: transparent;
  border-style: solid;
  border-top-width: 6px;
  border-right-width: 6px;
  border-bottom-width: 0;
  border-left-width: 6px;
  border-top-color: ${({ theme }) => theme.blueishBlack};
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
`;
