import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '@atoms/ActionButton';
import { ColorSelector } from '@atoms/ColorSelector';
import { useTheme } from 'styled-components/native';
import { Input } from '@atoms/Input';
import deleteIcon from '../../../assets/icons/delete.svg';
import undoIcon from '../../../assets/icons/undo.svg';
import confirmIcon from '../../../assets/icons/confirm.svg';
import { Container } from './styles';

interface CreateLocationCustomAddressConfigureZoneProps {
  onDeleteZonePress: () => void;
  onUndoZonePress: () => void;
  onConfirmZonePress: () => void;
  isEditingZone: boolean;
  locationName: string;
  setLocationName: (value: string) => void;
  currentZoneColor: string;
  changeCurrentZoneColor: (value: string) => void;
}

export const CreateLocationCustomAddressConfigureZone = ({
  locationName,
  setLocationName,
  currentZoneColor,
  changeCurrentZoneColor,
  onDeleteZonePress,
  onUndoZonePress,
  onConfirmZonePress,
  isEditingZone,
}: CreateLocationCustomAddressConfigureZoneProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [colorOpened, setColorOpened] = useState(false);
  return (
    <Container>
      <Input
        value={locationName}
        onChangeText={setLocationName}
        style={{ width: '45%' }}
        placeholder={t('zone_name')}
      />
      <ColorSelector
        color={currentZoneColor}
        onChange={changeCurrentZoneColor}
        isOpen={colorOpened}
        setIsOpen={setColorOpened}
        position="center-down"
      />
      <ActionButton
        backgroundColor={theme.purpleBlue}
        iconColor={theme.white}
        size="medium"
        icon={deleteIcon}
        onPress={onDeleteZonePress}
      />

      {!isEditingZone && (
        <ActionButton
          backgroundColor={theme.purpleBlue}
          iconColor={theme.white}
          size="medium"
          icon={undoIcon}
          onPress={onUndoZonePress}
        />
      )}
      {isEditingZone && (
        <ActionButton
          backgroundColor={theme.purpleBlue}
          iconColor={theme.white}
          size="medium"
          icon={confirmIcon}
          iconViewBox="0 0 17 13"
          onPress={onConfirmZonePress}
        />
      )}
    </Container>
  );
};
