import React from 'react';
import { WebLayout } from '@templates/WebLayout';
import { AnalyticsTabs } from '../../components/AnalyticsTabs';
import { MyDocuments } from '../../components/MyDocuments';
import { Container, RightContainer } from './styles.web';

const Dashboard: React.FC = () => {
  return (
    <WebLayout>
      <Container>
        <AnalyticsTabs />
        <RightContainer>
          <MyDocuments />
        </RightContainer>
      </Container>
    </WebLayout>
  );
};

export default Dashboard;
