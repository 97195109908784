import styled from 'styled-components/native';
import { Button } from '../../Button';
import { Body1 } from '../../Typography';

export const Container = styled.View`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

export const SideBySide = styled.View`
  margin-top: 16px;
  width: 98%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ConfirmButton = styled(Button)`
  margin-right: 16px;
  width: 136px;
  height: 40px;
  align-self: flex-end;
`;

export const CloseMainCouranteContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CheckBoxContainer = styled.View`
  flex-direction: row;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
`;

export const YesText = styled(Body1)`
  color: ${({ theme }) => theme.eletricGreen};
  margin-left: 8px;
`;

export const NoText = styled(Body1)`
  color: ${({ theme }) => theme.red};
  margin-left: 8px;
`;

export const CreateZoneButton = styled.TouchableOpacity`
  justify-content: space-evenly;
  height: 36px;
  width: 36px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.purpleBlue};
  justify-content: center;
  align-items: center;
`;

export const CreateTypeButtonContainer = styled.View`
  max-width: 200px;
  width: 200px;
`;

export const CreateTypeButton = styled(Button)`
  width: 128px;
  height: 32px;
  background-color: ${({ theme }) => theme.purpleBlue};
`;
