import { useTranslation } from 'react-i18next';
import { SelectableTitle } from '@atoms/SelectableTitle';
import { TimeLabel } from '@atoms/TimeLabel';
import { InfoAvatar } from '@atoms/InfoAvatar';

import { Container, Detail, DetailInfo, SourceName } from './styles';

interface EventInfoContentsPreviewProps {
  onSelect: () => void;
  isSelected?: boolean;
  title: string;
  date: string;
  sourceName: string;
  sourceAvatar?: string;
}

export const EventInfoContentsPreview = ({
  onSelect,
  isSelected,
  title,
  date,
  sourceName,
  sourceAvatar,
}: EventInfoContentsPreviewProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      <InfoAvatar avatar={sourceAvatar} />
      <Detail>
        <SelectableTitle
          title={title}
          onPress={onSelect}
          selected={isSelected}
        />
        <DetailInfo>
          <TimeLabel formattedDate={date} />
          <SourceName numberOfLines={1}>
            {`${t('source')}: ${sourceName}`}
          </SourceName>
        </DetailInfo>
      </Detail>
    </Container>
  );
};
