import { Body1, Caption } from '@nucleus/Typography';
import { TimeLabel } from '@atoms/TimeLabel';
import { Container, InfosContainer } from './styles';

interface EventInfoOverviewInfosProps {
  title: string;
  locationName: string;
  formattedDate: string;
}

export const EventInfoOverviewInfos = ({
  formattedDate,
  title,
  locationName,
}: EventInfoOverviewInfosProps) => {
  return (
    <Container>
      <Body1 numberOfLines={2}>{title}</Body1>
      <InfosContainer style={{ marginBottom: 4 }}>
        <Caption>{locationName}</Caption>
        <TimeLabel formattedDate={formattedDate} style={{ marginLeft: 4 }} />
      </InfosContainer>
    </Container>
  );
};
