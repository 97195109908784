import styled from 'styled-components';
import { Rnd } from 'react-rnd';

interface ContainerProps {
  hidden?: boolean;
}

export const Container = styled(Rnd)<ContainerProps>`
  z-index: 3;
  background-color: ${({ theme }) => theme.blueishBlack};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px #05050f;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;

export const Header = styled.div`
  width: 100%;
  height: 30px;
  border-bottom: 3px solid ${({ theme }) => theme.background};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  svg {
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
  svg:last-child {
    margin-right: 10px;
  }
`;
interface ContentContainerProps {
  isEditableMode: boolean;
}

export const ContentContainer = styled.div<ContentContainerProps>`
  width: 100%;
  height: ${({ isEditableMode }) =>
    isEditableMode ? 'calc(100% - 66px)' : 'calc(100% - 30px)'};
  display: flex;
  padding-bottom: ${({ isEditableMode }) => (isEditableMode ? '0' : '8px')};
`;

export const LeftContainer = styled.div`
  width: 50%;
  height: 100%;
  border-right: 1.5px solid ${({ theme }) => theme.background};
`;

export const RightContainer = styled.div`
  width: 50%;
  height: 100%;
  border-left: 1.5px solid ${({ theme }) => theme.background};
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 16px;
  height: 28px;
  margin: 4px;
`;
