import styled from 'styled-components/native';
import { transparentize } from 'polished';
import { Button } from '../../Button';

export const Container = styled.View`
  width: 100%;
  padding: 16px;
  justify-content: space-between;
`;

export const SideBySide = styled.View`
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 24px;
`;

export const InputContainer = styled.View`
  margin-top: 32px;
`;

export const ConfirmButton = styled(Button)`
  margin-right: 16px;
  margin-top: 60px;
  width: 136px;
  height: 40px;
  align-self: flex-end;
`;

export const CheckBoxContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

interface CheckBoxButtonProps {
  isHovered?: boolean;
  isActive?: boolean;
}
export const CheckBoxButton = styled.TouchableOpacity<CheckBoxButtonProps>`
  width: 228px;
  height: 93px;
  border-radius: 20px;
  border-width: 2px;
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.white : theme.purpleBlue};
  background-color: ${({ theme, isHovered }) =>
    isHovered ? transparentize(0.9, theme.purpleBlue) : theme.blueishBlack};
  align-items: flex-end;
  padding: 16px;
`;
