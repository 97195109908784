import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { MainCouranteAction } from '../services/hooks/useQueryMainCouranteInfo';
import { useQueryMainCouranteActions } from '../services/hooks/useQueryMainCouranteActions';
import { useZonesContext, Zone } from './ZonesContext';
import { useAuthContext } from './AuthContext';
import { useTabsControlContext } from './TabsControlContext';
import { useFiltersContext } from './FiltersContext';
import { updateTabData, getTabData } from '../utils/tabApi';
import { useMainCouranteActionRealTime } from '../hooks/useMainCouranteActionRealTime';

export interface Location {
  location_name: string;
  zone: Zone;
}

interface ActivatedMainCouranteActionsContextData {
  listOfActivedMainCouranteActions: MainCouranteAction[];
  setListOfActivedMainCouranteActions: (list: MainCouranteAction[]) => void;
  activatedActionId?: string | null;
  updateActivatedActionId: (actionId: string | null) => Promise<void>;
}

interface ActivatedMainCouranteActionsContextProps {
  children: ReactNode;
}

const ActivatedMainCouranteActionsContext = createContext(
  {} as ActivatedMainCouranteActionsContextData,
);

function ActivatedMainCouranteActionsContextProvider({
  children,
}: ActivatedMainCouranteActionsContextProps) {
  const { user } = useAuthContext();
  const { filters } = useFiltersContext();
  const { selectedTab } = useTabsControlContext();
  const { addNewZone, removeZones } = useZonesContext();
  const [activatedActionId, setActivatedActionId] = useState<string | null>();
  const [
    listOfActivedMainCouranteActions,
    setListOfActivedMainCouranteActions,
  ] = useState<MainCouranteAction[]>([]);

  const { data: mainCouranteActions } = useQueryMainCouranteActions({
    team_id:
      user?.teams && user?.teams.length > 0 ? user?.teams[0].id : undefined,
    main_courantes: filters.mainCourante,
    closed: user?.organization.setup?.typeMainCouranteActionToShow === 'closed',
    active: !!user?.organization.setup?.showAllActions,
  });

  useMainCouranteActionRealTime({
    setListOfActivedMainCouranteActions,
    selectedTab,
    teamId:
      user?.teams && user?.teams.length > 0 ? user?.teams[0].id : undefined,
    filters,
  });

  const updateActivatedActionId = useCallback(
    async (actionId?: string | null) => {
      setActivatedActionId(actionId);
      await updateTabData(selectedTab, {
        activatedActionId: actionId,
      });
    },
    [selectedTab],
  );

  useEffect(() => {
    if (mainCouranteActions && mainCouranteActions.length > 0) {
      setListOfActivedMainCouranteActions(mainCouranteActions);
    }
  }, [mainCouranteActions]);

  useEffect(() => {
    async function loadData() {
      const tab = await getTabData(selectedTab);
      if (!tab) return;
      setActivatedActionId(tab.activatedActionId);
    }
    loadData();
  }, [selectedTab]);

  useEffect(() => {
    removeZones('main_courante_action');
    if (!activatedActionId) return;
    const action = listOfActivedMainCouranteActions.find(
      ({ id }) => id === activatedActionId,
    );
    if (!action?.zone) return;
    addNewZone(action.zone);
  }, [
    activatedActionId,
    addNewZone,
    listOfActivedMainCouranteActions,
    removeZones,
  ]);

  return (
    <ActivatedMainCouranteActionsContext.Provider
      value={{
        listOfActivedMainCouranteActions,
        setListOfActivedMainCouranteActions,
        activatedActionId,
        updateActivatedActionId,
      }}
    >
      {children}
    </ActivatedMainCouranteActionsContext.Provider>
  );
}

function useActivatedMainCouranteActionsContext(): ActivatedMainCouranteActionsContextData {
  const context = useContext(ActivatedMainCouranteActionsContext);
  return context;
}

export {
  ActivatedMainCouranteActionsContextProvider,
  useActivatedMainCouranteActionsContext,
};
