import { TemperatureLabel } from './styles';

interface AnalyticsTabsDynamicDataCardTemperatureLabelProps {
  label: string;
}

export const AnalyticsTabsDynamicDataCardTemperatureLabel = ({
  label,
}: AnalyticsTabsDynamicDataCardTemperatureLabelProps) => {
  return <TemperatureLabel>{label}</TemperatureLabel>;
};
