import styled from 'styled-components/native';
import { darken } from 'polished';
import { Caption } from '@nucleus/Typography';

interface ContainerButtonProps {
  closed: boolean;
  isHovered: boolean;
}

export const ContainerButton = styled.TouchableOpacity<ContainerButtonProps>`
  border-width: 1px;
  border-color: ${({ theme, closed, isHovered }) =>
    // eslint-disable-next-line no-nested-ternary
    closed
      ? isHovered
        ? darken(0.1, theme.red)
        : theme.red
      : isHovered
      ? darken(0.1, theme.eletricGreen)
      : theme.eletricGreen};
  border-radius: 5px;
  width: 85px;
  height: 16px;
  justify-content: center;
  align-items: center;
`;

interface ContainerProps {
  closed: boolean;
}

export const Container = styled.View<ContainerProps>`
  border-width: 1px;
  border-color: ${({ theme, closed }) =>
    closed ? theme.red : theme.eletricGreen};
  border-radius: 5px;
  width: 85px;
  height: 16px;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
`;
export const Text = styled(Caption)<ContainerProps>`
  color: ${({ theme, closed }) => (closed ? theme.red : theme.eletricGreen)};
`;
