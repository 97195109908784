import { Select, Option } from '@atoms/Select';
import { Body1 } from '@nucleus/Typography';
import { Container } from './styles';

interface AnalyticsTabsMainCouranteFlowRatioConfigureFormSelectMainCouranteProps {
  selectedMainCourante?: string;
  mainCourantesOptions: Option[];
  onSelectMainCourante: (value: string[]) => void;
}

export const AnalyticsTabsMainCouranteFlowRatioConfigureFormSelectMainCourante =
  ({
    selectedMainCourante,
    mainCourantesOptions,
    onSelectMainCourante,
  }: AnalyticsTabsMainCouranteFlowRatioConfigureFormSelectMainCouranteProps) => {
    return (
      <Container style={{ zIndex: 3 }}>
        <Body1 style={{ marginRight: 8 }}>Main Courante:</Body1>
        <Select
          selectedOptions={selectedMainCourante ? [selectedMainCourante] : []}
          style={{ zIndex: 1, maxWidth: 200 }}
          options={mainCourantesOptions}
          onChange={onSelectMainCourante}
        />
      </Container>
    );
  };
