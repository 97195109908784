import { useWidgetGridContext } from '@contexts/WidgetGridContext';
import { useMutationCreateWidget } from '@services/hooks/useMutationCreateWidget';
import { MapData, mapWidgetinitialData } from '@templates/MapWidget';
import { useNavigation } from '@react-navigation/native';

// types slugs "custom-event-list" "event-list" "map-widget"

const fakeInitialConfigData = {
  name: 'New Event Config',
  type_id: '97887a8d-92a6-4c29-b7fc-69b9e65df17e',
  user_id: '95cd32f1-26a4-4b93-ba06-d1867cccc523',
  config: {
    foo: 'bar',
  },
};

type ConfigTypes = { foo: string };

interface InitialConfig {
  name: string;
  type_id: string;
  user_id: string;
  config: ConfigTypes;
}

type DataTypes = MapData | { foo: string };

interface WidgetInitialData {
  grid_height: number;
  grid_width: number;
  data: DataTypes;
}

interface WidgetsInitialData {
  [key: string]: WidgetInitialData;
}

interface WidgetsInitialConfig {
  [key: string]: InitialConfig;
}

const widgetsInitialData: WidgetsInitialData = {
  'map-widget': mapWidgetinitialData,
  'fake-widget': { grid_height: 2, grid_width: 2, data: { foo: 'bar' } },
};

const initialConfigs: WidgetsInitialConfig = {
  'fake-widget': fakeInitialConfigData,
};

interface CreateWidgetBaseParams {
  name: string;
  page_id: string;
  type_id: string;
  type_slug: string;
  config_id?: string;
  is_configurable: boolean;
}

export const useCreateNewWidget = (pageId: string) => {
  const { navigate } = useNavigation();
  const { createNewWidget } = useWidgetGridContext();
  const mutationCreateWidget = useMutationCreateWidget({ page_id: pageId });

  const createMapWidget = async ({
    page_id,
    type_id,
    config_id,
    type_slug,
    name,
    is_configurable,
  }: CreateWidgetBaseParams) => {
    const initialData = widgetsInitialData[type_slug];

    const configData = {
      config_id,
      config:
        is_configurable && !config_id ? initialConfigs[type_slug] : undefined,
    };

    const newWidget = createNewWidget<DataTypes, InitialConfig>({
      name,
      page_id,
      type_id,
      ...initialData,
      ...configData,
    });

    const newCreatedWidget = await mutationCreateWidget.mutateAsync(newWidget);
    if (is_configurable && !config_id && newCreatedWidget.config_id) {
      navigate('WidgetConfig', { id: newCreatedWidget.config_id });
    }
  };

  return { createMapWidget };
};
