import styled from 'styled-components/native';

export const Container = styled.View`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: space-between;
  padding: 0 16px;
  margin-bottom: 16px;
`;

export const TitleContainer = styled.View`
  flex-direction: row;
`;
