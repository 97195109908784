/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from 'react-query';
import { api } from '../eventApi';

interface Action {
  id: string;
  name: string;
}

export async function getActionTypes(team_id?: string): Promise<Action[]> {
  if (!team_id) return [];
  try {
    const response = await api.get('/v1/action_types', {
      params: {
        team_id,
      },
    });
    return response.data;
  } catch (err) {
    return [];
  }
}

export function useQueryActionTypes(team_id?: string) {
  return useQuery(['action_types', team_id], () => getActionTypes(team_id), {
    enabled: !!team_id,
  });
}
