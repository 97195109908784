import React from 'react';
import { useWidgetGridContext, IWidget } from '@contexts/WidgetGridContext';
import { Button } from '@atoms/Button';
import { WidgetEditModeContainer } from './styles';

interface WidgetEditModeProps {
  onSaveWidgets: (widgets: IWidget[]) => void;
}
export const WidgetEditMode = ({ onSaveWidgets }: WidgetEditModeProps) => {
  const { setIsEditMode, cancelEditMode, widgets } = useWidgetGridContext();

  const handleSave = () => {
    onSaveWidgets(widgets);
    setIsEditMode(false);
  };

  return (
    <WidgetEditModeContainer>
      <Button
        onPress={() => {
          cancelEditMode();
          setIsEditMode(false);
        }}
      >
        Cancel edit
      </Button>
      <Button style={{ marginLeft: 20 }} onPress={handleSave}>
        Save changes
      </Button>
    </WidgetEditModeContainer>
  );
};
