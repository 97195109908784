import styled from 'styled-components/native';
import { Overline } from '@nucleus/Typography';

export const Container = styled.View`
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 32px;
  width: 150px;
`;

export const Label = styled(Overline)`
  color: ${({ theme }) => theme.purpleBlue};
`;

interface ColorCircleProps {
  color: string;
}
export const ColorCircle = styled.View<ColorCircleProps>`
  margin-top: 16px;
  width: 38px;
  height: 38px;
  border-radius: 19px;
  background-color: ${({ color }) => color};
`;
