import styled from 'styled-components/native';

export const BoardContent = styled.View`
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  overflow-y: scroll;
`;
