import { useRef } from 'react';
import { TouchableOpacity } from 'react-native';
import { useHover } from 'react-native-web-hooks';
import { Title } from './styles';

interface SelectableTitleProps {
  title: string;
  selected?: boolean;
  onPress: () => void;
}

export const SelectableTitle = ({
  title,
  selected,
  onPress,
}: SelectableTitleProps) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <TouchableOpacity onPress={onPress}>
      <Title
        selected={selected}
        isHovered={isHovered}
        numberOfLines={1}
        ref={ref}
      >
        {title}
      </Title>
    </TouchableOpacity>
  );
};
