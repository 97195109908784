import { useState, useMemo } from 'react';
import { RndDragEvent, DraggableData } from 'react-rnd';
import { EventInfoHeader } from '@organisms/EventInfoHeader';
import { EventInfoContents } from '@organisms/EventInfoContents';
import { EventInfoOverview } from '@organisms/EventInfoOverview';
import { EventInfoDetail } from '@organisms/EventInfoDetail';
import { useEventsContext } from '@contexts/EventsContext';
import { useTabsControlContext } from '@contexts/TabsControlContext';
import { useFiltersContext } from '@contexts/FiltersContext';
import { useQueryFiltredEvents } from '@services/hooks/useQueryFiltredEvents';
import { useQueryEventInfo } from '@services/hooks/useQueryEventInfo';
import { Size } from '../../../utils/tabApi';
import { useScreenControl } from '../../../hooks/useScreenControl';

import {
  Container,
  ContentContainer,
  LeftContainer,
  RightContainer,
} from './styles';

interface EventInfoProps {
  parentSize: Size;
}

export const EventInfo = ({ parentSize }: EventInfoProps) => {
  const { selectedTab } = useTabsControlContext();
  const { filters, getFiltersInParams } = useFiltersContext();
  const { updateActivatedEventId, activatedEventId } = useEventsContext();

  const { data: events } = useQueryFiltredEvents(
    selectedTab,
    filters,
    getFiltersInParams,
  );

  const event = useMemo(() => {
    const evt = events?.find(ev => String(ev.id) === String(activatedEventId));
    if (evt?.type === 'normal') return evt;
    return undefined;
  }, [activatedEventId, events]);

  const {
    screenSize,
    screenPosition,
    isFullscreen,
    handleFullScreen,
    setScreenSize,
    setScreenPosition,
  } = useScreenControl({ parentSize });

  const [selectedContent, setSelectedContent] = useState<string>();

  const { data, isLoading } = useQueryEventInfo(event?.id || 0);

  if (!event) return null;

  return (
    <Container
      bounds="parent"
      minWidth={750}
      minHeight={450}
      size={screenSize}
      position={screenPosition}
      enableResizing={!isFullscreen}
      disableDragging={isFullscreen}
      onDragStop={(e: RndDragEvent, d: DraggableData) => {
        setScreenPosition({
          x: d.x,
          y: d.y,
        });
      }}
      onResize={(
        e: MouseEvent | TouchEvent,
        dir: unknown,
        elementRef: HTMLElement,
      ) => {
        setScreenSize({
          width: elementRef.offsetWidth,
          height: elementRef.offsetHeight,
        });
      }}
    >
      <EventInfoHeader
        onClosePress={() => updateActivatedEventId(null)}
        onFullScreenPress={handleFullScreen}
      />

      <ContentContainer>
        <LeftContainer>
          <EventInfoOverview
            media={data?.media}
            isLoading={isLoading}
            tags={event.tags}
            title={event.title}
            intensity={event.intensity}
            category={event.category}
            updatedDate={event.formatted_updated_at}
            createdDate={event.formatted_updated_at}
            locationName={event.location_name}
          />
          <EventInfoContents
            isLoading={isLoading}
            sources_count={data?.sources_count}
            onContentSelect={setSelectedContent}
            selectedContent={selectedContent}
          />
        </LeftContainer>

        <RightContainer>
          {selectedContent && <EventInfoDetail contentId={selectedContent} />}
        </RightContainer>
      </ContentContainer>
    </Container>
  );
};
