import { useRef, useState, KeyboardEvent } from 'react';
import { useTheme } from 'styled-components/native';
import { Caption } from '@nucleus/Typography';
import { Icon } from '@nucleus/Icon';
import { Portal } from '@gorhom/portal';
import DayPicker from 'react-day-picker';
import { format } from 'date-fns';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

import {
  Container,
  InputText,
  InputContainer,
  ErrorContainer,
  ErrorText,
  Picker,
} from './styles.web';
import scheduleIcon from '../../../assets/icons/schedule.svg';
import { DatePickerProps } from './types';
import errorIcon from '../../../assets/icons/error_outline.svg';

export const DatePicker = ({
  date,
  placeholder,
  onChange,
  error,
  onBlur,
}: DatePickerProps) => {
  const theme = useTheme();
  const [isOpened, setIsOpened] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const pickerRef = useRef<DayPicker>(null);

  useOutsideClick({
    componentRef: pickerRef,
    yOffset: 24,
    onOutsideClick: () => setIsOpened(false),
  });

  const handleDayClick = (newDate: Date) => {
    onChange(newDate);
    setIsOpened(false);
    if (onBlur) onBlur();
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      onChange(undefined);
    }
  };

  return (
    <Container onClick={() => setIsOpened(old => !old)}>
      {date && <Caption style={{ color: '#6a6a9f' }}>{placeholder}</Caption>}

      <InputContainer ref={containerRef}>
        <InputText
          type="text"
          value={date ? format(date, 'MM/dd/yyyy') : ''}
          placeholder={placeholder}
          onKeyDown={handleKeyPress}
        />
        <Icon as={scheduleIcon} />
      </InputContainer>

      {containerRef.current && isOpened && (
        <Portal>
          <Picker
            parentElement={containerRef.current}
            onDayClick={handleDayClick}
            selectedDays={[date]}
            ref={pickerRef}
            onBlur={onBlur}
          />
        </Portal>
      )}
      {error && (
        <ErrorContainer>
          <Icon
            as={errorIcon}
            testID="input-error-icon"
            fill={theme.red}
            width={16}
            height={16}
            viewBox="0 0 24 24"
          />
          <ErrorText>{error}</ErrorText>
        </ErrorContainer>
      )}
    </Container>
  );
};
