import styled, { css } from 'styled-components/native';
import { darken } from 'polished';
import { Body1 } from '@nucleus/Typography';

interface TitleProps {
  isHovered?: boolean;
  selected?: boolean;
}

export const Title = styled(Body1)<TitleProps>`
  ${({ isHovered, theme, selected }) => {
    if (selected && isHovered) {
      return css`
        color: ${darken(0.2, theme.yellow)};
      `;
    }
    if (selected) {
      return css`
        color: ${theme.yellow};
      `;
    }
    if (isHovered) {
      return css`
        color: ${darken(0.2, theme.white)};
      `;
    }
    return css`
      color: ${theme.white};
    `;
  }}
`;
