import { useTranslation } from 'react-i18next';
import { EventsFilterTypeSelectorButton } from '@molecules/EventsFilterTypeSelectorButton';

import { Container } from './styles';

const BASICS_FILTERS = 'basics';
const ADVANCED_FILTERS = 'advanced';

interface EventsFilterTypeSelectorProps {
  onBasicFilterPress: () => void;
  onAdvancedFilterPress: () => void;
  filterType: string;
}

export const EventsFilterTypeSelector = ({
  onBasicFilterPress,
  onAdvancedFilterPress,
  filterType,
}: EventsFilterTypeSelectorProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <EventsFilterTypeSelectorButton
        name={t(BASICS_FILTERS)}
        onPress={onBasicFilterPress}
        isActive={filterType === BASICS_FILTERS}
      />
      <EventsFilterTypeSelectorButton
        name={t(ADVANCED_FILTERS)}
        onPress={onAdvancedFilterPress}
        isActive={filterType === ADVANCED_FILTERS}
      />
    </Container>
  );
};
