import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToastContext } from '@contexts/ToastContext';
import { queryClient } from '@services/queryClient';
import { api } from '@services/userManagementApi';

interface updatePageData {
  name: string;
}
interface PageData {
  data: updatePageData;
  page_id: string;
}

interface CreateWorkspaceMutation {
  workspace_id: string;
}

interface PageResponse {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export async function updatePage({
  page_id,
  data,
}: PageData): Promise<PageResponse> {
  const response = await api.put(`/v1/pages/${page_id}`, data);
  return response.data;
}

export const useMutationUpdatePage = ({
  workspace_id,
}: CreateWorkspaceMutation) => {
  const { dispatchToast } = useToastContext();
  const { t } = useTranslation();

  return useMutation(
    async (data: PageData) => {
      const newPage = await updatePage(data);
      return newPage;
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['workspace', workspace_id]);
        dispatchToast({
          message: t('success_update_page'),
          type: 'success',
        });
      },
      onError: () => {
        dispatchToast({
          message: t('error_update_page'),
          type: 'error',
        });
      },
    },
  );
};
