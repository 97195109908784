import { Tag } from '@atoms/Tag';
import { Container } from './styles';

interface EventCardTagsProps {
  tags: string[];
}

export const EventCardTags = ({ tags }: EventCardTagsProps) => {
  return (
    <Container>
      {tags.map((tag: string) => (
        <Tag key={tag} tag={tag.substring(0, 16)} />
      ))}
    </Container>
  );
};
