/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { getMinutes, getHours, setMinutes, setHours } from 'date-fns';
import { Container, SideBySide, ConfirmButton } from './styles';
import { Input } from '../../Input';
import { DatePicker } from '../../DatePicker';
import { TimePicker } from '../../TimePicker';
import { Select } from '../../Select';
import { MultiLineInput } from '../../MultiLineInput';
import { AttachmentsInput } from '../../AttachmentsInput';
import { CreateEventLocation } from '../CreateEventLocation';
import { useMutationMainCourante } from '../../../services/hooks/useMutationMainCourante';
import { useZonesContext } from '../../../contexts/ZonesContext';
import { useCreateLocationContext } from '../../../contexts/CreateLocationContext';
import { queryClient } from '../../../services/queryClient';
import ErrorIcon from '../../../assets/icons/error_outline.svg';
import { CreateLocationButton } from '../CreateLocationButton';
import { useTabsControlContext } from '../../../contexts/TabsControlContext';

interface NewEventProps {
  title: string;
  teamId: string;
  eventId: string;
  onClose: () => void;
  setSeletedEvent: (event: {
    name: string;
    id: string;
    team_id: string;
  }) => void;
}

interface Location {
  location_color?: string;
  location_name?: string;
  location_coordinates: any;
}

interface CreateEventFormData {
  name: string;
  shared: string[];
  priority: number;
  time: Date;
  date: Date;
  desc: string;
  location: Location;
}

const relevances = [
  { value: '1', label: 'low' },
  { value: '2', label: 'medium' },
  { value: '3', label: 'high' },
];

export const NewEvent = ({
  title,
  teamId,
  eventId,
  setSeletedEvent,
  onClose,
}: NewEventProps) => {
  const { t } = useTranslation();
  const { deleteZoneById } = useZonesContext();
  const { selectedTab } = useTabsControlContext();
  const {
    setCreateLocationOpened,
    selectedLocation,
    setLocationId,
    setLocationZoneType,
  } = useCreateLocationContext();
  const useQuery = useMutationMainCourante(teamId);

  const createEventFormValidation = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t('name_required')),
        // shared: yup.array().of(yup.string()),
        priority: yup.number().required(t('priority_required')),
        time: yup.date().required(t('time_required')),
        date: yup.date().required(t('date_required')),
        desc: yup.string(),
        location: yup.object().shape({
          location_name: yup.string(),
          location_coordinates: yup.object().shape({
            type: yup.string().required(),
            coordinates: yup.mixed().when('type', {
              is: 'Point',
              then: yup.array().of(yup.number()),
              otherwise: yup.mixed().when('type', {
                is: 'LineString',
                then: yup.array().of(yup.array(yup.number())),
                otherwise: yup
                  .array()
                  .of(yup.array().of(yup.array().of(yup.number()))),
              }),
            }),
          }),
        }),
      }),
    [t],
  );

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<CreateEventFormData>({
    resolver: yupResolver(createEventFormValidation),
  });

  const onSubmit = async ({
    name,
    desc,
    date,
    time,
    priority,
    location,
  }: CreateEventFormData) => {
    const hour = getHours(time);
    const minute = getMinutes(time);

    let dateWithTime = setMinutes(date, minute);
    dateWithTime = setHours(dateWithTime, hour);
    try {
      const response = await useQuery.mutateAsync({
        updateCouranteId: eventId,
        updateCourante: {
          //    name,
          desc,
          date: dateWithTime,
          priority,
          //  team_id: teamId,
          location_color: location.location_color,
          location_name: location.location_name,
          location_coordinates: location.location_coordinates,
        },
        type: 'update',
      });

      setSeletedEvent({
        id: eventId,
        name: title,
        team_id: teamId,
      });
      queryClient.invalidateQueries(['filtredMainCourantes', selectedTab]);
      deleteZoneById(eventId);
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    if (selectedLocation) {
      setValue('location', {
        location_color: selectedLocation.location_color,
        location_name: selectedLocation.location_name,
        location_coordinates: selectedLocation.zone.geometry,
      });
    }
  }, [selectedLocation, setValue]);

  useEffect(() => {
    if (eventId && setLocationId) {
      setLocationId(`${eventId}_creating`);
      setLocationZoneType('main_courante');
    }
  }, [eventId, setLocationId, setLocationZoneType]);

  return (
    <Container>
      <SideBySide style={{ zIndex: 3 }}>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              placeholder={t('type_main_courante_title')}
              style={{ maxWidth: 270 }}
              value={value}
              onChangeText={onChange}
              error={errors.name?.message}
              editable={false}
            />
          )}
          name="name"
          defaultValue={title}
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              style={{ maxWidth: 200 }}
              placeholder={t('date')}
              date={value}
              onChange={onChange}
              error={errors.date?.message}
            />
          )}
          defaultValue={new Date()}
          name="date"
        />
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <TimePicker
              placeholder={t('hour')}
              onChange={onChange}
              value={value ? moment(value) : undefined}
              error={errors.time?.message}
            />
          )}
          defaultValue={new Date()}
          name="time"
        />
      </SideBySide>
      <SideBySide style={{ zIndex: 2, marginTop: 24 }}>
        <CreateLocationButton
          selectedLocation={selectedLocation}
          setCreateLocationOpened={setCreateLocationOpened}
          error={errors.location && t('location_required')}
        />
        {/*
        <Select
          style={{ maxWidth: 200, zIndex: 1 }}
          selectedOptions={[]}
          placeholder={t('shared_with')}
          options={[]}
          onChange={() => {}}
        />
        */}
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select
              style={{ maxWidth: 200 }}
              placeholder={t('priority')}
              options={relevances}
              selectedOptions={value ? [String(value)] : []}
              onChange={relevance => onChange(relevance[0])}
              error={errors.priority?.message}
            />
          )}
          name="priority"
        />
      </SideBySide>
      <SideBySide>
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <MultiLineInput
              style={{ maxWidth: 550 }}
              label={t('more_information')}
              placeholder={t('type_additional_information')}
              value={value}
              onChangeText={onChange}
              error={errors.desc?.message}
            />
          )}
          name="desc"
        />
      </SideBySide>
      <ConfirmButton onPress={handleSubmit(onSubmit)} loading={isSubmitting}>
        {t('confirm')}
      </ConfirmButton>
    </Container>
  );
};
