import styled from 'styled-components/native';
import { Platform } from 'react-native';

export const InputText = styled.TextInput`
  height: unset;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.blue};
  padding: 2px 4px;
  resize: none;

  color: ${({ theme }) => theme.white};
  font-size: ${Platform.OS === 'web' ? '1.4rem' : '14px'};
  line-height: ${Platform.OS === 'web' ? '2.0rem' : '20px'};
  font-weight: 400;
  font-family: ${Platform.OS === 'web' ? 'Open Sans' : 'OpenSans_400Regular'};
  letter-spacing: ${Platform.OS === 'web' ? '-0.044rem' : '-0.44px'};
  color: ${({ theme }) => theme.white};
`;
