import { AnalyticsTabsMainCouranteFlowRatioConfigureFormSelectMainCourante } from '@molecules/AnalyticsTabsMainCouranteFlowRatioConfigureFormSelectMainCourante';
import {
  AnalyticsTabsMainCouranteFlowRatioConfigureFormActionTypeLists,
  ActionData,
} from '@molecules/AnalyticsTabsMainCouranteFlowRatioConfigureFormActionTypeLists';
import { Option } from '@atoms/Select';
import { Container } from './styles';

interface AnalyticsTabsMainCouranteFlowRatioConfigureFormProps {
  selectedMainCourante?: string;
  mainCourantesOptions: Option[];
  onSelectMainCourante: (value: string[]) => void;
  listOfInputs: ActionData[];
  onAddInputPress: () => void;
  onRemoveInputPress: (value: string) => void;
  listOfOutputs: ActionData[];
  onAddOutputPress: () => void;
  onRemoveOutputPress: (value: string) => void;
}

export const AnalyticsTabsMainCouranteFlowRatioConfigureForm = ({
  selectedMainCourante,
  mainCourantesOptions,
  onSelectMainCourante,
  listOfInputs,
  onAddInputPress,
  onRemoveInputPress,
  listOfOutputs,
  onAddOutputPress,
  onRemoveOutputPress,
}: AnalyticsTabsMainCouranteFlowRatioConfigureFormProps) => {
  return (
    <Container>
      <AnalyticsTabsMainCouranteFlowRatioConfigureFormSelectMainCourante
        selectedMainCourante={selectedMainCourante}
        mainCourantesOptions={mainCourantesOptions}
        onSelectMainCourante={onSelectMainCourante}
      />

      <AnalyticsTabsMainCouranteFlowRatioConfigureFormActionTypeLists
        listOfActionTypes={listOfInputs}
        label="Inputs:"
        onRemoveActionFromList={onRemoveInputPress}
        onAddActionFromList={onAddInputPress}
        limitOfActionsOnList={3}
      />

      <AnalyticsTabsMainCouranteFlowRatioConfigureFormActionTypeLists
        listOfActionTypes={listOfOutputs}
        label="Outputs:"
        onRemoveActionFromList={onRemoveOutputPress}
        onAddActionFromList={onAddOutputPress}
        limitOfActionsOnList={3}
      />
    </Container>
  );
};
