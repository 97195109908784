/* eslint-disable @typescript-eslint/no-explicit-any */
import Chart from 'react-apexcharts';
import { HistoricalDataRadialBarChartProps } from './types';

const options = {
  plotOptions: {
    radialBar: {
      offsetY: 0,
      startAngle: 0,
      endAngle: 270,
      hollow: {
        margin: 5,
        size: '30%',
        background: 'transparent',
        image: undefined,
      },
      track: {
        show: true,
        background: '#05050F',
        opacity: 1,
        margin: 5,
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 3,
          opacity: 0.5,
        },
      },
      dataLabels: {
        name: {
          show: true,
          fontSize: '16px',
        },
        value: {
          show: true,
          fontSize: '22px',
          color: 'white',
        },
      },
    },
  },
  legend: {
    show: true,
    floating: true,
    fontSize: '12px',
    position: 'left',
    offsetX: 30,
    offsetY: 0,
    labels: {
      useSeriesColors: true,
    },
    markers: {
      width: 6,
      height: 6,
      strokeWidth: 0,
      strokeColor: '#fff',
      fillColors: undefined,
      radius: 6,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
    formatter(seriesName: string, opts: any) {
      return `${seriesName}:  ${opts.w.globals.series[opts.seriesIndex]}`;
    },
    itemMargin: {
      horizontal: 0,
      vertical: -2,
    },
  },
  colors: [
    '#FF7D05',
    '#023AFF',
    '#7517F8',
    '#FF2D2E',
    '#4DFFDF',
    '#8AFF6C',
    '#4DA1FF',
    '#FFD422',
  ],
  labels: [],
  stroke: {
    lineCap: 'round',
  },

  responsive: [
    {
      breakpoint: 1440,
      options: {
        chart: {
          width: 390,
        },
        legend: {
          fontSize: '12px',
          offsetY: 0,
          offsetX: 8,
          markers: {
            width: 6,
            height: 6,
            radius: 2,
          },
          itemMargin: {
            horizontal: 0,
            vertical: -3.5,
          },
        },
      },
    },
    {
      breakpoint: 1366,
      options: {
        chart: {
          width: 300,
        },
        legend: {
          fontSize: '9px',
          offsetY: -6,
          offsetX: 8,
          markers: {
            width: 4,
            height: 4,
            radius: 2,
          },
          itemMargin: {
            horizontal: 0,
            vertical: -4.8,
          },
        },
      },
    },
  ],
};

export const HistoricalDataRadialBarChart = ({
  series,
  labels,
  size,
}: HistoricalDataRadialBarChartProps) => {
  return (
    <Chart
      type="radialBar"
      width={450}
      options={
        {
          ...options,
          labels,
          plotOptions: {
            ...options.plotOptions,
            radialBar: {
              ...options.plotOptions.radialBar,
              hollow: { ...options.plotOptions.radialBar.hollow, size },
            },
          },
        } as any
      }
      series={series}
    />
  );
};
