import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useTheme } from 'styled-components/native';
import { SignIn } from '../screens/SignIn';
import { ForgetPassword } from '../screens/ForgetPassword';
import { ResetPassword } from '../screens/ResetPassword';

const AuthStack = createStackNavigator();

function AuthNavigator() {
  const theme = useTheme();
  return (
    <AuthStack.Navigator
      initialRouteName="SignIn"
      screenOptions={{
        headerShown: false,
        headerTintColor: theme.white,
        cardStyle: { backgroundColor: theme.background },
      }}
    >
      <AuthStack.Screen name="SignIn" component={SignIn} />
      <AuthStack.Screen name="ForgetPassword" component={ForgetPassword} />
      <AuthStack.Screen name="ResetPassword" component={ResetPassword} />
    </AuthStack.Navigator>
  );
}

export default AuthNavigator;
