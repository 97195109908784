import styled from 'styled-components/native';
import { darken } from 'polished';
import { Caption } from '@nucleus/Typography';

interface TextProps {
  isHovered?: boolean;
  selected?: boolean;
}

export const Text = styled(Caption)<TextProps>`
  color: ${({ isHovered, theme }) =>
    isHovered ? darken(0.2, theme.white) : theme.white};
  border-bottom-width: ${({ selected }) => (selected ? '2px' : '0')};
  border-color: ${({ theme }) => theme.blue};
`;
