import { useMemo } from 'react';
import { useTheme } from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { AnalyticsTabsDynamicDataCardVigilanceTooltipText } from '@molecules/AnalyticsTabsDynamicDataCardVigilanceTooltipText';
import { AnalyticsTabsDynamicDataCardTitle } from '@molecules/AnalyticsTabsDynamicDataCardTitle';
import { AnalyticsTabsDynamicDataCardVigilanceValue } from '@molecules/AnalyticsTabsDynamicDataCardVigilanceValue';
import { Tooltip } from '@atoms/Tooltip';
import { useWindowDimensions, Linking } from 'react-native';
import { Container, VigilancesContainer } from './styles';
import vigilanceIcon from '../../../assets/icons/vigilance.svg';
import { WeatherAlert } from '../../../services/hooks/useQueryWeatherInfo';

type ColorType = 'green' | 'yellow' | 'orange' | 'red' | 'grey';

interface AnalyticsTabsDynamicDataVigilanceCardProps {
  alerts: WeatherAlert[];
  lastUpdateFormatted: string;
  sourceUrl: string | null;
}

const DataColor = {
  green: 'eletricGreen' as const,
  yellow: 'lightYellow' as const,
  orange: 'yellow' as const,
  red: 'red' as const,
  grey: 'grey' as const,
};

/*
const DataAlert = {
  green: 'vigilance_green_alert_message',
  yellow: 'vigilance_light_yellow_alert_message',
  orange: 'vigilance_yellow_alert_message',
  red: 'vigilance_red_alert_message',
};
*/

export const AnalyticsTabsDynamicDataVigilanceCard = ({
  alerts,
  lastUpdateFormatted,
  sourceUrl,
}: AnalyticsTabsDynamicDataVigilanceCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { width } = useWindowDimensions();

  const isSmall = useMemo(() => width < 1700, [width]);

  return (
    <Container small={isSmall}>
      <AnalyticsTabsDynamicDataCardTitle
        icon={vigilanceIcon}
        title={t('vigilance')}
      />

      <VigilancesContainer
        small={isSmall}
        onPress={() => (sourceUrl ? Linking.openURL(sourceUrl) : null)}
      >
        {alerts.map(alert => (
          <>
            {alert.tags.map(tag => (
              <Tooltip
                content={
                  <AnalyticsTabsDynamicDataCardVigilanceTooltipText
                    message={alert.description}
                    source={alert.source}
                    lastUpdateFormatted={lastUpdateFormatted}
                  />
                }
                place="top"
              >
                <AnalyticsTabsDynamicDataCardVigilanceValue
                  label={t(tag).toUpperCase()}
                  color={theme[DataColor[alert.color as ColorType]]}
                />
              </Tooltip>
            ))}
          </>
        ))}
      </VigilancesContainer>
    </Container>
  );
};
