import { H6 } from '@nucleus/Typography';
import { useTranslation } from 'react-i18next';
import { AddressFilterInput, Location } from '@molecules/AddressFilterInput';
import { Spinner } from '@atoms/Spinner';
import { Container, TitleContainer } from './styles';

interface AnalyticsTabsDynamicDataHeaderProps {
  location: Location | undefined;
  changeLocation: (data: Location | undefined) => void;
  isFetching?: boolean;
}

export const AnalyticsTabsDynamicDataHeader = ({
  location,
  changeLocation,
  isFetching,
}: AnalyticsTabsDynamicDataHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <TitleContainer>
        <H6>{t('dynamic_data')}</H6>
        {isFetching && <Spinner size="small" style={{ marginLeft: 8 }} />}
      </TitleContainer>
      <AddressFilterInput
        placeholder={t('type_address')}
        val={location}
        onSelectAddress={changeLocation}
        style={{ maxWidth: 200 }}
      />
    </Container>
  );
};
