/* eslint-disable @typescript-eslint/no-explicit-any */
import { Video } from 'expo-av';
import { Hoverable } from 'react-native-web-hooks';
import { Container, RemoveFile } from './styles';
import CloseIcon from '../../assets/icons/close.svg';
import { Image } from '../Image';
import { usePreviewContext } from '../../contexts/PreviewContext';

interface PreviewMiniatureProps {
  source: any;
  type: 'image' | 'video' | undefined;
  onRemove?: () => void;
  style?: any;
}

export const PreviewMiniature = ({
  source,
  type,
  onRemove,
  style,
}: PreviewMiniatureProps) => {
  const { openPreview } = usePreviewContext();
  return (
    <Container
      style={style}
      onPress={() =>
        openPreview({
          source,
          type,
        })
      }
    >
      {type === 'video' ? (
        <Video
          source={{
            uri: source,
          }}
        />
      ) : (
        <Image source={source} />
      )}

      {onRemove && (
        <Hoverable>
          {isHovered => (
            <RemoveFile onPress={onRemove} isHovered={isHovered}>
              <CloseIcon viewBox="0 0 30 30" width="12" fill="#fff" />
            </RemoveFile>
          )}
        </Hoverable>
      )}
    </Container>
  );
};
