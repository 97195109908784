import styled from 'styled-components/native';
import { IconButton } from '@atoms/IconButton';
import { darken } from 'polished';

interface ButtonProps {
  isHovered?: boolean;
}
export const Button = styled(IconButton)<ButtonProps>`
  position: absolute;
  right: 72px;
  bottom: 56px;
  background-color: ${({ theme, isHovered }) =>
    isHovered ? darken(0.1, theme.purpleBlue) : theme.purpleBlue};
  width: 64px;
  height: 64px;
  border-radius: 36px;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;
