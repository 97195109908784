import slugify from 'slugify';

export const meteorFranceRegions = [
  'ain',
  'aisne',
  'allier',
  'alpes-de-haute-provence',
  'hautes-alpes',
  'alpes-maritimes',
  'ardeche',
  'ardennes',
  'ariege',
  'aube',
  'aude',
  'aveyron',
  'bouches-du-rhone',
  'calvados',
  'cantal',
  'charente',
  'charente-maritime',
  'cher',
  'correze',
  'corse-du-sud',
  'haute-corse',
  'cote-d-or',
  'cotes-d-armor',
  'creuse',
  'dordogne',
  'doubs',
  'drome',
  'eure',
  'eure-et-loir',
  'finistere',
  'gard',
  'haute-garonne',
  'gers',
  'gironde',
  'herault',
  'ille-et-vilaine',
  'indre',
  'indre-et-loire',
  'isere',
  'jura',
  'landes',
  'loir-et-cher',
  'loire',
  'haute-loire',
  'loire-atlantique',
  'loiret',
  'lot',
  'lot-et-garonne',
  'lozere',
  'maine-et-loire',
  'manche',
  'marne',
  'haute-marne',
  'mayenne',
  'meurthe-et-moselle',
  'meuse',
  'morbihan',
  'moselle',
  'nievre',
  'nord',
  'oise',
  'orne',
  'pas-de-calais',
  'puy-de-dome',
  'pyrenees-atlantiques',
  'hautes-pyrenees',
  'pyrenees-orientales',
  'bas-rhin',
  'haut-rhin',
  'rhone',
  'haute-saone',
  'saone-et-loire',
  'sarthe',
  'savoie',
  'haute-savoie',
  'paris',
  'seine-maritime',
  'seine-et-marne',
  'yvelines',
  'deux-sevres',
  'somme',
  'tarn',
  'tarn-et-garonne',
  'var',
  'vaucluse',
  'vendee',
  'vienne',
  'haute-vienne',
  'vosges',
  'yonne',
  'terr-de-belfort',
  'essonne',
  'hauts-de-seine',
  'seine-st-denis',
  'val-de-marne',
  'val-d-oise',
  'andorre',
];

export const getSourceLink = (place: string) => {
  const slugifiedPlace = slugify(place, {
    replacement: '-', // replace spaces with replacement character, defaults to `-`
    remove: undefined, // remove characters that match regex, defaults to `undefined`
    lower: true, // convert to lower case, defaults to `false`
    strict: false, // strip special characters except replacement, defaults to `false`
    locale: 'fr', // language code of the locale to use
    trim: true, // trim leading and trailing replacement chars, defaults to `true`
  }).replace("'", '-');

  if (meteorFranceRegions.includes(slugifiedPlace)) {
    return `https://vigilance.meteofrance.fr/fr/${slugifiedPlace}`;
  }
  return null;
};
